import React from 'react';
import Typography from '~/components/Typography';
import { IFormula } from '~/services/parallel/formulas.types';
import { IFormulaTypeEnum } from '~/services/parallel/formulas.types';

const FormulaList = ({
  value,
  formulaList,
  onSelectAttribute,
  highlightedFormula,
  setHighlightedFormula,
}: {
  value: string;
  formulaList: IFormula[];
  onSelectAttribute: (formula: IFormula) => void;
  highlightedFormula: {
    formula?: IFormula;
    index: number;
  };
  setHighlightedFormula: React.Dispatch<
    React.SetStateAction<{
      formula?: IFormula;
      index: number;
    }>
  >;
}): React.ReactNode => {
  const getPaddingStyle = ({
    formulaListLength,
    formulaIndex,
  }: {
    formulaListLength: number;
    formulaIndex: number;
  }): string => {
    if (formulaListLength === 1) {
      return 'py-3';
    } else if (formulaIndex === 0) {
      return 'pt-3 pb-2.5';
    } else if (formulaIndex > 0 && formulaIndex === formulaListLength - 1) {
      return 'pt-2.5 pb-3';
    } else {
      return 'py-2.5';
    }
  };
  return (
    <div
      data-testid="formula-list"
      className={`absolute flex flex-col z-20 shadow-xl rounded-xl overflow-hidden text-nowrap ${value.length ? '' : 'hidden'}`}
    >
      {formulaList.map((formula, index) => (
        <div
          className={`px-6 ${formula.type === IFormulaTypeEnum.Expense ? 'py-1 items-center justify-between' : 'py-2'} flex flex-nowrap  ${getPaddingStyle({ formulaListLength: formulaList.length, formulaIndex: index })} ${index === highlightedFormula.index ? 'bg-neutral-15' : 'bg-white'} hover:bg-neutral-15`}
          onClick={(e) => {
            e.stopPropagation();
            onSelectAttribute(formula);
            setHighlightedFormula({ formula: undefined, index: 0 });
          }}
          onMouseDown={(e) => {
            e.stopPropagation();
          }}
          onMouseUp={(e) => {
            e.stopPropagation();
          }}
          key={formula.uuid}
        >
          {formula.recipe.name}
          {formula.type === IFormulaTypeEnum.Expense && (
            <div className="bg-neutral-25 px-3 py-1 rounded-full ml-2">
              <Typography color="empty">Expense</Typography>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default FormulaList;
