import React, { RefObject, forwardRef, useCallback, useContext } from 'react';
import TimeseriesOptions from './TimeseriesOptions';
import { TimeModifierFunctionEnum } from '../../entity/schemas';
import isEqual from 'lodash.isequal';
import { FormulaBuilderInputContext } from './FormulaBuilderInputContext';
import { IFormula } from '~/services/parallel/formulas.types';
import { IUpdateTimeModifier } from '../../entity/types';

const FormulaElement = forwardRef<
  HTMLDivElement,
  {
    selectedFormula: IFormula;
    selectable?: boolean;
    timeModifier: {
      function?: TimeModifierFunctionEnum;
      period?: number;
    };
    formulaTextValue: string;
    handleUpdateTimeModifier?: ({
      timeModifier,
      formulaForUpdate,
      formulaTextValue,
      refToUpdate,
    }: IUpdateTimeModifier) => void;
    formulaIndex?: number;
  }
>(({ selectedFormula, selectable, formulaTextValue, timeModifier, handleUpdateTimeModifier, formulaIndex }, ref) => {
  const { inputPosition, setInputPosition, segmentToDelete, setSegmentToDelete, value, updatedFormula, variables } =
    useContext(FormulaBuilderInputContext);
  let timeSeriesValue = 'This Month';
  if ('function' in timeModifier && timeModifier.function) {
    switch (timeModifier.function) {
      case TimeModifierFunctionEnum.Previous: {
        switch (timeModifier.period) {
          case 1:
            timeSeriesValue = `Last Month`;
            break;
          case 2:
            timeSeriesValue = `2 Months Ago`;
            break;
          case 3:
            timeSeriesValue = `3 Months Ago`;
            break;
          case 4:
            timeSeriesValue = `4 Months Ago`;
            break;
          case 5:
            timeSeriesValue = `5 Months Ago`;
            break;
          case 6:
            timeSeriesValue = `6 Months Ago`;
            break;
          case 7:
            timeSeriesValue = `7 Months Ago`;
            break;
          case 8:
            timeSeriesValue = `8 Months Ago`;
            break;
          case 9:
            timeSeriesValue = `9 Months Ago`;
            break;
          case 10:
            timeSeriesValue = `10 Months Ago`;
            break;
          case 11:
            timeSeriesValue = `11 Months Ago`;
            break;
          case 12:
            timeSeriesValue = `12 Months Ago`;
            break;
          default:
            throw new Error('Invalid time modifier');
        }
        break;
      }
      default:
        throw new Error('Invalid time modifier');
    }
  }

  const updateTimeModifier = useCallback(
    (
      timeModifier:
        | 'This Month'
        | 'Last Month'
        | '2 Months Ago'
        | '3 Months Ago'
        | '4 Months Ago'
        | '5 Months Ago'
        | '6 Months Ago'
        | '7 Months Ago'
        | '8 Months Ago'
        | '9 Months Ago'
        | '10 Months Ago'
        | '11 Months Ago'
        | '12 Months Ago',
    ): void => {
      if (!handleUpdateTimeModifier) return;
      switch (timeModifier) {
        case 'This Month':
          handleUpdateTimeModifier({
            timeModifier: {},
            formulaForUpdate: selectedFormula,
            refToUpdate: ref,
            formulaTextValue,
            formulaCopy: [...updatedFormula],
            variablesCopy: { ...variables },
          });
          break;
        case 'Last Month':
          handleUpdateTimeModifier({
            timeModifier: {
              function: TimeModifierFunctionEnum.Previous,
              period: 1,
            },
            formulaForUpdate: selectedFormula,
            refToUpdate: ref,
            formulaTextValue,
            formulaCopy: [...updatedFormula],
            variablesCopy: { ...variables },
          });
          break;
        case '2 Months Ago':
          handleUpdateTimeModifier({
            timeModifier: {
              function: TimeModifierFunctionEnum.Previous,
              period: 2,
            },
            formulaForUpdate: selectedFormula,
            refToUpdate: ref,
            formulaTextValue,
            formulaCopy: [...updatedFormula],
            variablesCopy: { ...variables },
          });
          break;
        case '3 Months Ago':
          handleUpdateTimeModifier({
            timeModifier: {
              function: TimeModifierFunctionEnum.Previous,
              period: 3,
            },
            formulaForUpdate: selectedFormula,
            refToUpdate: ref,
            formulaTextValue,
            formulaCopy: [...updatedFormula],
            variablesCopy: { ...variables },
          });
          break;
        case '4 Months Ago':
          handleUpdateTimeModifier({
            timeModifier: {
              function: TimeModifierFunctionEnum.Previous,
              period: 4,
            },
            formulaForUpdate: selectedFormula,
            refToUpdate: ref,
            formulaTextValue,
            formulaCopy: [...updatedFormula],
            variablesCopy: { ...variables },
          });
          break;
        case '5 Months Ago':
          handleUpdateTimeModifier({
            timeModifier: {
              function: TimeModifierFunctionEnum.Previous,
              period: 5,
            },
            formulaForUpdate: selectedFormula,
            refToUpdate: ref,
            formulaTextValue,
            formulaCopy: [...updatedFormula],
            variablesCopy: { ...variables },
          });
          break;
        case '6 Months Ago':
          handleUpdateTimeModifier({
            timeModifier: {
              function: TimeModifierFunctionEnum.Previous,
              period: 6,
            },
            formulaForUpdate: selectedFormula,
            refToUpdate: ref,
            formulaTextValue,
            formulaCopy: [...updatedFormula],
            variablesCopy: { ...variables },
          });
          break;
        case '7 Months Ago':
          handleUpdateTimeModifier({
            timeModifier: {
              function: TimeModifierFunctionEnum.Previous,
              period: 7,
            },
            formulaForUpdate: selectedFormula,
            refToUpdate: ref,
            formulaTextValue,
            formulaCopy: [...updatedFormula],
            variablesCopy: { ...variables },
          });
          break;
        case '8 Months Ago':
          handleUpdateTimeModifier({
            timeModifier: {
              function: TimeModifierFunctionEnum.Previous,
              period: 8,
            },
            formulaForUpdate: selectedFormula,
            refToUpdate: ref,
            formulaTextValue,
            formulaCopy: [...updatedFormula],
            variablesCopy: { ...variables },
          });
          break;
        case '9 Months Ago':
          handleUpdateTimeModifier({
            timeModifier: {
              function: TimeModifierFunctionEnum.Previous,
              period: 9,
            },
            formulaForUpdate: selectedFormula,
            refToUpdate: ref,
            formulaTextValue,
            formulaCopy: [...updatedFormula],
            variablesCopy: { ...variables },
          });
          break;
        case '10 Months Ago':
          handleUpdateTimeModifier({
            timeModifier: {
              function: TimeModifierFunctionEnum.Previous,
              period: 10,
            },
            formulaForUpdate: selectedFormula,
            refToUpdate: ref,
            formulaTextValue,
            formulaCopy: [...updatedFormula],
            variablesCopy: { ...variables },
          });
          break;
        case '11 Months Ago':
          handleUpdateTimeModifier({
            timeModifier: {
              function: TimeModifierFunctionEnum.Previous,
              period: 11,
            },
            formulaForUpdate: selectedFormula,
            refToUpdate: ref,
            formulaTextValue,
            formulaCopy: [...updatedFormula],
            variablesCopy: { ...variables },
          });
          break;
        case '12 Months Ago':
          handleUpdateTimeModifier({
            timeModifier: {
              function: TimeModifierFunctionEnum.Previous,
              period: 12,
            },
            formulaForUpdate: selectedFormula,
            refToUpdate: ref,
            formulaTextValue,
            formulaCopy: [...updatedFormula],
            variablesCopy: { ...variables },
          });
          break;
      }
    },
    [updatedFormula, variables],
  );

  const classStyle = ((): string => {
    if (selectable) {
      return 'border-r border-neutral-100 rounded-r-full pr-3';
    } else if (timeSeriesValue === 'This Month') {
      return 'pr-3';
    } else {
      return 'pr-1';
    }
  })();

  return (
    <div className="flex flex-row cursor-pointer">
      {formulaIndex !== undefined &&
        (inputPosition !== formulaIndex || (inputPosition === formulaIndex && Boolean(value.length))) && (
          <div
            onClick={(e) => {
              if (selectable) {
                e.stopPropagation();
                if (formulaIndex >= 0 && inputPosition !== formulaIndex) {
                  setInputPosition(formulaIndex);
                }
              }
            }}
            className="w-2 max-w-2 min-w-2 cursor-text"
          />
        )}
      <div
        ref={ref}
        className={`flex flex-row text-nowrap border border-neutral-100 focus:border-green-400 rounded-full bg-neutral-15 
        ${timeSeriesValue !== 'This Month' || selectable ? ' px-3' : 'pl-3'} ${selectable && ' formulaWithTimeseriesOptions'}`}
        onClick={(e) => {
          if (selectable) {
            e.stopPropagation();
            if (segmentToDelete?.segmentRef && isEqual(ref, segmentToDelete.segmentRef)) {
              setSegmentToDelete(undefined);
              ref &&
                'current' in ref &&
                ref.current?.classList.remove('!bg-green-25', '!border-green-400', 'text-green-500');
            } else {
              if (segmentToDelete) {
                updatedFormula[segmentToDelete.segmentIndex].ref.current?.classList.remove(
                  '!bg-green-25',
                  '!border-green-400',
                  'text-green-500',
                );
              }
              setSegmentToDelete({
                segmentRef: ref as RefObject<HTMLDivElement>,
                segmentIndex: formulaIndex ?? 0,
                segmentValue: formulaTextValue,
              });
              ref &&
                'current' in ref &&
                ref.current?.classList.add('!bg-green-25', '!border-green-400', 'text-green-500');
            }
          }
        }}
      >
        <div className={`h-full py-0.5 ${classStyle}`}>{selectedFormula.recipe.name}</div>
        {selectable ? (
          <div
            className="pl-1.5 py-0.5 timeseriesOptions text-neutral-200 hover:text-neutral-400"
            data-testid={`timeseries-options-${selectedFormula.recipe.name}`}
          >
            <TimeseriesOptions updateTimeModifier={updateTimeModifier} timeseriesValue={timeSeriesValue} />
          </div>
        ) : timeSeriesValue === 'This Month' ? null : (
          <div className="py-0.5 text-neutral-200 italic">{timeSeriesValue}</div>
        )}
      </div>
    </div>
  );
});

FormulaElement.displayName = 'FormulaElement';

export default FormulaElement;
