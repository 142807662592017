/* eslint-disable security/detect-unsafe-regex */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { State } from '~/store';
import useInput from '~/components/Input/useInput';
import InputState = Types.InputState;
import { RadioInputState } from '~/components/RadioInput/RadioInput.types';
import { useRadioInput } from '~/components/RadioInput';
import { useSelectMultiple } from '~/components/SelectMultiple';
import usePeriodPicker, { IPeriodPickerState } from '~/components/PeriodPicker/usePeriodPicker';
import { SelectMultipleState } from '~/components/SelectMultiple/SelectMultiple.types';
import { useSelect } from '~/components/Select';
import { SelectState } from '~/components/Select/Select.types';
import { IExpense } from '~/pages/Expenses/components/types';
import { getHeadcountDriverTypeSelected } from '~/pages/Expenses/utils/getHeadcountDriverTypeSelected';
import { getDepartmentsSelected } from '~/pages/Expenses/utils/getDepartmentsSelected';
import { getSelectedIntegrationMappings } from '~/pages/Expenses/utils/getSelectedIntegrationMappings';
import type { IIntegrationMapping } from '~/services/parallel/integrations.types';
import * as stringDate from '~/utils/stringDate/index';
import { IRecipeVariables } from '~/services/parallel/formulas.types';
import { IFormula } from '~/services/parallel/formulas.types';
import { IEmploymentType } from '~/pages/Headcount/entity/types';
import { employmentTypeToLabel } from '~/pages/Headcount/entity/types';
import { isDuplicateName } from '../../utils/isDuplicateName';

export type ExpenseType = 'setCost' | 'headcountDriven' | 'custom';

export interface IFormulaState {
  topLevelFormulaUuid?: string;
  formula: string;
  variables: IRecipeVariables;
  formulaList: IFormula[];
  editable?: boolean;
}

export interface IFormulaInputState {
  formulaState?: IFormulaState;
  variables?: IRecipeVariables;
  formulaTitle?: string;
  formulaUuid?: string;
  isCustom: boolean;
}

const tagToCategoryMap: Record<string, string> = {
  'People & Facilities': 'People & Facilities',
  'Cost of Goods Sold': 'COGS',
  Software: 'Software',
  Marketing: 'Marketing',
  Other: 'Other',
};

export interface IExpenseFormState {
  errorMessage: string;
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
  organizationUuid: string;
  type: ExpenseType;
  setType: React.Dispatch<React.SetStateAction<ExpenseType>>;
  name: InputState;
  setName: React.Dispatch<React.SetStateAction<InputState>>;
  category: SelectState;
  setCategory: React.Dispatch<React.SetStateAction<SelectState>>;
  frequency: RadioInputState;
  setFrequency: React.Dispatch<React.SetStateAction<RadioInputState>>;
  headcountDriverType: SelectState;
  setHeadcountDriverType: React.Dispatch<React.SetStateAction<SelectState>>;
  amount: InputState;
  setAmount: React.Dispatch<React.SetStateAction<InputState>>;
  percentage: InputState;
  setPercentage: React.Dispatch<React.SetStateAction<InputState>>;
  departments: SelectMultipleState;
  setDepartments: React.Dispatch<React.SetStateAction<SelectMultipleState>>;
  startDate: IPeriodPickerState;
  setStartDate: React.Dispatch<React.SetStateAction<IPeriodPickerState>>;
  endDate: IPeriodPickerState;
  setEndDate: React.Dispatch<React.SetStateAction<IPeriodPickerState>>;
  resetFormState: () => void;
  validateFormState: ({ expenses, formulas }: { expenses: IExpense[]; formulas: IFormula[] }) => boolean;
  setFormStateFromIExpense: ({
    expense,
    expenseIntegrationMappings,
  }: {
    expense: IExpense;
    expenseIntegrationMappings: IIntegrationMapping[];
  }) => void;
  formulaInputState: IFormulaInputState;
  setFormulaInputState: React.Dispatch<React.SetStateAction<IFormulaInputState>>;
  integrationMappings: SelectMultipleState;
  setIntegrationMappings: React.Dispatch<React.SetStateAction<SelectMultipleState>>;
  employmentTypes: SelectMultipleState;
  setEmploymentTypes: React.Dispatch<React.SetStateAction<SelectMultipleState>>;
}

const getFrequencyOptions = (type: ExpenseType): RadioInputState['options'] => {
  return type !== 'headcountDriven'
    ? [
        {
          label: 'Monthly',
          value: 'monthly',
        },
        {
          label: 'Quarterly',
          value: 'quarterly',
        },
        {
          label: 'Annually',
          value: 'annually',
        },
        {
          label: 'One-time',
          value: 'oneTime',
        },
      ]
    : [
        {
          label: 'Monthly',
          value: 'monthly',
        },
        {
          label: 'Quarterly',
          value: 'quarterly',
        },
        {
          label: 'Annually',
          value: 'annually',
        },
        {
          label: 'One-time',
          value: 'oneTime',
        },
        {
          label: 'On-hire',
          value: 'onHire',
        },
      ];
};

const useExpenseFormState = (): IExpenseFormState => {
  const [errorMessage, setErrorMessage] = useState('');
  const { uuid: organizationUuid } = useSelector((state: State) => state.organization);
  const [expenseUuid, setExpenseUuid] = useState<string | undefined>(undefined);
  const [type, setType] = useState<ExpenseType>('setCost');
  const [name, setName] = useInput({
    validation: /^.*$/,
  });
  const [category, setCategory] = useSelect({
    options: [
      {
        label: 'People & Facilities',
        value: 'People & Facilities',
      },
      {
        label: 'COGS',
        value: 'Cost of Goods Sold',
      },
      {
        label: 'Software',
        value: 'Software',
      },
      {
        label: 'Marketing',
        value: 'Marketing',
      },
      {
        label: 'Other',
        value: 'Other',
      },
    ],
  });
  const [frequency, setFrequency] = useRadioInput({
    options: getFrequencyOptions(type),
    selected: { label: 'Monthly', value: 'monthly' },
    errorMessage: 'Frequency is required',
  });
  const [formulaInputState, setFormulaInputState] = useState<IFormulaInputState>({
    formulaState: {
      formula: '',
      variables: {},
      formulaList: [],
      editable: true,
    },
    isCustom: false,
    variables: {},
    formulaTitle: '',
    formulaUuid: '',
  });
  const [integrationMappings, setIntegrationMappings] = useSelectMultiple({
    multiple: true,
  });
  const [employmentTypes, setEmploymentTypes] = useSelectMultiple({
    multiple: true,
    options: [
      {
        value: 'ALL',
        label: 'All',
      },
      {
        value: IEmploymentType.FullTime,
        label: employmentTypeToLabel[IEmploymentType.FullTime],
      },
      {
        value: IEmploymentType.PartTime,
        label: employmentTypeToLabel[IEmploymentType.PartTime],
      },
      {
        value: IEmploymentType.Contractor,
        label: employmentTypeToLabel[IEmploymentType.Contractor],
      },
    ],
    selected: [
      {
        value: IEmploymentType.FullTime,
        label: employmentTypeToLabel[IEmploymentType.FullTime],
      },
    ],
  });

  useEffect(() => {
    if (frequency.selected?.value === 'onHire' && type === 'setCost') {
      setFrequency((prevState) => ({
        ...prevState,
        selected: undefined,
        options: getFrequencyOptions(type),
      }));
    } else {
      setFrequency((prevState) => ({
        ...prevState,
        options: getFrequencyOptions(type),
      }));
    }
  }, [type, setFrequency, frequency.selected?.value]);

  const [headcountDriverType, setHeadcountDriverType] = useSelect({
    options: [
      {
        label: 'Dollar Amount per Employee',
        value: 'headcountFixed',
      },
      {
        label: 'Percent of Monthly Compensation',
        value: 'headcountPercentCompensation',
      },
    ],
    selected: {
      label: 'Dollar Amount per Employee',
      value: 'headcountFixed',
    },
    isNullable: true,
  });
  const [amount, setAmount] = useInput({
    validation: /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
    errorMessage: 'Amount is required',
  });
  const [percentage, setPercentage] = useInput({
    validation: /^(100(\.0{1,2})?|[0-9]{1,2}(\.\d{1,2})?)$/,
    errorMessage: 'Percentage is required and must be between 0.00 and 100.00',
  });
  const [departments, setDepartments] = useSelectMultiple({
    multiple: true,
  });
  const [startDate, setStartDate] = usePeriodPicker({
    startDate: stringDate.startOfMonth(stringDate.getStringDate()),
    endDate: stringDate.endOfMonth(stringDate.getStringDate()),
    mode: 'month',
  });
  const [endDate, setEndDate] = usePeriodPicker({
    startDate: null,
    endDate: null,
    mode: 'month',
  });

  const setFormStateFromIExpense = async ({
    expense,
    expenseIntegrationMappings,
  }: {
    expense: IExpense;
    expenseIntegrationMappings: IIntegrationMapping[];
  }): Promise<void> => {
    let setNewType: ExpenseType;
    if (['headcountFixed', 'headcountPercentCompensation'].includes(expense.context.driver)) {
      setNewType = 'headcountDriven';
    } else if (expense.context.driver === 'custom') {
      setNewType = 'custom';
    } else {
      setNewType = 'setCost';
    }
    setType(setNewType);
    setExpenseUuid(expense.expenseUuid);
    setName((prevState) => ({
      ...prevState,
      value: expense.name,
      valid: true,
      pristine: false,
      touched: true,
    }));
    setCategory((prevState) => ({
      ...prevState,
      selected: {
        label: tagToCategoryMap[expense.context.tag],
        value: expense.context.tag,
      },
      valid: true,
      pristine: false,
      touched: true,
    }));
    setFrequency((prevState) => ({
      ...prevState,
      selected: getFrequencyOptions(setNewType).find((option) => option.value === expense.context.frequency),
      valid: true,
      pristine: false,
      touched: true,
      options: getFrequencyOptions(setNewType),
    }));
    setHeadcountDriverType((prevState) => ({
      ...prevState,
      selected: getHeadcountDriverTypeSelected({
        driver: expense.context.driver,
      }),
      valid: true,
      pristine: false,
      touched: true,
    }));
    setAmount((prevState) => ({
      ...prevState,
      value:
        expense.context.driver !== 'headcountPercentCompensation' && expense.context.amount
          ? (expense.context.amount / 100).toString()
          : '',
      valid: true,
      pristine: false,
      touched: true,
    }));
    setPercentage((prevState) => ({
      ...prevState,
      value:
        expense.context.driver === 'headcountPercentCompensation' && expense.context.amount
          ? (expense.context.amount / 100).toPrecision(4)
          : '',
      valid: true,
      pristine: false,
      touched: true,
    }));
    const selectedDepartments = await getDepartmentsSelected({
      departmentUuids: expense.context.departments,
    });
    setDepartments((prevState) => ({
      ...prevState,
      selected: selectedDepartments,
      valid: true,
      pristine: false,
      touched: true,
    }));
    setStartDate({
      ...startDate,
      startDate: expense.context.startDate,
      endDate: stringDate.endOfMonth(expense.context.startDate),
    });

    const selectedMappings = getSelectedIntegrationMappings({
      expenseMappings: expense.dataSourceUuids,
      expenseIntegrationMappings,
    });
    setIntegrationMappings((prevState) => ({
      ...prevState,
      selected: selectedMappings,
      options: expenseIntegrationMappings.map((mapping) => ({
        label: mapping.name,
        value: mapping.uuid,
        disabled:
          mapping.currentlyInUse &&
          !(mapping.uuid && expense.dataSourceUuids && expense.dataSourceUuids.includes(mapping.uuid)),
      })),
    }));

    const updatedEndDate = expense.context.endDate ?? null;
    setEndDate({
      ...endDate,
      startDate: updatedEndDate,
      endDate: updatedEndDate,
    });

    setEmploymentTypes((prevState) => ({
      ...prevState,
      selected: expense.context.employmentTypes.includes('all')
        ? [
            {
              value: 'ALL',
              label: 'All',
            },
          ]
        : expense.context.employmentTypes.map((type) => ({
            value: type,
            label: type === 'all' ? 'All' : employmentTypeToLabel[type],
          })),
    }));
  };

  const validateFormState = ({ expenses, formulas }: { expenses: IExpense[]; formulas: IFormula[] }): boolean => {
    let isValid = true;
    const { isDuplicate, isDiscontinuedDuplicate } = isDuplicateName({
      name: name.value,
      expenses,
      formulas,
      expenseUuid,
    });
    if (!name.valid || isDuplicate) {
      setName((prevState) => ({
        ...prevState,
        touched: true,
        pristine: false,
        valid: false,
        errorMessage: isDuplicate
          ? isDiscontinuedDuplicate
            ? 'This name matches a discontinued expense. Please choose a different name or edit the discontinued expense.'
            : 'Expense name already in use'
          : 'Enter a valid expense name',
      }));
      isValid = false;
    }
    if (!category.selected) {
      setCategory((prevState) => ({
        ...prevState,
        touched: true,
        pristine: false,
      }));
      isValid = false;
    }
    if (!frequency.selected) {
      setFrequency((prevState) => ({
        ...prevState,
        touched: true,
        pristine: false,
        valid: false,
      }));
      isValid = false;
    }
    if (!amount.valid && headcountDriverType.selected?.value !== 'headcountPercentCompensation' && type !== 'custom') {
      setAmount((prevState) => ({
        ...prevState,
        touched: true,
        pristine: false,
      }));
      isValid = false;
    }
    if (!percentage.valid && headcountDriverType.selected?.value === 'headcountPercentCompensation') {
      setPercentage((prevState) => ({
        ...prevState,
        touched: true,
        pristine: false,
      }));
      isValid = false;
    }

    setDepartments((prevState) => ({
      ...prevState,
      touched: true,
      pristine: false,
      valid:
        category.selected?.value !== 'Cost of Goods Sold'
          ? Boolean(departments.selected && departments.selected.length > 0)
          : departments.selected?.length === 0,
    }));

    return isValid;
  };

  const resetFormState = (): void => {
    setExpenseUuid(undefined);
    setType('setCost');
    setName({
      ...name,
      value: '',
      valid: false,
      pristine: true,
      touched: false,
      disabled: false,
    });
    setCategory({
      ...category,
      selected: undefined,
      valid: false,
      pristine: true,
      touched: false,
      disabled: false,
    });
    setFrequency({
      ...frequency,
      selected: { label: 'Monthly', value: 'monthly' },
      valid: false,
      pristine: true,
      touched: false,
      disabled: false,
    });
    setHeadcountDriverType({
      ...headcountDriverType,
      selected: {
        label: 'Dollar Amount per Employee',
        value: 'headcountFixed',
      },
      valid: false,
      pristine: true,
      touched: false,
      disabled: false,
    });
    setAmount({
      ...amount,
      value: '',
      valid: false,
      pristine: true,
      touched: false,
      disabled: false,
    });
    setPercentage({
      ...percentage,
      value: '',
      valid: false,
      pristine: true,
      touched: false,
      disabled: false,
    });
    setDepartments({
      ...departments,
      selected: undefined,
      valid: false,
      pristine: true,
      touched: false,
    });
    setStartDate({
      ...startDate,
      startDate: stringDate.startOfMonth(stringDate.getStringDate()),
      endDate: stringDate.endOfMonth(stringDate.getStringDate()),
    });
    setEndDate({
      ...endDate,
      startDate: null,
      endDate: null,
    });
    setIntegrationMappings((prevState) => ({
      ...prevState,
      selected: [],
    }));
    setEmploymentTypes((prevState) => ({
      ...prevState,
      options: prevState.options.map((option) => ({
        ...option,
        disabled: false,
      })),
      selected: [
        {
          value: IEmploymentType.FullTime,
          label: employmentTypeToLabel[IEmploymentType.FullTime],
        },
      ],
      disabled: false,
      pristine: true,
      touched: false,
      valid: false,
    }));
  };

  return {
    errorMessage,
    setErrorMessage,
    organizationUuid,
    type,
    setType,
    name,
    setName,
    category,
    setCategory,
    frequency,
    setFrequency,
    headcountDriverType,
    setHeadcountDriverType,
    amount,
    setAmount,
    percentage,
    setPercentage,
    departments,
    setDepartments,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    resetFormState,
    validateFormState,
    setFormStateFromIExpense,
    formulaInputState,
    setFormulaInputState,
    integrationMappings,
    setIntegrationMappings,
    employmentTypes,
    setEmploymentTypes,
  };
};

export default useExpenseFormState;
