import { turnLetterAbbreviatedIntoNumber } from '~/utils/turnLetterAbbreviatedIntoNumber';
import { formatYValue } from './formatYValue';
import { IFormattingEnum } from '~/services/parallel/formulas.types';

export const convertRawValueToRounded = ({
  value,
  formatting,
  yMax,
  yMin,
}: {
  value: number;
  formatting: IFormattingEnum;
  yMax: number;
  yMin: number;
}): number => {
  const CENTS_IN_DOLLAR = 100;
  switch (formatting) {
    case IFormattingEnum.Currency: {
      const formattedValue = formatYValue({
        value,
        formatType: IFormattingEnum.Currency,
        yMax,
        yMin,
      });
      return (
        turnLetterAbbreviatedIntoNumber({
          value: formattedValue,
        }) / CENTS_IN_DOLLAR
      );
    }
    case IFormattingEnum.Number: {
      const formattedValue = formatYValue({
        value,
        formatType: IFormattingEnum.Number,
        yMax,
        yMin,
      });
      return (
        turnLetterAbbreviatedIntoNumber({
          value: formattedValue,
        }) / CENTS_IN_DOLLAR
      );
    }
    default: {
      return value / 100;
    }
  }
};
