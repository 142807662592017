import React, { useContext, useRef } from 'react';
import { IInitialValue } from '../Cell/entities/types';
import { CellContext } from '@tanstack/react-table';
import { HeadcountContext } from '~/pages/Headcount/context/HeadcountContext';
import CurrencyInput from '~/components/Input/currencyInput/CurrencyInput';
import { useFeatureFlagHarness } from '~/utils/hooks/useFeatureFlag';

const CreationPayRate = ({
  cellContext,
  handleBlur,
}: {
  cellContext: CellContext<Record<string, IInitialValue>, IInitialValue>;
  handleBlur: () => void;
}): React.ReactNode => {
  const bonusAndCommission = useFeatureFlagHarness('bonusAndCommission');

  const {
    inlineCreationFormState: {
      inlineCreate,
      inlineCreatePayRate,
      setInlineCreatePayRate,
      setAddInlineAdditionalCompensation,
    },
  } = useContext(HeadcountContext);
  const inlineCurrencyInputRef = useRef<HTMLInputElement>(null);

  return inlineCreate ? (
    <div className="min-w-[130px] relative">
      <CurrencyInput
        id={`create-${cellContext.column.id}`}
        state={inlineCreatePayRate}
        setState={setInlineCreatePayRate}
        onBlur={handleBlur}
        ref={inlineCurrencyInputRef}
      />
      {bonusAndCommission && document.activeElement === inlineCurrencyInputRef.current && (
        <div
          onMouseDown={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setAddInlineAdditionalCompensation((prev) => !prev);
          }}
          className="absolute cursor-pointer rounded-lg text-green-400 hover:bg-green-15 hover:text-green-500 flex justify-center items-center shadow-[0px_2px_4px_0px_rgba(0,_0,_0,_0.20)] top-12 left-1/2 transform -translate-x-1/2 px-5 py-3 whitespace-nowrap w-fit h-full bg-white z-20"
        >
          <p>+ Add Commissions / Bonus</p>
        </div>
      )}
    </div>
  ) : (
    <div />
  );
};

export default CreationPayRate;
