import { SelectState } from '~/components/Select/Select.types';
import React, { useState } from 'react';

const useSelect = ({
  options,
  selected,
  isNullable = false,
  isLoading = false,
  disabled = false,
}: Partial<SelectState> & { isNullable?: boolean; isLoading?: boolean }): [
  value: SelectState,
  setState: React.Dispatch<React.SetStateAction<SelectState>>,
  resetState: () => void,
  setLoading: (isLoading: boolean) => void,
  disabled: boolean,
] => {
  const valid = ((isNullable && !selected) || options?.some((option) => option.value === selected?.value)) ?? false;

  let selectedValue = selected;
  if (!selectedValue) {
    selectedValue = { label: null, value: null };
  }

  const initialState: SelectState = {
    options: options ?? [],
    selected: selectedValue,
    valid,
    touched: false,
    pristine: true,
    isLoading,
    disabled,
  };

  const [selectState, setSelectState] = useState(initialState);
  const resetInput = (): void => setSelectState(initialState);
  const setLoading = (isLoading: boolean): void => {
    setSelectState((prevState) => ({ ...prevState, isLoading }));
  };
  return [selectState, setSelectState, resetInput, setLoading, disabled];
};

export default useSelect;
