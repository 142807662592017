import { IFormattingEnum } from '~/services/parallel/formulas.types';
import formatToLetterAbbreviatedNumber from '~/utils/formatToLetterAbbreviatedNumber';

export const formatYValue = ({
  value,
  formatType,
  yMax,
  yMin,
}: {
  value: number;
  formatType: IFormattingEnum;
  yMax: number;
  yMin: number;
}): string => {
  const HUNDRED_THOUSAND_WITH_CENTS = 10000000;
  const MILLION_WITH_CENTS = 100000000;
  const HUNDRED_MILLION_WITH_CENTS = 10000000000;
  const THOUSAND_WITH_CENTS = 100000;
  const HUNDRED_WITH_CENTS = 10000;
  const absoluteValue = Math.abs(value);
  const absoluteMax = Math.abs(yMax);
  const absoluteMin = Math.abs(yMin);

  let DECIMAL_PLACES = 0;

  if (absoluteValue < HUNDRED_WITH_CENTS && absoluteMax < HUNDRED_WITH_CENTS && absoluteMin < HUNDRED_WITH_CENTS) {
    DECIMAL_PLACES = 2;
  } else if (
    (absoluteValue >= MILLION_WITH_CENTS && absoluteValue < HUNDRED_MILLION_WITH_CENTS) ||
    (absoluteValue >= THOUSAND_WITH_CENTS && absoluteValue < HUNDRED_THOUSAND_WITH_CENTS)
  ) {
    DECIMAL_PLACES = 1;
  }

  switch (formatType) {
    case IFormattingEnum.Currency:
      return formatToLetterAbbreviatedNumber({
        value,
        decimal: DECIMAL_PLACES,
      });
    case IFormattingEnum.Percent:
      return `${parseFloat(value.toFixed(Math.abs(value) < 100 ? 2 : 0))}%`;
    default:
      return formatToLetterAbbreviatedNumber({
        value,
        decimal: DECIMAL_PLACES,
        showCurrency: false,
      });
  }
};
