import React, { useContext, useEffect, useMemo, useState } from 'react';
import Timeline from '~/components/Timeline';
import { HeadcountContext } from '../../context/HeadcountContext';
import { IPositionDetailsWithOrderedDates } from '../../entity/types';
import HeadcountTimelineNodeContent from './HeadcountTimelineNodeContent';
import { State } from '~/store';
import { useDispatch, useSelector } from 'react-redux';
import useQueryParams from '~/utils/hooks/useQueryParams';
import Button from '~/components/Button';
import { ZPositionDetailsWithOrderedDates } from '../../entity/schemas';
import { useRevalidator } from 'react-router-dom';
import Typography from '~/components/Typography';
import { update, updateScenarioLoadingState, updateScenarioMode } from '~/store/scenarioSlice';
import isEqual from 'lodash.isequal';
import { fetchSpecificPositions } from '../../utils/fetchSpecificPositions';
import EditPosition from './EditPosition/EditPosition';
import { IEditPositionFormFields } from './EditPosition/EditPositionForm';
import { useFeatureFlagHarness } from '~/utils/hooks/useFeatureFlag';
import { updatePositionsDates } from '~/services/parallel/positions';
import { fetchScenario } from '~/services/parallel/scenarios';
import logger from '~/utils/logger';
import EditTermDate from './EditTermDate/EditTermDate';
import * as stringDate from '~/utils/stringDate';
import { IStringDate } from '~/utils/stringDate/types';
import UpdateCompensationModal from '../CellPayRate/UpdateCompensationModal';

const HeadcountTimeline = ({
  addNewPosition,
  onlyNewHires,
  createScenario,
  onlyActivePositions,
  reloadDashboard,
}: {
  addNewPosition?: () => void;
  onlyNewHires?: boolean;
  createScenario?: boolean;
  onlyActivePositions?: boolean;
  reloadDashboard?: () => Promise<void>;
}): React.ReactNode => {
  const inlineHeadcount = useFeatureFlagHarness('inlineHeadcount');
  const revalidator = useRevalidator();
  const dispatch = useDispatch();
  const {
    renderedPositions,
    addTermDateState,
    positionActiveStateDict,
    setPositions,
    setRenderedPositions,
    editPositionUuid,
    setEditPositionUuid,
    editPositionFormState,
    positions,
    inlineCreationFormState: { clearInlineCreateForm, inlineCreate },
    setPositionDataDict,
    payRateModalState,
    setPayRateModalState,
    terminationModalState,
    setTerminationModalState,
    search,
  } = useContext(HeadcountContext);
  const [draggingChanges, setDraggingChanges] = useState<{
    positionIds: string[];
    daysToAdd: number;
  }>({ positionIds: [], daysToAdd: 0 });
  const { activeScenarioUuid } = useSelector((state: State) => state.scenario);
  const scenarioState = useSelector((state: State) => state.scenario);
  const { uuid: userUuid } = useSelector((state: State) => state.user);
  const {
    uuid: organizationUuid,
    configuration: { monthsOutToForecast },
  } = useSelector((state: State) => state.organization);
  const [queryParams] = useQueryParams();
  const selectedDepartment = queryParams.get('departments');
  const [positionsList, setPositionsList] = useState<IPositionDetailsWithOrderedDates[]>(
    ZPositionDetailsWithOrderedDates.array().parse(renderedPositions),
  );

  if (inlineCreate && inlineHeadcount) {
    clearInlineCreateForm();
  }

  useEffect(() => {
    if (!isEqual(positionsList, renderedPositions)) {
      setPositionsList(ZPositionDetailsWithOrderedDates.array().parse(renderedPositions));
    }
  }, [renderedPositions]);

  const handleDateChange = async ({
    positionIds,
    daysToAdd,
  }: {
    positionIds: string[];
    daysToAdd: number;
  }): Promise<void> => {
    setDraggingChanges({ positionIds, daysToAdd });
  };

  const handleSave = async ({ positionIds }: { positionIds: string[] }): Promise<void> => {
    if (!activeScenarioUuid && createScenario) {
      dispatch(updateScenarioMode('creating'));
      dispatch(updateScenarioLoadingState('creating'));
    } else if (createScenario) {
      dispatch(updateScenarioLoadingState('updating'));
    }
    const { daysToAdd } = draggingChanges;

    const updates: {
      positionUuid: string;
      hireDate: string;
      termDate: string | null;
    }[] = [];

    const updatedPositions = positionsList.map((position) => {
      if (positionIds.includes(position.positionUuid)) {
        const updatedPosition = {
          ...position,
          hireDate: stringDate.addDays(position.hireDate, daysToAdd),
        };

        if (position.termDate) {
          updatedPosition.termDate = stringDate.addDays(position.termDate, daysToAdd);
        }

        updates.push({
          positionUuid: position.positionUuid,
          hireDate: updatedPosition.hireDate,
          termDate: updatedPosition.termDate,
        });

        return updatedPosition;
      }
      return position;
    });

    if (!isEqual(updatedPositions, positionsList)) {
      setPositionsList(updatedPositions);
    }

    // Reset dragging changes
    setDraggingChanges((prevState) => ({
      ...prevState,
      positionIds: [],
      daysToAdd: 0,
    }));

    try {
      const response = await updatePositionsDates({
        organizationUuid,
        scenarioUuid: activeScenarioUuid ?? undefined,
        positionUpdates: updates,
        createScenario,
      });

      if (response.scenarioUuid && !activeScenarioUuid) {
        const scenario = await fetchScenario({
          organizationUuid,
          scenarioUuid: response.scenarioUuid,
        });

        dispatch(
          update({
            ...scenarioState,
            activeScenarioUuid: response.scenarioUuid,
            activeScenarioHasChanges: true,
            scenarioLoadingState: 'creating',
            activeScenarioData: {
              type: 'dynamic',
              uuid: response.scenarioUuid,
              organizationUuid,
              changeDescription: 'Untitled Scenario',
              createdBy: userUuid,
              createdAt: stringDate.getStringDate(),
              updatedAt: stringDate.getStringDate(),
              effectiveAt: stringDate.getStringDate(),
              markedAsStaleAt: null,
            },
            scenarios: [scenario, ...scenarioState.scenarios],
          }),
        );
      }
      const positionsToUpdate = await fetchSpecificPositions({
        positionUuids: updates.map((update) => update.positionUuid),
        organizationUuid,
        scenarioUuid: activeScenarioUuid ?? undefined,
      });
      setPositions((prevPositions) => {
        return prevPositions.map((position) => {
          const updatedPosition = positionsToUpdate.find(
            (updatedPosition) => updatedPosition.positionUuid === position.positionUuid,
          );
          return updatedPosition
            ? { ...position, ...updatedPosition }
            : {
                ...position,
                isActive: positionActiveStateDict[position.positionUuid],
              };
        });
      });
      setRenderedPositions((prevPositions) => {
        return prevPositions.map((position) => {
          const updatedPosition = positionsToUpdate.find(
            (updatedPosition) => updatedPosition.positionUuid === position.positionUuid,
          );
          return updatedPosition
            ? { ...position, ...updatedPosition }
            : {
                ...position,
                isActive: positionActiveStateDict[position.positionUuid],
              };
        });
      });
      setPositionDataDict((prev) => {
        const newPositionDataDict = { ...prev };
        positionsToUpdate.forEach((position) => {
          newPositionDataDict[position.positionUuid] = {
            ...prev[position.positionUuid],
            hireDate: position.hireDate,
            termDate: position.termDate,
          };
        });
        return newPositionDataDict;
      });
      if (reloadDashboard) {
        await reloadDashboard();
      }
    } catch (error) {
      logger.error(error as Error);
    } finally {
      dispatch(updateScenarioLoadingState('idle'));
    }
  };

  let startBoundary: IStringDate = useMemo(() => {
    if (onlyNewHires) {
      return stringDate.startOfMonth(stringDate.subtractMonths(stringDate.getStringDate(), 1)); // Use today's date as the start boundary
    }

    return positionsList.length
      ? positionsList.reduce(
          (earliestDate: IStringDate, { hireDate }: IPositionDetailsWithOrderedDates): IStringDate => {
            return stringDate.isBefore({ dateToCheck: hireDate, comparison: earliestDate })
              ? stringDate.subtractMonths(hireDate, 1)
              : earliestDate;
          },
          stringDate.subtractMonths(stringDate.getStringDate(), 1),
        )
      : stringDate.subtractMonths(stringDate.getStringDate(), 1);
  }, [onlyNewHires, positionsList]);
  startBoundary = stringDate.startOfMonth(startBoundary);

  const endBoundary: IStringDate = stringDate.endOfMonth(
    stringDate.addMonths(stringDate.getStringDate(), monthsOutToForecast),
  );

  const timelineHeight = useMemo(() => {
    if (activeScenarioUuid) {
      return 'max-h-[calc(100vh_-_270px)]';
    } else {
      return 'max-h-[calc(100vh_-_214px)]';
    }
  }, [activeScenarioUuid]);

  const timelineNodes = positionsList
    .sort((a, b) => {
      return stringDate.isBefore({ dateToCheck: a.orderedDate, comparison: b.orderedDate }) ? -1 : 1;
    })
    .filter((position) => {
      if (search.value) {
        const isEmployeeNameMatch = position.employeeName?.toLowerCase().includes(search.value.toLowerCase());
        const isTitleMatch = position.title.toLowerCase().includes(search.value.toLowerCase());
        return isEmployeeNameMatch || isTitleMatch;
      }
      return true;
    })
    .filter((position) => {
      if (onlyNewHires) {
        return stringDate.isAfter({ dateToCheck: position.hireDate, comparison: stringDate.getStringDate() });
      }
      return true;
    })
    .filter((position) => {
      return onlyActivePositions ? position.isActive : true;
    })
    .filter((position) => {
      const parsedPosition = ZPositionDetailsWithOrderedDates.parse(position);
      if (selectedDepartment && selectedDepartment !== 'all') {
        return parsedPosition.departmentUuid === selectedDepartment;
      } else {
        return true;
      }
    })
    .map((position) => {
      let alteredStartDate: string = position.hireDate;
      let alteredEndDate: string | null = position.termDate;
      if (draggingChanges.positionIds.includes(position.positionUuid)) {
        alteredStartDate = stringDate.addDays(position.hireDate, draggingChanges.daysToAdd);
        alteredEndDate = position.termDate ? stringDate.addDays(position.termDate, draggingChanges.daysToAdd) : null;
      }

      return (
        <Timeline.Node
          key={position.positionUuid}
          id={position.positionUuid}
          startDate={alteredStartDate}
          endDate={alteredEndDate ?? undefined}
        >
          <HeadcountTimelineNodeContent
            position={position}
            createScenario={createScenario}
            reloadDashboard={reloadDashboard}
          />
        </Timeline.Node>
      );
    });

  const handleOptimisticUpdate = async ({ positionUuid }: { positionUuid: string | null }): Promise<void> => {
    if (!positionUuid) {
      return;
    }
    const [updatedPosition] = await fetchSpecificPositions({
      positionUuids: [positionUuid],
      organizationUuid,
      scenarioUuid: activeScenarioUuid ?? undefined,
    });

    const updateIndex = renderedPositions.findIndex(
      (renderedPosition) => renderedPosition.positionUuid === positionUuid,
    );

    const updatedPositionIndex = positions?.findIndex((oldPosition) => oldPosition.positionUuid === positionUuid);
    if (updatedPositionIndex !== undefined && updatedPositionIndex !== -1 && positions) {
      setPositions((prev) =>
        [...prev].map((position) =>
          position.positionUuid === updatedPosition.positionUuid
            ? { ...updatedPosition, orderedDate: position.orderedDate }
            : position,
        ),
      );
    }
    if (updateIndex !== -1) {
      setPositionDataDict((prev) => ({
        ...prev,
        [updatedPosition.positionUuid]: {
          employeeName: updatedPosition.employeeName ?? '',
          title: updatedPosition.title,
          department: updatedPosition.currentDepartment,
          hireDate: updatedPosition.hireDate,
          termDate: updatedPosition.termDate,
          fullyBurdenedCost: updatedPosition.fullyBurdenedCost,
          employmentType: updatedPosition.employmentType,
        },
      }));
      setRenderedPositions((prev) =>
        [...prev].map((position) =>
          position.positionUuid === updatedPosition.positionUuid
            ? { ...updatedPosition, orderedDate: position.orderedDate }
            : position,
        ),
      );
    }
  };

  const emptyTimeline = onlyNewHires ? (
    <div className="w-full h-[calc(90dvh_-_374px)] left-1/2 transform translate-x-1/3 ">
      <div className="sticky top-1/2 transform -translate-y-1/2 justify-center items-center flex px-4 py-2 border border-neutral-50 rounded-lg bg-neutral-15">
        <Typography color="empty">No Future Hires</Typography>
      </div>
    </div>
  ) : (
    <div className="sticky top-1/2 left-1/2 transform translate-x-1/2 -translate-y-1/2 justify-center items-center flex gap-2 px-4 py-2 border border-neutral-50 rounded-lg bg-neutral-15">
      <Typography color="empty">No Positions</Typography>
    </div>
  );

  return (
    <Timeline.Container
      startDateBoundary={startBoundary}
      endDateBoundary={endBoundary}
      onChange={handleDateChange}
      onSave={handleSave}
      ref={addTermDateState.containerRef}
      className={timelineHeight}
      availableSelections={positionsList.map((position) => position.positionUuid)}
    >
      {timelineNodes.length > 0 ? timelineNodes : emptyTimeline}
      {addNewPosition && (
        <Button
          id="create-position-button"
          onClick={addNewPosition}
          fill="outlineSolid"
          className="sticky bottom-8 left-5 ml-5 !w-fit z-10"
        >
          Create Position
        </Button>
      )}
      <EditPosition
        id={`edit-position-modal-${editPositionUuid}`}
        isOpen={Boolean(editPositionUuid)}
        onClose={() => {
          editPositionFormState.resetFormState();
          setEditPositionUuid(null);
        }}
        editPositionFormState={editPositionFormState}
        reload={() => revalidator.revalidate()}
        awaitCalculations
        editPositionUuid={editPositionUuid}
        handleOptimisticUpdate={handleOptimisticUpdate}
        createScenario={createScenario}
        fieldsToEdit={[
          IEditPositionFormFields.EMPLOYEE,
          IEditPositionFormFields.TITLE,
          IEditPositionFormFields.DEPARTMENT,
        ]}
      />
      {payRateModalState.isOpen && (
        <UpdateCompensationModal
          id={`update-compensation-modal-${payRateModalState.positionUuid}`}
          isOpen={payRateModalState.isOpen}
          onClose={() =>
            setPayRateModalState({
              isOpen: false,
              positionUuid: null,
              positionHireDate: null,
              payRateChangeHistory: [],
              bonusChangeHistory: null,
              commissionChangeHistory: null,
              currentPayRate: 0,
              currentBonus: null,
              currentCommission: null,
            })
          }
          positionUuid={payRateModalState.positionUuid}
          positionEffectiveAt={payRateModalState.positionHireDate}
          payRateChangeHistory={payRateModalState.payRateChangeHistory}
          bonusChangeHistory={payRateModalState.bonusChangeHistory ?? []}
          commissionChangeHistory={payRateModalState.commissionChangeHistory ?? []}
          createScenario={createScenario}
          currentPayRate={payRateModalState.currentPayRate}
          currentBonus={payRateModalState.currentBonus}
          currentCommission={payRateModalState.currentCommission}
        />
      )}
      {terminationModalState.isOpen && (
        <EditTermDate
          id={`term-date-modal-${terminationModalState.positionUuid}`}
          isOpen={terminationModalState.isOpen}
          onClose={() =>
            setTerminationModalState({
              isOpen: false,
              positionUuid: null,
              positionHireDate: null,
              currentTerminationDate: null,
            })
          }
          positionUuid={terminationModalState.positionUuid}
          currentTerminationDate={terminationModalState.currentTerminationDate}
          minTerminationDate={terminationModalState.positionHireDate}
          handleOptimisticUpdate={handleOptimisticUpdate}
          organizationUuid={organizationUuid}
          createScenario={createScenario}
        />
      )}
    </Timeline.Container>
  );
};

export default HeadcountTimeline;
