import { TrashIcon } from '@heroicons/react/24/outline';
import React, { useContext } from 'react';
import { HeadcountContext } from '~/pages/Headcount/context/HeadcountContext';

const CancelCreation = (): React.ReactNode => {
  const {
    inlineCreationFormState: { inlineCreate, clearInlineCreateForm },
  } = useContext(HeadcountContext);

  return inlineCreate ? (
    <TrashIcon
      className={`flex-shrink-0 size-[18px] hover:text-red-500 text-neutral-100 cursor-pointer`}
      onClick={clearInlineCreateForm}
    />
  ) : (
    <div />
  );
};

export default CancelCreation;
