import React, { useContext, useEffect, useState } from 'react';
import { IInitialValue } from '../entities/types';
import { CellContext } from '@tanstack/react-table';
import { HeadcountContext } from '~/pages/Headcount/context/HeadcountContext';
import Select, { useSelect } from '~/components/Select';
import { IEmploymentType } from '~/pages/Headcount/entity/types';
import { useSelector } from 'react-redux';
import { State } from '~/store';
import logger from '~/utils/logger';
import toast from 'react-hot-toast';
import * as api from '~/services/parallel/index';
import { employmentTypeToLabel } from '~/pages/Headcount/entity/types';
import { SelectType } from '~/components/Select/Select.types';
import { fetchSpecificPositions } from '~/pages/Headcount/utils/fetchSpecificPositions';

const CellSelectEmploymentType = ({
  initialValue,
  cellContext,
}: {
  initialValue: IInitialValue;
  cellContext: CellContext<Record<string, IInitialValue>, IInitialValue>;
}): React.ReactElement => {
  const organizationUuid = useSelector((state: State) => state.organization.uuid);
  const { activeScenarioUuid } = useSelector((state: State) => state.scenario);
  const { positionActiveStateDict, positionDataDict, setPositionDataDict } = useContext(HeadcountContext);
  const [selectEmploymentType, setSelectEmploymentType] = useSelect({
    selected: {
      value: positionDataDict[initialValue.positionUuid].employmentType,
      label: employmentTypeToLabel[positionDataDict[initialValue.positionUuid].employmentType],
    },
    options: [
      {
        value: IEmploymentType.FullTime,
        label: employmentTypeToLabel[IEmploymentType.FullTime],
      },
      {
        value: IEmploymentType.PartTime,
        label: employmentTypeToLabel[IEmploymentType.PartTime],
      },
      {
        value: IEmploymentType.Contractor,
        label: employmentTypeToLabel[IEmploymentType.Contractor],
      },
    ],
  });
  const [isActive, setIsActive] = useState(positionActiveStateDict[initialValue.positionUuid]);

  useEffect(() => {
    setIsActive(positionActiveStateDict[initialValue.positionUuid]);
  }, [positionActiveStateDict[initialValue.positionUuid]]);

  const onBlur = async (selected?: SelectType): Promise<void> => {
    try {
      if (selected?.value && selected.value !== positionDataDict[initialValue.positionUuid].employmentType) {
        await api.positions.updatePositionAttributeValue({
          organizationUuid,
          scenarioUuid: activeScenarioUuid ?? undefined,
          positionUuid: initialValue.positionUuid,
          attributeName: cellContext.column.id,
          attributeValue: selected.value,
          includeFullyBurdenedCost: true,
        });

        const [updatedPosition] = await fetchSpecificPositions({
          positionUuids: [initialValue.positionUuid],
          organizationUuid,
          scenarioUuid: activeScenarioUuid ?? undefined,
        });

        setPositionDataDict((prev) => ({
          ...prev,
          [updatedPosition.positionUuid]: {
            employeeName: updatedPosition.employeeName ?? '',
            title: updatedPosition.title,
            department: updatedPosition.currentDepartment,
            hireDate: updatedPosition.hireDate,
            termDate: updatedPosition.termDate,
            fullyBurdenedCost: updatedPosition.fullyBurdenedCost,
            employmentType: updatedPosition.employmentType,
          },
        }));
      }
    } catch (error) {
      if (error instanceof Error) logger.error(error);
      setSelectEmploymentType((prev) => ({
        ...prev,
        selected: {
          label: employmentTypeToLabel[positionDataDict[initialValue.positionUuid].employmentType],
          value: positionDataDict[initialValue.positionUuid].employmentType,
        },
      }));
      toast.error('Failed to update employment type');
    }
  };

  return (
    <div className="w-full group" data-testid={`${cellContext.column.id}-${cellContext.row.index}`}>
      <div
        className={`text-nowrap overflow-hidden text-ellipsis ${!isActive && activeScenarioUuid && 'text-neutral-100 cursor-default'} group-hover:hidden`}
      >
        {selectEmploymentType.selected?.label ?? '-'}
      </div>
      <div
        className={`hidden group-hover:block`}
        data-testid={`${cellContext.column.id}-${cellContext.row.index}-selector`}
      >
        <Select id="employmentType" state={selectEmploymentType} setState={setSelectEmploymentType} onBlur={onBlur} />
      </div>
    </div>
  );
};

export default CellSelectEmploymentType;
