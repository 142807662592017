import React, { Fragment, ReactNode, useEffect, useState } from 'react';
import { Dialog, Transition, DialogPanel, TransitionChild, DialogTitle } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import Input from '../Input/InputWrapper';
import EllipsisDropdown, { Option } from '../EllipsisDropdown';

type ISizeOptions = 'xxxs' | 'xxs' | 'xs' | 'sm' | 'md' | 'xl' | 'full' | 'lg' | 'xxl';
type IHeightOptions = 'default' | 'full';
interface Props {
  id?: string;
  isOpen: boolean;
  onClose?: () => void;
  children: ReactNode;
  title?: string | ReactNode;
  position?: 'top' | 'center';
  size?: ISizeOptions;
  showClose?: boolean;
  modalOptions?: Option[];
  textInput?: {
    id?: string;
    placeholder: string;
    state: Types.InputState;
    setState: React.Dispatch<React.SetStateAction<Types.InputState>>;
    resetState?: React.Dispatch<React.SetStateAction<Types.InputState>>;
    className?: string;
    type?: 'search' | 'textarea' | 'text' | 'password' | 'currency' | 'percentage';
  };
  height?: IHeightOptions;
  styles?: {
    panelClassName?: string;
    panelDivClassName?: string;
    childrenClassName?: string;
    headerClassName?: string;
  };
}
const Modal = ({
  id,
  isOpen,
  onClose,
  title,
  children,
  position,
  size = 'xl',
  showClose,
  textInput,
  modalOptions,
  height = 'default',
  styles,
}: Props): React.ReactElement => {
  const [modalSize, setModalSize] = useState<ISizeOptions>(size);

  useEffect(() => {
    setModalSize(size);
  }, [size]);

  const sizeStyle: Record<ISizeOptions, string> = {
    xxxs: 'max-w-xs',
    xxs: 'max-w-sm',
    xs: 'max-w-md',
    sm: 'max-w-lg',
    md: 'max-w-xl',
    xl: 'max-w-4xl',
    lg: 'max-w-2xl',
    xxl: 'max-w-6xl',
    full: 'max-w-full',
  };

  const heightStyle: Record<IHeightOptions, string> = {
    default: '',
    full: 'min-h-[100vh] !my-0 !rounded-none',
  };

  return (
    <Transition show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-60" onClose={onClose ?? ((): void => {})}>
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div
            className={`flex min-h-full items-end justify-center p-4 text-center sm:p-0 ${
              position === 'top' ? 'sm:items-start' : 'sm:items-center'
            }`}
          >
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel
                className={`relative transform bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full rounded-2xl ${sizeStyle[modalSize]} ${heightStyle[height]} ${styles?.panelClassName}`}
              >
                <div
                  className={`bg-white px-4 pb-4 pt-5 sm:py-6 sm:px-8 rounded-2xl ${styles?.panelDivClassName}`}
                  data-testid={id}
                >
                  <div className={`flex items-start justify-between w-full ${styles?.headerClassName}`}>
                    <DialogTitle as="h3" className="font-bold leading-6 text-gray-900 text-lg mb-1 w-full">
                      {title}
                    </DialogTitle>
                    {textInput && (
                      <div>
                        <Input
                          id={textInput.id ?? ''}
                          placeholder={textInput.placeholder}
                          state={textInput.state}
                          setState={textInput.setState}
                          className={textInput.className}
                          type={textInput.type}
                        />
                      </div>
                    )}
                    {modalOptions && <EllipsisDropdown options={modalOptions} id={`${id}-modal-options`} />}
                    {showClose && (
                      <button onClick={onClose} type="button" data-testid={`${id}-close`}>
                        <XMarkIcon className="size-6 text-neutral-100" />
                      </button>
                    )}
                  </div>
                  <div className={`flex w-full ${styles?.childrenClassName}`}>{children}</div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
export default Modal;
