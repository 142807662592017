import {
  ExpandedState,
  createColumnHelper,
  getCoreRowModel,
  getExpandedRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { format, startOfMonth } from 'date-fns';
import React, { useContext, useEffect, useMemo, useRef } from 'react';
import MonthCell from './MonthCell';
import { IFormulaActual, IFormulaOverride, IRoundDirectionEnum, IVariables } from '../../entity/types';
import { toZonedTime } from 'date-fns-tz';
import { settingsSlice } from '~/store/settingsSlice';
import generateFormulaArray from '../../utils/generateFormulaArray';
import { FinancialModelContext } from '../../context/FinancialModelContext';
import FormulaCell from './FormulaCell';
import FinancialModelBaseTable from './FinancialModelBaseTable';
import request from '~/utils/request';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '~/store';
import toast from 'react-hot-toast';
import isEqual from 'lodash.isequal';
import { IIntegration, IIntegrationMapping } from '~/utils/schemas/integrations';
import { buildExpand, convertDictToGroups } from '../../utils/convertExpandCollapse';
import { IFormattingEnum, IFormula } from '~/services/parallel/formulas.types';
import { IRoundingInstructions } from '~/components/Formulas/context/types';

interface CreatedColumn {
  id: string;
  label: string;
  className?: string;
}

interface CellData {
  roundingInstructions?: { direction: IRoundDirectionEnum; precision: number };
  date?: string;
  uuid?: string;
  formulaTitle?: string;
  value: string;
  variables?: IVariables;
  overrides?: IFormulaOverride[] | null;
  actuals?: IFormulaActual[] | null;
  dataSourceUuids: string[];
  isProtected?: boolean;
  colIndex?: number;
  rowIndex?: number;
  formatting?: IFormattingEnum | null;
}

type TableRow = Record<string, CellData>;

interface GroupedTableData {
  groupName: string;
  subRows: TableRow[];
}

interface IProps {
  setFormulaBuilderState: React.Dispatch<
    React.SetStateAction<{
      isOpen: boolean;
      mode: 'create' | 'edit';
      formulaTitle?: string;
      formulaUuid?: string;
      formulaData?: {
        topLevelFormulaUuid?: string;
        formula: string;
        variables: IVariables;
        formulaList: IFormula[];
        editable?: boolean;
        isProtected?: boolean;
      };
      dataSourceUuids: string[];
      variables?: IVariables;
      roundingInstructions?: IRoundingInstructions;
      formatting?: IFormattingEnum | null;
    }>
  >;
  deleteGroup: (groupName: string) => void;
  updateGroup: (groupName: string) => void;
  integrationsData: {
    integrations: IIntegration[];
    mappings: IIntegrationMapping[];
  };
}

const FinancialModelTable = ({
  setFormulaBuilderState,
  deleteGroup,
  updateGroup,
  integrationsData,
}: IProps): React.ReactNode => {
  const {
    parsedFormulas: formulas,
    overridesHaveChanges,
    actualsHaveChanges,
    setSelectedMonthCell,
    setDownloadableModel,
    monthsBetweenDates,
    dragMode,
    search,
  } = useContext(FinancialModelContext);

  const {
    organization: { uuid: organizationUuid },
    scenario: { activeScenarioUuid },
  } = useSelector((state: State) => state);
  const dispatch = useDispatch();
  const financialModelExpand = useSelector((state: State) => state.settings.financialModelExpand);
  const [sortingState, setSortingState] = React.useState<{ name: string; sortOrder: string[] }[]>(formulas.sorting);
  const [expanded, setExpanded] = React.useState<ExpandedState>(true);
  const columnHelper = createColumnHelper<TableRow>();
  const firstRender = useRef(true);
  const expandUpdateNeeded = useRef(true);

  useEffect(() => {
    const updateSortOrder = async (): Promise<void> => {
      const response = await request({
        url: '/formulas/sorting',
        method: 'PATCH',
        body: { groups: sortingState },
        headers: {
          'Organization-Uuid': organizationUuid,
        },
        params: {
          scenarioUuid: activeScenarioUuid,
        },
      });
      if (response.status >= 400) {
        toast.error('Failed to update sorting order');
      }
    };

    if (!firstRender.current) {
      updateSortOrder();
    } else {
      firstRender.current = false;
    }
  }, [sortingState]);

  useEffect(() => {
    setSortingState((prev) => (isEqual(prev, formulas.sorting) ? prev : formulas.sorting));
  }, [formulas.sorting]);

  const formatTableDataForCsv = ({
    dataForExport,
    monthColumnsForExport,
  }: {
    dataForExport: GroupedTableData[];
    monthColumnsForExport: CreatedColumn[];
  }): string => {
    // Create headers
    const headers = ['Attribute Title', ...monthColumnsForExport.map((month) => month.label)];

    // Format data rows
    const rows = dataForExport.flatMap((group) => {
      // Add a row for the group name
      const groupRow = [group.groupName, ...Array(monthColumnsForExport.length).fill('')];

      // Format the data rows for this group
      const dataRows = group.subRows.map((row) => {
        if (!row) return [];
        const rowData = [row.attributeTitle.value];
        monthColumnsForExport.forEach((month) => {
          const cellData = row[month.id];
          if (cellData) {
            // Check for actuals first, then overrides, then regular value
            if (cellData.actuals && cellData.actuals.length > 0) {
              const actual = cellData.actuals.find((a) => a.date === cellData.date);
              rowData.push(
                actual ? actual.value.toString() : parseFloat((Number(cellData.value) / 100).toFixed(2)).toString(),
              );
            } else if (cellData.overrides && cellData.overrides.length > 0) {
              const override = cellData.overrides.find((o) => o.date === cellData.date);
              rowData.push(
                override ? override.value.toString() : parseFloat((Number(cellData.value) / 100).toFixed(2)).toString(),
              );
            } else {
              rowData.push(parseFloat((Number(cellData.value) / 100).toFixed(2)).toString());
            }
          } else {
            rowData.push('');
          }
        });
        return rowData;
      });

      // Return the group row followed by its data rows
      return [groupRow, ...dataRows];
    });

    const csvData = [headers, ...rows];

    return csvData.map((row) => row.join(',')).join('\n');
  };

  useEffect(() => {
    if (expandUpdateNeeded.current) {
      if (typeof expanded === 'boolean') {
        if (financialModelExpand?.length) {
          const expandedState = financialModelExpand.reduce(
            (output, { name, expanded }) => {
              output[`group_${name}`] = Boolean(expanded);
              return output;
            },
            {} as Record<string, boolean>,
          );
          setExpanded(expandedState);
        } else {
          const newState = buildExpand({
            sorting: formulas.sorting,
          });
          dispatch(
            settingsSlice.actions.update({
              financialModelExpand: newState,
            }),
          );
          const expandedState = newState.reduce(
            (output, { name, expanded }) => {
              output[`group_${name}`] = Boolean(expanded);
              return output;
            },
            {} as Record<string, boolean>,
          );
          setExpanded(expandedState);
        }
      } else {
        const newState = convertDictToGroups({
          sortOrder: formulas.sorting,
          expanded,
        });
        dispatch(
          settingsSlice.actions.update({
            financialModelExpand: newState,
          }),
        );
      }
      expandUpdateNeeded.current = false;
    }
  }, [expanded, financialModelExpand]);

  const { tableData, tableColumns } = useMemo(() => {
    const desiredColumns = [
      {
        id: 'attributeTitle',
        label: '',
        className: 'w-full text-left text-nowrap',
      },
      {
        id: 'formula',
        label: '',
        className: 'w-full text-left text-nowrap',
      },
    ];

    const tableColumns = desiredColumns.map((col) =>
      columnHelper.accessor(col.id, {
        enableResizing: true,
        size: col.id === 'formula' ? 450 : 250,
        minSize: col.id === 'formula' ? 200 : 120,
        enablePinning: true,
        header: () => <div className={col.className}>{col.label.toUpperCase()}</div>,
        cell: (info) => {
          const formula = generateFormulaArray({
            topLevelFormulaUuid: info.row.original.attributeTitle.uuid ?? undefined,
            formula: info.row.original.formula.value,
            variables: info.row.original.formula.variables,
            formulaList: formulas.list,
          });

          let integrationName;
          const dataSourceUuids = info.row.original.formula.dataSourceUuids;
          if (dataSourceUuids.length) {
            const integrationMapping = integrationsData.mappings.find((mapping) =>
              dataSourceUuids.includes(mapping.uuid),
            );
            if (integrationMapping) {
              const integration = integrationsData.integrations.find(
                (integration) => integration.uuid === integrationMapping.integrationUuid,
              );
              integrationName = integration?.source.slug ?? undefined;
            }
          }
          const isContractFormula =
            ['Contract Revenue Recognition', 'Contract Cash Collection', 'Contract Setup Fees'].includes(
              info.row.original.attributeTitle.value,
            ) && col.id === 'formula';

          const isReferencedElsewhere = formulas.list.some((f) =>
            Object.values(f.recipe.variables).some(
              (variable) => variable.formulaUuid === info.row.original.attributeTitle.uuid,
            ),
          );

          return (
            <FormulaCell
              onClick={() => {
                if (info.row.original.formula.variables) {
                  setFormulaBuilderState({
                    isOpen: true,
                    mode: 'edit',
                    formulaTitle: info.row.original.attributeTitle.value,
                    formulaUuid: info.row.original.attributeTitle.uuid,
                    formulaData: {
                      topLevelFormulaUuid: info.row.original.attributeTitle.uuid ?? undefined,
                      formula: info.row.original.formula.value,
                      variables: info.row.original.formula.variables,
                      formulaList: formulas.list,
                      editable: true,
                      isProtected: Boolean(info.row.original.attributeTitle.isProtected),
                    },
                    dataSourceUuids: dataSourceUuids,
                    variables: info.row.original.formula.variables,
                    roundingInstructions: info.row.original.formula.roundingInstructions,
                    formatting: info.row.original.formula.formatting,
                  });
                }
              }}
              valueToDisplay={
                col.id === 'formula' ? formula.map((segment) => segment.element) : info.row.original[col.id].value
              }
              integration={integrationName}
              isContractFormula={isContractFormula ? info.row.original.attributeTitle.value : undefined}
              isIsolated={!isReferencedElsewhere}
            />
          );
        },
        enableSorting: false,
      }),
    );

    const monthColumns = monthsBetweenDates.map((date) => ({
      id: format(startOfMonth(toZonedTime(date, 'UTC')), 'yyyy-MM-dd'),
      label: format(startOfMonth(toZonedTime(date, 'UTC')), 'MMM yyyy'),
      className: 'w-[100px] max-w-[100px] text-right text-nowrap',
    }));

    monthColumns.forEach((month) => {
      tableColumns.push(
        columnHelper.accessor(month.id, {
          enableResizing: false,
          enablePinning: false,
          header: () => <div className={month.className}>{month.label.toUpperCase()}</div>,
          cell: (cellContext) => {
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            if (!cellContext.getValue()) return <div />; // Necessary as table renders invalid cells otherwise
            return <MonthCell cellContext={cellContext} />;
          },
          enableSorting: false,
        }),
      );
    });

    const ungroupedTableData = formulas.list.map((formula) => {
      const row = {
        attributeTitle: {
          value: formula.recipe.name,
          uuid: formula.formulaUuid,
          isProtected: formula.isProtected,
          formulaTitle: formula.recipe.name,
        },
        formula: {
          value: formula.recipe.expression,
          variables: formula.recipe.variables,
          roundingInstructions: formula.recipe.roundingInstructions,
          dataSourceUuids: formula.dataSourceUuids,
          formatting: formula.formatting,
          formulaTitle: formula.recipe.name,
        },
      };
      monthColumns.forEach((month, colIndex) => {
        const matchedFormula = formula.calculations.find((calculation) => {
          const a = format(startOfMonth(toZonedTime(month.id, 'UTC')), 'yyyy-MM-dd');
          const b = format(startOfMonth(toZonedTime(calculation.date, 'UTC')), 'yyyy-MM-dd');
          return a === b;
        });
        if (!matchedFormula) throw new Error('Matching data not present');
        row[month.id] = {
          date: matchedFormula.date,
          value: (matchedFormula.value ?? '').toString(),
          overrides: formula.overrides,
          actuals: formula.actuals,
          uuid: formula.formulaUuid,
          colIndex: colIndex + 3,
          formatting: formula.formatting,
          formulaTitle: formula.recipe.name,
          roundingInstructions: formula.recipe.roundingInstructions,
        };
      });
      return row;
    });
    const formulaUuidsNotInSortingState = ungroupedTableData.filter(
      (row) => !sortingState.some((group) => group.sortOrder.includes(row.attributeTitle.uuid)),
    );
    if (formulaUuidsNotInSortingState.length) {
      sortingState
        .find((group) => group.name === 'Ungrouped Attributes')
        ?.sortOrder.push(...formulaUuidsNotInSortingState.map((row) => row.attributeTitle.uuid));
    }
    const groupedTableData = sortingState.map((group) => {
      return {
        groupName: group.name,
        subRows: group.sortOrder.map((uuid) => {
          const formula = ungroupedTableData.find((row) => row.attributeTitle.uuid === uuid);
          return formula;
        }),
      };
    });

    let globalRowIndex = 1;
    groupedTableData.forEach((group) => {
      group.subRows.forEach((row) => {
        if (row) {
          Object.keys(row).forEach((key) => {
            if (key !== 'attributeTitle' && key !== 'formula') {
              row[key].rowIndex = globalRowIndex;
            }
          });
          globalRowIndex++;
        }
      });
    });

    setSelectedMonthCell((prev) => ({
      ...prev,
      maxCol: monthColumns.length + 2,
      minCol: 3,
      maxRow: formulas.list.length,
      minRow: 1,
    }));

    if (groupedTableData.length) {
      const csvData = formatTableDataForCsv({
        dataForExport: groupedTableData as GroupedTableData[],
        monthColumnsForExport: monthColumns,
      });
      setDownloadableModel(csvData);
    }

    return {
      tableData: groupedTableData,
      tableColumns,
    };
  }, [formulas, sortingState, monthsBetweenDates]);

  const filteredTableData = useMemo(() => {
    if (!search.value) return tableData;

    const matchingFormulaUuids = formulas.list
      .filter((formula) => formula.recipe.name.toLowerCase().includes(search.value.toLowerCase()))
      .map((formula) => formula.formulaUuid);

    return tableData
      .map((group) => {
        const filteredSubRows = group.subRows.filter((subRow) => {
          const attributeTitleUuid = subRow?.attributeTitle.uuid;
          const formulaVariables = subRow?.formula.variables;

          const matchesAttributeTitle = matchingFormulaUuids.includes(attributeTitleUuid);
          const matchesVariables =
            formulaVariables &&
            Object.values(formulaVariables).some((variable) => matchingFormulaUuids.includes(variable.formulaUuid));

          return matchesAttributeTitle || matchesVariables;
        });

        return {
          ...group,
          subRows: filteredSubRows,
        };
      })
      .filter((group) => group.subRows.length > 0);
  }, [tableData, search, formulas]);

  const table = useReactTable({
    columns: tableColumns,
    data: filteredTableData,
    state: {
      expanded,
    },
    enableSorting: true,
    onExpandedChange: (updaterOrValue) => {
      const newExpanded = typeof updaterOrValue === 'function' ? updaterOrValue(expanded) : updaterOrValue;

      if (!dragMode.isDragging) {
        expandUpdateNeeded.current = true;
      }
      setExpanded(newExpanded);
    },
    getRowId: (row, index, parent) => {
      if (!row) return index.toString(); // Ensure row is defined
      if (parent) {
        return `${parent.id}.${row.attributeTitle.uuid || index.toString()}`;
      } else if (row.groupName) {
        return `group_${row.groupName}`;
      } else {
        return row.attributeTitle.uuid || index.toString();
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- tanstack returns undefined rows
    getSubRows: (row) => (row ? row.subRows : []),
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    columnResizeMode: 'onChange',
    debugTable: false,
  });

  const styles = useMemo(() => {
    return {
      table: 'w-full h-full',
      tHead: 'sticky top-0 z-[11] bg-white !shadow-sm ',
      th: 'px-4 py-2 bg-white text-xs font-normal text-neutral-200 text-nowrap overflow-hidden whitespace-nowrap',
      td: 'h-14 border-t border-b border-gray-200 bg-white text-nowrap text-nowrap overflow-hidden whitespace-nowrap',
      tRow: overridesHaveChanges || actualsHaveChanges ? '' : 'financialModelRow',
    };
  }, [overridesHaveChanges, actualsHaveChanges]);

  return (
    <div className="max-w-full h-full overflow-scroll hide-scrollbar">
      <FinancialModelBaseTable
        id="financial-model-table"
        styles={styles}
        table={table}
        deleteGroup={deleteGroup}
        updateGroup={updateGroup}
        sortingState={sortingState}
        setSortingState={setSortingState}
        expandedState={expanded}
      />
    </div>
  );
};

export default FinancialModelTable;
