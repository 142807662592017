import React, { useEffect, useMemo, useState } from 'react';
import Typography from '~/components/Typography';
import request from '~/utils/request';
import { useSelector } from 'react-redux';
import { State } from '~/store';
import { IAPIResponse } from '~/utils/types';
import { StatusCodes } from 'http-status-codes';
import GoalTile from './GoalTile';
import GoalModal from './GoalModal';
import { ICompanyGoal, IConsolidatedGraphBody } from '../../entity/types';
import { ZCompanyGoal } from '../../entity/schemas';
import RevenueGoalGraph from './RevenueGoalGraph';
import { IStringDate } from '~/utils/stringDate/types';
import { getRevenueGoalMoMPercentageAndMonths } from '../../utils/goals/getRevenueGoalMoMPercentageAndMonths';
import { generateRevenueGoalGraphData } from '../../utils/goals/generateRevenueGoalGraphData';
import { ILineProps } from '~/components/LineGraph/entity/types';
import Button from '~/components/Button';
import TargetIcon from './TargetIcon';

const CompanyGoals = ({
  revenueReport,
  currentRevenueGoal,
  setShowRevenueGoal,
}: {
  revenueReport: IConsolidatedGraphBody | null;
  currentRevenueGoal: ICompanyGoal | null;
  setShowRevenueGoal: (show: boolean) => void;
}): React.ReactElement => {
  const organizationUuid = useSelector((state: State) => state.organization.uuid);
  const { momGrowthPercentage, months } = useMemo((): {
    momGrowthPercentage: number | null;
    months: IStringDate[] | null;
  } => {
    return getRevenueGoalMoMPercentageAndMonths({
      revenueReport,
      currentRevenueGoal,
    });
  }, [revenueReport, currentRevenueGoal]);

  const { lines, dataKeys, data } = useMemo((): {
    lines: ILineProps[] | null;
    dataKeys: string[] | null;
    data: IConsolidatedGraphBody['data'] | null;
  } => {
    return generateRevenueGoalGraphData({
      revenueReport,
      momGrowthPercentage,
      months,
    });
  }, [revenueReport, momGrowthPercentage, months]);
  const [createGoalModal, setCreateGoalModal] = useState<{
    open: boolean;
    goalUuid: string | null;
    formulaUuid: string | null;
    targetValue: string | null;
    targetDate: string | null;
  }>({
    open: false,
    goalUuid: null,
    formulaUuid: null,
    targetValue: null,
    targetDate: null,
  });
  const [companyGoals, setCompanyGoals] = useState<ICompanyGoal[]>([]);

  const fetchCompanyGoals = async (): Promise<void> => {
    const response = (await request({
      method: 'GET',
      url: '/goals',
      headers: {
        'Organization-Uuid': organizationUuid,
      },
    })) as IAPIResponse<ICompanyGoal[]>;
    if (response.status === StatusCodes.OK) {
      const parsedResponse = ZCompanyGoal.array().parse(response.data.data);
      setCompanyGoals(parsedResponse.filter((goal) => goal.goalUuid !== currentRevenueGoal?.goalUuid));
    }
  };

  useEffect(() => {
    fetchCompanyGoals();
  }, []);

  const onDeleteGoal = (goalUuid: string): void => {
    setCompanyGoals(companyGoals.filter((goal) => goal.goalUuid !== goalUuid));
  };

  return (
    <div className="flex flex-col gap-5">
      {lines && dataKeys && data && currentRevenueGoal ? (
        <div className="flex flex-col gap-2">
          <Typography weight="semibold">Revenue Goal</Typography>
          <div>
            <RevenueGoalGraph
              revenueReport={revenueReport ?? undefined}
              lines={lines}
              dataKeys={dataKeys}
              data={data}
              goalValue={currentRevenueGoal.targetValue}
              cardClassName="border-b-0 rounded-b-none"
            />
            <GoalTile
              goalUuid={currentRevenueGoal.goalUuid}
              formulaUuid={currentRevenueGoal.formulaUuid ?? ''}
              targetValue={currentRevenueGoal.targetValue}
              targetDate={currentRevenueGoal.targetDate}
              setCreateGoalModal={() => setShowRevenueGoal(true)}
              className="mt-[-4px] rounded-b-2xl"
            />
          </div>
        </div>
      ) : (
        <div className="w-full flex flex-col items-center px-4 pt-6 pb-5 rounded-2xl border border-neutral-50 gap-2">
          <TargetIcon />
          <Typography size="sm" color="disabled">
            No Active Revenue Target
          </Typography>
          <Button fill="clear" className="!w-fit !p-0" onClick={() => setShowRevenueGoal(true)}>
            Add Goal
          </Button>
        </div>
      )}
      <div className="flex flex-col gap-2">
        <Typography weight="semibold">Other Goals</Typography>
        {companyGoals
          .sort((a, b) => {
            if (!a.formulaUuid) return 1;
            if (!b.formulaUuid) return -1;
            return a.formulaUuid.localeCompare(b.formulaUuid);
          })
          .map((goal) => (
            <GoalTile
              key={goal.uuid}
              goalUuid={goal.goalUuid}
              formulaUuid={goal.formulaUuid ?? ''}
              targetValue={goal.targetValue}
              targetDate={goal.targetDate ?? null}
              onDelete={onDeleteGoal}
              setCreateGoalModal={setCreateGoalModal}
            />
          ))}
        <div
          className="flex py-2 px-3 bg-green-15 border border-green-15 rounded cursor-pointer"
          onClick={() => {
            setCreateGoalModal({
              open: true,
              goalUuid: null,
              formulaUuid: null,
              targetValue: null,
              targetDate: null,
            });
          }}
        >
          <Typography color="primaryGreen" weight="medium">
            Add Goal
          </Typography>
        </div>
      </div>
      <GoalModal
        createGoalModal={createGoalModal}
        setCreateGoalModal={setCreateGoalModal}
        setTopLevelGoals={setCompanyGoals}
      />
    </div>
  );
};

export default CompanyGoals;
