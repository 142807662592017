import React, { ReactElement, useContext, useState } from 'react';
import { IInitialValue } from '../entities/types';
import { HeadcountContext } from '~/pages/Headcount/context/HeadcountContext';
import DeletePositionModal from '../../../DeletePositionModal';
import { CellContext } from '@tanstack/react-table';
import EllipsisDropdown from '~/components/EllipsisDropdown';
import { useSelector } from 'react-redux';
import { State } from '~/store';
import { useFeatureFlagHarness } from '~/utils/hooks/useFeatureFlag';

const CellOptions = ({
  cellContext,
  initialValue,
}: {
  cellContext: CellContext<Record<string, IInitialValue>, IInitialValue>;
  initialValue: IInitialValue;
}): ReactElement => {
  const inlineHeadcount = useFeatureFlagHarness('inlineHeadcount');
  const {
    deletePosition,
    setOpenTermDateRowIndex,
    deleteIsLoading,
    setEditPositionUuid,
    inlineCreationFormState: { clearInlineCreateForm },
  } = useContext(HeadcountContext);
  const activeScenarioUuid = useSelector((state: State) => state.scenario.activeScenarioUuid);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const handleAddTerm = (): void => {
    if (inlineHeadcount) {
      clearInlineCreateForm();
    }
    const termDateElementParent: HTMLElement | null = document.querySelector(
      `[data-testid="termDate-${cellContext.row.index}-container"]`,
    );
    if (termDateElementParent) {
      termDateElementParent.click();
    }
    setOpenTermDateRowIndex(cellContext.row.index);
  };

  const handlePayChange = (): void => {
    if (inlineHeadcount) {
      clearInlineCreateForm();
    }
    const payRateElement: HTMLElement | null = document.querySelector(
      `[data-testid="payRate-${cellContext.row.index}"]`,
    );

    if (payRateElement) {
      payRateElement.click();
    }
  };

  const handleClickDelete = (): void => {
    if (inlineHeadcount) {
      clearInlineCreateForm();
    }
    if (activeScenarioUuid) {
      deletePosition({
        positionUuid: initialValue.positionUuid,
      });
    } else {
      setIsDeleteModalOpen(true);
    }
  };

  const handleEdit = (): void => {
    if (inlineHeadcount) {
      clearInlineCreateForm();
    }
    setEditPositionUuid(initialValue.positionUuid);
  };

  return (
    <div>
      <DeletePositionModal
        id="delete-position-modal"
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onConfirm={() =>
          deletePosition({
            positionUuid: initialValue.positionUuid,
          })
        }
        deleteIsLoading={deleteIsLoading}
        positionTitle={initialValue.value ?? ''}
      />
      <EllipsisDropdown
        options={[
          {
            label: 'Edit',
            onClick: handleEdit,
          },
          {
            label: 'Pay Change',
            onClick: handlePayChange,
          },
          {
            label: 'Term',
            onClick: handleAddTerm,
          },
          {
            label: 'Delete',
            onClick: () => handleClickDelete(),
            className: 'text-red-500',
          },
        ]}
        id={`${cellContext.column.id}-${cellContext.row.index}`}
      />
    </div>
  );
};

export default CellOptions;
