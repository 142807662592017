import React from 'react';

const TargetIcon = (): React.ReactElement => {
  return (
    <svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="19.5008" cy="21.3166" r="18.0442" transform="rotate(-90 19.5008 21.3166)" stroke="#C9C9C9" />
      <circle cx="19.5006" cy="21.3167" r="11.6452" transform="rotate(-90 19.5006 21.3167)" stroke="#C9C9C9" />
      <path
        d="M19.5014 15.8096C22.543 15.8096 25.0087 18.2753 25.0087 21.3169C25.0087 24.3585 22.543 26.8242 19.5014 26.8242C16.4598 26.8242 13.9941 24.3585 13.9941 21.3169C13.9941 18.2753 16.4598 15.8096 19.5014 15.8096Z"
        stroke="#C9C9C9"
      />
      <path
        d="M19.2124 21.0284C19.0171 21.2237 19.0171 21.5403 19.2124 21.7355C19.4076 21.9308 19.7242 21.9308 19.9195 21.7355L19.2124 21.0284ZM19.9195 21.7355L30.6281 11.0269L29.921 10.3198L19.2124 21.0284L19.9195 21.7355Z"
        fill="#C9C9C9"
      />
      <path
        d="M35.4359 1V5.39298H40.0432L34.6323 10.8039H30.0786V6.3573L35.4359 1Z"
        fill="white"
        stroke="#C9C9C9"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default TargetIcon;
