import React, { useContext, useEffect, useMemo, useState } from 'react';
import Typography from '~/components/Typography';
import NewExpenseMappingsTable from './NewExpenseMappingsTable';
import { ExpensesPageContext } from '../../context/ExpensesContext';
import { IIntegrationMapping } from '~/services/parallel/integrations.types';
import Button from '~/components/Button';
import LinkToExistingModal from './LinkToExistingModal';
import * as stringDate from '~/utils/stringDate';

const NewExpenseList = (): React.ReactNode => {
  const { expenseIntegrationMappings, searchByName } = useContext(ExpensesPageContext);
  const [unusedExpenseMappings, setUnusedExpenseMappings] = useState<IIntegrationMapping[]>([]);
  const [showUnusedExpenseMappings, setShowUnusedExpenseMappings] = useState(false);
  const [datasourceToLink, setDatasourceToLink] = useState<{ uuid: string | null; name: string | null } | null>(null);

  useEffect(() => {
    if (expenseIntegrationMappings.length) {
      setUnusedExpenseMappings(
        expenseIntegrationMappings.filter((mapping) => {
          return !mapping.currentlyInUse;
        }),
      );
    }
  }, [expenseIntegrationMappings]);

  useEffect(() => {
    if (searchByName && showUnusedExpenseMappings) {
      setShowUnusedExpenseMappings(false);
    }
  }, [searchByName]);

  const newExpenseMappings = useMemo(() => {
    return unusedExpenseMappings.filter((mapping) => {
      return stringDate.isAfter({
        comparison: stringDate.subtractDays(stringDate.getStringDate(new Date()), 14),
        dateToCheck: stringDate.getStringDate(mapping.createdAt),
      });
    });
  }, [unusedExpenseMappings]);

  if (unusedExpenseMappings.length === 0) {
    return null;
  }

  return (
    <div className="relative flex flex-col mx-10 my-4 px-10 py-3 bg-green-15 rounded-2xl">
      <div className="absolute top-1 right-0">
        <Button
          fill="clear"
          onClick={() => {
            setShowUnusedExpenseMappings(!showUnusedExpenseMappings);
          }}
        >
          {showUnusedExpenseMappings ? 'Hide' : 'View/Connect'}
        </Button>
      </div>
      <div
        className={`flex flex-row gap-2 transition-all duration-500 ease-in-out ${showUnusedExpenseMappings ? 'max-h-0 opacity-0' : 'max-h-6 opacity-100 '} `}
      >
        <Typography className="!text-green-400">{unusedExpenseMappings.length} Unlinked Data Sources</Typography>
        {!!newExpenseMappings.length && (
          <div className="bg-green-25 rounded-full px-2.5">
            <Typography color="lightGreen">{newExpenseMappings.length} New</Typography>
          </div>
        )}
      </div>
      <div
        className={`transition-all duration-500 ease-in-out ${
          showUnusedExpenseMappings ? 'max-h-[660px] opacity-100' : 'max-h-0 opacity-0'
        } overflow-hidden`}
      >
        <div className="flex flex-col py-5">
          <Typography size="md" weight="bold">
            Connect Data Sources to Expense
          </Typography>
          <Typography color="secondary">
            Choose to add new expenses to your forecast, merge them with an existing forecasted expense, or exclude it
            from future projections
          </Typography>

          <div className="max-h-[550px] overflow-auto no-scrollbar my-2 rounded-lg border border-neutral-50">
            <NewExpenseMappingsTable onLink={setDatasourceToLink} unusedExpenseMappings={unusedExpenseMappings} />
          </div>
        </div>
      </div>
      <LinkToExistingModal
        isOpen={!!datasourceToLink?.uuid}
        onClose={() => setDatasourceToLink(null)}
        datasourceToLink={datasourceToLink}
      />
    </div>
  );
};

export default NewExpenseList;
