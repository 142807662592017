import React, { useContext, useEffect } from 'react';
import Modal from '~/components/Modal';
import { ExpensesPageContext } from '~/pages/ExpensesDeprecated/context/ExpensesContext';
import {
  GenerateExpensesContext,
  GenerateExpensesContextProvider,
  IActiveSelectionEnum,
  ISegmentState,
} from './GenerateExpensesContext';
import Typography from '~/components/Typography';
import parallelWordmarkLogo from '~/assets/parallelWordmarkLogo.svg';
import {
  CheckIcon,
  ComputerDesktopIcon,
  MegaphoneIcon,
  PuzzlePieceIcon,
  ServerStackIcon,
  UserGroupIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import GeneratedExpensesPage from './GeneratedExpensesPage';

const Segment = ({
  segmentState,
  icon: Icon,
  label,
  setAsActiveSegment,
  isFinalize,
}: {
  segmentState: ISegmentState;
  icon: React.ElementType;
  label: string;
  setAsActiveSegment: () => void;
  isFinalize?: boolean;
}): React.ReactNode => {
  const displayCheckIcon = segmentState.touched && !segmentState.selected;
  const strokeColor = segmentState.selected ? 'stroke-neutral-800' : 'stroke-neutral-100';
  const segmentColor = segmentState.selected ? 'primary' : segmentState.touched ? 'green' : 'disabled';

  const handleClick = (): void => {
    if (segmentState.touched && !isFinalize) {
      setAsActiveSegment();
    }
  };

  return (
    <div
      className={`flex flex-row items-center gap-2 text-nowrap ${
        segmentState.touched && !isFinalize ? 'cursor-pointer' : 'cursor-default'
      }`}
      onClick={handleClick}
    >
      {displayCheckIcon ? (
        <div className="flex justify-center items-center bg-green-200 size-8 border border-green-25 rounded">
          <CheckIcon className="size-4 stroke-white" />
        </div>
      ) : (
        <div className="flex justify-center items-center bg-white size-8 border border-green-25 rounded">
          <Icon className={`size-4 ${strokeColor}`} />
        </div>
      )}
      <Typography weight="medium" color={segmentColor}>
        {label}
      </Typography>
    </div>
  );
};

const CloseGenerateExpensesModal = ({ onClose }: { onClose: () => void }): React.ReactNode => {
  return (
    <button
      onClick={onClose}
      type="button"
      data-testid="close-auto-generate-expenses"
      className="absolute top-2 right-4"
    >
      <XMarkIcon className="size-6 text-neutral-100 hover:text-neutral-200" />
    </button>
  );
};

export const GenerateExpensesContainer = (): React.ReactNode => {
  const { autoGeneratedExpenses, setAutoGeneratedExpenses } = useContext(ExpensesPageContext);
  const {
    setActiveSection,
    peopleAndFacilitiesSegmentState,
    cogsSegmentState,
    softwareExpensesSegmentState,
    marketingSegmentState,
    otherSegmentState,
    resetSegmentStates,
  } = useContext(GenerateExpensesContext);

  useEffect(() => {
    if (!autoGeneratedExpenses) {
      resetSegmentStates();
    }
  }, [autoGeneratedExpenses]);

  const segments = (
    <div className="flex flex-col gap-4">
      <Segment
        segmentState={peopleAndFacilitiesSegmentState}
        icon={UserGroupIcon}
        label="People & Facilities"
        setAsActiveSegment={() => setActiveSection(IActiveSelectionEnum.PEOPLE_AND_FACILITIES)}
      />
      <Segment
        segmentState={cogsSegmentState}
        icon={ServerStackIcon}
        label="Cost of Goods Sold"
        setAsActiveSegment={() => setActiveSection(IActiveSelectionEnum.COGS)}
      />
      <Segment
        segmentState={softwareExpensesSegmentState}
        icon={ComputerDesktopIcon}
        label="Software"
        setAsActiveSegment={() => setActiveSection(IActiveSelectionEnum.SOFTWARE)}
      />
      <Segment
        segmentState={marketingSegmentState}
        icon={MegaphoneIcon}
        label="Marketing"
        setAsActiveSegment={() => setActiveSection(IActiveSelectionEnum.MARKETING)}
      />
      <Segment
        segmentState={otherSegmentState}
        icon={PuzzlePieceIcon}
        label="Other"
        setAsActiveSegment={() => setActiveSection(IActiveSelectionEnum.OTHER)}
      />
    </div>
  );

  return (
    <Modal
      size="full"
      height="full"
      isOpen={autoGeneratedExpenses}
      onClose={() => setAutoGeneratedExpenses(false)}
      styles={{
        headerClassName: '!h-0',
        panelDivClassName: '!p-0 !min-h-[100vh]',
        childrenClassName: ' !min-h-[100vh]',
      }}
    >
      <div className="flex flex-row relative w-full">
        <CloseGenerateExpensesModal onClose={() => setAutoGeneratedExpenses(false)} />
        <div className="flex flex-col gap-4 h-full px-12 bg-green-15">
          <div>
            <img src={parallelWordmarkLogo} alt="Parallel" className="h-6 mb-6 mt-14" />
          </div>
          {segments}
        </div>
        <GeneratedExpensesPage />
      </div>
    </Modal>
  );
};

const GenerateExpensesModal = (): React.ReactNode => {
  return (
    <GenerateExpensesContextProvider>
      <GenerateExpensesContainer />
    </GenerateExpensesContextProvider>
  );
};

export default GenerateExpensesModal;
