import { SparklesIcon } from '@heroicons/react/24/outline';
import React, { useContext, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Typography from '~/components/Typography';
import { State } from '~/store';
import { HeadcountContext } from '~/pages/Headcount/context/HeadcountContext';

const titleToSalaryEstimate = {
  'account executive': '$60K - $120K',
  ae: '$60K - $120K',
  sdr: '$40K - $65K',
  designer: '$70K - $120K',
  'product manager': '$85K - $130K',
  pm: '$85K - $130K',
  engineer: '$100K - $160K',
  'software engineer': '$100K - $160K',
  'customer success manager': '$60K - $100K',
  'cs manager': '$60K - $100K',
};

const CreationRecommendedPayRate = (): React.ReactElement | null => {
  const organizationUuid = useSelector((state: State) => state.organization.uuid);
  const {
    inlineCreationFormState: { inlineCreate, inlineCreateTitle, recommendedPayRateLoading },
  } = useContext(HeadcountContext);
  const [renderRecommendedPayRate, setRenderRecommendedPayRate] = useState(false);
  const [isTyping, setIsTyping] = useState(false);

  useEffect(() => {
    if (inlineCreateTitle.value) {
      setIsTyping(true);
      const timer = setTimeout(() => {
        setIsTyping(false);
      }, 500);

      return () => clearTimeout(timer);
    }
    return undefined;
  }, [inlineCreateTitle.value]);

  useEffect(() => {
    if (isTyping) {
      setRenderRecommendedPayRate(false);
    } else if (recommendedPayRateLoading) {
      setRenderRecommendedPayRate(true);
    }
  }, [isTyping, recommendedPayRateLoading]);

  if (
    organizationUuid !== 'bc517888-11e3-47cb-a639-582c38422a29' ||
    !inlineCreate ||
    !inlineCreateTitle.value ||
    !renderRecommendedPayRate
  ) {
    return null;
  }

  if (recommendedPayRateLoading) {
    return (
      <div className="flex items-center justify-center w-[160px] gap-2 rounded-lg shadow-lg px-2 py-1 mb-1 absolute z-10 bg-white top-0 left-[-8px]">
        <Typography
          size="xs"
          color="lightGreen"
          className="animate-shimmerLoadingText bg-[linear-gradient(110deg,#AAB7A4,45%,#64755C,55%,#AAB7A4)] bg-[length:200%_100%] inline-block bg-clip-text text-transparent"
        >
          Generating Tailored Salary Estimate
        </Typography>
      </div>
    );
  }

  return (
    <div className="flex items-center justify-center w-[160px] gap-2 rounded-lg shadow-lg px-2 py-1 mb-1 absolute z-10 bg-white top-0 left-[-8px]">
      <SparklesIcon className="size-4 text-green-200" />
      <div className="flex flex-col text-center">
        <Typography size="xs" color="lightGreen">
          Salary Estimate
        </Typography>
        <Typography size="xs" color="green">
          {inlineCreateTitle.value.toLowerCase() in titleToSalaryEstimate
            ? titleToSalaryEstimate[inlineCreateTitle.value.toLowerCase() as keyof typeof titleToSalaryEstimate]
            : '$95K - $125K'}
        </Typography>
      </div>
    </div>
  );
};

export default CreationRecommendedPayRate;
