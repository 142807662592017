import React, { useState } from 'react';

export interface IDatePickerState {
  value: {
    startDate: string | null;
    endDate: string | null;
  };
  valid: boolean;
  touched: boolean;
  pristine: boolean;
  minDate?: string | null;
  maxDate?: string | null;
  errorMessage?: string;
  disabled?: boolean;
}

const useDatePicker = (
  initialValues?: Partial<IDatePickerState>,
): [value: IDatePickerState, setState: React.Dispatch<React.SetStateAction<IDatePickerState>>] => {
  const { value, minDate, maxDate, valid, errorMessage, disabled } = initialValues ?? {};
  const initialState: IDatePickerState = {
    value: value ?? {
      startDate: null,
      endDate: null,
    },
    valid: valid ?? false,
    touched: false,
    pristine: true,
    minDate: minDate ?? null,
    maxDate: maxDate ?? null,
    errorMessage: errorMessage ?? 'Start date is required',
    disabled: disabled ?? false,
  };

  const [input, setDatePicker] = useState(initialState);

  return [input, setDatePicker];
};

export default useDatePicker;
