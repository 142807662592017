import { ReactElement, RefObject } from 'react';
import {
  ZFormulaRecipe,
  ZFormulaOverride,
  ZFormulaActual,
  ZFormulaCalculation,
  ZVariable,
  variablesSchema,
  ZRoundingInstructions,
  TimeModifierFunctionEnum,
  ZSorting,
} from './schemas';
import { z } from 'zod';
import { IFormula } from '~/services/parallel/formulas.types';

export type IVariable = z.infer<typeof ZVariable>;
export type IVariables = z.infer<typeof variablesSchema>;
export type IFormulaRecipe = z.infer<typeof ZFormulaRecipe>;
export type IFormulaOverride = z.infer<typeof ZFormulaOverride>;
export type IFormulaActual = z.infer<typeof ZFormulaActual>;
export type IFormulaCalculation = z.infer<typeof ZFormulaCalculation>;
export type ISorting = z.infer<typeof ZSorting>;
export type IRoundingInstructions = z.infer<typeof ZRoundingInstructions>;
export enum IRoundDirectionEnum {
  Up = 'up',
  Down = 'down',
  Nearest = 'nearest',
}

export interface IFormulaSegment {
  element: ReactElement;
  ref: RefObject<HTMLDivElement>;
  textValue: string;
  type: 'constant' | 'formula' | 'operator' | 'invalid' | 'calculated';
}

export interface IUpdateTimeModifier {
  timeModifier: {
    function?: TimeModifierFunctionEnum;
    period?: number;
  };
  formulaForUpdate: IFormula;
  formulaTextValue: string;
  refToUpdate: RefObject<HTMLDivElement>;
  formulaCopy: IFormulaSegment[];
  variablesCopy: IVariables;
}

export interface IUpdateCalculationModifier {
  calculationModifier: {
    jobTitle?: string;
  };
  formulaForUpdate: IFormula;
  formulaTextValue: string;
  refToUpdate: RefObject<HTMLDivElement>;
  formulaCopy: IFormulaSegment[];
  variablesCopy: IVariables;
}

export interface IValueToAdd {
  type: 'constant' | 'formula' | 'operator' | 'invalid' | 'calculated';
  value: string;
  newIndex: number;
  associatedFormula?: IFormula;
}

export const OPERATOR_LIST = ['+', '-', '*', '/', '(', ')'];
