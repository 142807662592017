import React, { ReactElement } from 'react';
import { CellContext } from '@tanstack/react-table';
import { IInitialValue } from './entities/types';
import CellInput from './CellInput';
import { CellSelectDepartment, CellSelectEmploymentType } from './CellSelect';
import CellDateInput from './CellDateInput';
import CellOptions from './CellOptions';
import TogglePositionCell from './TogglePositionCell';
import CellTotalCost from './CellTotalCost/CellTotalCost';
import CellPayRateNew from '../../CellPayRate';

const Cell = ({
  cellContext,
}: {
  cellContext: CellContext<Record<string, IInitialValue>, IInitialValue>;
}): ReactElement => {
  const initialValue = cellContext.getValue();
  switch (cellContext.column.id) {
    case 'isActive': {
      return <TogglePositionCell initialValue={initialValue} />;
    }
    case 'payRate': {
      return <CellPayRateNew initialValue={initialValue} cellContext={cellContext} />;
    }
    case 'departmentUuid': {
      return <CellSelectDepartment initialValue={initialValue} cellContext={cellContext} />;
    }
    case 'employeeName':
    case 'title': {
      return <CellInput initialValue={initialValue} cellContext={cellContext} />;
    }
    case 'hireDate':
    case 'termDate': {
      return <CellDateInput initialValue={initialValue} cellContext={cellContext} />;
    }
    case 'fullyBurdenedCost': {
      return <CellTotalCost initialValue={initialValue} cellContext={cellContext} />;
    }
    case 'employmentType': {
      return <CellSelectEmploymentType initialValue={initialValue} cellContext={cellContext} />;
    }
    case 'options':
    default: {
      return <CellOptions cellContext={cellContext} initialValue={initialValue} />;
    }
  }
};

export default Cell;
