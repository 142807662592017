import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import Button from '~/components/Button';
import Modal from '~/components/Modal';
import Typography from '~/components/Typography';
import store, { State } from '~/store';
import request from '~/utils/request';
import { IAPIResponse } from '~/utils/types';
import CustomizationTile from './CustomizationTile';
import { useInput } from '~/components/Input/InputWrapper';
import { IDashboardConfiguration, organizationSlice } from '~/store/organizationSlice';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import smallMetricsIcon from '~/assets/smallMetricsIcon.svg';
import largeGraphsIcon from '~/assets/largeGraphsIcon.svg';
import leversIcon from '~/assets/leversIcon.svg';
import Skeleton from '~/components/Skeleton';
import { useFormulaInput } from '~/components/FormulaListInput/useFormulaListInput';
import FormulaListInput from '~/components/FormulaListInput';
import { IFormula, IFormulaTypeEnum } from '~/services/parallel/formulas.types';
import * as stringDate from '~/utils/stringDate';

interface ICustomizeDashboardModalProps {
  isOpen: boolean;
  close: () => void;
}

const CustomizeDashboardModal = ({ isOpen, close }: ICustomizeDashboardModalProps): React.ReactNode => {
  const { dashboardConfiguration } = useSelector((state: State) => state.organization.configuration);
  const { uuid: organizationUuid } = useSelector((state: State) => state.organization);
  const { activeScenarioUuid } = useSelector((state: State) => state.scenario);
  const [formulas, setFormulas] = useState<{
    formulas: IFormula[];
    loading: boolean;
  }>({
    formulas: [],
    loading: true,
  });
  const [renderedDashboardConfiguration, setRenderedDashboardConfiguration] =
    useState<IDashboardConfiguration>(dashboardConfiguration);
  const [levers, setLevers] = useState<React.ReactNode[]>([]);
  const [largeGraphs, setLargeGraphs] = useState<React.ReactNode[]>([]);
  const [smallMetrics, setSmallMetrics] = useState<React.ReactNode[]>([]);
  const [addingValue, setAddingValue] = useState<'metrics' | 'graphs' | 'levers' | null>(null);
  const [input, setInput, resetInput] = useInput({
    value: '',
  });

  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    const fetchFormulas = async (): Promise<void> => {
      const formulas = (await request({
        url: '/formulas',
        method: 'GET',
        headers: { 'Organization-Uuid': organizationUuid },
        params: {
          types: [IFormulaTypeEnum.ModelBuilder, IFormulaTypeEnum.Expense],
          scenarioUuid: activeScenarioUuid ?? undefined,
        },
      })) as IAPIResponse<IFormula[]>;
      setFormulas({ formulas: formulas.data.data, loading: false });
    };

    if (isOpen) {
      fetchFormulas();
    }
  }, [isOpen, activeScenarioUuid]);

  const initiallyFilteredFormulaList = useMemo(() => {
    if (!input.value.length) return [];
    const runwayFormula = {
      formulaUuid: 'Runway',
      recipe: {
        name: 'Runway',
      },
    } as IFormula;
    const filteredList = (addingValue === 'metrics' ? [...formulas.formulas, runwayFormula] : formulas.formulas).filter(
      (formula) => {
        const isPastExpense =
          formula.type === IFormulaTypeEnum.Expense &&
          'endDate' in formula.recipe &&
          formula.recipe.endDate &&
          stringDate.isBefore({ comparison: stringDate.getStringDate(), dateToCheck: formula.recipe.endDate });

        return (
          addingValue &&
          addingValue in renderedDashboardConfiguration &&
          !renderedDashboardConfiguration[addingValue as keyof IDashboardConfiguration].includes(formula.formulaUuid) &&
          !isPastExpense
        );
      },
    );
    return filteredList;
  }, [input.value, formulas, addingValue]);

  const { filteredFormulaList, highlightedFormula, setHighlightedFormula, handleKeyDown, onSelectAttribute } =
    useFormulaInput({
      formulaList: initiallyFilteredFormulaList,
      inputValue: input.value,
      onSelectAttribute: (attribute: IFormula): void => {
        setRenderedDashboardConfiguration((prev) => {
          const currentArray = prev[addingValue as keyof IDashboardConfiguration];
          if (!currentArray.includes(attribute.formulaUuid)) {
            return {
              ...prev,
              [addingValue as keyof IDashboardConfiguration]: [...currentArray, attribute.formulaUuid],
            };
          }
          return prev;
        });
        setAddingValue(null);
        resetInput();
      },
      showFormulaOnEquals: true,
    });

  useEffect(() => {
    setRenderedDashboardConfiguration(dashboardConfiguration);
  }, [dashboardConfiguration]);

  const handleRemove = (args: { formulaUuid: string; type: 'metrics' | 'graphs' | 'levers' }): void => {
    setRenderedDashboardConfiguration((prev) => ({
      ...prev,
      [args.type]: prev[args.type].filter((uuid) => uuid !== args.formulaUuid),
    }));
  };

  useEffect(() => {
    if (formulas.formulas.length > 0) {
      if (renderedDashboardConfiguration.levers.length > 0) {
        const leverItems = renderedDashboardConfiguration.levers.reduce((acc, lever) => {
          const formula = formulas.formulas.find((formula) => formula.formulaUuid === lever);
          if (formula) {
            acc.push(
              <CustomizationTile
                title={formula.recipe.name}
                formulaUuid={formula.formulaUuid}
                type="levers"
                onRemove={handleRemove}
              />,
            );
          }
          return acc;
        }, [] as React.ReactNode[]);
        setLevers(leverItems);
      } else {
        setLevers([]);
      }

      if (renderedDashboardConfiguration.graphs.length > 0) {
        const largeGraphItems = renderedDashboardConfiguration.graphs.reduce((acc, graph) => {
          const formula = formulas.formulas.find((formula) => formula.formulaUuid === graph);
          if (formula) {
            acc.push(
              <CustomizationTile
                title={formula.recipe.name}
                formulaUuid={formula.formulaUuid}
                type="graphs"
                onRemove={handleRemove}
              />,
            );
          }
          return acc;
        }, [] as React.ReactNode[]);
        setLargeGraphs(largeGraphItems);
      } else {
        setLargeGraphs([]);
      }

      if (renderedDashboardConfiguration.metrics.length > 0) {
        const smallMetricItems = renderedDashboardConfiguration.metrics.reduce((acc, smallMetric) => {
          if (smallMetric === 'Runway') {
            acc.push(<CustomizationTile title="Runway" formulaUuid="Runway" type="metrics" onRemove={handleRemove} />);
          } else {
            const formula = formulas.formulas.find((formula) => formula.formulaUuid === smallMetric);
            if (formula) {
              acc.push(
                <CustomizationTile
                  title={formula.recipe.name}
                  formulaUuid={formula.formulaUuid}
                  type="metrics"
                  onRemove={handleRemove}
                />,
              );
            }
          }
          return acc;
        }, [] as React.ReactNode[]);
        setSmallMetrics(smallMetricItems);
      } else {
        setSmallMetrics([]);
      }
    }
  }, [formulas, renderedDashboardConfiguration]);

  useEffect(() => {
    if (inputRef.current && addingValue) {
      inputRef.current.focus();
    }
  }, [inputRef, addingValue]);

  const handleSave = async (): Promise<void> => {
    const response = await request({
      url: `/organizations/${organizationUuid}/settings`,
      method: 'PATCH',
      body: {
        dashboardConfiguration: renderedDashboardConfiguration,
      },
    });

    if (response.status === 200) {
      const organizationState = store.getState().organization;
      store.dispatch(
        organizationSlice.actions.update({
          ...organizationState,
          configuration: {
            ...organizationState.configuration,
            dashboardConfiguration: renderedDashboardConfiguration,
          },
        }),
      );
      close();
      setAddingValue(null);
    }
  };

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleDragEnd = (event: DragEndEvent, type: 'metrics' | 'graphs' | 'levers'): void => {
    const { active, over } = event;

    if (!over) return;

    if (active.id !== over.id) {
      setRenderedDashboardConfiguration((items) => {
        const oldIndex = items[type].indexOf(active.id.toString());
        const newIndex = items[type].indexOf(over.id.toString());

        return {
          ...items,
          [type]: arrayMove(items[type], oldIndex, newIndex),
        };
      });
    }
  };

  const loadingSkeleton = (
    <div className="flex flex-col gap-2">
      <Skeleton height={40} width={'full'} baseColor="green" />
      <Skeleton height={40} width={'full'} baseColor="green" />
      <Skeleton height={40} width={'full'} baseColor="green" />
      <Skeleton height={40} width={'full'} baseColor="green" />
    </div>
  );

  return (
    <Modal isOpen={isOpen} size="xl" title="Customize Dashboard">
      <div className="flex flex-col w-full mt-3" data-testid="customize-dashboard-modal">
        <div className="grid grid-cols-3 gap-4">
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={(result) => handleDragEnd(result, 'metrics')}
          >
            <div className="flex flex-col gap-2" data-testid="small-metrics-list">
              <Typography weight="medium" className="flex items-center gap-2">
                <img src={smallMetricsIcon} alt="small metrics" /> Small Metrics
              </Typography>
              {formulas.loading ? (
                loadingSkeleton
              ) : (
                <SortableContext items={renderedDashboardConfiguration.metrics} strategy={verticalListSortingStrategy}>
                  {smallMetrics}
                </SortableContext>
              )}
              {addingValue === 'metrics' ? (
                <FormulaListInput
                  inputId="metrics"
                  ref={inputRef}
                  attributeTitle={input}
                  setAttributeTitle={setInput}
                  handleKeyDown={handleKeyDown}
                  filteredFormulaList={filteredFormulaList}
                  onSelectAttribute={onSelectAttribute}
                  highlightedFormula={highlightedFormula}
                  setHighlightedFormula={setHighlightedFormula}
                />
              ) : (
                <div
                  onClick={() => {
                    setAddingValue('metrics');
                    resetInput();
                  }}
                  data-testid="add-metric-button"
                  className="flex py-2 px-3 bg-green-15 border border-green-15 rounded cursor-pointer"
                >
                  <Typography color="primaryGreen" weight="medium">
                    Add Metric
                  </Typography>
                </div>
              )}
            </div>
          </DndContext>
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={(result) => handleDragEnd(result, 'graphs')}
          >
            <div className="flex flex-col gap-2" data-testid="graphs-list">
              <Typography weight="medium" className="flex items-center gap-2">
                <img src={largeGraphsIcon} alt="large graphs" />
                Large Graphs
              </Typography>
              {formulas.loading ? (
                loadingSkeleton
              ) : (
                <SortableContext items={renderedDashboardConfiguration.graphs} strategy={verticalListSortingStrategy}>
                  {largeGraphs}
                </SortableContext>
              )}
              {addingValue === 'graphs' ? (
                <FormulaListInput
                  inputId="graphs"
                  ref={inputRef}
                  attributeTitle={input}
                  setAttributeTitle={setInput}
                  handleKeyDown={handleKeyDown}
                  filteredFormulaList={filteredFormulaList}
                  onSelectAttribute={onSelectAttribute}
                  highlightedFormula={highlightedFormula}
                  setHighlightedFormula={setHighlightedFormula}
                />
              ) : (
                <div
                  onClick={() => {
                    setAddingValue('graphs');
                    resetInput();
                  }}
                  data-testid="add-graph-button"
                  className="flex py-2 px-3 bg-green-15 border border-green-15 rounded cursor-pointer"
                >
                  <Typography color="primaryGreen" weight="medium">
                    Add Graph
                  </Typography>
                </div>
              )}
            </div>
          </DndContext>
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={(result) => handleDragEnd(result, 'levers')}
          >
            <div className="flex flex-col gap-2" data-testid="levers-list">
              <Typography weight="medium" className="flex items-center gap-2">
                <img src={leversIcon} alt="levers" /> Key Levers
              </Typography>
              {formulas.loading ? (
                loadingSkeleton
              ) : (
                <SortableContext items={renderedDashboardConfiguration.levers} strategy={verticalListSortingStrategy}>
                  {levers}
                </SortableContext>
              )}
              {addingValue === 'levers' ? (
                <FormulaListInput
                  inputId="levers"
                  ref={inputRef}
                  attributeTitle={input}
                  setAttributeTitle={setInput}
                  handleKeyDown={handleKeyDown}
                  filteredFormulaList={filteredFormulaList}
                  onSelectAttribute={onSelectAttribute}
                  highlightedFormula={highlightedFormula}
                  setHighlightedFormula={setHighlightedFormula}
                />
              ) : (
                <div
                  onClick={() => {
                    setAddingValue('levers');
                    resetInput();
                  }}
                  data-testid="add-lever-button"
                  className="flex py-2 px-3 bg-green-15 border border-green-15 rounded cursor-pointer"
                >
                  <Typography color="primaryGreen" weight="medium">
                    Add Lever
                  </Typography>
                </div>
              )}
            </div>
          </DndContext>
        </div>
        <div className="flex items-center justify-between w-full mt-8">
          <div className="w-fit">
            <Button
              fill="clear"
              className="!w-fit !px-0"
              onClick={() => {
                close();
                setAddingValue(null);
                resetInput();
              }}
            >
              Cancel
            </Button>
          </div>
          <div className="w-fit">
            <Button onClick={handleSave}>Save</Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CustomizeDashboardModal;
