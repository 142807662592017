import { IStringDate } from '~/utils/stringDate/types';
import * as stringDate from '~/utils/stringDate';

export const getMoMGrowthPercentageToReachGoal = ({
  currentValue,
  targetValue,
  startMonth,
  targetMonth,
}: {
  currentValue: number;
  targetValue: number;
  startMonth: IStringDate;
  targetMonth: IStringDate;
}): number => {
  const monthsToTarget = stringDate.differenceInCalendarMonths({
    startDate: startMonth,
    endDate: targetMonth,
  });

  if (monthsToTarget <= 0) return 0;
  const ratio = targetValue / currentValue;
  const onePlusR = Math.pow(ratio, 1 / monthsToTarget);
  const growthRate = onePlusR - 1;
  return parseFloat(growthRate.toFixed(4));
};
