import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { IInitialValue } from '../HeadcountTable/Cell/entities/types';
import { CellContext } from '@tanstack/react-table';
import formatCurrency from '~/utils/formatCurrency';
import UpcomingChangePopover from './UpcomingChangePopover';
import useHover from '~/utils/hooks/useHover';
import { z } from 'zod';
import { ZAdditionalCompensationValue, ZTemporalValue } from '../../entity/schemas';
import { HeadcountContext } from '~/pages/Headcount/context/HeadcountContext';
import { useSelector } from 'react-redux';
import { State } from '~/store';
import HoverPopover from '~/components/HoverPopover';
import Typography from '~/components/Typography';
import { IAdditionalCompensationValue } from '../../entity/types';
import UpdateCompensationModal from './UpdateCompensationModal';

const ZPayRateValue = z.object({
  value: z.number({ coerce: true }),
  positionUuid: z.string(),
  effectiveAt: z.union([z.string(), z.literal('onHire')]),
  upcomingChange: z.boolean().optional(),
  changeHistory: z.array(
    ZTemporalValue.extend({
      effectiveAt: z.union([z.string(), z.literal('onHire')]),
      value: z.number({ coerce: true }),
    }),
  ),
  additionalCompensations: z.record(z.string(), z.union([ZAdditionalCompensationValue, z.null()])).optional(),
  additionalCompensationsHistory: z
    .record(z.string(), z.union([z.array(ZAdditionalCompensationValue), z.null()]))
    .optional(),
});

const frequencyToText: Record<string, string> = {
  monthly: 'Monthly',
  quarterly: 'Quarterly',
  annually: 'Annual',
};

const CellPayRate = ({
  initialValue,
  cellContext,
  disabled,
}: {
  initialValue: IInitialValue;
  cellContext?: CellContext<Record<string, IInitialValue>, IInitialValue>;
  disabled?: boolean;
}): ReactElement => {
  const hideSensitiveData = useSelector((state: State) => state.settings.hideSensitiveData);
  const { positionActiveStateDict } = useContext(HeadcountContext);
  const activeScenarioUuid = useSelector((state: State) => state.scenario.activeScenarioUuid);
  const [isActive, setIsActive] = useState(positionActiveStateDict[initialValue.positionUuid]);

  useEffect(() => {
    setIsActive(positionActiveStateDict[initialValue.positionUuid]);
  }, [positionActiveStateDict[initialValue.positionUuid]]);

  const [ref, hovering] = useHover();
  const {
    value,
    positionUuid,
    effectiveAt: positionEffectiveAt,
    changeHistory,
    upcomingChange,
    additionalCompensations,
    additionalCompensationsHistory,
  } = ZPayRateValue.parse(initialValue);

  const [isOpen, setIsOpen] = useState(false);

  const getAdditionalCompensationValue = (additionalCompensation: IAdditionalCompensationValue | null): number => {
    if (!additionalCompensation) return 0;
    if (additionalCompensation.frequency === 'monthly') {
      return additionalCompensation.value * 12;
    } else if (additionalCompensation.frequency === 'quarterly') {
      return additionalCompensation.value * 4;
    } else if (additionalCompensation.frequency === 'annually') {
      return additionalCompensation.value;
    }
    return 0;
  };

  const bonusCompensation = getAdditionalCompensationValue(additionalCompensations?.bonus ?? null);
  const commissionCompensation = getAdditionalCompensationValue(additionalCompensations?.commission ?? null);
  const totalCompensation = value + bonusCompensation + commissionCompensation;

  const disabledStyles = 'text-neutral-100 cursor-default';
  return (
    <div
      title={!hideSensitiveData ? formatCurrency(value, false) : undefined}
      className={`flex flex-row gap-4 items-center justify-end select-none ${isActive || !activeScenarioUuid ? 'cursor-pointer' : disabledStyles}`}
      ref={ref}
      data-testid={`${cellContext?.column.id ?? ''}-${cellContext?.row.index ?? ''}-container`}
    >
      {isOpen && (
        <UpdateCompensationModal
          id={cellContext?.column.id ?? ''}
          isOpen={isOpen}
          onClose={() => {
            setIsOpen(false);
          }}
          positionUuid={positionUuid}
          positionEffectiveAt={positionEffectiveAt}
          payRateChangeHistory={changeHistory}
          bonusChangeHistory={additionalCompensationsHistory?.bonus ?? []}
          commissionChangeHistory={additionalCompensationsHistory?.commission ?? []}
          currentPayRate={value}
          currentBonus={additionalCompensations?.bonus ?? null}
          currentCommission={additionalCompensations?.commission ?? null}
        />
      )}
      {upcomingChange && (
        <UpcomingChangePopover id={`${cellContext?.column.id ?? ''}-${cellContext?.row.index}-upcoming-change`} />
      )}
      {hideSensitiveData ? (
        <div className="cursor-default">*********</div>
      ) : (
        <HoverPopover
          anchor="top"
          hoverDelay={300}
          buttonContent={
            <div
              onClick={() => {
                if (!disabled && isActive) setIsOpen(true);
              }}
              className={`${hovering && 'text-green-400 underline underline-offset-4'}`}
              data-testid={`${cellContext?.column.id ?? ''}-${cellContext?.row.index ?? ''}`}
            >
              {formatCurrency(totalCompensation, false)}
            </div>
          }
          panelClassName=" w-[225px] shadow-[0px_2px_4px_0px_rgba(0,_0,_0,_0.20)] bg-black px-5 py-3 rounded-lg shadow-[0px_2px_4px_0px_rgba(0,_0,_0,_0.20)]"
          panelContent={
            <div className="flex flex-col gap-1">
              <div className="flex flex-row justify-between">
                <Typography weight="semibold" size="xs" color="white">
                  Salary/Wages
                </Typography>
                <Typography size="xs" color="white">
                  {formatCurrency(value, false)}
                </Typography>
              </div>
              {additionalCompensations?.bonus?.value && (
                <div className="flex flex-row justify-between">
                  <Typography
                    weight="semibold"
                    size="xs"
                    color="white"
                  >{`${frequencyToText[additionalCompensations.bonus.frequency]} Bonus`}</Typography>
                  <Typography size="xs" color="white">
                    {formatCurrency(additionalCompensations.bonus.value, false)}
                  </Typography>
                </div>
              )}
              {additionalCompensations?.commission?.value && (
                <div className="flex flex-row justify-between">
                  <Typography
                    weight="semibold"
                    size="xs"
                    color="white"
                  >{`${frequencyToText[additionalCompensations.commission.frequency]} Comm.`}</Typography>
                  <Typography size="xs" color="white">
                    {formatCurrency(additionalCompensations.commission.value, false)}
                  </Typography>
                </div>
              )}
            </div>
          }
        />
      )}
    </div>
  );
};

export default CellPayRate;
