import React, { useContext, useState } from 'react';
import { IExpense } from '../types';
import Typography from '~/components/Typography';
import formatPercent from '~/utils/formatPercent';
import formatCurrency from '~/utils/formatCurrency';
import Button from '~/components/Button';
import { ExpensesPageContext } from '~/pages/ExpensesDeprecated/context/ExpensesContext';
import { convertDepartmentUuidsToDisplayName } from '~/pages/ExpensesDeprecated/utils/convertDepartmentUuidsToDisplayName';
import EllipsisDropdown from '~/components/EllipsisDropdown';
import quickbooksIcon from '~/assets/logos/quickbooks.svg';
import { ChevronDownIcon, ChevronUpIcon, InformationCircleIcon } from '@heroicons/react/24/outline';
import Divider from '~/components/Divider';
import { useSelector } from 'react-redux';
import { State } from '~/store';
import request from '~/utils/request';
import { StatusCodes } from 'http-status-codes';
import HoverPopover from '~/components/HoverPopover';
import { ReadableFormulaDisplay } from '../../utils/ReadableFormulaDisplay';
import { CENTS_PER_DOLLAR } from '~/utils/constants/currency';
import * as api from '~/services/parallel/index';
import logger from '~/utils/logger';
import toast from 'react-hot-toast';
import { IIntegrationMapping } from '~/services/parallel/integrations.types';
import ProposedCardLoadingState from './ProposedCardLoadingState';
import { getProposedCardAcronym } from '../../utils/getProposedCardAcronym';
import LinkToExpenseModal from './LinkToExpenseModal';

const TYPE_DISPLAY_MAP = {
  setCost: 'Set Cost',
  headcountFixed: 'Amount per Employee',
  headcountPercentCompensation: 'Percent of Salary',
  custom: 'Custom',
};

const FREQUENCY_DISPLAY_MAP = {
  monthly: 'Monthly',
  oneTime: 'One Time',
  onHire: 'On Hire',
  quarterly: 'Quarterly',
  annually: 'Annually',
};

const ProposedExpenseCard = ({ expense }: { expense: IExpense }): React.ReactNode => {
  const {
    departmentDict,
    setExpenseUuid,
    setExpenseModal,
    setDesiredCreationStatus,
    expenseIntegrationMappings,
    proposedExpenses,
    setProposedExpenses,
    isProposedExpenseLoadingDictionary,
    setIsProposedExpenseLoadingDictionary,
  } = useContext(ExpensesPageContext);
  const organizationUuid = useSelector((state: State) => state.organization.uuid);
  const [displayDataSources, setDisplayDataSources] = useState<boolean>(false);
  const [displayForecastReasoning, setDisplayForecastReasoning] = useState<boolean>(false);
  const [datasourceUuidToSplit, setDatasourceUuidToSplit] = useState<IIntegrationMapping | null>(null);
  const [datasourceUuidToMove, setDatasourceUuidToMove] = useState<string | null>(null);

  const handleDeleteExpense = async (): Promise<void> => {
    const response = await request({
      method: 'DELETE',
      url: `/expenses/${expense.expenseUuid}`,
      headers: { 'Organization-Uuid': organizationUuid },
    });

    if (response.status === StatusCodes.NO_CONTENT) {
      setProposedExpenses(proposedExpenses.filter((proposedExpense) => proposedExpense.uuid !== expense.uuid));
    }
  };

  const handleSplitOutDataSource = async ({
    dataSourceUuidToSplit,
  }: {
    dataSourceUuidToSplit: string;
  }): Promise<void> => {
    try {
      const dataSource = expenseIntegrationMappings.find((mapping) => mapping.uuid === dataSourceUuidToSplit);
      if (!dataSource) throw new Error('Data source not found');
      setDatasourceUuidToSplit(dataSource);
      const { originalExpense, newExpense } = await api.expenses.splitOutDataSourceFromExpense({
        organizationUuid,
        expenseUuid: expense.expenseUuid,
        dataSourceUuidToSplit,
        remainingDataSourceUuids: expense.dataSourceUuids?.filter((uuid) => uuid !== dataSourceUuidToSplit) ?? [],
      });
      setProposedExpenses(
        proposedExpenses
          .map((expense) =>
            expense.expenseUuid === originalExpense.expenseUuid ? [originalExpense, newExpense] : [expense],
          )
          .flat(),
      );
    } catch (error) {
      if (error instanceof Error) logger.error(error);
      toast.error('Failed to split out data source');
    } finally {
      setDatasourceUuidToSplit(null);
    }
  };

  const handleDoNotForecastDataSource = async ({ dataSourceUuid }: { dataSourceUuid: string }): Promise<void> => {
    try {
      setIsProposedExpenseLoadingDictionary((prev) => ({ ...prev, [expense.expenseUuid]: true }));
      const newExpense = await api.expenses.doNotForecastDatasource({
        organizationUuid,
        expenseUuid: expense.expenseUuid,
        dataSourceUuid,
      });

      if (newExpense) {
        setProposedExpenses(
          proposedExpenses.map((proposedExpense) =>
            proposedExpense.expenseUuid === expense.expenseUuid ? newExpense : proposedExpense,
          ),
        );
        toast.success('Connection has been removed and the expense has been updated');
      } else {
        setProposedExpenses(proposedExpenses.filter((proposedExpense) => proposedExpense.uuid !== expense.uuid));
        toast.success('Connection has been removed and the expense has been removed from the forecast');
      }
    } catch (error) {
      if (error instanceof Error) logger.error(error);
      toast.error('Failed to remove datasource from the forecasted expense');
    } finally {
      setIsProposedExpenseLoadingDictionary((prev) => ({ ...prev, [expense.expenseUuid]: false }));
    }
  };

  if (datasourceUuidToSplit || isProposedExpenseLoadingDictionary[expense.expenseUuid]) {
    return (
      <div className="flex flex-col gap-4">
        <ProposedCardLoadingState name={expense.name} />
        {datasourceUuidToSplit && <ProposedCardLoadingState name={datasourceUuidToSplit.name} />}
      </div>
    );
  }

  return (
    <div className="flex flex-col border border-neutral-50 rounded-lg">
      <div className="flex flex-row w-full px-6 py-4">
        <div className="max-h-[46px] max-w-[46px] min-h-[46px] min-w-[46px] text-[16.5px] text-neutral-200 bg-neutral-15 border border-neutral-50 rounded-full flex items-center justify-center mr-4">
          {getProposedCardAcronym(expense.name)}
        </div>
        <div className="flex flex-row justify-between w-full">
          <div className="flex flex-col w-1/3">
            <Typography weight="semibold">{expense.name}</Typography>
            <Typography color="secondary">{TYPE_DISPLAY_MAP[expense.context.driver]}</Typography>
          </div>
          <div className="flex flex-col w-1/6">
            <Typography color="empty">Amount</Typography>
            {expense.context.amount !== undefined ? (
              <Typography>
                {expense.context.driver === 'headcountPercentCompensation'
                  ? formatPercent({ value: expense.context.amount / 10000 })
                  : formatCurrency(expense.context.amount)}
              </Typography>
            ) : (
              <div className="flex flex-row gap-1">
                <Typography>Formula</Typography>
                <HoverPopover
                  buttonContent={
                    <div className="text-left flex items-center gap-1">
                      <InformationCircleIcon className="size-5 stroke-neutral-400 stroke-[1.5]" />
                    </div>
                  }
                  panelContent={
                    <div className="py-3 px-4 bg-black text-left whitespace-nowrap">
                      <ReadableFormulaDisplay formula={expense.readableFormulaExpression ?? ''} />
                    </div>
                  }
                  panelClassName="shadow-md rounded-lg"
                />
              </div>
            )}
          </div>
          <div className="flex flex-col w-1/6">
            <Typography color="empty">Frequency</Typography>
            <Typography>{FREQUENCY_DISPLAY_MAP[expense.context.frequency]}</Typography>
          </div>
          <div className="flex flex-col w-1/6">
            <Typography color="empty">Departments</Typography>
            <Typography>
              {expense.context.departments[0] === 'ALL'
                ? 'All'
                : convertDepartmentUuidsToDisplayName({
                    departmentUuids: expense.context.departments,
                    departmentDict,
                  })}
            </Typography>
          </div>
          <div className="flex flex-row gap-4 w-1/6">
            {!!expense.dataSourceUuids?.length && (
              <div className="flex justify-center items-center">
                <div
                  onClick={() => setDisplayDataSources(!displayDataSources)}
                  className="flex flex-row cursor-pointer hover:bg-neutral-15 hover:border-neutral-75 items-center border border-neutral-50 justify-center rounded-lg p-2 gap-1"
                >
                  <img src={quickbooksIcon} className="size-5" />
                  <Typography color="lightGray" size="xs">
                    {expense.dataSourceUuids.length}
                  </Typography>
                  {displayDataSources ? (
                    <ChevronUpIcon className="size-4 stroke-neutral-400 stroke-[1.5]" />
                  ) : (
                    <ChevronDownIcon className="size-4 stroke-neutral-400 stroke-[1.5]" />
                  )}
                </div>
              </div>
            )}
            <EllipsisDropdown
              className="flex items-center justify-center"
              options={[
                {
                  label: 'Edit',
                  onClick: (): void => {
                    setExpenseUuid({
                      uuid: expense.uuid,
                      expenseUuid: expense.expenseUuid,
                      type: 'edit',
                    });
                    setDesiredCreationStatus('proposed');
                    setExpenseModal(true);
                  },
                  id: `edit-proposed-expense-${expense.name}`,
                },
                {
                  label: 'Delete',
                  className: 'text-red-400',
                  onClick: handleDeleteExpense,
                },
              ]}
            />
          </div>
        </div>
      </div>
      {expense.context.forecastReasoning && (
        <div className={`flex flex-col bg-neutral-15 ${displayDataSources ? '' : 'rounded-b-lg'}`}>
          <Divider />
          <div
            onClick={() => setDisplayForecastReasoning(!displayForecastReasoning)}
            className={`flex flex-row justify-between items-center px-6 ${displayForecastReasoning ? 'pt-2.5 pb-1' : 'py-2.5'} cursor-pointer`}
          >
            <Typography color="lightGray">Forecast Reasoning</Typography>
            {displayForecastReasoning ? (
              <ChevronUpIcon className="size-5 stroke-neutral-400 stroke-[1.5]" />
            ) : (
              <ChevronDownIcon className="size-5 stroke-neutral-400 stroke-[1.5]" />
            )}
          </div>
          {displayForecastReasoning && (
            <div className="flex flex-col px-6 mb-2.5">
              <Typography color="empty">{expense.context.forecastReasoning}</Typography>
            </div>
          )}
        </div>
      )}
      {displayDataSources && !!expense.dataSourceUuids?.length && (
        <div className="flex flex-col bg-neutral-15 rounded-b-lg">
          <Divider />
          <div className="flex flex-col px-6 py-3">
            {expense.dataSourceUuids.map((dataSourceUuid, index) => {
              const dataSource = expenseIntegrationMappings.find((mapping) => mapping.uuid === dataSourceUuid);
              const datasourceMetadata = expense.datasourcesMetadata?.find(
                (metadata) => metadata.datasourceUuid === dataSourceUuid,
              );
              const isLastItem = index === (expense.dataSourceUuids?.length ?? 0) - 1;
              return (
                <div className="flex flex-col" key={dataSourceUuid}>
                  <div className="flex flex-row w-full justify-between">
                    <div className="flex gap-2 items-center">
                      <div className="bg-white border border-neutral-50 rounded-full px-2 py-1">
                        <Typography color="empty" size="xs">
                          {formatPercent({ value: datasourceMetadata?.percentOfTotalExpense ?? 0, decimalToRound: 0 })}
                        </Typography>
                      </div>
                      <Typography weight="light" color="empty" className="italic">
                        {dataSource?.name}
                      </Typography>
                      <HoverPopover
                        buttonContent={<InformationCircleIcon className="size-4 text-neutral-200" />}
                        panelContent={
                          <div className="bg-black py-2 px-3 rounded-lg flex items-center gap-4">
                            <div className="flex flex-col">
                              <Typography color="empty" size="sm">
                                Last Month
                              </Typography>
                              <Typography color="white">
                                {formatCurrency((datasourceMetadata?.lastMonthValue ?? 0) * CENTS_PER_DOLLAR)}
                              </Typography>
                            </div>
                            <div className="flex flex-col">
                              <Typography color="empty" size="sm">
                                3 Month Average
                              </Typography>
                              <Typography color="white">
                                {formatCurrency((datasourceMetadata?.threeMonthAverageValue ?? 0) * CENTS_PER_DOLLAR)}
                              </Typography>
                            </div>
                            <div className="flex flex-col">
                              <Typography color="empty" size="sm">
                                12 Month Average
                              </Typography>
                              <Typography color="white">
                                {formatCurrency((datasourceMetadata?.twelveMonthAverageValue ?? 0) * CENTS_PER_DOLLAR)}
                              </Typography>
                            </div>
                          </div>
                        }
                        anchor="top"
                      />
                    </div>
                    <div className="flex flex-row gap-2 items-center">
                      {expense.dataSourceUuids?.length && expense.dataSourceUuids.length > 1 && (
                        <Button
                          onClick={() => handleSplitOutDataSource({ dataSourceUuidToSplit: dataSourceUuid })}
                          fill="disabledToClear"
                          className="!w-fit !px-0 !py-0"
                        >
                          Split Out
                        </Button>
                      )}
                      <EllipsisDropdown
                        options={[
                          {
                            label: 'Link with Existing Expense',
                            onClick: () => setDatasourceUuidToMove(dataSourceUuid),
                          },
                          {
                            label: 'Do Not Forecast',
                            className: 'text-red-400',
                            onClick: () => handleDoNotForecastDataSource({ dataSourceUuid }),
                          },
                        ]}
                      />
                    </div>
                  </div>
                  {!isLastItem && <Divider className="my-2" />}
                </div>
              );
            })}
          </div>
        </div>
      )}
      <LinkToExpenseModal
        isOpen={!!datasourceUuidToMove}
        onClose={() => setDatasourceUuidToMove(null)}
        movedFromExpenseUuid={expense.expenseUuid}
        datasourceUuidToMove={datasourceUuidToMove}
      />
    </div>
  );
};

export default ProposedExpenseCard;
