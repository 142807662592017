import { IFormula, IFormulaTypeEnum } from '~/services/parallel/formulas.types';

export const filterFormulaList = ({
  value,
  formulaList,
  inputAttributeTitle,
  attributeType,
}: {
  value: string;
  formulaList: IFormula[];
  inputAttributeTitle: string;
  attributeType?: IFormulaTypeEnum;
}): IFormula[] => {
  if (!value.length) return [];

  const prefilteredList = [...formulaList];
  // Allow Formulas to self reference immediately
  if (inputAttributeTitle) {
    const exists = prefilteredList.some(
      (formula) => formula.recipe.name === inputAttributeTitle && (!attributeType || formula.type === attributeType),
    );
    if (!exists) {
      // We are mocking the formula object here so it can be selected
      prefilteredList.push({
        uuid: 'self',
        formulaUuid: 'self',
        dataSourceUuids: [],
        organizationUuid: '',
        scenarioUuid: null,
        createdBy: null,
        deletedBy: null,
        createdAt: '',
        deletedAt: null,
        isProtected: false,
        type: attributeType ?? IFormulaTypeEnum.ModelBuilder,
        recipe: {
          name: inputAttributeTitle,
          expression: '',
          variables: {},
        },
        overrides: [],
        actuals: [],
        calculations: [],
        formatting: null,
      });
    }
  }

  const filteredList = prefilteredList.filter((formula) =>
    formula.recipe.name.toLowerCase().includes(value.trim().toLowerCase()),
  );

  return filteredList;
};
