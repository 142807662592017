import React, { useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import Button from '~/components/Button';
import Modal from '~/components/Modal';
import Select, { useSelect } from '~/components/Select';
import { ExpensesPageContext } from '~/pages/Expenses/context/ExpensesContext';
import * as api from '~/services/parallel/index';
import { State } from '~/store';
import logger from '~/utils/logger';

const LinkToExpenseModal = ({
  isOpen,
  onClose,
  movedFromExpenseUuid,
  datasourceUuidToMove,
}: {
  isOpen: boolean;
  onClose: () => void;
  movedFromExpenseUuid: string;
  datasourceUuidToMove: string | null;
}): React.ReactNode => {
  const organizationUuid = useSelector((state: State) => state.organization.uuid);
  const { proposedExpenses, setIsProposedExpenseLoadingDictionary, setProposedExpenses } =
    useContext(ExpensesPageContext);
  const [movedToExpenseSelector, setMovedToExpenseSelector] = useSelect({
    options: proposedExpenses.map((expense) => ({ label: expense.name, value: expense.expenseUuid })),
  });
  const [movedToExpenseUuid, setMovedToExpenseUuid] = useState<string | null>(null);

  useEffect(() => {
    if (movedToExpenseSelector.selected?.value && typeof movedToExpenseSelector.selected.value === 'string') {
      setMovedToExpenseUuid(movedToExpenseSelector.selected.value);
    } else {
      setMovedToExpenseUuid(null);
    }
  }, [movedToExpenseSelector.selected?.value]);

  const handleLink = async (): Promise<void> => {
    try {
      if (movedToExpenseUuid && datasourceUuidToMove) {
        setIsProposedExpenseLoadingDictionary((prev) => ({
          ...prev,
          [movedFromExpenseUuid]: true,
          [movedToExpenseUuid]: true,
        }));
        const { movedFromExpense, movedToExpense } = await api.expenses.moveDatasourceToDifferentExpense({
          organizationUuid,
          expenseUuidToMoveTo: movedToExpenseUuid,
          expenseUuidToMoveFrom: movedFromExpenseUuid,
          datasourceUuidToMove: datasourceUuidToMove,
        });
        if (movedFromExpense) {
          setProposedExpenses((prev) =>
            prev.map((expense) => {
              if (expense.expenseUuid === movedToExpense.expenseUuid) {
                return movedToExpense;
              } else if (expense.expenseUuid === movedFromExpense.expenseUuid) {
                return movedFromExpense;
              }
              return expense;
            }),
          );
        } else {
          setProposedExpenses((prev) =>
            prev
              .filter((expense) => expense.expenseUuid !== movedFromExpenseUuid)
              .map((expense) => (expense.expenseUuid === movedToExpense.expenseUuid ? movedToExpense : expense)),
          );
        }
        toast.success('Both the new and existing expenses have been updated');
      }
    } catch (error) {
      if (error instanceof Error) logger.error(error);
      toast.error('Failed to link expense');
    } finally {
      if (movedToExpenseUuid) {
        setIsProposedExpenseLoadingDictionary((prev) => ({
          ...prev,
          [movedFromExpenseUuid]: false,
          [movedToExpenseUuid]: false,
        }));
      }
      onClose();
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Pair with Expense" size="xs">
      <div className="flex flex-col gap-4 w-full items-center mt-4">
        <Select id="select-expense" state={movedToExpenseSelector} setState={setMovedToExpenseSelector} />
        <div className="flex items-center justify-between w-full">
          <Button onClick={onClose} className="!w-fit !px-0" fill="clear">
            Back
          </Button>
          <Button onClick={handleLink} className="!w-fit">
            Link
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default LinkToExpenseModal;
