import React, { ReactElement, useContext } from 'react';
import EllipsisDropdown from '~/components/EllipsisDropdown';
import { FinancialModelContext } from '../context/FinancialModelContext';
import { useFeatureFlagHarness } from '~/utils/hooks/useFeatureFlag';
import { IPrebuiltComponentType } from './PrebuiltComponents/PrebuiltOptions';

const FinancialModelOptions = (): ReactElement => {
  const { downloadableModel, setPrebuiltComponentModalState } = useContext(FinancialModelContext);
  const templateFormulas = useFeatureFlagHarness('templateFormulas');

  const handleExport = (): void => {
    if (downloadableModel) {
      const blob = new Blob([downloadableModel], {
        type: 'text/csv;charset=utf-8;',
      });
      const link = document.createElement('a');
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', 'financial_model_export.csv');
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  };

  const dropdownOptions = templateFormulas
    ? [
        {
          label: 'Add Pre-built Components',
          onClick: () =>
            setPrebuiltComponentModalState({
              isOpen: true,
              display: IPrebuiltComponentType.Options,
            }),
        },
        {
          label: 'Export Values',
          onClick: handleExport,
        },
      ]
    : [
        {
          label: 'Export Values',
          onClick: handleExport,
        },
      ];

  return (
    <div className="relative z-40">
      <EllipsisDropdown id="financial-model-options" options={dropdownOptions} />
    </div>
  );
};

export default FinancialModelOptions;
