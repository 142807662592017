import { z } from 'zod';
import { IRoundDirectionEnum } from './types';
import { ZStringDate } from '~/utils/stringDate/types';

export enum ICalculationTypeEnum {
  HeadcountNumber = 'headcountNumber',
  NewHireNumber = 'newHireNumber',
  SalariesAndWages = 'salariesAndWages',
  Bonuses = 'bonuses',
  Commissions = 'commissions',
  SoftwareExpenses = 'softwareExpenses',
  OtherExpenses = 'otherExpenses',
  PeopleFacilities = 'peopleAndFacilities',
  COGS = 'cogs',
  Marketing = 'marketing',
}

export const ZCalculationTypeEnum = z.nativeEnum(ICalculationTypeEnum);

export enum VariableTypeEnum {
  Constant = 'constant',
  Formula = 'formula',
  Self = 'self',
  Calculated = 'calculated',
  Invalid = 'invalid',
}

export enum TimeModifierFunctionEnum {
  Previous = 'previous',
  Average = 'average',
}

export enum CalculationTypeEnum {
  HeadcountNumber = 'headcountNumber',
  SalariesAndWages = 'salariesAndWages',
  Bonuses = 'bonuses',
  Commissions = 'commissions',
  SoftwareExpenses = 'softwareExpenses',
  OtherExpenses = 'otherExpenses',
  PeopleFacilities = 'peopleAndFacilities',
  COGS = 'cogs',
  Marketing = 'marketing',
}

export enum IFormattingEnum {
  Number = 'number',
  Currency = 'currency',
  Percent = 'percent',
}

export const ZCalculationModifier = z
  .object({
    jobTitle: z.string().optional(),
  })
  .optional();

export const ZVariable = z.object({
  type: z.nativeEnum(VariableTypeEnum),
  formulaUuid: z.string().nullable(),
  constantValue: z.union([z.number(), z.string()]).optional().nullable(),
  timeModifier: z
    .object({
      function: z.nativeEnum(TimeModifierFunctionEnum),
      period: z.number(),
    })
    .partial(),
  calculationType: ZCalculationTypeEnum.nullable(),
  calculationModifier: ZCalculationModifier,
});

export const ZConstantVariable = ZVariable.extend({
  type: z.literal(VariableTypeEnum.Constant),
  formulaUuid: z.literal(null),
  constantValue: z.number(),
});

export const ZFormulaVariable = ZVariable.extend({
  type: z.literal(VariableTypeEnum.Formula),
  formulaUuid: z.string(),
  constantValue: z.null(),
});

export const ZCalculationVariable = ZVariable.extend({
  type: z.literal(VariableTypeEnum.Calculated),
  calculationType: ZCalculationTypeEnum,
  formulaUuid: z.null(),
  constantValue: z.null(),
});

export const ZInvalidVariable = ZVariable.extend({
  type: z.literal(VariableTypeEnum.Self),
  formulaUuid: z.null(),
  constantValue: z.string(),
  calculationType: z.null(),
});

export const ZRoundingInstructions = z
  .object({
    direction: z.nativeEnum(IRoundDirectionEnum),
    precision: z.number(),
  })
  .nullable();

export const variablesSchema = z.record(z.string().regex(/^\$\d+$/), ZVariable);

export const ZFormulaRecipe = z.object({
  name: z.string(),
  expression: z.string(),
  variables: variablesSchema,
  roundingInstructions: ZRoundingInstructions.optional(),
  relatedResourceUuid: z.string().uuid().nullable().optional(),
  startDate: ZStringDate.nullable().optional(),
  endDate: ZStringDate.nullable().optional(),
});

export const ZFormulaCalculation = z.object({
  date: z.string(),
  value: z.number().nullable(),
});

export const ZFormulaOverride = z.object({
  date: z.string(),
  value: z.number(),
});

export const ZFormulaActual = z.object({
  date: z.string(),
  value: z.number(),
});

export const ZSorting = z.object({
  uuid: z.string(),
  name: z.string(),
  formulaUuids: z.array(z.string()),
});
