import React, { useContext, useRef, useEffect } from 'react';
import { HeadcountContext } from '~/pages/Headcount/context/HeadcountContext';
import Input from '~/components/Input/InputWrapper';
import { IInitialValue } from '../Cell/entities/types';
import { CellContext } from '@tanstack/react-table';
import { z } from 'zod';
import Button from '~/components/Button';

const CreationInput = ({
  cellContext,
  handleBlur,
}: {
  cellContext: CellContext<Record<string, IInitialValue>, IInitialValue>;
  handleBlur: () => void;
}): React.ReactNode => {
  const inputRef = useRef<HTMLInputElement>(null);

  const {
    inlineCreationFormState: {
      inlineCreate,
      setInlineCreate,
      inlineCreateTitle,
      setInlineCreateTitle,
      inlineCreateEmployeeName,
      setInlineCreateEmployeeName,
      setRecommendedPayRateLoading,
    },
  } = useContext(HeadcountContext);
  const parsedColumnId = z.enum(['employeeName', 'title']).parse(cellContext.column.id);

  useEffect(() => {
    if (inlineCreate && parsedColumnId === 'employeeName' && inputRef.current) {
      inputRef.current.focus();
    }
  }, [inlineCreate, parsedColumnId]);

  if (parsedColumnId === 'employeeName' && !inlineCreate) {
    return (
      <Button
        id="create-position-button-inline"
        fill="clear"
        className="!w-fit !px-0"
        onClick={() => setInlineCreate(true)}
      >
        + Add Position
      </Button>
    );
  }

  return inlineCreate ? (
    <Input
      id={`create-${parsedColumnId}`}
      type="text"
      onBlur={() => {
        if (parsedColumnId === 'title') {
          setRecommendedPayRateLoading(true);
          setTimeout(() => {
            setRecommendedPayRateLoading(false);
          }, 3000);
        }
        handleBlur();
      }}
      state={parsedColumnId === 'title' ? inlineCreateTitle : inlineCreateEmployeeName}
      setState={parsedColumnId === 'title' ? setInlineCreateTitle : setInlineCreateEmployeeName}
      textRef={inputRef}
      placeholder={parsedColumnId === 'title' ? 'Title' : 'Name (Optional)'}
      className="min-w-[150px]"
    />
  ) : (
    <div />
  );
};

export default CreationInput;
