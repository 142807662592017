import React, { useContext, useEffect, useState } from 'react';
import { DashboardPageContext, DashboardPageContextProvider } from './context/DashboardContext';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '~/store';
import Header from '~/components/Header';
import UserDateRange from '~/components/UserDateRange';
import Typography from '~/components/Typography';
import DashboardGraphs from './components/DashboardGraphs/DashboardGraphs';
import DashboardMetrics from './components/DashboardMetrics/DashboardMetrics';
import HeadcountProvider, { HeadcountContext } from '~/pages/Headcount/context/HeadcountContext';
import * as HeadcountTimeline from '~/pages/Headcount/components/HeadcountTimeline';
import CreatePosition from '../Headcount/components/CreatePosition';
import { AdjustmentsVerticalIcon, ChevronRightIcon, UserPlusIcon } from '@heroicons/react/24/outline';
import SegmentedControlUnderlined from '~/components/SegmentedControlUnderlined';
import DashboardLeverGraphs from './components/DashboardLeverGraphs/DashboardLeverGraphs';
import Button from '~/components/Button';
import CustomizeDashboardModal from './components/CustomizeDashboard/CustomizeDashboardModal';
import { settingsSlice } from '~/store/settingsSlice';
import { ShareButton } from './components/ShareButton';
import ScenarioDropdown from '~/components/ScenarioDropdown';
import ComparisonDropdown from './components/CompareScenarioContainer/ComparisonDropdown/ComparisonDropdown';
import SelectedComparisonContainer from './components/CompareScenarioContainer/SelectedComparisonContainer';
import CompanyGoalsModal from './components/Goals/CompanyGoalsModal';
import Divider from '~/components/Divider';
import useQueryParams from '~/utils/hooks/useQueryParams';

const DashboardContainer = (): React.ReactNode => {
  const { reports, reload, pageLoading } = useContext(DashboardPageContext);
  const {
    reload: reloadHeadcount,
    positionFormState,
    createPositionModalIsOpen,
    setCreatePositionModalIsOpen,
    hasReloaded,
    setHasReloaded,
    pageLoading: headcountPageLoading,
  } = useContext(HeadcountContext);
  const dispatch = useDispatch();
  const { activeScenarioUuid, activeScenarioData, selectedScenarioUuids, scenarios } = useSelector(
    (state: State) => state.scenario,
  );
  const { collapseInteractiveSection, isInteractiveSectionCollapsed } = useSelector((state: State) => state.settings);
  const sideMenuExpanded = useSelector((state: State) => state.user.preferences.sideMenuExpanded);
  const userUuid = useSelector((state: State) => state.user.uuid);
  const [hideInteractiveSection, setHideInteractiveSection] = useState<boolean>(false);
  const [selectedInteractiveSection, setSelectedInteractiveSection] = useState<string>('levers');
  const [showCustomizeDashboardModal, setShowCustomizeDashboardModal] = useState<boolean>(false);
  const [rightPosition, setRightPosition] = useState<string>('-right-10');
  const [queryParams] = useQueryParams();
  const [goalsModalOpen, setGoalsModalOpen] = useState<boolean>(queryParams.get('showRevenueGoal') === 'true');

  useEffect(() => {
    if (queryParams.get('showRevenueGoal') === 'true') {
      setGoalsModalOpen(true);
    }
  }, [queryParams]);

  useEffect(() => {
    const checkScrollbar = (): void => {
      const childContainer = document.getElementById('child-container');
      if (childContainer) {
        const hasScrollbar = childContainer.scrollHeight > childContainer.clientHeight;
        if (hasScrollbar) {
          setRightPosition('-right-10'); // Adjust this value as needed
        } else {
          setRightPosition('-right-[52px]'); // Original value
        }
      }
    };

    checkScrollbar();
    window.addEventListener('resize', checkScrollbar);

    return () => {
      window.removeEventListener('resize', checkScrollbar);
    };
  }, [pageLoading, headcountPageLoading]);

  useEffect(() => {
    const handleResize = (): void => {
      if (window.innerWidth <= 1000) {
        setHideInteractiveSection(true);
      } else if (sideMenuExpanded && window.innerWidth <= 1080) {
        setHideInteractiveSection(true);
      } else if (!sideMenuExpanded && window.innerWidth > 1000) {
        setHideInteractiveSection(false);
      } else {
        setHideInteractiveSection(false);
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [sideMenuExpanded]);

  useEffect(() => {
    if (!pageLoading && !headcountPageLoading) {
      reload();
    }
  }, [hasReloaded]);

  const selectedComparisonDisplay = (
    <div className={`flex flex-row gap-2 overflow-x-auto no-scrollbar`}>
      {selectedScenarioUuids.map((scenario, index) => {
        const scenarioForUuid = scenarios.find((s) => s.uuid === scenario);
        if (!scenarioForUuid) {
          return null;
        }

        return <SelectedComparisonContainer scenario={scenarioForUuid} key={scenario} index={index} />;
      })}
      {activeScenarioUuid && activeScenarioData && (
        <SelectedComparisonContainer scenario={activeScenarioData} isActive />
      )}
    </div>
  );

  const dashboardHeader = (
    <Header
      title="Dashboard"
      startChildren={
        <div className="flex flex-row gap-2">
          <ScenarioDropdown reload={reload} />
        </div>
      }
      endChildren={
        <div className="w-full flex flex-row justify-between items-center max-sm:hidden gap-2">
          {!hideInteractiveSection && <ShareButton disabled={!!activeScenarioUuid} />}
          {userUuid && <UserDateRange pickerAlignment="right" />}
        </div>
      }
    />
  );

  const hiresLabel = (
    <div className="flex gap-2 justify-center items-center">
      <UserPlusIcon className="size-5" />
      <span>Hires</span>
    </div>
  );
  const leversLabel = (
    <div className="flex gap-2 justify-center items-center">
      <AdjustmentsVerticalIcon className="size-5" />
      <span>Levers</span>
    </div>
  );

  return (
    <div
      className={`transition-all duration-500 w-full flex flex-col max-sm:items-center max-sm:pb-16 overflow-x-clip`}
    >
      {dashboardHeader}
      <div className="flex w-full h-full px-10 pt-5 justify-center flex flex-col">
        <div className="flex w-full justify-center items-center px-10">
          <div
            className={`flex flex-row gap-2 w-full ${!hideInteractiveSection && !isInteractiveSectionCollapsed ? 'max-w-[1438px]' : 'max-w-[1000px]'} justify-start`}
          >
            <ComparisonDropdown />
            {selectedComparisonDisplay}
          </div>
        </div>
        <div className="flex flex-row w-full justify-center px-10 pt-1 pb-6 gap-3 z-10">
          <div className="w-full flex flex-col gap-3 max-w-[1000px]">
            <DashboardMetrics staticMetrics={reports.static} consolidatedReports={reports.consolidated} />
            <DashboardGraphs consolidatedReports={reports.consolidated} />
          </div>
          {!hideInteractiveSection && !isInteractiveSectionCollapsed && (
            <div
              className={`sticky top-[88px] flex flex-col gap-2 max-h-[calc(95dvh-106px)] h-fit w-[426px] max-w-[426px] min-w-[426px] transform transition-transform duration-500 ${
                collapseInteractiveSection && ' translate-x-[calc(50vw)]'
              }`}
            >
              <div className="border border border-green-50 rounded-xl px-4 pb-4 flex flex-col flex-grow w-full h-full overflow-y-auto !shadow-[0_2px_9px_0_rgba(0,_0,_0,_0.04)]">
                <div className="mb-3 pt-1 sticky top-0 bg-white z-10">
                  <SegmentedControlUnderlined
                    name="selectInteractiveSection"
                    segments={[
                      { value: 'levers', label: leversLabel },
                      { value: 'hires', label: hiresLabel },
                    ]}
                    value={selectedInteractiveSection}
                    setValue={setSelectedInteractiveSection}
                  />
                </div>
                {selectedInteractiveSection === 'levers' ? (
                  <DashboardLeverGraphs consolidatedReports={reports.consolidated} />
                ) : (
                  <HeadcountTimeline.component
                    onlyNewHires
                    addNewPosition={() => setCreatePositionModalIsOpen(true)}
                    createScenario
                    reloadDashboard={reload}
                  />
                )}
              </div>
              <button
                className="absolute -right-8 top-[calc(50%-28px)] w-8 h-12 bg-white flex justify-center items-center rounded-r-full border-r border-t border-b border-green-50"
                onClick={() => {
                  dispatch(settingsSlice.actions.update({ collapseInteractiveSection: true }));
                  setTimeout(() => {
                    dispatch(settingsSlice.actions.update({ isInteractiveSectionCollapsed: true }));
                  }, 300);
                }}
              >
                <ChevronRightIcon className="size-5 text-green-400" />
              </button>
              {!pageLoading && (
                <div className="flex h-fit w-full justify-end items-center gap-2">
                  <Button
                    fill="clear"
                    className="!w-fit !p-0"
                    id="customize-dashboard-button"
                    onClick={() => setShowCustomizeDashboardModal(true)}
                  >
                    Customize Dashboard
                  </Button>
                  <Divider orientation="vertical" className="text-neutral-50" />
                  <Button fill="clear" className="!w-fit !p-0" onClick={() => setGoalsModalOpen(true)}>
                    Goals
                  </Button>
                </div>
              )}
            </div>
          )}
        </div>
        {!hideInteractiveSection && (
          <div
            className={`absolute top-[calc(50%-64px)] ${rightPosition} -rotate-90 transform transition-opacity duration-500 ${
              collapseInteractiveSection ? 'opacity-100 z-10' : 'opacity-0 z-0'
            }`}
          >
            <button
              onClick={() => {
                dispatch(settingsSlice.actions.update({ isInteractiveSectionCollapsed: false }));
                setTimeout(() => {
                  dispatch(settingsSlice.actions.update({ collapseInteractiveSection: false }));
                }, 150);
              }}
              className="border-l border-t border-r border-green-50 rounded-tl-lg rounded-tr-lg py-1 px-3 bg-white"
            >
              <Typography size="xs" color="green" className="!font-thin tracking-widest ">
                SHOW LEVERS
              </Typography>
            </button>
          </div>
        )}
      </div>
      <CustomizeDashboardModal
        isOpen={showCustomizeDashboardModal}
        close={() => setShowCustomizeDashboardModal(false)}
      />
      <CreatePosition
        isOpen={createPositionModalIsOpen}
        setModal={setCreatePositionModalIsOpen}
        positionFormState={positionFormState}
        reload={() => {
          reload();
          reloadHeadcount();
        }}
        setHasReloaded={setHasReloaded}
        createScenario
        awaitCalculations
      />
      <CompanyGoalsModal isOpen={goalsModalOpen} close={() => setGoalsModalOpen(false)} />
    </div>
  );
};

const Dashboard = (): React.ReactNode => {
  return (
    <DashboardPageContextProvider>
      <HeadcountProvider>
        <DashboardContainer />
      </HeadcountProvider>
    </DashboardPageContextProvider>
  );
};

export default Dashboard;
