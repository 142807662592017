import React, { ReactElement } from 'react';
import Button from '~/components/Button';
import { TrashIcon } from '@heroicons/react/24/outline';
import CurrencyInput from '~/components/Input/currencyInput/CurrencyInput';
import Select from '~/components/Select';
import { SelectState } from '~/components/Select/Select.types';
import { IPeriodPickerState } from '~/components/PeriodPicker/usePeriodPicker';
import PeriodPicker from '~/components/PeriodPicker';

interface Props {
  addNewAdditionalCompensation: boolean;
  hireDate: string;
  setAddNewAdditionalCompensation: React.Dispatch<React.SetStateAction<boolean>>;
  effectiveDate: IPeriodPickerState;
  setEffectiveDate: React.Dispatch<React.SetStateAction<IPeriodPickerState>>;
  additionalCompensation: Types.InputState;
  setAdditionalCompensation: React.Dispatch<React.SetStateAction<Types.InputState>>;
  frequency: SelectState;
  setFrequency: React.Dispatch<React.SetStateAction<SelectState>>;
  timing: SelectState;
  setTiming: React.Dispatch<React.SetStateAction<SelectState>>;
  onCancel: () => void;
}

const NewAdditionalCompensationForm = ({
  addNewAdditionalCompensation,
  hireDate,
  setAddNewAdditionalCompensation,
  effectiveDate,
  setEffectiveDate,
  additionalCompensation,
  setAdditionalCompensation,
  frequency,
  setFrequency,
  timing,
  setTiming,
  onCancel,
}: Props): ReactElement => {
  return addNewAdditionalCompensation ? (
    <div className="flex flex-row gap-2 items-start my-1">
      <div className="w-[23%]">
        <CurrencyInput
          id="additionalCompensation"
          state={additionalCompensation}
          setState={setAdditionalCompensation}
          required
        />
      </div>
      <div className="w-[23%]">
        <Select id="frequency" state={frequency} setState={setFrequency} required />
      </div>
      <div className="w-[23%]">
        <Select id="timing" state={timing} setState={setTiming} required />
      </div>
      <div className="w-[23%]">
        <PeriodPicker id="effectiveDate" beAfter={hireDate} state={effectiveDate} setState={setEffectiveDate} />
      </div>
      <TrashIcon
        onClick={onCancel}
        className="fixed right-6 mt-2.5 size-5 text-neutral-100 cursor-pointer hover:text-neutral-400"
      />
    </div>
  ) : (
    <Button
      id="add-pay-change"
      onClick={() => {
        setAddNewAdditionalCompensation(true);
      }}
      fill="clear"
      className="!w-fit !px-0 my-1"
    >
      + Add Change
    </Button>
  );
};

export default NewAdditionalCompensationForm;
