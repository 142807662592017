export const formatCurrencyInput = ({
  inputValue,
  decimalSeparator = '.',
  groupSeparator = ',',
  preserveLeadingZeros = true,
}: {
  inputValue: string;
  decimalSeparator?: string;
  groupSeparator?: string;
  preserveLeadingZeros?: boolean;
}): string => {
  if (!inputValue) return '';
  inputValue = inputValue.replace(/,/g, '');
  const isNegative = inputValue[0] === '-';
  const unsignedValue = isNegative ? inputValue.slice(1) : inputValue;
  const [integerPart, fractionPart] = unsignedValue.split(decimalSeparator);

  let formattedIntegerPart = integerPart;
  if (groupSeparator) {
    // eslint-disable-next-line security/detect-unsafe-regex
    formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, groupSeparator);
  }

  if (!preserveLeadingZeros) {
    formattedIntegerPart = formattedIntegerPart ? parseInt(formattedIntegerPart.replace(/,/g, '')).toString() : '0';
  }

  let formattedValue =
    typeof fractionPart !== 'undefined'
      ? `${formattedIntegerPart}${decimalSeparator}${fractionPart.slice(0, 2)}`
      : formattedIntegerPart;

  if (isNegative) {
    formattedValue = `-${formattedValue}`;
  }

  return formattedValue;
};
