import React, { useContext, useEffect } from 'react';
import Select, { useSelect } from '~/components/Select';
import { HeadcountContext } from '~/pages/Headcount/context/HeadcountContext';
import { IEmploymentType } from '~/pages/Headcount/entity/types';
import { employmentTypeToLabel } from '~/pages/Headcount/entity/types';

const CreationEmploymentType = ({ handleBlur }: { handleBlur: () => void }): React.ReactElement => {
  const {
    inlineCreationFormState: { inlineCreate, inlineCreateEmploymentType, setInlineCreateEmploymentType },
  } = useContext(HeadcountContext);
  const [selectEmploymentType, setSelectEmploymentType] = useSelect({
    options: [
      {
        label: employmentTypeToLabel[IEmploymentType.FullTime],
        value: IEmploymentType.FullTime,
      },
      {
        label: employmentTypeToLabel[IEmploymentType.PartTime],
        value: IEmploymentType.PartTime,
      },
      {
        label: employmentTypeToLabel[IEmploymentType.Contractor],
        value: IEmploymentType.Contractor,
      },
    ],
    selected: {
      label: employmentTypeToLabel[inlineCreateEmploymentType],
      value: inlineCreateEmploymentType,
    },
  });

  useEffect(() => {
    if (selectEmploymentType.selected?.value) {
      setInlineCreateEmploymentType(selectEmploymentType.selected.value as IEmploymentType);
    }
  }, [selectEmploymentType.selected?.value]);

  return inlineCreate ? (
    <Select
      id="create-employment-type"
      state={selectEmploymentType}
      setState={setSelectEmploymentType}
      onBlur={handleBlur}
    />
  ) : (
    <div />
  );
};

export default CreationEmploymentType;
