import { createSlice } from '@reduxjs/toolkit';
import Pusher from 'pusher-js';

export interface SocketState {
  pusher: Pusher | null;
  channels: Record<string, unknown>;
}

const initialState = {
  pusher: null,
  channels: {},
} as SocketState;

export const socketSlice = createSlice({
  name: '_socket',
  initialState,
  reducers: {
    setPusher: (state, action) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      state.pusher = action.payload;
    },
    reset: () => initialState,
  },
});

export const { setPusher } = socketSlice.actions;
export default socketSlice.reducer;
