import React, { useContext } from 'react';
import Modal from '~/components/Modal';
import { HeadcountContext } from '~/pages/Headcount/context/HeadcountContext';
import Button from '~/components/Button';
import Typography from '~/components/Typography';
import AdditionalCompensationInputs from '../../AdditionalCompensationInputs';

const CreationAdditionalCompensation = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}): React.ReactNode => {
  const {
    inlineCreationFormState: {
      addInlineCreateCommission,
      setAddInlineCreateCommission,
      inlineCreateCommission,
      setInlineCreateCommission,
      inlineCreateCommissionFrequency,
      setInlineCreateCommissionFrequency,
      inlineCreateCommissionEffectiveMonth,
      setInlineCreateCommissionEffectiveMonth,
      addInlineCreateBonus,
      setAddInlineCreateBonus,
      inlineCreateBonus,
      setInlineCreateBonus,
      inlineCreateBonusFrequency,
      setInlineCreateBonusFrequency,
      inlineCreateBonusEffectiveMonth,
      setInlineCreateBonusEffectiveMonth,
      resetInlineBonusAndCommission,
    },
  } = useContext(HeadcountContext);

  return (
    <Modal
      title="Additional Compensation"
      isOpen={isOpen}
      onClose={() => {
        resetInlineBonusAndCommission();
        onClose();
      }}
      size="sm"
    >
      <div className="flex flex-col">
        <Typography color="secondary">
          Add commissions/bonuses to give a complete picture of the positions total compensation
        </Typography>
        <AdditionalCompensationInputs
          addCommission={addInlineCreateCommission}
          setAddCommission={setAddInlineCreateCommission}
          commission={inlineCreateCommission}
          setCommission={setInlineCreateCommission}
          commissionFrequency={inlineCreateCommissionFrequency}
          setCommissionFrequency={setInlineCreateCommissionFrequency}
          commissionEffectiveMonthPattern={inlineCreateCommissionEffectiveMonth}
          setCommissionEffectiveMonthPattern={setInlineCreateCommissionEffectiveMonth}
          addBonus={addInlineCreateBonus}
          setAddBonus={setAddInlineCreateBonus}
          bonus={inlineCreateBonus}
          setBonus={setInlineCreateBonus}
          bonusFrequency={inlineCreateBonusFrequency}
          setBonusFrequency={setInlineCreateBonusFrequency}
          bonusEffectiveMonthPattern={inlineCreateBonusEffectiveMonth}
          setBonusEffectiveMonthPattern={setInlineCreateBonusEffectiveMonth}
        />
        <div className="flex flex-row justify-end mt-4 justify-between">
          <Button
            onClick={() => {
              resetInlineBonusAndCommission();
              onClose();
            }}
            className="!w-fit !px-0 "
            fill="clear"
          >
            Cancel
          </Button>
          <Button className="!w-fit !px-5" onClick={onClose}>
            Save
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default CreationAdditionalCompensation;
