import React, { RefObject, forwardRef, useContext } from 'react';
import { IUpdateCalculationModifier } from '../../entity/types';
import HeadcountOptions from './HeadcountOptions';
import { useInput } from '~/components/Input/InputWrapper';
import isEqual from 'lodash.isequal';
import { FormulaBuilderInputContext } from './FormulaBuilderInputContext';
import { IFormula } from '~/services/parallel/formulas.types';

const CalculatedFormulaElement = forwardRef<
  HTMLDivElement,
  {
    selectedFormula: IFormula;
    selectable?: boolean;
    formulaTextValue: string;
    calculationModifier?: {
      jobTitle?: string;
    };
    handleUpdateCalculationModifier?: ({
      calculationModifier,
      formulaForUpdate,
      formulaTextValue,
      refToUpdate,
    }: IUpdateCalculationModifier) => void;
    formulaIndex?: number;
  }
>(
  (
    {
      selectedFormula,
      selectable,
      formulaTextValue,
      calculationModifier,
      handleUpdateCalculationModifier,
      formulaIndex,
    },
    ref,
  ) => {
    const [jobTitle, setJobTitle] = useInput({
      value: calculationModifier?.jobTitle ?? '',
    });
    const { segmentToDelete, setSegmentToDelete, inputPosition, setInputPosition, value, variables, updatedFormula } =
      useContext(FormulaBuilderInputContext);

    const updateCalculationModifier = (): void => {
      if (!handleUpdateCalculationModifier) return;
      handleUpdateCalculationModifier({
        calculationModifier:
          jobTitle.value.toLowerCase().trim() === 'all' || jobTitle.value.trim() === ''
            ? {}
            : {
                jobTitle: jobTitle.value,
              },
        formulaForUpdate: selectedFormula,
        formulaTextValue,
        refToUpdate: ref,
        formulaCopy: [...updatedFormula],
        variablesCopy: { ...variables },
      });
    };

    const hasCalculationModifier =
      selectedFormula.recipe.name === 'Headcount' || selectedFormula.recipe.name === 'New Hires';

    const classStyle = ((): string => {
      if (selectable && hasCalculationModifier) {
        return 'border-r border-neutral-100 rounded-r-full pr-3';
      } else if (!hasCalculationModifier) {
        return 'pr-3';
      } else {
        return 'pr-1';
      }
    })();

    return (
      <div className="flex flex-row cursor-pointer">
        {formulaIndex !== undefined &&
          (inputPosition !== formulaIndex || (inputPosition === formulaIndex && Boolean(value.length))) && (
            <div
              onClick={(e) => {
                if (selectable) {
                  e.stopPropagation();
                  if (formulaIndex >= 0 && inputPosition !== formulaIndex) {
                    setInputPosition(formulaIndex);
                  }
                }
              }}
              className="w-2 max-w-2 min-w-2 cursor-text"
            />
          )}
        <div
          ref={ref}
          className={`flex flex-row text-nowrap border border-neutral-100 focus:border-green-400 rounded-full bg-neutral-15 
        ${hasCalculationModifier ? 'px-3' : 'pl-3'} ${selectable && ' formulaWithTimeseriesOptions'}`}
          onClick={(e) => {
            if (selectable) {
              e.stopPropagation();
              if (segmentToDelete?.segmentRef && isEqual(ref, segmentToDelete.segmentRef)) {
                setSegmentToDelete(undefined);
                ref &&
                  'current' in ref &&
                  ref.current?.classList.remove('!bg-green-25', '!border-green-400', 'text-green-500');
              } else {
                if (segmentToDelete) {
                  updatedFormula[segmentToDelete.segmentIndex].ref.current?.classList.remove(
                    '!bg-green-25',
                    '!border-green-400',
                    'text-green-500',
                  );
                }
                setSegmentToDelete({
                  segmentRef: ref as RefObject<HTMLDivElement>,
                  segmentIndex: formulaIndex ?? 0,
                  segmentValue: selectedFormula.formulaUuid,
                });
                ref &&
                  'current' in ref &&
                  ref.current?.classList.add('!bg-green-25', '!border-green-400', 'text-green-500');
              }
            }
          }}
        >
          <div className={`h-full py-0.5 ${classStyle}`}>{selectedFormula.recipe.name}</div>
          {hasCalculationModifier &&
            (selectable ? (
              <div
                data-testid="calculation-modifier-button"
                className="pl-1.5 py-0.5 timeseriesOptions text-neutral-200 hover:text-neutral-400"
              >
                <HeadcountOptions
                  buttonVal={calculationModifier?.jobTitle ?? 'All'}
                  jobTitle={jobTitle}
                  setJobTitle={setJobTitle}
                  updateCalculationModifier={updateCalculationModifier}
                />
              </div>
            ) : (
              <div className="py-0.5 text-neutral-200 italic">{calculationModifier?.jobTitle ?? 'All'}</div>
            ))}
        </div>
      </div>
    );
  },
);

CalculatedFormulaElement.displayName = 'CalculatedFormulaElement';

export default CalculatedFormulaElement;
