import { addMonths } from './addMonths';
import { getLastDayOfMonth } from './getLastDayOfMonth';
import { IStringDate } from './types';

export const subtractMonths = (date: IStringDate, months: number): IStringDate => {
  let [year, month, day] = date.split('-').map(Number);
  let remainingMonths = months;

  if (remainingMonths < 0) return addMonths(date, Math.abs(remainingMonths));

  while (remainingMonths > 0) {
    if (month === 1) {
      year -= 1;
      month = 12;
      remainingMonths -= 1;
    } else {
      const decrement = Math.min(remainingMonths, month - 1);
      month -= decrement;
      remainingMonths -= decrement;
    }
  }

  // Adjust the day if it exceeds the last day of the target month
  const lastDayOfTargetMonth = Number(getLastDayOfMonth(`${year}-${String(month).padStart(2, '0')}-01`));

  if (day > lastDayOfTargetMonth) {
    day = lastDayOfTargetMonth;
  }

  return `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
};
