import Button from '~/components/Button';
import Card from '~/components/Card';
import Input from '~/components/Input/InputWrapper';
import React from 'react';
import { Link } from 'react-router-dom';
import { GoogleLogin } from '@react-oauth/google';
import logger from '~/utils/logger';

interface Props {
  email: Types.InputState;
  setEmail: React.Dispatch<React.SetStateAction<Types.InputState>>;
  usePassword?: boolean;
  password: Types.InputState;
  setPassword: React.Dispatch<React.SetStateAction<Types.InputState>>;
  login: () => void;
  errorMessage: string;
  onGoogleLogin: (token: string) => void;
}

const Login = ({
  email,
  setEmail,
  usePassword,
  password,
  setPassword,
  login,
  errorMessage,
  onGoogleLogin,
}: Props): React.ReactNode => {
  return (
    <div className="flex flex-col items-center justify-start w-screen h-screen">
      <div className="flex flex-col w-[95vw] max-w-[500px] mt-[10vh]">
        <h1>Login</h1>
        <Card className="w-full mt-5 gap-3 !pt-6 !pb-2 !px-8">
          <div className="w-full flex items-center justify-center">
            <GoogleLogin
              onSuccess={(credentialResponse) => {
                if (credentialResponse.credential) {
                  onGoogleLogin(credentialResponse.credential);
                } else {
                  logger.error(new Error('Google Login Failed'));
                }
              }}
              onError={() => {
                logger.error(new Error('Google Login Failed'));
              }}
              width="434"
            />
          </div>
          <div className="flex items-center justify-center w-full my-2">
            <div className="flex-1 h-px bg-gray-300"></div>
            <span className="px-4 text-gray-500 text-sm">or</span>
            <div className="flex-1 h-px bg-gray-300"></div>
          </div>
          <Input
            id="email"
            state={email}
            setState={setEmail}
            label="Email address"
            placeholder="Enter your email address here"
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                login();
              }
            }}
          />
          <div className={`${usePassword ? 'w-full flex' : 'hidden'}`}>
            <Input
              id="password"
              state={password}
              setState={setPassword}
              label="Password"
              placeholder="Enter your password here"
              type="password"
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  login();
                }
              }}
            />
          </div>
          <Button id="login" onClick={login} className="mt-1 mb-2 w-full">
            Log In
          </Button>
          <div className={`${usePassword ? 'w-full flex' : 'hidden'}`}>
            <Link to="/auth/forgot-password" className="text-left text-green-400 hover:text-green-500">
              Forgot your password?
            </Link>
          </div>
          <p className="text-red">{errorMessage}</p>
        </Card>
      </div>
    </div>
  );
};

export default Login;
