import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { State } from '~/store';
import useInput from '~/components/Input/useInput';
import { useDatePicker } from '~/components/DatePicker';
import { IDatePickerState } from '~/components/DatePicker/useDatePicker';
import { useSelect } from '~/components/Select';
import { SelectState } from '~/components/Select/Select.types';
import { employmentTypeToLabel, IEmploymentType } from '../entity/types';

export interface IPositionFormState {
  errorMessage: string;
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
  organizationUuid: string;
  hireDate: IDatePickerState;
  setHireDate: React.Dispatch<React.SetStateAction<IDatePickerState>>;
  title: Types.InputState;
  setTitle: React.Dispatch<React.SetStateAction<Types.InputState>>;
  payRate: Types.InputState;
  setPayRate: React.Dispatch<React.SetStateAction<Types.InputState>>;
  selectDepartment: SelectState;
  setSelectDepartment: React.Dispatch<React.SetStateAction<SelectState>>;
  resetFormState: () => void;
  numberToCreate: Types.InputState;
  setNumberToCreate: React.Dispatch<React.SetStateAction<Types.InputState>>;
  isChecked: boolean;
  setIsChecked: React.Dispatch<React.SetStateAction<boolean>>;
  selectEmploymentType: SelectState;
  setSelectEmploymentType: React.Dispatch<React.SetStateAction<SelectState>>;
  commission: Types.InputState;
  setCommission: React.Dispatch<React.SetStateAction<Types.InputState>>;
  commissionFrequency: SelectState;
  setCommissionFrequency: React.Dispatch<React.SetStateAction<SelectState>>;
  commissionEffectiveMonthPattern: SelectState;
  setCommissionEffectiveMonthPattern: React.Dispatch<React.SetStateAction<SelectState>>;
  bonus: Types.InputState;
  setBonus: React.Dispatch<React.SetStateAction<Types.InputState>>;
  bonusFrequency: SelectState;
  setBonusFrequency: React.Dispatch<React.SetStateAction<SelectState>>;
  bonusEffectiveMonthPattern: SelectState;
  setBonusEffectiveMonthPattern: React.Dispatch<React.SetStateAction<SelectState>>;
}

const usePositionFormState = (): IPositionFormState => {
  const [errorMessage, setErrorMessage] = useState('');
  const { uuid: organizationUuid } = useSelector((state: State) => state.organization);
  const [hireDate, setHireDate] = useDatePicker({
    errorMessage: 'Hire date is required',
  });
  const [title, setTitle] = useInput({
    validation: /.+/,
    errorMessage: 'Job title is required',
  });
  const [payRate, setPayRate] = useInput({
    validation: /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
    errorMessage: 'Annual Pay is required',
  });
  const [selectDepartment, setSelectDepartment] = useSelect({
    options: [],
  });
  const [numberToCreate, setNumberToCreate] = useInput({
    validation: /^(?:100|[1-9][0-9]?)$/, // Regex to match numbers 1-100
    valid: true,
    value: '1',
    errorMessage: 'Number to create must be a number between 1 and 100',
  });
  const [isChecked, setIsChecked] = useState(false);
  const [selectEmploymentType, setSelectEmploymentType] = useSelect({
    options: [
      {
        label: employmentTypeToLabel[IEmploymentType.FullTime],
        value: IEmploymentType.FullTime,
      },
      {
        label: employmentTypeToLabel[IEmploymentType.PartTime],
        value: IEmploymentType.PartTime,
      },
      {
        label: employmentTypeToLabel[IEmploymentType.Contractor],
        value: IEmploymentType.Contractor,
      },
    ],
    selected: {
      label: employmentTypeToLabel[IEmploymentType.FullTime],
      value: IEmploymentType.FullTime,
    },
  });
  const [commission, setCommission] = useInput({
    validation: /^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
  });
  const [commissionFrequency, setCommissionFrequency] = useSelect({
    options: [
      { label: 'Monthly', value: 'monthly' },
      { label: 'Quarterly', value: 'quarterly' },
      { label: 'Annually', value: 'annually' },
    ],
    selected: { label: 'Monthly', value: 'monthly' },
  });
  const [commissionEffectiveMonthPattern, setCommissionEffectiveMonthPattern] = useSelect({
    options: [
      { label: 'Jan, Apr, Jul, Oct', value: '1' },
      { label: 'Feb, May, Aug, Nov', value: '2' },
      { label: 'Mar, Jun, Sep, Dec', value: '3' },
    ],
    selected: { label: 'Jan, Apr, Jul, Oct', value: '1' },
  });
  const [bonus, setBonus] = useInput({
    validation: /^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
  });
  const [bonusFrequency, setBonusFrequency] = useSelect({
    options: [
      { label: 'Monthly', value: 'monthly' },
      { label: 'Quarterly', value: 'quarterly' },
      { label: 'Annually', value: 'annually' },
    ],
    selected: { label: 'Monthly', value: 'monthly' },
  });
  const [bonusEffectiveMonthPattern, setBonusEffectiveMonthPattern] = useSelect({
    options: [
      { label: 'Jan, Apr, Jul, Oct', value: '1' },
      { label: 'Feb, May, Aug, Nov', value: '2' },
      { label: 'Mar, Jun, Sep, Dec', value: '3' },
    ],
    selected: { label: 'Jan, Apr, Jul, Oct', value: '1' },
  });

  useEffect(() => {
    if (commissionFrequency.selected?.value === 'monthly') {
      setCommissionEffectiveMonthPattern({
        ...commissionEffectiveMonthPattern,
        options: [
          { label: 'Jan, Apr, Jul, Oct', value: '1' },
          { label: 'Feb, May, Aug, Nov', value: '2' },
          { label: 'Mar, Jun, Sep, Dec', value: '3' },
        ],
        selected: {
          label: 'Jan, Apr, Jul, Oct',
          value: '1',
        },
      });
    } else if (commissionFrequency.selected?.value === 'quarterly') {
      setCommissionEffectiveMonthPattern({
        ...commissionEffectiveMonthPattern,
        options: [
          { label: 'Jan, Apr, Jul, Oct', value: '1' },
          { label: 'Feb, May, Aug, Nov', value: '2' },
          { label: 'Mar, Jun, Sep, Dec', value: '3' },
        ],
        selected: {
          label: 'Jan, Apr, Jul, Oct',
          value: '1',
        },
      });
    } else if (commissionFrequency.selected?.value === 'annually') {
      setCommissionEffectiveMonthPattern({
        ...commissionEffectiveMonthPattern,
        options: [
          { label: 'January', value: '1' },
          { label: 'February', value: '2' },
          { label: 'March', value: '3' },
          { label: 'April', value: '4' },
          { label: 'May', value: '5' },
          { label: 'June', value: '6' },
          { label: 'July', value: '7' },
          { label: 'August', value: '8' },
          { label: 'September', value: '9' },
          { label: 'October', value: '10' },
          { label: 'November', value: '11' },
          { label: 'December', value: '12' },
        ],
        selected: {
          label: 'January',
          value: '1',
        },
      });
    }
  }, [commissionFrequency.selected?.value]);

  useEffect(() => {
    if (bonusFrequency.selected?.value === 'monthly') {
      setBonusEffectiveMonthPattern({
        ...bonusEffectiveMonthPattern,
        options: [
          { label: 'Jan, Apr, Jul, Oct', value: '1' },
          { label: 'Feb, May, Aug, Nov', value: '2' },
          { label: 'Mar, Jun, Sep, Dec', value: '3' },
        ],
        selected: {
          label: 'Jan, Apr, Jul, Oct',
          value: '1',
        },
      });
    } else if (bonusFrequency.selected?.value === 'quarterly') {
      setBonusEffectiveMonthPattern({
        ...bonusEffectiveMonthPattern,
        options: [
          { label: 'Jan, Apr, Jul, Oct', value: '1' },
          { label: 'Feb, May, Aug, Nov', value: '2' },
          { label: 'Mar, Jun, Sep, Dec', value: '3' },
        ],
        selected: {
          label: 'Jan, Apr, Jul, Oct',
          value: '1',
        },
      });
    } else if (bonusFrequency.selected?.value === 'annually') {
      setBonusEffectiveMonthPattern({
        ...bonusEffectiveMonthPattern,
        options: [
          { label: 'January', value: '1' },
          { label: 'February', value: '2' },
          { label: 'March', value: '3' },
          { label: 'April', value: '4' },
          { label: 'May', value: '5' },
          { label: 'June', value: '6' },
          { label: 'July', value: '7' },
          { label: 'August', value: '8' },
          { label: 'September', value: '9' },
          { label: 'October', value: '10' },
          { label: 'November', value: '11' },
          { label: 'December', value: '12' },
        ],
        selected: {
          label: 'January',
          value: '1',
        },
      });
    }
  }, [bonusFrequency.selected?.value]);

  const resetFormState = (): void => {
    setTitle({
      ...title,
      value: '',
      valid: false,
      pristine: true,
      touched: false,
      disabled: false,
    });
    setHireDate({
      ...hireDate,
      value: {
        startDate: null,
        endDate: null,
      },
      valid: false,
      pristine: true,
      touched: false,
      disabled: false,
    });
    setPayRate({
      ...payRate,
      value: '',
      valid: false,
      pristine: true,
      touched: false,
      disabled: false,
    });
    setSelectDepartment({
      ...selectDepartment,
      selected: undefined,
      valid: false,
      pristine: true,
      touched: false,
      disabled: false,
    });
    setNumberToCreate({
      ...numberToCreate,
      value: '1',
      valid: true,
      pristine: true,
      touched: false,
      disabled: false,
    });
    setIsChecked(false);
    setCommission({
      ...commission,
      value: '',
    });
    setCommissionFrequency({
      ...commissionFrequency,
      selected: {
        label: 'Monthly',
        value: 'monthly',
      },
    });
    setCommissionEffectiveMonthPattern({
      ...commissionEffectiveMonthPattern,
      selected: {
        label: 'Jan, Apr, Jul, Oct',
        value: '1',
      },
    });
    setBonus({
      ...bonus,
      value: '',
    });
    setBonusFrequency({
      ...bonusFrequency,
      selected: {
        label: 'Monthly',
        value: 'monthly',
      },
    });
    setBonusEffectiveMonthPattern({
      ...bonusEffectiveMonthPattern,
      selected: {
        label: 'Jan, Apr, Jul, Oct',
        value: '1',
      },
    });
  };

  return {
    errorMessage,
    setErrorMessage,
    organizationUuid,
    hireDate,
    setHireDate,
    title,
    setTitle,
    payRate,
    selectDepartment,
    setSelectDepartment,
    setPayRate,
    resetFormState,
    numberToCreate,
    setNumberToCreate,
    isChecked,
    setIsChecked,
    selectEmploymentType,
    setSelectEmploymentType,
    commission,
    setCommission,
    commissionFrequency,
    setCommissionFrequency,
    commissionEffectiveMonthPattern,
    setCommissionEffectiveMonthPattern,
    bonus,
    setBonus,
    bonusFrequency,
    setBonusFrequency,
    bonusEffectiveMonthPattern,
    setBonusEffectiveMonthPattern,
  };
};

export default usePositionFormState;
