import { StatusCodes } from 'http-status-codes';
import { ZPositionDetails, ZPositionSchema } from '~/pages/Headcount/entity/schemas';
import { IPosition, IPositionDetails } from '~/pages/Headcount/entity/types';
import request from '~/utils/request';
import { IAPIResponse } from '~/utils/types';

export const updatePositionsDates = async ({
  organizationUuid,
  scenarioUuid,
  positionUpdates,
  createScenario,
}: {
  organizationUuid: string;
  scenarioUuid?: string;
  positionUpdates: {
    positionUuid: string;
    hireDate: string;
    termDate: string | null;
  }[];
  createScenario?: boolean;
}): Promise<{ data: IPosition[]; scenarioUuid: string | null }> => {
  const response = (await request({
    url: `/positions`,
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      'Organization-Uuid': organizationUuid,
    },
    params: {
      scenarioUuid: scenarioUuid ?? undefined,
      createScenario: !createScenario || scenarioUuid ? undefined : true,
    },
    body: positionUpdates,
  })) as IAPIResponse<IPositionDetails[]>;

  if (response.status === StatusCodes.OK) {
    const parsedData = ZPositionSchema.array().parse(response.data.data);
    return {
      data: parsedData,
      scenarioUuid: typeof response.headers['scenario-uuid'] === 'string' ? response.headers['scenario-uuid'] : null,
    };
  }
  throw new Error('Failed to update positions dates');
};

export const updatePositionAttributeValue = async ({
  organizationUuid,
  scenarioUuid,
  positionUuid,
  attributeName,
  attributeValue,
}: {
  organizationUuid: string;
  scenarioUuid?: string;
  positionUuid: string;
  attributeName: string;
  attributeValue: string;
  includeFullyBurdenedCost?: boolean;
}): Promise<IPositionDetails> => {
  const response = (await request({
    url: `/organizations/${organizationUuid}/positions/${positionUuid}`,
    method: 'PATCH',
    body: {
      [attributeName]: attributeValue,
    },
    params: {
      scenarioUuid,
      includeFullyBurdenedCost: true,
    },
  })) as IAPIResponse<IPositionDetails>;

  if (response.status === StatusCodes.CREATED) {
    const parsedData = ZPositionDetails.parse(response.data.data);
    return parsedData;
  }
  throw new Error('Failed to update position attribute value');
};
