import React, { ReactElement, useEffect, useState } from 'react';
import { useInput } from '~/components/Input/InputWrapper';
import CurrencyInput from '~/components/Input/currencyInput/CurrencyInput';
import { PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline';
import Typography from '~/components/Typography';
import { CENTS_PER_DOLLAR } from '~/utils/constants/currency';
import * as stringDate from '~/utils/stringDate';
import DatePicker, { useDatePicker } from '~/components/DatePicker';

const PayRate = ({
  hireDate,
  effectiveAt,
  currentValue,
  updatedPayRates,
  setUpdatedPayRates,
  setPayRatesRequireUpdate,
}: {
  hireDate: string;
  effectiveAt: 'onHire' | string;
  currentValue: number;
  updatedPayRates: {
    effectiveAt: 'onHire' | string;
    value: number;
  }[];
  setUpdatedPayRates: React.Dispatch<
    React.SetStateAction<
      {
        effectiveAt: 'onHire' | string;
        value: number;
      }[]
    >
  >;
  payRatesRequireUpdate: boolean;
  setPayRatesRequireUpdate: React.Dispatch<React.SetStateAction<boolean>>;
}): ReactElement => {
  const [payRate, setPayRate] = useInput({
    // eslint-disable-next-line security/detect-unsafe-regex
    validation: /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
    errorMessage: 'Annual Pay is required',
    value: (currentValue / CENTS_PER_DOLLAR).toString(),
    disabled: true,
  });

  const [effectiveDate, setEffectiveDate] = useDatePicker({
    errorMessage: 'Effective date is required',
    value: { startDate: effectiveAt, endDate: effectiveAt },
    minDate: hireDate,
    disabled: true,
  });

  const [isEditing, setIsEditing] = useState(false);

  const formattedEffectiveAt = effectiveAt === 'onHire' ? hireDate : effectiveAt;

  useEffect(() => {
    if (Number(payRate.value) * CENTS_PER_DOLLAR !== currentValue && payRate.valid) {
      const newPayRates = updatedPayRates.map((existingPayRate) => {
        if (existingPayRate.effectiveAt === effectiveAt) {
          return {
            ...existingPayRate,
            value: Number(payRate.value) * CENTS_PER_DOLLAR,
          };
        }
        return existingPayRate;
      });
      setUpdatedPayRates(newPayRates);
      setPayRatesRequireUpdate(true);
    }
  }, [payRate, setUpdatedPayRates]);

  useEffect(() => {
    if (
      effectiveDate.value.startDate &&
      !stringDate.isSameDate({ date1: effectiveDate.value.startDate, date2: effectiveAt })
    ) {
      const newPayRates = updatedPayRates.map((existingPayRate) => {
        if (existingPayRate.effectiveAt === effectiveAt) {
          return {
            ...existingPayRate,
            updatedEffectiveDate: effectiveDate.value.startDate,
          };
        }
        return existingPayRate;
      });
      setUpdatedPayRates(newPayRates);
      setPayRatesRequireUpdate(true);
    }
  }, [effectiveDate, setUpdatedPayRates]);

  const handleDeletePayChange = async (): Promise<void> => {
    setUpdatedPayRates((prev) =>
      prev.filter(
        (existingPayRate) =>
          existingPayRate.effectiveAt === 'onHire' ||
          !stringDate.isSameDate({ date1: existingPayRate.effectiveAt, date2: effectiveAt }),
      ),
    );
    setPayRatesRequireUpdate(true);
  };

  const handleEditClick = (): void => {
    setIsEditing(true);
    setPayRate((prev) => ({ ...prev, disabled: false }));
    setEffectiveDate((prev) => ({ ...prev, disabled: effectiveAt === 'onHire' }));
  };

  return (
    <div className={`flex flex-row gap-2 items-start my-1 transition-all`}>
      <div className="w-[46%]">
        <CurrencyInput id={`payRate-${formattedEffectiveAt}`} state={payRate} setState={setPayRate} />
      </div>
      {effectiveAt === 'onHire' ? (
        <div className="w-[46%] py-2 flex justify-center items-center border border-gray-300 rounded bg-neutral-25">
          <Typography id={`pay-change-date-${formattedEffectiveAt}`} color="disabled">
            Hire Date
          </Typography>
        </div>
      ) : (
        <div className="w-[46%]">
          <DatePicker id="effectiveDate" state={effectiveDate} setState={setEffectiveDate} />
        </div>
      )}
      {!isEditing && (
        <PencilSquareIcon
          onClick={handleEditClick}
          className="fixed right-6 mt-2.5 size-5 text-neutral-100 cursor-pointer hover:text-neutral-400"
        />
      )}
      {isEditing && effectiveAt !== 'onHire' && (
        <TrashIcon
          onClick={handleDeletePayChange}
          className="fixed right-6 mt-2.5 size-5 text-neutral-100 cursor-pointer hover:text-neutral-400"
          data-testid={`delete-pay-change-${formattedEffectiveAt}`}
        />
      )}
    </div>
  );
};

export default PayRate;
