import React, { ReactElement, useContext, useMemo } from 'react';
import BaseTable from '~/components/Table/Base/BaseTable';
import {
  SortingFn,
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { HeadcountContext } from '../../context/HeadcountContext';
import Cell from './Cell';
import { IInitialValue } from './Cell/entities/types';
import Typography from '~/components/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '~/store';
import TotalCostColumnHeader from './TotalCostColumnHeader';
import { z } from 'zod';
import useQueryParams from '~/utils/hooks/useQueryParams';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/solid';
import { settingsSlice } from '~/store/settingsSlice';
import EditPosition from '../HeadcountTimeline/EditPosition/EditPosition';
import { fetchSpecificPositions } from '../../utils/fetchSpecificPositions';
import { IEditPositionFormFields } from '../HeadcountTimeline/EditPosition/EditPositionForm';
import { useFeatureFlagHarness } from '~/utils/hooks/useFeatureFlag';
import CreationCell from './CreationCell/CreationCell';
import * as stringDate from '~/utils/stringDate';
import CreationAdditionalCompensation from './CreationCell/CreationAdditionalCompensation';

const HeadcountTable = (): ReactElement => {
  const dispatch = useDispatch();
  const {
    renderedPositions,
    showTermedPositions,
    editPositionUuid,
    setEditPositionUuid,
    editPositionFormState,
    positions,
    setPositions,
    setRenderedPositions,
    search,
    positionDataDict,
    setPositionDataDict,
    inlineCreationFormState: { addInlineAdditionalCompensation, setAddInlineAdditionalCompensation },
  } = useContext(HeadcountContext);
  const activeScenarioUuid = useSelector((state: State) => state.scenario.activeScenarioUuid);
  const hideSensitiveData = useSelector((state: State) => state.settings.hideSensitiveData);
  const columnHelper = createColumnHelper<Record<string, IInitialValue>>();
  const [queryParams] = useQueryParams();
  const selectedDepartment = queryParams.get('departments');
  const { uuid: organizationUuid } = useSelector((state: State) => state.organization);
  const inlineHeadcount = useFeatureFlagHarness('inlineHeadcount');

  const alphabeticalSortingFn: SortingFn<Record<string, IInitialValue>> = (rowA, rowB, columnId) => {
    const ZColumnId = z.union([z.literal('title'), z.literal('employeeName'), z.literal('departmentUuid')]);

    interface IRowSort {
      original: Record<
        'title' | 'employeeName' | 'departmentUuid',
        {
          value: string | null;
          isAddPositionRow: boolean | undefined;
        }
      >;
    }

    const parsedColumnId = ZColumnId.parse(columnId);

    const parsedRowA = rowA as unknown as IRowSort;
    const parsedRowB = rowB as unknown as IRowSort;

    if (inlineHeadcount) {
      if (parsedRowA.original[parsedColumnId].isAddPositionRow)
        return table.getColumn(columnId)?.getIsSorted() === 'asc' ? 1 : -1;
      if (parsedRowB.original[parsedColumnId].isAddPositionRow)
        return table.getColumn(columnId)?.getIsSorted() === 'asc' ? -1 : 1;
    }

    const valueA = parsedRowA.original[parsedColumnId].value ?? '';

    const valueB = parsedRowB.original[parsedColumnId].value ?? '';

    return valueA.localeCompare(valueB);
  };

  const dateSortingFn: SortingFn<Record<string, IInitialValue>> = (rowA, rowB) => {
    const sortDirection = table.getColumn('hireDate')?.getIsSorted();

    if ('hireDate' in rowA.original && 'hireDate' in rowB.original) {
      const ZSortableRow = z.object({
        value: z.string(),
        isAddPositionRow: z.boolean().optional(),
      });

      const parsedRowA = ZSortableRow.parse(rowA.original.hireDate);
      const parsedRowB = ZSortableRow.parse(rowB.original.hireDate);

      if (inlineHeadcount) {
        if (parsedRowA.isAddPositionRow) return sortDirection === 'asc' ? 1 : -1;
        if (parsedRowB.isAddPositionRow) return sortDirection === 'asc' ? -1 : 1;
      }

      if (parsedRowA.value === parsedRowB.value) {
        return 0;
      }
      return stringDate.isBefore({ dateToCheck: parsedRowA.value, comparison: parsedRowB.value }) ? 1 : -1;
    }
    return 0;
  };

  const handleOptimisticUpdate = async ({ positionUuid }: { positionUuid: string | null }): Promise<void> => {
    if (!positionUuid) {
      return;
    }
    const [updatedPosition] = await fetchSpecificPositions({
      positionUuids: [positionUuid],
      organizationUuid,
      scenarioUuid: activeScenarioUuid ?? undefined,
    });

    const updateIndex = renderedPositions.findIndex(
      (renderedPosition) => renderedPosition.positionUuid === positionUuid,
    );

    const updatedPositionIndex = positions?.findIndex((oldPosition) => oldPosition.positionUuid === positionUuid);

    if (updatedPositionIndex !== undefined && updatedPositionIndex !== -1 && positions) {
      setPositions((prev) =>
        [...prev].map((position) =>
          position.positionUuid === updatedPosition.positionUuid
            ? { ...updatedPosition, orderedDate: position.orderedDate }
            : position,
        ),
      );
    }
    if (updateIndex !== -1) {
      setRenderedPositions((prev) =>
        [...prev].map((position) =>
          position.positionUuid === updatedPosition.positionUuid
            ? { ...updatedPosition, orderedDate: position.orderedDate }
            : position,
        ),
      );
    }
    setPositionDataDict((prev) => ({
      ...prev,
      [updatedPosition.positionUuid]: {
        employeeName: updatedPosition.employeeName ?? '',
        title: updatedPosition.title,
        department: updatedPosition.currentDepartment,
        hireDate: updatedPosition.hireDate,
        termDate: updatedPosition.termDate,
        fullyBurdenedCost: updatedPosition.fullyBurdenedCost,
        employmentType: updatedPosition.employmentType,
      },
    }));
  };

  const desiredColumns = [
    {
      id: 'employeeName',
      label: 'Employee',
      className: 'w-full text-left ml-2.5 text-nowrap',
      sortingFn: alphabeticalSortingFn,
      enableSorting: true,
    },
    {
      id: 'title',
      label: 'Title',
      className: 'w-full text-left ml-2.5 text-nowrap',
      sortingFn: alphabeticalSortingFn,
      enableSorting: true,
    },
    {
      id: 'employmentType',
      label: 'Employment Type',
      className: 'w-full text-left text-nowrap',
      sortingFn: alphabeticalSortingFn,
      enableSorting: true,
    },
    {
      id: 'departmentUuid',
      label: 'Department',
      className: 'w-full text-left text-nowrap',
      sortingFn: alphabeticalSortingFn,
      enableSorting: true,
    },
    {
      id: 'payRate',
      label: 'Pay Rate',
      className: 'w-full text-right text-nowrap flex flex-row items-center justify-end',
    },
    {
      id: 'fullyBurdenedCost',
      label: 'Total Cost',
      className: 'flex w-full justify-end items-end text-nowrap',
    },
    {
      id: 'hireDate',
      label: 'Hire Date',
      className: 'w-full text-left text-nowrap',
      sortingFn: dateSortingFn,
      enableSorting: true,
    },
    {
      id: 'termDate',
      label: 'Term Date',
      className: 'w-full text-left text-nowrap',
    },
    { id: 'options', label: '', className: 'w-full text-left text-nowrap' },
  ];

  if (activeScenarioUuid) {
    desiredColumns.unshift({
      id: 'isActive',
      label: '',
      className: 'w-full text-left text-nowrap',
    });
  }

  const { tableData = [], tableColumns } = useMemo(() => {
    const tableColumns = desiredColumns.map((col) =>
      columnHelper.accessor(col.id, {
        enableResizing: false,
        enablePinning: false,
        enableSorting: col.enableSorting ?? false,
        header: () => (
          <div className={col.className}>
            {col.id === 'fullyBurdenedCost' ? <TotalCostColumnHeader /> : col.label.toUpperCase()}
            {col.id === 'payRate' &&
              (hideSensitiveData ? (
                <EyeSlashIcon
                  onClick={() =>
                    dispatch(
                      settingsSlice.actions.update({
                        hideSensitiveData: !hideSensitiveData,
                      }),
                    )
                  }
                  className="size-4 cursor-pointer ml-1"
                />
              ) : (
                <EyeIcon
                  onClick={() =>
                    dispatch(
                      settingsSlice.actions.update({
                        hideSensitiveData: !hideSensitiveData,
                      }),
                    )
                  }
                  className="size-4 cursor-pointer ml-1"
                />
              ))}
          </div>
        ),
        cell: (cellContext) => {
          // Check if this is the "Add Position" row and the first column
          const initialValue = cellContext.getValue();
          if (initialValue.isAddPositionRow) {
            return <CreationCell cellContext={cellContext} />;
          }
          return <Cell cellContext={cellContext} />;
        },
        sortingFn: col.sortingFn,
      }),
    );

    let tableData: Record<string, IInitialValue>[] = [];
    if (renderedPositions.length) {
      let filteredPositions = renderedPositions;
      if (selectedDepartment && selectedDepartment !== 'all') {
        filteredPositions = filteredPositions.filter((position) => position.departmentUuid === selectedDepartment);
      }
      if (!showTermedPositions) {
        filteredPositions = filteredPositions.filter(
          (position) =>
            (position.termDate &&
              !stringDate.isBefore({ dateToCheck: position.termDate, comparison: stringDate.getStringDate() })) ||
            !position.termDate,
        );
      }
      if (search.value) {
        filteredPositions = filteredPositions.filter((position) => {
          const employeeNameMatch = positionDataDict[position.positionUuid].employeeName
            .toLowerCase()
            .includes(search.value.toLowerCase());
          const titleMatch = positionDataDict[position.positionUuid].title
            .toLowerCase()
            .includes(search.value.toLowerCase());
          return employeeNameMatch || titleMatch;
        });
      }
      tableData = filteredPositions
        .sort((a, b) => {
          if (stringDate.isBefore({ dateToCheck: a.orderedDate, comparison: b.orderedDate })) {
            return -1;
          }
          if (stringDate.isAfter({ dateToCheck: a.orderedDate, comparison: b.orderedDate })) {
            return 1;
          }
          return 0;
        })
        .map((position) => {
          const attributeDict = {} as Record<string, IInitialValue>;

          const hasUpcomingChanges = [
            ...position.payRates,
            ...(position.bonuses || []),
            ...(position.commissions || []),
          ].some(
            (item) =>
              item.effectiveAt !== 'onHire' &&
              stringDate.isAfter({
                dateToCheck: item.effectiveAt,
                comparison: stringDate.getStringDate(),
              }),
          );

          attributeDict.payRate = {
            value: position.currentPayRate.value.toString(),
            positionUuid: position.positionUuid,
            changeHistory: position.payRates,
            effectiveAt: position.currentPayRate.effectiveAt,
            upcomingChange: hasUpcomingChanges,
            additionalCompensations: {
              bonus: position.currentBonus,
              commission: position.currentCommission,
            },
            additionalCompensationsHistory: {
              bonus: position.bonuses,
              commission: position.commissions,
            },
          };

          attributeDict.employeeName = {
            value: position.employeeName,
            positionUuid: position.positionUuid,
          };

          attributeDict.departmentUuid = {
            value: position.currentDepartment.name,
            positionUuid: position.positionUuid,
          };

          attributeDict.title = {
            value: position.title,
            positionUuid: position.positionUuid,
          };

          attributeDict.hireDate = {
            value: position.hireDate,
            positionUuid: position.positionUuid,
            maxDate: position.termDate ? position.termDate : undefined,
          };

          attributeDict.termDate = {
            value: position.termDate,
            positionUuid: position.positionUuid,
            minDate: position.hireDate,
          };

          attributeDict.isActive = {
            value: position.isActive.toString(),
            positionUuid: position.positionUuid,
            displayToggle: stringDate.isAfter({
              dateToCheck: position.hireDate,
              comparison: stringDate.getStringDate(),
            }),
          };

          attributeDict.fullyBurdenedCost = {
            value: position.fullyBurdenedCost?.toString() ?? null,
            positionUuid: position.positionUuid,
          };

          attributeDict.options = {
            value: position.title,
            positionUuid: position.positionUuid,
          };

          attributeDict.employmentType = {
            value: position.employmentType,
            positionUuid: position.positionUuid,
          };

          return attributeDict;
        });
    }

    if (inlineHeadcount) {
      tableData.push({
        employeeName: { value: '', positionUuid: '', isAddPositionRow: true },
        title: { value: '', positionUuid: '', isAddPositionRow: true },
        departmentUuid: { value: '', positionUuid: '', isAddPositionRow: true },
        payRate: { value: '', positionUuid: '', isAddPositionRow: true },
        fullyBurdenedCost: { value: '', positionUuid: '', isAddPositionRow: true },
        hireDate: { value: '', positionUuid: '', isAddPositionRow: true },
        termDate: { value: '', positionUuid: '', isAddPositionRow: true },
        options: { value: '', positionUuid: '', isAddPositionRow: true },
        isActive: { value: '', positionUuid: '', isAddPositionRow: true },
        employmentType: { value: '', positionUuid: '', isAddPositionRow: true },
      });
    }

    return { tableData, tableColumns };
  }, [renderedPositions, activeScenarioUuid, selectedDepartment, showTermedPositions, hideSensitiveData, search.value]);

  const table = useReactTable({
    columns: tableColumns,
    data: tableData,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const emptyState = (
    <div className="my-6">
      <Typography color="empty">No positions found</Typography>
    </div>
  );

  return (
    <div className="max-w-full overflow-scroll hide-scrollbar h-full pb-96 px-10">
      {/* <HeadcountGraphs /> */}
      <BaseTable
        styles={{
          table: 'w-full',
          th: 'px-4 py-2 text-xs font-normal text-neutral-200',
          td: 'h-14 px-4 border-t border-b border-gray-200',
        }}
        emptyState={emptyState}
        // @ts-expect-error - tanstack doesn't like typed rows
        table={table}
        id="headcount-table"
      />
      <EditPosition
        id={`edit-position-modal-${editPositionUuid}`}
        isOpen={Boolean(editPositionUuid)}
        onClose={() => {
          editPositionFormState.resetFormState();
          setEditPositionUuid(null);
        }}
        editPositionFormState={editPositionFormState}
        awaitCalculations
        editPositionUuid={editPositionUuid}
        handleOptimisticUpdate={handleOptimisticUpdate}
        fieldsToEdit={[
          IEditPositionFormFields.EMPLOYEE,
          IEditPositionFormFields.TITLE,
          IEditPositionFormFields.DEPARTMENT,
          IEditPositionFormFields.HIREDATE,
          IEditPositionFormFields.EMPLOYMENT_TYPE,
        ]}
      />
      <CreationAdditionalCompensation
        isOpen={addInlineAdditionalCompensation}
        onClose={() => setAddInlineAdditionalCompensation(false)}
      />
    </div>
  );
};

export default HeadcountTable;
