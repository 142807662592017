import { IFormula } from '~/services/parallel/formulas.types';
import { IExpense } from '../components/types';
import * as stringDate from '~/utils/stringDate/index';

export const isDuplicateName = ({
  name,
  expenses,
  formulas,
  expenseUuid,
}: {
  name: string;
  expenses: IExpense[];
  formulas: IFormula[];
  expenseUuid?: string;
}): { isDuplicate: boolean; isDiscontinuedDuplicate: boolean } => {
  let isDiscontinuedDuplicate = false;

  const duplicateExpense = expenses.find(
    (expense) => expense.name.toLowerCase() === name.toLowerCase() && expense.expenseUuid !== expenseUuid,
  );
  const duplicateFormula = formulas.find(
    (formula) =>
      formula.recipe.name.toLowerCase() === name.toLowerCase() &&
      expenseUuid &&
      expenseUuid !== formula.recipe.relatedResourceUuid,
  );

  if (duplicateExpense) {
    isDiscontinuedDuplicate = Boolean(
      duplicateExpense.context.endDate &&
        stringDate.isBefore({
          dateToCheck: duplicateExpense.context.endDate,
          comparison: stringDate.startOfMonth(stringDate.getStringDate()),
        }),
    );
  }

  return { isDuplicate: Boolean(duplicateExpense || duplicateFormula), isDiscontinuedDuplicate };
};
