import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import Header from '~/components/Header';
import FormulaBuilder from './components/FormulaBuilder/FormulaBuilder';
import FinancialModelTable from './components/FinancialModelTable/FinancialModelTable';
import Button from '~/components/Button';
import { IFormulaActual, IFormulaOverride, IVariables } from './entity/types';
import FinancialModelProvider, { FinancialModelContext } from './context/FinancialModelContext';
import request from '~/utils/request';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '~/store';
import isEqual from 'lodash.isequal';
import Divider from '~/components/Divider';
import { IManageGroupModalState, ManageGroupModal } from './components/ManageGroupModal';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import HoverPopover from '~/components/HoverPopover';
import FinancialModelTooltip from './components/InfoTooltip/FinancialModelTooltip';
import FinancialModelOptions from './components/FinancialModelOptions';
import FinancialModelLegend from './components/FinancialModelLegend';
import UserDateRange from '~/components/UserDateRange';
import { useFeatureFlagHarness } from '~/utils/hooks/useFeatureFlag';
import Modal from '~/components/Modal';
import { PullQuickbooksActuals } from './components/PullQuickbooksActuals';
import { IAPIResponse } from '~/utils/types';
import { IIntegration, IIntegrationMapping } from '~/utils/schemas/integrations';
import Skeleton from 'react-loading-skeleton';
import { IFormattingEnum } from './entity/schemas';
import { settingsSlice } from '~/store/settingsSlice';
import { cloneDeep } from 'lodash';
import PrebuiltComponentsModal from './components/PrebuiltComponents/PrebuiltComponentsModal';
import Input from '~/components/Input/InputWrapper';
import { IFormula } from '~/services/parallel/formulas.types';
import { IRoundingInstructions } from '~/components/Formulas/context/types';

const FinancialModelContainer = (): React.ReactNode => {
  const dispatch = useDispatch();
  const integrationsEnabled = useFeatureFlagHarness('integrations');
  const searchFinancialModel = useFeatureFlagHarness('searchFinancialModel');
  const firstRender = useRef(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isPullQuickbooksActualsOpen, setIsPullQuickbooksActualsOpen] = useState(false);
  const {
    dataSources,
    overridesList,
    setOverridesList,
    parsedFormulas,
    overridesHaveChanges,
    actualsList,
    setActualsList,
    actualsHaveChanges,
    setSelectedMonthCell,
    loading,
    revalidate,
    revalidateLoading,
    prebuiltComponentModalState,
    setPrebuiltComponentModalState,
    search,
    setSearch,
  } = useContext(FinancialModelContext);
  const {
    user: {
      preferences: { defaultGraphStartDate, defaultGraphEndDate },
    },
    scenario: { activeScenarioUuid },
    organization: { uuid: organizationUuid },
    settings: { financialModelExpand },
  } = useSelector((state: State) => state);
  const [integrationsData, setIntegrationsData] = useState<{
    integrations: IIntegration[];
    mappings: IIntegrationMapping[];
  }>({
    integrations: [],
    mappings: [],
  });

  const [formulaBuilderState, setFormulaBuilderState] = useState<{
    isOpen: boolean;
    mode: 'create' | 'edit';
    formulaTitle?: string;
    formulaUuid?: string;
    formulaData?: {
      topLevelFormulaUuid?: string;
      formula: string;
      variables: IVariables;
      formulaList: IFormula[];
      editable?: boolean;
      isProtected?: boolean;
    };
    dataSourceUuids: string[];
    variables?: IVariables;
    roundingInstructions?: IRoundingInstructions;
    formatting?: IFormattingEnum | null;
  }>({
    isOpen: false,
    mode: 'create',
    dataSourceUuids: [],
  });
  const [manageGroupState, setManageGroupState] = useState<IManageGroupModalState>({
    groupNameToEdit: null,
    isOpen: false,
  });

  const searchInputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent): void => {
      if ((event.ctrlKey || event.metaKey) && event.key === 'f') {
        event.preventDefault();
        searchInputRef.current?.focus();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    if (!revalidateLoading) {
      setIsLoading(false);
    }
  }, [revalidateLoading]);

  useEffect(() => {
    const fetchIntegrationsData = async (): Promise<void> => {
      if (!organizationUuid) return;

      const integrations = (await request({
        url: `/integrations`,
        method: 'GET',
        headers: { 'Organization-Uuid': organizationUuid },
      })) as IAPIResponse<IIntegration[]>;

      const accountingIntegration = integrations.data.data.find((integration) => integration.category === 'accounting');

      if (accountingIntegration) {
        const mappingsResponse = (await request({
          url: `/integrations/${accountingIntegration.uuid}/mappings`,
          method: 'GET',
          headers: { 'Organization-Uuid': organizationUuid },
          params: { scope: 'modelBuilder' },
        })) as IAPIResponse<IIntegrationMapping[]>;

        if (mappingsResponse.data.data.length > 0) {
          setIntegrationsData({
            integrations: integrations.data.data,
            mappings: mappingsResponse.data.data,
          });
        }
      }
    };
    fetchIntegrationsData();
  }, [organizationUuid]);

  const handleUpdateMonthValues = async (): Promise<void> => {
    setIsLoading(true);

    const updates: Record<string, { overrides?: IFormulaOverride[]; actuals?: IFormulaActual[] }> = {};

    const overridesToUpdate = Object.keys(overridesList).reduce(
      (acc, key) => {
        if (!isEqual(overridesList[key], originalOverridesList[key])) {
          acc[key] = overridesList[key];
        }
        return acc;
      },
      {} as Record<string, IFormulaOverride[]>,
    );

    const actualsToUpdate = Object.keys(actualsList).reduce(
      (acc, key) => {
        if (!isEqual(actualsList[key], originalActualsList[key])) {
          acc[key] = actualsList[key];
        }
        return acc;
      },
      {} as Record<string, IFormulaActual[]>,
    );

    Object.keys(overridesToUpdate).forEach((key) => {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (!updates[key]) {
        updates[key] = { overrides: [], actuals: [] };
      }
      updates[key].overrides = overridesToUpdate[key];
    });

    Object.keys(actualsToUpdate).forEach((key) => {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (!updates[key]) {
        updates[key] = { overrides: [], actuals: [] };
      }
      updates[key].actuals = actualsToUpdate[key];
    });

    Object.keys(updates).forEach((key) => {
      const shouldDeleteOverride =
        overridesList[key].length > 0 && originalOverridesList[key].length > 0 && !updates[key].overrides?.length;

      const shouldDeleteActual =
        actualsList[key].length > 0 && originalActualsList[key].length > 0 && !updates[key].actuals?.length;

      if (shouldDeleteOverride) {
        delete updates[key].overrides;
      }
      if (shouldDeleteActual) {
        delete updates[key].actuals;
      }

      if (!updates[key].overrides && !updates[key].actuals) {
        delete updates[key];
      }
    });

    if (!Object.keys(updates).length) {
      setIsLoading(false);
      return;
    }

    await request({
      url: '/formulas/batch',
      method: 'PATCH',
      body: updates,
      headers: {
        'Organization-Uuid': organizationUuid,
      },
      params: {
        scenarioUuid: activeScenarioUuid ?? undefined,
      },
    });

    setOverridesList({});
    setActualsList({});
    setSelectedMonthCell((prev) => {
      return {
        ...prev,
        colIndex: undefined,
        rowIndex: undefined,
      };
    });
    revalidate();
    setIsLoading(false);
  };

  const openGroupModal = useCallback(
    (groupName?: string): void => {
      setManageGroupState({
        groupNameToEdit: groupName ?? null,
        isOpen: true,
      });
    },
    [setManageGroupState],
  );

  const deleteGroup = useCallback(
    async (groupName: string): Promise<void> => {
      const formulasToUngroup = parsedFormulas.sorting.find((group) => group.name === groupName);

      const newSortOrder = parsedFormulas.sorting
        .filter((group) => group.name !== groupName)
        .map((group) => {
          if (group.name === 'Ungrouped Attributes') {
            return {
              name: group.name,
              sortOrder: group.sortOrder.concat(formulasToUngroup?.sortOrder ?? []),
            };
          }
          return group;
        });

      await request({
        url: '/formulas/sorting',
        method: 'PATCH',
        body: {
          groups: newSortOrder,
        },
        headers: { 'Organization-Uuid': organizationUuid },
        params: {
          scenarioUuid: activeScenarioUuid,
        },
      });

      // Update store setting and remove the removed group from the array
      dispatch(
        settingsSlice.actions.update({
          financialModelExpand: financialModelExpand.filter((group) => group.name !== groupName),
        }),
      );

      revalidate();
    },
    [activeScenarioUuid, organizationUuid, parsedFormulas],
  );

  useEffect(() => {
    if (!firstRender.current) {
      revalidate();
    } else {
      firstRender.current = false;
    }
  }, [defaultGraphStartDate, defaultGraphEndDate]);

  const originalActualsList = useMemo(() => {
    return parsedFormulas.list.reduce(
      (acc, formula) => ({
        ...acc,
        [formula.formulaUuid]: formula.actuals.reduce((acc, actual) => {
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          if (actual) {
            acc.push({
              date: actual.date,
              value: actual.value,
            });
          }
          return acc;
        }, [] as IFormulaActual[]),
      }),
      {} as Record<string, IFormulaActual[]>,
    );
  }, [parsedFormulas]);

  const originalOverridesList = useMemo(() => {
    return parsedFormulas.list.reduce(
      (acc, formula) => ({
        ...acc,
        [formula.formulaUuid]: formula.overrides.reduce((acc, override) => {
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          if (override) {
            acc.push({
              date: override.date,
              value: override.value,
            });
          }
          return acc;
        }, [] as IFormulaOverride[]),
      }),
      {} as Record<string, IFormulaOverride[]>,
    );
  }, [parsedFormulas]);

  if (loading) {
    return (
      <div className={`max-w-full w-full max-sm:pb-32 max-h-screen`}>
        <Header
          title="Financial Model"
          startChildren={
            <HoverPopover
              buttonContent={<InformationCircleIcon className="size-4 text-neutral-500" />}
              panelContent={<FinancialModelTooltip />}
              anchor="bottom"
              panelClassName="shadow-md z-50"
            />
          }
        />
        <div className={`pt-10 px-12 max-w-full w-full max-h-[92vh] flex flex-col gap-4 mt-10`}>
          <Skeleton className="w-full h-[45px] mb-2" count={40} />
        </div>
      </div>
    );
  }

  return (
    <div className={`max-w-full w-full max-sm:pb-32 max-h-screen`}>
      <Header
        title="Financial Model"
        startChildren={
          <div className="flex flex-row gap-2 items-center justify-center">
            <HoverPopover
              buttonContent={<InformationCircleIcon className="size-4 text-neutral-500" />}
              panelContent={<FinancialModelTooltip />}
              anchor="bottom"
              panelClassName="shadow-md z-50"
            />
          </div>
        }
        endChildren={
          <div className="flex items-center gap-2">
            {(overridesHaveChanges || actualsHaveChanges) && (
              <div className="flex flex-row gap-2">
                <Button
                  onClick={() => {
                    setOverridesList(cloneDeep(originalOverridesList));
                    setActualsList(cloneDeep(originalActualsList));
                  }}
                  fill="destructiveOutline"
                  className="!w-fit"
                  disabled={isLoading}
                  id="revert-overrides-button"
                >
                  Revert
                </Button>
                <Button
                  onClick={handleUpdateMonthValues}
                  className="!w-fit"
                  loading={isLoading}
                  id="save-overrides-button"
                >
                  Save
                </Button>
              </div>
            )}
            {!(overridesHaveChanges || actualsHaveChanges) && integrationsEnabled && (
              <>
                {integrationsData.mappings.length > 0 && (
                  <Button fill="outline" className="!px-4" onClick={() => setIsPullQuickbooksActualsOpen(true)}>
                    Pull Actuals
                  </Button>
                )}
                <Modal
                  isOpen={isPullQuickbooksActualsOpen}
                  onClose={() => setIsPullQuickbooksActualsOpen(false)}
                  size="xxs"
                >
                  <PullQuickbooksActuals onClose={() => setIsPullQuickbooksActualsOpen(false)} />
                </Modal>
              </>
            )}
            <UserDateRange pickerAlignment="right" />
            <FinancialModelOptions />
          </div>
        }
      />
      <div className={`pt-10 pl-12 max-w-full w-full max-h-[92vh] flex flex-col gap-4 relative`}>
        {searchFinancialModel && parsedFormulas.list.length ? (
          <div className="absolute top-6 left-12 z-30">
            <Input
              id="search"
              type="search"
              placeholder="Search"
              state={search}
              setState={setSearch}
              ref={searchInputRef} // Add ref to the search input
            />
          </div>
        ) : null}
        {parsedFormulas.list.length ? (
          <FinancialModelTable
            setFormulaBuilderState={setFormulaBuilderState}
            deleteGroup={deleteGroup}
            updateGroup={openGroupModal}
            integrationsData={integrationsData}
          />
        ) : (
          <div className="w-full text-center py-10">There are no formulas to display</div>
        )}
        <div className="flex items-center justify-between pb-20">
          <div className="flex gap-3 items-center">
            <Button
              className="!w-fit !p-0"
              fill="clear"
              onClick={() => {
                setFormulaBuilderState((prev) => ({
                  ...prev,
                  isOpen: true,
                  mode: 'create',
                  formula: undefined,
                  formulaData: undefined,
                  variables: {},
                  formulaTitle: '',
                  formulaUuid: '',
                  formatting: IFormattingEnum.Number,
                }));
              }}
              id="new-attribute-button"
            >
              New Attribute
            </Button>
            <Divider orientation="vertical" className="text-green" />
            <Button id="new-group-button" className="!w-fit !p-0" fill="clear" onClick={() => openGroupModal()}>
              New Group
            </Button>
          </div>
          <FinancialModelLegend />
        </div>
      </div>
      <ManageGroupModal state={manageGroupState} setState={setManageGroupState} sortOrder={parsedFormulas.sorting} />
      <FormulaBuilder
        state={formulaBuilderState}
        cancel={() =>
          setFormulaBuilderState((prev) => ({
            ...prev,
            isOpen: false,
            variables: {},
            formula: undefined,
            formulaTitle: '',
            formatting: IFormattingEnum.Number,
          }))
        }
        confirm={() =>
          setFormulaBuilderState((prev) => ({
            ...prev,
            isOpen: false,
            variables: {},
            formula: undefined,
            formulaTitle: '',
            formatting: IFormattingEnum.Number,
          }))
        }
        parsedFormulas={parsedFormulas}
        dataSources={dataSources}
        revalidate={revalidate}
        revalidateLoading={revalidateLoading}
      />
      <PrebuiltComponentsModal
        prebuiltComponentModalState={prebuiltComponentModalState}
        setPrebuiltComponentModalState={setPrebuiltComponentModalState}
      />
    </div>
  );
};

const FinancialModel = (): React.ReactNode => {
  return (
    <FinancialModelProvider>
      <FinancialModelContainer />
    </FinancialModelProvider>
  );
};

export default FinancialModel;
