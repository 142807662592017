import React, { useContext, useEffect, useRef } from 'react';
import Header from '~/components/Header';
import ExpensesTable from './components/Table/ExpensesTable';
import { ExpensesPageContext, ExpensesPageContextProvider } from '~/pages/Expenses/context/ExpensesContext';
import Button from '~/components/Button';
import CreateExpenseModal from '~/pages/Expenses/components/CreateExpense/CreateExpenseModal';
import DiscontinueExpenseModal from './components/DiscontinueExpenseModal';
import DeleteExpenseModal from './components/DeleteExpenseModal';
import { useExitScenarioListener } from '~/utils/hooks/useExitScenarioListener';
import ExpensesGraph from './components/Graph/ExpensesGraph';
import UserDateRange from '~/components/UserDateRange';
import { useSelector } from 'react-redux';
import { State } from '~/store';
import Skeleton from 'react-loading-skeleton';
import Select from '~/components/Select';
import Checkbox from '~/components/Checkbox';
import GenerateExpensesModal from './components/GenerateExpenses/GenerateExpensesModal';
import { useFeatureFlagHarness } from '~/utils/hooks/useFeatureFlag';
import ScenarioDropdown from '~/components/ScenarioDropdown';
import * as stringDate from '~/utils/stringDate';
import SegmentedControl from '~/components/SegmentedControl';
import { ListBulletIcon, TableCellsIcon } from '@heroicons/react/24/outline';
import ActualsAndOverridesLegend from '~/components/ActualsAndOverridesLegend/ActualsAndOverridesLegend';
import { FormulasProvider } from '~/components/Formulas/context/FormulasContext';
import { IFormulaTypeEnum } from '~/services/parallel/formulas.types';
import SearchInput from '../../components/Formulas/SearchInput/SearchInput';
import ExpenseFormulasTable from './ExpenseFormulasTable';
import useQueryParams from '~/utils/hooks/useQueryParams';
import { ExpenseFrequencyEnum } from './components/types';
import NewExpenseList from './components/NewExpenseMappings/NewExpenseList';
import PullAccountingActuals from '~/components/PullAccountingActuals';

enum IViewEnum {
  MONTHLY = 'monthly',
  LIST = 'list',
}

const ExpensesPage = (): React.ReactNode => {
  const newExpenseNotifications = useFeatureFlagHarness('newExpenseNotifications');

  const preferences = useSelector((state: State) => state.user.preferences);
  const { activeScenarioUuid } = useSelector((state: State) => state.scenario);
  const {
    reload,
    setExpenseModal,
    loading,
    showPastExpenses,
    setShowPastExpenses,
    expenses,
    categories,
    types,
    setCategories,
    setTypes,
    expensesDictionary,
    setDesiredCreationStatus,
  } = useContext(ExpensesPageContext);
  const isNotInitialRender = useRef(false);
  const [queryParams, setQueryParams] = useQueryParams();
  const selectedView = queryParams.get('view') ?? 'list';
  const generateExpenses = useFeatureFlagHarness('generateExpenses');
  const expensesTableViewEnabled = useFeatureFlagHarness('expensesTableView');

  useExitScenarioListener(reload);

  useEffect(() => {
    if (isNotInitialRender.current) {
      reload();
    } else {
      isNotInitialRender.current = true;
    }
  }, [preferences.defaultGraphStartDate, preferences.defaultGraphEndDate, activeScenarioUuid]);

  if (loading) {
    return (
      <div className="w-full max-sm:min-h-screen max-sm:pb-32" data-testid="expenses-page-loading">
        <Header title="Expenses" />
        <div className="px-10 mt-10 mb-4">
          <Skeleton className="w-full h-[250px]" baseColor="#F8F9F6" />
        </div>
        <div className="px-10 max-w-full w-full">
          <Skeleton height={40} count={20} className="mb-4" baseColor="#F8F9F6" />
        </div>
      </div>
    );
  }

  const hasPastExpenses = expenses.some(
    (expense) =>
      (expense.context.endDate &&
        stringDate.isBefore({
          dateToCheck: expense.context.endDate,
          comparison: stringDate.getStringDate(),
        })) ||
      (expense.context.frequency === ExpenseFrequencyEnum.OneTime &&
        stringDate.isBefore({
          dateToCheck: expense.context.startDate,
          comparison: stringDate.getStringDate(),
        })),
  );

  return (
    <div className="w-full max-sm:min-h-screen pb-32">
      <Header
        title="Expenses"
        startChildren={
          <div className="flex flex-row gap-2">
            <ScenarioDropdown reload={reload} />
            {expensesTableViewEnabled && selectedView === IViewEnum.MONTHLY && <ActualsAndOverridesLegend />}
          </div>
        }
        endChildren={
          <div className="flex w-full justify-end items-center gap-4">
            <PullAccountingActuals />
            <UserDateRange pickerAlignment="right" />
          </div>
        }
      />
      <div>
        <FormulasProvider mode={IFormulaTypeEnum.Expense} expensesDictionary={expensesDictionary}>
          {expenses.length > 0 && (
            <div className="w-full flex items-center justify-between px-10 mt-10 mb-4">
              <div className="flex items-center gap-2 justify-center">
                {expensesTableViewEnabled && (
                  <div className="w-[90px]">
                    <SegmentedControl
                      name="view-control"
                      value={selectedView}
                      setValue={(value) => {
                        const currentParams = Object.fromEntries(queryParams.entries());
                        currentParams.view = value;
                        setQueryParams(currentParams);
                      }}
                      segments={[
                        {
                          value: IViewEnum.LIST,
                          label: <ListBulletIcon className="size-6 py-1" />,
                        },
                        {
                          value: IViewEnum.MONTHLY,
                          label: <TableCellsIcon className="size-6 py-1" />,
                        },
                      ]}
                    />
                  </div>
                )}
                {selectedView === IViewEnum.LIST && (
                  <>
                    <div className="w-[200px] flex justify-center">
                      <Select
                        id="select-category"
                        state={categories}
                        setState={setCategories}
                        placeholder="All Categories"
                      />
                    </div>
                    <div className="w-[150px] flex justify-center">
                      <Select id="select-type" state={types} setState={setTypes} placeholder="All Frequencies" />
                    </div>
                  </>
                )}
                {hasPastExpenses && (
                  <div className="flex text-nowrap items-center gap-2 mb-1">
                    <Checkbox
                      checked={showPastExpenses}
                      toggleValue={() => setShowPastExpenses(!showPastExpenses)}
                      label="Show Past Expenses"
                      id="show-past-employees"
                    />
                  </div>
                )}
              </div>
              <div className="flex items-center gap-2">
                <SearchInput />
                <Button
                  fill="outline"
                  onClick={() => {
                    setExpenseModal(true);
                    setDesiredCreationStatus('created');
                  }}
                  id="new-expense-button"
                  className="max-sm:hidden !w-fit"
                >
                  Add Expense
                </Button>
              </div>
            </div>
          )}
          {selectedView === IViewEnum.LIST && (
            <>
              <ExpensesGraph />
              {newExpenseNotifications && !!expenses.length && <NewExpenseList />}
              <ExpensesTable />
            </>
          )}
          {selectedView === IViewEnum.MONTHLY && (
            <div className="w-full pl-10">
              <ExpenseFormulasTable reload={reload} />
            </div>
          )}

          <DiscontinueExpenseModal />
          <DeleteExpenseModal />
          <CreateExpenseModal />
          {generateExpenses && <GenerateExpensesModal />}
        </FormulasProvider>
      </div>
    </div>
  );
};

const Page = (): React.ReactNode => {
  return (
    <ExpensesPageContextProvider>
      <ExpensesPage />
    </ExpensesPageContextProvider>
  );
};

export default Page;
