import React, { forwardRef } from 'react'; // Updated import
import Input from '~/components/Input/InputWrapper';
import FormulaList from '~/pages/FinancialModelDeprecated/components/FormulaBuilder/FormulaList';
import { IFormula } from '~/services/parallel/formulas.types';

const FormulaListInput = forwardRef(
  (
    {
      attributeTitle,
      setAttributeTitle,
      handleKeyDown,
      filteredFormulaList,
      onSelectAttribute,
      highlightedFormula,
      setHighlightedFormula,
      inputLabel,
      inputPlaceholder,
      inputId,
      className,
    }: {
      attributeTitle: Types.InputState;
      setAttributeTitle: React.Dispatch<React.SetStateAction<Types.InputState>>;
      handleKeyDown: (e: React.KeyboardEvent<Element>) => void;
      filteredFormulaList: IFormula[];
      onSelectAttribute: (attribute: IFormula) => void;
      highlightedFormula: {
        formula?: IFormula;
        index: number;
      };
      setHighlightedFormula: React.Dispatch<React.SetStateAction<{ formula?: IFormula; index: number }>>;
      inputLabel?: string;
      inputPlaceholder?: string;
      inputId?: string;
      className?: string;
    },
    ref,
  ) => {
    return (
      <div className={className}>
        <Input
          ref={ref}
          state={attributeTitle}
          label={inputLabel}
          setState={setAttributeTitle}
          id={`${inputId}-formula-list`}
          type="search"
          onKeyDown={handleKeyDown}
          placeholder={inputPlaceholder}
        />
        <FormulaList
          value={attributeTitle.value}
          formulaList={filteredFormulaList}
          onSelectAttribute={onSelectAttribute}
          highlightedFormula={highlightedFormula}
          setHighlightedFormula={setHighlightedFormula}
        />
      </div>
    );
  },
);

FormulaListInput.displayName = 'FormulaListInput';

export default FormulaListInput;
