import React, { useContext, useEffect, useState } from 'react';
import { StatusCodes } from 'http-status-codes';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import Switch from '~/components/Switch';
import { HeadcountContext } from '~/pages/Headcount/context/HeadcountContext';
import request from '~/utils/request';
import { IAPIResponse } from '~/utils/types';
import { State } from '~/store';
import logger from '~/utils/logger';
import { updateScenarioLoadingState } from '~/store/scenarioSlice';

const PositionToggle = ({
  positionUuid,
  successCallback,
  useFullPageLoadingState,
}: {
  positionUuid: string;
  successCallback?: () => void;
  useFullPageLoadingState?: boolean;
}): React.ReactElement => {
  const dispatch = useDispatch();
  const { positionActiveStateDict, setPositionActiveStateDict } = useContext(HeadcountContext);
  const [positionEnabled, setPositionEnabled] = useState<boolean>(positionActiveStateDict[positionUuid] ?? false);
  const { uuid: organizationUuid } = useSelector((state: State) => state.organization);
  const { activeScenarioUuid } = useSelector((state: State) => state.scenario);

  useEffect(() => {
    setPositionEnabled(positionActiveStateDict[positionUuid] ?? false);
  }, [positionActiveStateDict[positionUuid], positionUuid]);

  const togglePositionActiveInScenario = async (checked: boolean): Promise<void> => {
    if (!activeScenarioUuid) {
      logger.error(new Error('Toggles should not be available in the base view'));
      return;
    }

    if (useFullPageLoadingState) {
      dispatch(updateScenarioLoadingState('updating'));
    }

    setPositionEnabled(checked);
    setPositionActiveStateDict((prev) => ({
      ...prev,
      [positionUuid]: checked,
    }));

    const response = (await request({
      method: 'PATCH',
      url: `/organizations/${organizationUuid}/positions/${positionUuid}`,
      params: {
        scenarioUuid: activeScenarioUuid,
        awaitCalculations: true,
      },
      body: {
        isActive: checked,
      },
    })) as IAPIResponse;

    if (response.status !== StatusCodes.CREATED) {
      setPositionEnabled(!checked);
      setPositionActiveStateDict((prev) => ({
        ...prev,
        [positionUuid]: !checked,
      }));
      logger.error(new Error('Request to toggle position failed on the backend'));
      toast.error('Failed to make changes');
    } else {
      successCallback?.();
    }
    if (useFullPageLoadingState) {
      dispatch(updateScenarioLoadingState('idle'));
    }
  };

  return (
    <Switch
      id={`is-active-switch-${positionUuid}`}
      checked={positionEnabled}
      onChange={togglePositionActiveInScenario}
    />
  );
};

export default PositionToggle;
