import React, { useContext } from 'react';
import { IIntegrationMapping } from '~/services/parallel/integrations.types';
import Typography from '~/components/Typography';
import Button from '~/components/Button';
import { ExpensesPageContext } from '../../context/ExpensesContext';

const NewExpenseMappingsTable = ({
  unusedExpenseMappings,
  onLink,
}: {
  unusedExpenseMappings: IIntegrationMapping[];
  onLink: React.Dispatch<React.SetStateAction<{ uuid: string | null; name: string | null } | null>>;
}): React.ReactNode => {
  const { setExpenseModal, formState } = useContext(ExpensesPageContext);
  return (
    <div className="flex flex-col bg-white">
      <div className="sticky top-0 z-10 bg-white rounded-t-lg py-2 px-4 border-b border-neutral-50">
        <div className="flex justify-between items-center">
          <Typography size="xs" color="empty">
            GL
          </Typography>
          <Typography size="xs" color="empty">
            Actions
          </Typography>
        </div>
      </div>
      <div className="flex flex-col">
        {unusedExpenseMappings
          .sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime())
          .map((mapping) => {
            return (
              <div
                className="flex justify-between items-center py-2 px-4 border-t border-neutral-50"
                key={mapping.uuid}
              >
                <Typography>{mapping.name}</Typography>
                <div className="flex gap-4">
                  <Button
                    className="!w-fit !px-5 !py-2"
                    fill="outline"
                    onClick={() => onLink({ uuid: mapping.uuid, name: mapping.name })}
                  >
                    Link with Existing
                  </Button>
                  <Button
                    className="!w-fit !px-5 !py-2"
                    onClick={() => {
                      setExpenseModal(true);
                      formState.setIntegrationMappings((prev) => ({
                        ...prev,
                        selected: [{ label: mapping.name, value: mapping.uuid }],
                      }));
                      formState.setName((prev) => ({
                        ...prev,
                        valid: prev.validation.test(mapping.name),
                        value: mapping.name,
                      }));
                    }}
                  >
                    Create Expense
                  </Button>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default NewExpenseMappingsTable;
