import { useEffect, useState } from 'react';
import pageData from './pageData';
import { IIntegrationMapping, ZIntegrationMapping } from '~/utils/schemas/integrations';
import { z } from 'zod';
import { useSelector } from 'react-redux';
import { State } from '~/store';
import { IFormula, ZFormula } from '~/services/parallel/formulas.types';

const ZGroupSorting = z.array(
  z.object({
    name: z.string(),
    sortOrder: z.array(z.string()),
  }),
);

const ZParsedLoader = z.object({
  monthsBetweenDates: z.array(z.date()),
  list: z.array(ZFormula),
  sorting: ZGroupSorting,
  dataSources: z.array(ZIntegrationMapping),
});

export const useFinancialModelData = (): {
  data: {
    dataSources: IIntegrationMapping[];
    monthsBetweenDates: Date[];
    list: IFormula[];
    sorting: { name: string; sortOrder: string[] }[];
  };
  loading: boolean;
  revalidate: () => Promise<void>;
  revalidateLoading: boolean;
} => {
  const activeScenarioUuid = useSelector((state: State) => state.scenario.activeScenarioUuid);
  const [data, setData] = useState<{
    dataSources: IIntegrationMapping[];
    monthsBetweenDates: Date[];
    list: IFormula[];
    sorting: { name: string; sortOrder: string[] }[];
  }>({
    dataSources: [],
    monthsBetweenDates: [],
    list: [],
    sorting: [],
  });
  const [loading, setLoading] = useState<boolean>(true);
  const [revalidateLoading, setRevalidateLoading] = useState<boolean>(false);

  const fetchData = async (): Promise<void> => {
    setRevalidateLoading(true);
    const response = await pageData();
    const parsedResponse = ZParsedLoader.parse(response);
    setData({ ...parsedResponse });
    setRevalidateLoading(false);
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [activeScenarioUuid]);

  return { data, loading, revalidate: fetchData, revalidateLoading };
};
