import React, { forwardRef } from 'react';
import Input from '~/components/Input/InputWrapper';
import Typography from '~/components/Typography';

interface CurrencyInputProps {
  id?: string;
  state: Types.InputState;
  setState: React.Dispatch<React.SetStateAction<Types.InputState>>;
  label?: string;
  required?: boolean;
  inputClassName?: string;
  onBlur?: () => void;
}

const CurrencyInput = forwardRef<HTMLInputElement, CurrencyInputProps>(
  ({ id, state, setState, label, required = false, inputClassName, onBlur }, ref): React.ReactElement => {
    const showingError = state.touched && !state.pristine && !state.valid;
    return (
      <label className="flex flex-col justify-start w-full">
        {label && (
          <div className="flex flex-row">
            <Typography
              size="xs"
              color={state.disabled ? 'disabled' : 'primary'}
              className="mb-1"
              id={id ? `${id}-label` : undefined}
            >
              {label}
            </Typography>
            {required && (
              <Typography tag="span" size="2xs" color={state.disabled ? 'disabled' : 'primary'}>
                *
              </Typography>
            )}
          </div>
        )}
        <div className="relative">
          <div
            className={`h-[42px] items-center z-10 absolute flex justify-center pl-3 pr-2 rounded-l shadow-xs text-neutral-200 ${
              state.disabled ? ' bg-neutral-25 text-neutral-75 border-y border-l border-gray-300' : ' bg-gray-15'
            }${showingError ? ' border-red-300' : ''}`}
          >
            {'$'}
          </div>
          <Input
            id={id ? `${id}-currency` : 'currency'}
            type="currency"
            state={state}
            setState={setState}
            className={`${inputClassName} w-full pl-10`}
            onBlur={onBlur}
            ref={ref}
          />
        </div>
      </label>
    );
  },
);

CurrencyInput.displayName = 'CurrencyInput';

export default CurrencyInput;
