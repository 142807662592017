import React, { useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import Button from '~/components/Button';
import Modal from '~/components/Modal';
import Select, { useSelect } from '~/components/Select';
import Typography from '~/components/Typography';
import { ExpensesPageContext } from '~/pages/Expenses/context/ExpensesContext';
import * as api from '~/services/parallel/index';
import { State } from '~/store';
import logger from '~/utils/logger';

const LinkToExistingModal = ({
  isOpen,
  onClose,
  datasourceToLink,
}: {
  isOpen: boolean;
  onClose: () => void;
  datasourceToLink: { uuid: string | null; name: string | null } | null;
}): React.ReactNode => {
  const organizationUuid = useSelector((state: State) => state.organization.uuid);
  const { expenses, setExpenses } = useContext(ExpensesPageContext);
  const [expenseToLinkSelector, setExpenseToLinkSelector] = useSelect({
    options: expenses.map((expense) => ({ label: expense.name, value: expense.expenseUuid })),
  });
  const [expenseUuidToLink, setExpenseUuidToLink] = useState<string | null>(null);

  useEffect(() => {
    if (expenseToLinkSelector.selected?.value && typeof expenseToLinkSelector.selected.value === 'string') {
      setExpenseUuidToLink(expenseToLinkSelector.selected.value);
    } else {
      setExpenseUuidToLink(null);
    }
  }, [expenseToLinkSelector.selected?.value]);

  const handleLink = async (): Promise<void> => {
    try {
      const expenseToLink = expenses.find((expense) => expense.expenseUuid === expenseUuidToLink);
      if (expenseToLink && datasourceToLink?.uuid) {
        const updatedExpense = await api.expenses.updateExpense({
          organizationUuid,
          expenseUuid: expenseToLink.expenseUuid,
          updateBody: {
            dataSourceUuids: [...(expenseToLink.dataSourceUuids || []), datasourceToLink.uuid],
          },
        });

        setExpenses((prev) =>
          prev.map((expense) => (expense.expenseUuid === expenseUuidToLink ? updatedExpense : expense)),
        );

        toast.success('Data source linked to expense');
      }
    } catch (error) {
      if (error instanceof Error) logger.error(error);
      toast.error('Failed to link data source to expense');
    } finally {
      onClose();
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Link to Expense" size="xs">
      <div className="flex flex-col gap-3 w-full">
        <Typography color="secondary">{datasourceToLink?.name}</Typography>
        <div className="flex flex-col gap-4 w-full items-center">
          <Select id="select-expense" state={expenseToLinkSelector} setState={setExpenseToLinkSelector} />
          <div className="flex items-center justify-between w-full">
            <Button onClick={onClose} className="!w-fit !px-0" fill="clear">
              Cancel
            </Button>
            <Button onClick={handleLink} className="!w-fit">
              Link
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default LinkToExistingModal;
