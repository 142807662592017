import React, { ReactElement, useContext, useEffect, useMemo, useState } from 'react';
import BaseTable from '~/components/Table/Base/BaseTable';
import {
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  sortingFns,
  useReactTable,
} from '@tanstack/react-table';
import formatCurrency from '~/utils/formatCurrency';
import { formatEndDateForTable } from '~/pages/ExpensesDeprecated/utils/formatEndDateForTable';
import { ExpensesPageContext } from '~/pages/ExpensesDeprecated/context/ExpensesContext';
import { convertDepartmentUuidsToDisplayName } from '~/pages/ExpensesDeprecated/utils/convertDepartmentUuidsToDisplayName';
import expenseModelEmptyState from '~/assets/expenseModelEmptyState.svg';
import Typography from '~/components/Typography';
import EllipsisDropdown from '~/components/EllipsisDropdown';
import { IExpenseTableRow, IExpense, ExpenseFrequencyEnum } from '../types';
import formatPercent from '~/utils/formatPercent';
import { sortAmountFn, sortDateFn } from '~/pages/ExpensesDeprecated/components/Table/customSortingFunctions';
import { useSelector } from 'react-redux';
import { State } from '~/store';
import { filterExpenses } from './filterExpenses';
import useQueryParams from '~/utils/hooks/useQueryParams';
import request from '~/utils/request';
import { StatusCodes } from 'http-status-codes';
import { isEqual } from 'lodash';
import Button from '~/components/Button';
import HoverPopover from '~/components/HoverPopover';
import { useFeatureFlagHarness } from '~/utils/hooks/useFeatureFlag';
import * as stringDate from '~/utils/stringDate';
import useFormulaContext from '~/components/Formulas/context/useFormulaContext';

const TYPE_DISPLAY_MAP = {
  setCost: 'Set Cost',
  headcountFixed: 'Amount per Employee',
  headcountPercentCompensation: 'Percent of Salary',
  custom: 'Custom',
};

const FREQUENCY_DISPLAY_MAP = {
  monthly: 'Monthly',
  oneTime: 'One Time',
  onHire: 'On Hire',
  quarterly: 'Quarterly',
  annually: 'Annually',
};

export const PERCENTAGE_CONVERSION_DENOMINATOR = 10000;

const ExpenseTable = (): ReactElement => {
  const {
    expenses,
    departmentDict,
    positions,
    lockedDate,
    setDiscontinueExpense,
    setDeleteExpense,
    setExpenseUuid,
    setExpenseModal,
    setExpenses,
    revalidateFilteredExpensesReport,
    showPastExpenses,
    connectedIntegrations,
    setAutoGeneratedExpenses,
    setDesiredCreationStatus,
  } = useContext(ExpensesPageContext);
  const { searchFilter } = useFormulaContext();
  const { activeScenarioUuid } = useSelector((state: State) => state.scenario);
  const organizationUuid = useSelector((state: State) => state.organization.uuid);
  const [filteredExpenses, setFilteredExpenses] = useState<IExpense[]>([]);
  const [queryParams] = useQueryParams();
  const tagParam = queryParams.get('tagFilter');
  const frequencyParam = queryParams.get('frequencyFilter');
  const generateExpenses = useFeatureFlagHarness('generateExpenses');

  useEffect(() => {
    const newFilteredExpenses = filterExpenses({
      expenses,
      search: searchFilter.value,
      lockedDate,
      tagParam,
      frequencyParam,
      positions,
    });

    if (!isEqual(newFilteredExpenses, filteredExpenses)) {
      setFilteredExpenses(newFilteredExpenses);
    }
  }, [expenses, searchFilter.value, lockedDate, queryParams, positions, departmentDict]);

  /**
   * Generate table data
   */
  const { tableData, tableColumns } = useMemo(() => {
    const columns = [
      { id: 'name', label: 'NAME', enableSorting: true },
      { id: 'tag', label: 'CATEGORY', enableSorting: true },
      { id: 'driver', label: 'TYPE', enableSorting: true },
      { id: 'department', label: 'DEPARTMENT', enableSorting: true },
      {
        id: 'amount',
        label: 'AMOUNT',
        enableSorting: true,
        sortingFn: sortAmountFn,
      },
      {
        id: 'frequency',
        label: 'FREQUENCY',
        enableSorting: true,
      },
      { id: 'startDate', label: 'START DATE', enableSorting: true, sortingFn: sortDateFn },
      { id: 'endDate', label: 'END DATE' },
      { id: 'ellipsisDropdown', label: '' },
    ];

    const columnHelper = createColumnHelper<IExpenseTableRow>();

    const tableColumns = columns.map((col) =>
      columnHelper.accessor(col.id, {
        enableResizing: false,
        enablePinning: false,
        header: () => <div className="text-left">{col.label}</div>,
        cell: (info) => (
          <Typography
            color={info.row.original.isGrayedOut ? 'disabled' : undefined}
            id={`expense-${col.id}-${info.row.index}`}
          >
            {info.row.original[col.id]}
          </Typography>
        ),
        enableSorting: col.enableSorting ?? false,
        sortingFn: col.sortingFn ?? sortingFns.alphanumeric,
      }),
    );

    const handleDeleteExpense = async ({
      uuid,
      expenseUuid,
      expenseTitle,
    }: {
      uuid: string;
      expenseUuid: string;
      expenseTitle?: string;
    }): Promise<void> => {
      if (activeScenarioUuid) {
        const response = await request({
          method: 'DELETE',
          url: `/expenses/${expenseUuid}`,
          params: {
            scenarioUuid: activeScenarioUuid,
          },
          headers: {
            'Organization-Uuid': organizationUuid,
          },
        });

        if (response.status === StatusCodes.NO_CONTENT) {
          setExpenses(expenses.filter((expense) => expense.uuid !== uuid));
          setDeleteExpense(undefined);
          revalidateFilteredExpensesReport();
        }
      } else {
        setDeleteExpense({ uuid, expenseUuid, expenseTitle });
      }
    };

    const tableData = filteredExpenses
      .filter((expense) => {
        if (showPastExpenses) {
          return true;
        } else if (expense.context.endDate) {
          return stringDate.isSameMonthOrAfter({
            dateToCheck: expense.context.endDate,
            comparison: stringDate.getStringDate(),
          });
        } else if (expense.context.frequency === ExpenseFrequencyEnum.OneTime) {
          return stringDate.isSameMonthOrAfter({
            dateToCheck: expense.context.startDate,
            comparison: stringDate.getStringDate(),
          });
        } else {
          return true;
        }
      })
      .map(({ name, context, uuid, expenseUuid, isGrayedOut }, index) => {
        let amount;
        if (context.driver === 'custom') {
          amount = '-';
        } else {
          amount =
            context.driver === 'headcountPercentCompensation'
              ? formatPercent({
                  value: (context.amount as number) / PERCENTAGE_CONVERSION_DENOMINATOR,
                })
              : formatCurrency(context.amount as number);
        }
        return {
          name,
          tag: context.tag,
          driver: TYPE_DISPLAY_MAP[context.driver],
          department: (
            <HoverPopover
              buttonContent={
                <div className="!max-w-[200px] truncate">
                  {convertDepartmentUuidsToDisplayName({
                    departmentUuids: context.departments,
                    departmentDict,
                  })}
                </div>
              }
              panelContent={
                <div className="bg-black px-2 py-1 rounded-lg items-center justify-center">
                  <Typography color="white">
                    {convertDepartmentUuidsToDisplayName({
                      departmentUuids: context.departments,
                      departmentDict,
                    })}
                  </Typography>
                </div>
              }
              hoverDelay={500}
            />
          ),
          amount,
          frequency: context.driver === 'custom' ? '-' : FREQUENCY_DISPLAY_MAP[context.frequency],
          startDate: stringDate.format(context.startDate, 'MMMM yyyy'),
          endDate: formatEndDateForTable({
            endDate: context.endDate,
            frequency: context.frequency,
          }),
          ellipsisDropdown: (
            <EllipsisDropdown
              id={`expense-ellipsis-dropdown-${uuid}`}
              placeTop={Boolean(
                activeScenarioUuid && filteredExpenses.length > 1 && index === filteredExpenses.length - 1,
              )}
              options={[
                {
                  label: 'Edit',
                  onClick: (): void => {
                    setExpenseUuid({ uuid, expenseUuid, type: 'edit' });
                    setExpenseModal(true);
                  },
                  className: 'max-sm:hidden',
                },
                {
                  label: 'Duplicate',
                  onClick: (): void => {
                    setExpenseUuid({ uuid, expenseUuid, type: 'create' });
                    setExpenseModal(true);
                  },
                  className: 'max-sm:hidden',
                },
                ...(context.frequency !== 'oneTime'
                  ? [
                      {
                        label: 'Discontinue',
                        onClick: () =>
                          setDiscontinueExpense({
                            uuid,
                            expenseUuid,
                            startDate: context.startDate,
                            expenseTitle: name,
                          }),
                        className: 'max-sm:hidden',
                      },
                    ]
                  : []),
                {
                  label: 'Delete',
                  onClick: () => handleDeleteExpense({ uuid, expenseUuid, expenseTitle: name }),
                  className: 'text-red-500 max-sm:hidden',
                },
              ]}
            />
          ),
          isGrayedOut,
        };
      });

    return { tableData, tableColumns };
  }, [filteredExpenses, setDiscontinueExpense, setDeleteExpense, showPastExpenses]);

  const table = useReactTable({
    columns: tableColumns,
    data: tableData,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const tableEmptyState = (
    <div className="flex flex-col items-center gap-2 mt-[18vh] pb-16">
      <img src={expenseModelEmptyState} alt="Empty Tasks Illustration" className="w-64 h-auto" />
      <div className="flex flex-col items-center gap-4 max-w-[438px] text-center">
        <Typography color="empty" size="sm">
          This will help you anticipate costs, manage cash flow, and make informed decisions on hiring and growth —
          ensuring better resource allocation.
        </Typography>
        <div className="flex flex-col justify-center items-center gap-2">
          {generateExpenses && (
            <HoverPopover
              buttonContent={
                <Button
                  onClick={() => setAutoGeneratedExpenses(true)}
                  className="!w-fit !px-5"
                  disabled={connectedIntegrations.length === 0}
                >
                  Generate Forecasted Expenses
                </Button>
              }
              panelContent={
                connectedIntegrations.length === 0 ? (
                  <div className="pb-1 bg-transparent">
                    <div className="flex flex-col bg-black px-5 py-3 rounded-lg !w-[242px]">
                      <Typography color="white" size="xs" weight="semibold">
                        Required Data
                      </Typography>
                      <Typography color="white" size="xs">
                        Accounting Integrations
                      </Typography>
                    </div>
                  </div>
                ) : null
              }
              anchor="top"
            />
          )}
          <Button
            id="manually-add-expenses"
            onClick={() => {
              setExpenseModal(true);
              setDesiredCreationStatus('created');
            }}
            className="!w-fit !px-2"
            fill="clear"
          >
            Manually Add Expenses
          </Button>
        </div>
      </div>
    </div>
  );

  const noResultsEmptyState = (
    <div className="flex flex-col items-center gap-2 py-16">
      <img src={expenseModelEmptyState} alt="Empty Tasks Illustration" className="w-64 h-auto" />
      <div className="flex flex-col items-center gap-4 max-w-[512px] text-center">
        <Typography color="empty" size="sm">
          No Expenses
        </Typography>
      </div>
    </div>
  );

  return (
    <>
      {expenses.length ? (
        <div>
          <div className="max-w-full w-full overflow-scroll hide-scrollbar px-10">
            {filteredExpenses.length ? (
              <BaseTable
                id="expenses-table"
                // @ts-expect-error - tanstack table doesn't like typed rows
                table={table}
                styles={{
                  table: 'w-full',
                  th: 'px-4 py-2 text-xs font-normal text-neutral-200 text-nowrap',
                  td: 'h-14 px-4 border-t border-b border-gray-200 text-nowrap',
                }}
              />
            ) : (
              noResultsEmptyState
            )}
          </div>
        </div>
      ) : (
        tableEmptyState
      )}
    </>
  );
};

export default ExpenseTable;
