import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import Modal from '~/components/Modal';
import Button from '~/components/Button';
import Typography from '~/components/Typography';
import { ZTemporalValue } from '~/pages/Headcount/entity/schemas';
import { z } from 'zod';
import { IAdditionalCompensationValue } from '../../entity/types';
import formatCurrency from '~/utils/formatCurrency';
import ChangePayRateModal from './ChangePayRateModal';
import ChangeAdditionalCompensation from './ChangeAdditionalCompensation';
import { useFeatureFlagHarness } from '~/utils/hooks/useFeatureFlag';
import SegmentedControl from '~/components/SegmentedControl';
import * as stringDate from '~/utils/stringDate';
import { ClockIcon } from '@heroicons/react/24/outline';

const ZPayRateTemporalValue = ZTemporalValue.extend({
  effectiveAt: z.union([z.string(), z.literal('onHire')]),
  value: z.number({ coerce: true }),
});

type TPayRateTemporalValue = z.infer<typeof ZPayRateTemporalValue>;

interface Props {
  id?: string;
  isOpen: boolean;
  onClose: () => void;
  currentPayRate: number;
  currentBonus: IAdditionalCompensationValue | null;
  currentCommission: IAdditionalCompensationValue | null;
  positionUuid: string | null;
  positionEffectiveAt: string | null;
  payRateChangeHistory: TPayRateTemporalValue[];
  bonusChangeHistory: IAdditionalCompensationValue[];
  commissionChangeHistory: IAdditionalCompensationValue[];
  createScenario?: boolean;
}

const frequencyToText: Record<string, string> = {
  monthly: 'month',
  quarterly: 'quarter',
  annually: 'year',
};

const frequencyMultiplier: Record<string, number> = {
  monthly: 12,
  quarterly: 4,
  annually: 1,
};

const UpcomingChangeDisplay = ({ id }: { id: string }): ReactElement => {
  return (
    <div data-testid={id} className="w-fit bg-green-400 rounded-full p-1">
      <ClockIcon className="size-4 text-white" />
    </div>
  );
};

const ValueDisplay = ({
  currentPayRate,
  currentBonus,
  currentCommission,
  setDisplay,
  onClose,
  compensationDisplayType,
  upcomingChanges,
}: {
  currentPayRate: number;
  currentBonus: IAdditionalCompensationValue | null;
  currentCommission: IAdditionalCompensationValue | null;
  setDisplay: React.Dispatch<React.SetStateAction<'valueDisplay' | 'payRate' | 'bonus' | 'commission'>>;
  onClose: () => void;
  compensationDisplayType: 'annually' | 'perPeriod';
  upcomingChanges: {
    payRate: boolean;
    bonus: boolean;
    commission: boolean;
  };
}): ReactElement => {
  const isPeriodDisplay = compensationDisplayType === 'perPeriod';
  const getAdditionalCompensationAnnualValue = (
    additionalCompensation: IAdditionalCompensationValue | null,
  ): string => {
    if (!additionalCompensation) return '$0';
    return formatCurrency(additionalCompensation.value * frequencyMultiplier[additionalCompensation.frequency], false);
  };
  return (
    <div className="flex flex-col w-full mt-2" data-testid="pay-change-modal">
      <div className="flex flex-row gap-2 w-full justify-between">
        <div className="flex flex-col whitespace-nowrap gap-1 border border-neutral-50 rounded-lg px-4 py-3 w-1/3">
          <div className="flex flex-row justify-between">
            <Typography weight="medium">Salary</Typography>
            {upcomingChanges.payRate && <UpcomingChangeDisplay id={`upcoming-change-payRate`} />}
          </div>
          <Typography color="secondary">
            {isPeriodDisplay
              ? `${formatCurrency(currentPayRate / 12, false)}/month`
              : formatCurrency(currentPayRate, false)}
          </Typography>
          <Button fill="clear" className="!w-fit !px-0 !py-0" onClick={() => setDisplay('payRate')}>
            Add Change
          </Button>
        </div>
        <div className="flex flex-col whitespace-nowrap gap-1 border border-neutral-50 rounded-lg px-4 py-3  w-1/3">
          <div className="flex flex-row justify-between">
            <Typography weight="medium">Commission</Typography>
            {upcomingChanges.commission && <UpcomingChangeDisplay id={`upcoming-change-commission`} />}
          </div>
          <Typography color="secondary">
            {isPeriodDisplay
              ? `${formatCurrency(currentCommission?.value ?? 0, false)}/${frequencyToText[currentCommission?.frequency ?? 'monthly']}`
              : getAdditionalCompensationAnnualValue(currentCommission)}
          </Typography>
          <Button fill="clear" className="!w-fit !px-0 !py-0" onClick={() => setDisplay('commission')}>
            Add Change
          </Button>
        </div>
        <div className="flex flex-col whitespace-nowrap gap-1 border border-neutral-50 rounded-lg px-4 py-3  w-1/3">
          <div className="flex flex-row justify-between">
            <Typography weight="medium">Bonus</Typography>
            {upcomingChanges.bonus && <UpcomingChangeDisplay id={`upcoming-change-bonus`} />}
          </div>
          <Typography color="secondary">
            {isPeriodDisplay
              ? `${formatCurrency(currentBonus?.value ?? 0, false)}/${frequencyToText[currentBonus?.frequency ?? 'monthly']}`
              : getAdditionalCompensationAnnualValue(currentBonus)}
          </Typography>
          <Button fill="clear" className="!w-fit !px-0 !py-0" onClick={() => setDisplay('bonus')}>
            Add Change
          </Button>
        </div>
      </div>
      <div className="flex flex-row w-full justify-between mt-2">
        <Button id="cancel-pay-change" onClick={() => onClose()} fill="clear" className="!w-fit !px-0">
          Close
        </Button>
      </div>
    </div>
  );
};

const UpdateCompensationModal = ({
  id,
  isOpen,
  onClose,
  currentPayRate,
  currentBonus,
  currentCommission,
  positionUuid,
  positionEffectiveAt,
  payRateChangeHistory,
  bonusChangeHistory,
  commissionChangeHistory,
  createScenario,
}: Props): ReactElement => {
  const bonusAndCommission = useFeatureFlagHarness('bonusAndCommission');
  const [display, setDisplay] = useState<'valueDisplay' | 'payRate' | 'bonus' | 'commission'>(
    bonusAndCommission ? 'valueDisplay' : 'payRate',
  );
  const [compensationDisplayType, setCompensationDisplayType] = useState<'annually' | 'perPeriod'>('annually');
  const [isRendered, setIsRendered] = useState(false);

  // Manage the rendered state for transitions
  useEffect(() => {
    if (display === 'valueDisplay') {
      setIsRendered(true);
    } else {
      setIsRendered(false);
    }
  }, [display]);

  const getUpcomingChanges = (changeHistory: TPayRateTemporalValue[] | IAdditionalCompensationValue[]): boolean => {
    return changeHistory.some((change) =>
      stringDate.isAfter({
        dateToCheck: change.effectiveAt,
        comparison: stringDate.getStringDate(),
      }),
    );
  };

  const displayOptions: {
    modalSize: 'xxs' | 'lg' | 'md';
    title: string | React.ReactNode;
    innerComponent: React.ReactNode;
  } = useMemo(() => {
    switch (display) {
      case 'payRate':
        return {
          modalSize: 'xxs',
          title: 'Salary and Wages',
          innerComponent: (
            <ChangePayRateModal
              onClose={() => onClose()}
              positionUuid={positionUuid}
              positionEffectiveAt={positionEffectiveAt}
              changeHistory={payRateChangeHistory}
              createScenario={createScenario}
              onBack={() => setDisplay('valueDisplay')}
            />
          ),
        };
      case 'bonus':
        return {
          modalSize: 'lg',
          title: 'Bonus',
          innerComponent: (
            <ChangeAdditionalCompensation
              additionalCompensationType="bonuses"
              positionUuid={positionUuid}
              positionEffectiveAt={positionEffectiveAt}
              changeHistory={bonusChangeHistory}
              onClose={() => onClose()}
              createScenario={createScenario}
              onBack={() => setDisplay('valueDisplay')}
            />
          ),
        };
      case 'commission':
        return {
          modalSize: 'lg',
          title: 'Commission',
          innerComponent: (
            <ChangeAdditionalCompensation
              additionalCompensationType="commissions"
              positionUuid={positionUuid}
              positionEffectiveAt={positionEffectiveAt}
              changeHistory={commissionChangeHistory}
              onClose={() => onClose()}
              createScenario={createScenario}
              onBack={() => setDisplay('valueDisplay')}
            />
          ),
        };
      case 'valueDisplay':
      default:
        return {
          modalSize: 'md',
          title: (
            <div className="w-full flex flex-row justify-between items-center gap-8">
              <Typography
                size="lg"
                weight="bold"
                className={`whitespace-nowrap transition-[width] duration-200 ${
                  isRendered ? 'w-[251px]' : 'w-0'
                } overflow-hidden`}
              >
                Total Cash Compensation
              </Typography>
              <div className="max-w-[227px] min-w-[227px] w-[227px]">
                <SegmentedControl
                  name="compensation-control"
                  value={compensationDisplayType}
                  setValue={(val) => setCompensationDisplayType(val as 'annually' | 'perPeriod')}
                  segments={[
                    { value: 'annually', label: <Typography>Annually</Typography> },
                    { value: 'perPeriod', label: <Typography>Per Period</Typography> },
                  ]}
                />
              </div>
            </div>
          ),
          innerComponent: (
            <ValueDisplay
              currentPayRate={currentPayRate}
              currentBonus={currentBonus}
              currentCommission={currentCommission}
              setDisplay={setDisplay}
              onClose={onClose}
              compensationDisplayType={compensationDisplayType}
              upcomingChanges={{
                payRate: getUpcomingChanges(payRateChangeHistory),
                bonus: getUpcomingChanges(bonusChangeHistory),
                commission: getUpcomingChanges(commissionChangeHistory),
              }}
            />
          ),
        };
    }
  }, [compensationDisplayType, display, isRendered]);

  return (
    <Modal id={id} title={displayOptions.title} isOpen={isOpen} size={displayOptions.modalSize}>
      {displayOptions.innerComponent}
    </Modal>
  );
};

export default UpdateCompensationModal;
