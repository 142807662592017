import React, { useEffect, useMemo, useRef, useState } from 'react';
import { headcountLoaderSchema } from '~/pages/Headcount/entity/schemas';
import formatHeadcountCsv from '../utils/formatHeadcountCsv';
import {
  IAdditionalCompensationValue,
  IEmploymentType,
  IPositionDetails,
  IPositionDetailsWithOrderedDates,
} from '../entity/types';
import usePositionFormState, { IPositionFormState } from '../components/usePositionFormState';
import { IDatePickerState } from '~/components/DatePicker/useDatePicker';
import emptyHeadcountStateIllustration from '~/assets/emptyHeadcountStateIllustration.svg';
import Typography from '~/components/Typography';
import request from '~/utils/request';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '~/store';
import useHeadcountData from '../useHeadcountData';
import { StatusCodes } from 'http-status-codes';
import useEditPositionFormState, {
  IEditPositionFormState,
} from '../components/HeadcountTimeline/EditPosition/useEditPositionFormState';
import logger from '~/utils/logger';
import { handleCreateScenario } from '~/utils/handleCreateScenario';
import { IAPIResponse } from '~/utils/types';
import { useInput } from '~/components/Input/InputWrapper';
import { updateScenarioLoadingState } from '~/store/scenarioSlice';
import useInlineCreationFormState, { IInlineCreationFormState } from '../components/useInlineCreationFormState';

export interface IPositionDataDict {
  employeeName: string;
  title: string;
  department: { uuid: string; name: string };
  hireDate: string;
  termDate: string | null;
  fullyBurdenedCost: number | null | undefined;
  employmentType: IEmploymentType;
}

export interface IHeadcountContext {
  createPositionModalIsOpen: boolean;
  setCreatePositionModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  csvToExport: (string | number | boolean | null | undefined)[][];
  reload: () => void;
  emptyTableState: React.ReactNode;
  positions?: IPositionDetailsWithOrderedDates[];
  setPositions: React.Dispatch<React.SetStateAction<IPositionDetailsWithOrderedDates[]>>;
  deletePosition: ({
    positionUuid,
    createScenario,
  }: {
    positionUuid: string;
    createScenario?: boolean;
  }) => Promise<void>;
  addTermDateState: {
    termDate: IDatePickerState | null;
    positionUuid: string | null;
    containerRef: React.RefObject<HTMLDivElement>;
  };
  setAddTermDateState: React.Dispatch<
    React.SetStateAction<{
      termDate: IDatePickerState | null;
      positionUuid: string | null;
      containerRef: React.RefObject<HTMLDivElement>;
    }>
  >;
  openTermDateRowIndex: number | null;
  setOpenTermDateRowIndex: React.Dispatch<React.SetStateAction<number | null>>;
  pageLoading: boolean;
  deleteIsLoading: boolean;
  showTermedPositions: boolean;
  setShowTermedPositions: React.Dispatch<React.SetStateAction<boolean>>;
  renderedPositions: IPositionDetailsWithOrderedDates[];
  setRenderedPositions: React.Dispatch<React.SetStateAction<IPositionDetailsWithOrderedDates[]>>;
  hasReloaded: boolean;
  setHasReloaded: React.Dispatch<React.SetStateAction<boolean>>;
  editPositionUuid: string | null;
  setEditPositionUuid: React.Dispatch<React.SetStateAction<string | null>>;
  positionActiveStateDict: Record<string, boolean>;
  setPositionActiveStateDict: React.Dispatch<React.SetStateAction<Record<string, boolean>>>;
  search: Types.InputState;
  setSearch: React.Dispatch<React.SetStateAction<Types.InputState>>;
  positionDataDict: Record<string, IPositionDataDict>;
  setPositionDataDict: React.Dispatch<React.SetStateAction<Record<string, IPositionDataDict>>>;
  fetchedPositions: IPositionDetails[];
  payRateModalState: {
    isOpen: boolean;
    positionUuid: string | null;
    positionHireDate: string | null;
    payRateChangeHistory: { value: number; effectiveAt: string }[];
    bonusChangeHistory: IAdditionalCompensationValue[] | null;
    commissionChangeHistory: IAdditionalCompensationValue[] | null;
    currentPayRate: number;
    currentBonus: IAdditionalCompensationValue | null;
    currentCommission: IAdditionalCompensationValue | null;
  };
  setPayRateModalState: React.Dispatch<
    React.SetStateAction<{
      isOpen: boolean;
      positionUuid: string | null;
      positionHireDate: string | null;
      payRateChangeHistory: { value: number; effectiveAt: string }[];
      bonusChangeHistory: IAdditionalCompensationValue[] | null;
      commissionChangeHistory: IAdditionalCompensationValue[] | null;
      currentPayRate: number;
      currentBonus: IAdditionalCompensationValue | null;
      currentCommission: IAdditionalCompensationValue | null;
    }>
  >;
  terminationModalState: {
    isOpen: boolean;
    positionUuid: string | null;
    positionHireDate: string | null;
    currentTerminationDate: string | null;
  };
  setTerminationModalState: React.Dispatch<
    React.SetStateAction<{
      isOpen: boolean;
      positionUuid: string | null;
      positionHireDate: string | null;
      currentTerminationDate: string | null;
    }>
  >;
  positionFormState: IPositionFormState;
  editPositionFormState: IEditPositionFormState;
  inlineCreationFormState: IInlineCreationFormState;
}

export const HeadcountContext = React.createContext({} as IHeadcountContext);

const emptyTableState = (
  <div className="flex flex-col items-center gap-4 py-16 bg-white">
    <img src={emptyHeadcountStateIllustration} alt="Empty Headcount Illustration" className="w-36 h-auto" />
    <Typography color="empty">No Positions</Typography>
  </div>
);

export const HeadcountProvider = ({ children }: { children: React.ReactNode }): React.ReactNode => {
  const headcountData = useHeadcountData();
  const dispatch = useDispatch();
  const revalidate = headcountData.revalidate;
  const { uuid: organizationUuid } = useSelector((state: State) => state.organization);
  const { activeScenarioUuid } = useSelector((state: State) => state.scenario);
  const [positions, setPositions] = useState<IPositionDetailsWithOrderedDates[]>([]);
  const [renderedPositions, setRenderedPositions] = useState<IPositionDetailsWithOrderedDates[]>([]);
  const [showTermedPositions, setShowTermedPositions] = useState<boolean>(false);
  const [hasReloaded, setHasReloaded] = useState<boolean>(true);
  const [editPositionUuid, setEditPositionUuid] = useState<string | null>(null);
  const [positionActiveStateDict, setPositionActiveStateDict] = useState<Record<string, boolean>>({});
  const [search, setSearch] = useInput({
    validation: /.*/,
  });
  const [positionDataDict, setPositionDataDict] = useState<Record<string, IPositionDataDict>>({});
  const fetchedPositions = useMemo(() => {
    return headcountData.data ? headcountLoaderSchema.parse(headcountData.data).positions ?? [] : [];
  }, [headcountData.data]);
  const [payRateModalState, setPayRateModalState] = useState<{
    isOpen: boolean;
    positionUuid: string | null;
    positionHireDate: string | null;
    payRateChangeHistory: { value: number; effectiveAt: string }[];
    bonusChangeHistory: IAdditionalCompensationValue[] | null;
    commissionChangeHistory: IAdditionalCompensationValue[] | null;
    currentPayRate: number;
    currentBonus: IAdditionalCompensationValue | null;
    currentCommission: IAdditionalCompensationValue | null;
  }>({
    isOpen: false,
    positionUuid: null,
    positionHireDate: null,
    payRateChangeHistory: [],
    bonusChangeHistory: null,
    commissionChangeHistory: null,
    currentPayRate: 0,
    currentBonus: null,
    currentCommission: null,
  });
  const [terminationModalState, setTerminationModalState] = useState<{
    isOpen: boolean;
    positionUuid: string | null;
    positionHireDate: string | null;
    currentTerminationDate: string | null;
  }>({
    isOpen: false,
    positionUuid: null,
    positionHireDate: null,
    currentTerminationDate: null,
  });

  const inlineCreationFormState = useInlineCreationFormState();
  const positionFormState = usePositionFormState();
  const editPositionFormState = useEditPositionFormState(editPositionUuid);

  useEffect(() => {
    setPositions(
      fetchedPositions.map((position) => ({
        ...position,
        orderedDate: position.hireDate,
      })),
    );

    setRenderedPositions(
      fetchedPositions.map((position) => ({
        ...position,
        orderedDate: position.hireDate,
      })),
    );

    const positionDataDict = fetchedPositions.reduce(
      (acc, position) => {
        acc[position.positionUuid] = {
          employeeName: position.employeeName ?? '',
          title: position.title,
          department: position.currentDepartment,
          hireDate: position.hireDate,
          termDate: position.termDate,
          fullyBurdenedCost: position.fullyBurdenedCost,
          employmentType: position.employmentType,
        };
        return acc;
      },
      {} as Record<string, IPositionDataDict>,
    );
    setPositionDataDict(positionDataDict);

    const activeStateDict = fetchedPositions.reduce(
      (acc, position) => {
        if (!activeScenarioUuid && !position.isActive) {
          logger.error(new Error(`Position ${position.positionUuid} is marked inactive in base model`));
        }
        acc[position.positionUuid] = activeScenarioUuid ? position.isActive : true;
        return acc;
      },
      {} as Record<string, boolean>,
    );
    setPositionActiveStateDict(activeStateDict);
  }, [fetchedPositions]);

  const [createPositionModalIsOpen, setCreatePositionModalIsOpen] = useState(false);
  const timelineRef = useRef<HTMLDivElement>(null);
  const [addTermDateState, setAddTermDateState] = useState<{
    termDate: IDatePickerState | null;
    positionUuid: string | null;
    containerRef: React.RefObject<HTMLDivElement>;
  }>({
    termDate: null,
    positionUuid: null,
    containerRef: timelineRef,
  });
  const [openTermDateRowIndex, setOpenTermDateRowIndex] = useState<number | null>(null);
  const [deleteIsLoading, setDeleteIsLoading] = useState<boolean>(false);

  const csvToExport = formatHeadcountCsv({
    positions,
  });

  const deletePosition = async ({
    positionUuid,
    createScenario,
  }: {
    positionUuid: string;
    createScenario?: boolean;
  }): Promise<void> => {
    try {
      setDeleteIsLoading(true);
      const deletePositionResponse = (await request({
        method: 'DELETE',
        url: `/organizations/${organizationUuid}/positions/${positionUuid}`,
        params: {
          scenarioUuid: activeScenarioUuid ?? undefined,
          createScenario: !activeScenarioUuid && createScenario ? true : undefined,
        },
      })) as IAPIResponse;

      if (deletePositionResponse.status === StatusCodes.NO_CONTENT) {
        await handleCreateScenario({ response: deletePositionResponse });
        toast.success('Position deleted successfully');
        setPositions((prev) =>
          prev.filter((position) => {
            if ('uuid' in position) {
              return position.uuid !== positionUuid;
            } else {
              return position.positionUuid !== positionUuid;
            }
          }),
        );
        setRenderedPositions((prev) =>
          prev.filter((position) => {
            if ('uuid' in position) {
              return position.uuid !== positionUuid;
            } else {
              return position.positionUuid !== positionUuid;
            }
          }),
        );
        revalidate();
      } else {
        toast.error('Failed to delete position');
      }
    } catch (error) {
      if (error instanceof Error) {
        logger.error(error);
      }
      toast.error('An error occurred while deleting the position');
    } finally {
      setDeleteIsLoading(false);
      if (createScenario) {
        dispatch(updateScenarioLoadingState('idle'));
      }
    }
  };

  return (
    <HeadcountContext.Provider
      value={{
        createPositionModalIsOpen,
        setCreatePositionModalIsOpen,
        csvToExport,
        reload: revalidate,
        emptyTableState,
        positions,
        setPositions,
        deletePosition,
        addTermDateState,
        setAddTermDateState,
        openTermDateRowIndex,
        setOpenTermDateRowIndex,
        pageLoading: headcountData.loading,
        deleteIsLoading,
        showTermedPositions,
        setShowTermedPositions,
        renderedPositions,
        setRenderedPositions,
        hasReloaded,
        setHasReloaded,
        editPositionUuid,
        setEditPositionUuid,
        positionActiveStateDict,
        setPositionActiveStateDict,
        search,
        setSearch,
        positionDataDict,
        setPositionDataDict,
        fetchedPositions,
        payRateModalState,
        setPayRateModalState,
        terminationModalState,
        setTerminationModalState,
        positionFormState,
        editPositionFormState,
        inlineCreationFormState,
      }}
    >
      {children}
    </HeadcountContext.Provider>
  );
};

export default HeadcountProvider;
