import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { IInitialValue } from '../entities/types';
import { CellContext } from '@tanstack/react-table';
import { useSelect } from '~/components/Select';
import SelectDepartment from '~/components/SelectDepartment';
import request from '~/utils/request';
import { useSelector } from 'react-redux';
import { z } from 'zod';
import { State } from '~/store';
import toast from 'react-hot-toast';
import { StatusCodes } from 'http-status-codes';
import { HeadcountContext } from '~/pages/Headcount/context/HeadcountContext';
import { fetchSpecificPositions } from '~/pages/Headcount/utils/fetchSpecificPositions';
import { ZPositionDetails } from '~/pages/Headcount/entity/schemas';
import logger from '~/utils/logger';

const ZUpdateAttributeValueResponse = z.object({
  data: z.object({
    data: ZPositionDetails,
  }),
  status: z.number(),
});

const CellSelectDepartment = ({
  initialValue,
  cellContext,
}: {
  initialValue: IInitialValue;
  cellContext: CellContext<Record<string, IInitialValue>, IInitialValue>;
}): ReactElement => {
  const { positionActiveStateDict, positionDataDict, setPositionDataDict } = useContext(HeadcountContext);

  const { uuid: organizationUuid } = useSelector((state: State) => state.organization);
  const { activeScenarioUuid } = useSelector((state: State) => state.scenario);
  const [selectDepartment, setSelectDepartment] = useSelect({
    selected: {
      value: positionDataDict[initialValue.positionUuid].department.uuid,
      label: positionDataDict[initialValue.positionUuid].department.name,
    },
    options: [],
  });
  const [isActive, setIsActive] = useState(positionActiveStateDict[initialValue.positionUuid]);

  useEffect(() => {
    setIsActive(positionActiveStateDict[initialValue.positionUuid]);
  }, [positionActiveStateDict[initialValue.positionUuid]]);

  const onBlur = async (): Promise<void> => {
    if (
      selectDepartment.selected?.value &&
      selectDepartment.selected.value !== positionDataDict[initialValue.positionUuid].department.uuid
    ) {
      try {
        const updatedValueResponse = await request({
          method: 'PATCH',
          url: `/organizations/${organizationUuid}/positions/${initialValue.positionUuid}`,
          params: {
            scenarioUuid: activeScenarioUuid ?? undefined,
          },
          body: {
            [cellContext.column.id]: selectDepartment.selected.value,
          },
        });

        const parsedResponse = ZUpdateAttributeValueResponse.parse(updatedValueResponse);

        if (parsedResponse.status === StatusCodes.CREATED) {
          const [updatedPosition] = await fetchSpecificPositions({
            positionUuids: [initialValue.positionUuid],
            organizationUuid,
            scenarioUuid: activeScenarioUuid ?? undefined,
          });
          setPositionDataDict((prev) => ({
            ...prev,
            [updatedPosition.positionUuid]: {
              employeeName: updatedPosition.employeeName ?? '',
              title: updatedPosition.title,
              department: updatedPosition.currentDepartment,
              hireDate: updatedPosition.hireDate,
              termDate: updatedPosition.termDate,
              fullyBurdenedCost: updatedPosition.fullyBurdenedCost,
              employmentType: updatedPosition.employmentType,
            },
          }));
        } else {
          toast.error('Failed to update department');
          setSelectDepartment((prev) => ({
            ...prev,
            selected: {
              label: prev.selected?.label ?? '',
              value: initialValue.value?.toString() ?? '',
            },
          }));
        }
      } catch (error) {
        if (error instanceof Error) {
          logger.error(error);
        }
        toast.error('Failed to update department');
        setSelectDepartment((prev) => ({
          ...prev,
          selected: {
            label: prev.selected?.label ?? '',
            value: initialValue.value?.toString() ?? '',
          },
        }));
      }
    }
  };

  useEffect(() => {
    if (selectDepartment.selected?.value !== initialValue.value?.toString()) {
      onBlur();
    }
  }, [selectDepartment.selected]);

  const disabledStyles = isActive || !activeScenarioUuid ? '' : ' text-neutral-100 cursor-default';

  return (
    <div className="w-44 group" data-testid={`${cellContext.column.id}-${cellContext.row.index}`}>
      <div className={`text-nowrap overflow-hidden text-ellipsis ${disabledStyles} group-hover:hidden`}>
        {selectDepartment.selected?.label ?? '-'}
      </div>
      <div
        className={`hidden group-hover:block`}
        data-testid={`${cellContext.column.id}-${cellContext.row.index}-selector`}
      >
        <SelectDepartment
          departmentOptions={selectDepartment}
          setDepartmentOptions={setSelectDepartment}
          id={`department-${cellContext.row.index}`}
        />
      </div>
    </div>
  );
};

export default CellSelectDepartment;
