import React, { ReactElement, useEffect, useState } from 'react';
import { useInput } from '~/components/Input/InputWrapper';
import CurrencyInput from '~/components/Input/currencyInput/CurrencyInput';
import { PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline';
import Typography from '~/components/Typography';
import { CENTS_PER_DOLLAR } from '~/utils/constants/currency';
import * as stringDate from '~/utils/stringDate';
import Select, { useSelect } from '~/components/Select';
import PeriodPicker from '~/components/PeriodPicker';
import usePeriodPicker from '~/components/PeriodPicker/usePeriodPicker';

const MONTH_PATTERN_OPTIONS: Record<number, string> = {
  1: 'Jan, Apr, Jul, Oct',
  2: 'Feb, May, Aug, Nov',
  3: 'Mar, Jun, Sep, Dec',
};

const MONTH_PATTERN_OPTIONS_YEARLY: Record<number, string> = {
  1: 'January',
  2: 'February',
  3: 'March',
  4: 'April',
  5: 'May',
  6: 'June',
  7: 'July',
  8: 'August',
  9: 'September',
  10: 'October',
  11: 'November',
  12: 'December',
};

export const quarterlyEffectivePatterns = [
  { label: 'Jan, Apr, Jul, Oct', value: '1' },
  { label: 'Feb, May, Aug, Nov', value: '2' },
  { label: 'Mar, Jun, Sep, Dec', value: '3' },
];

export const monthOptions = [
  { label: 'January', value: '1' },
  { label: 'February', value: '2' },
  { label: 'March', value: '3' },
  { label: 'April', value: '4' },
  { label: 'May', value: '5' },
  { label: 'June', value: '6' },
  { label: 'July', value: '7' },
  { label: 'August', value: '8' },
  { label: 'September', value: '9' },
  { label: 'October', value: '10' },
  { label: 'November', value: '11' },
  { label: 'December', value: '12' },
];

const FREQUENCY_VALUE_MAP: Record<string, string> = {
  monthly: 'Monthly',
  quarterly: 'Quarterly',
  annually: 'Annually',
};

const AdditionalCompensation = ({
  hireDate,
  effectiveAt,
  currentValue,
  currentFrequency,
  currentTiming,
  updatedAdditionalCompensations,
  setUpdatedAdditionalCompensations,
  setAdditionalCompensationsRequireUpdate,
}: {
  hireDate: string;
  effectiveAt: 'onHire' | string;
  currentValue: number;
  currentFrequency: string;
  currentTiming: number | null;
  updatedAdditionalCompensations: {
    effectiveAt: 'onHire' | string;
    value: number;
    frequency: string;
    effectiveMonthPattern: number | null;
  }[];
  setUpdatedAdditionalCompensations: React.Dispatch<
    React.SetStateAction<
      {
        effectiveAt: 'onHire' | string;
        value: number;
        frequency: string;
        effectiveMonthPattern: number | null;
      }[]
    >
  >;
  additionalCompensationsRequireUpdate: boolean;
  setAdditionalCompensationsRequireUpdate: React.Dispatch<React.SetStateAction<boolean>>;
}): ReactElement => {
  const [additionalCompensation, setAdditionalCompensation] = useInput({
    // eslint-disable-next-line security/detect-unsafe-regex
    validation: /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
    errorMessage: 'Annual Pay is required',
    value: (currentValue / CENTS_PER_DOLLAR).toString(),
    disabled: true,
  });
  const [frequency, setFrequency] = useSelect({
    options: [
      { label: 'Monthly', value: 'monthly' },
      { label: 'Quarterly', value: 'quarterly' },
      { label: 'Annually', value: 'annually' },
    ],
    selected: { label: FREQUENCY_VALUE_MAP[currentFrequency], value: currentFrequency },
    disabled: true,
  });
  const currentEffectiveMonthPattern = currentFrequency === 'annually' ? monthOptions : quarterlyEffectivePatterns;
  let currentEffectiveMonthPatternValue;
  if (currentFrequency === 'annually' && currentTiming) {
    currentEffectiveMonthPatternValue = {
      label: MONTH_PATTERN_OPTIONS_YEARLY[currentTiming],
      value: `${currentTiming}`,
    };
  } else if (currentFrequency === 'quarterly' && currentTiming) {
    currentEffectiveMonthPatternValue = {
      label: MONTH_PATTERN_OPTIONS[currentTiming],
      value: `${currentTiming}`,
    };
  }
  const [effectiveMonthPattern, setEffectiveMonthPattern] = useSelect({
    options: currentEffectiveMonthPattern,
    selected: currentEffectiveMonthPatternValue,
    disabled: true,
  });
  const [effectiveDate, setEffectiveDate] = usePeriodPicker({
    startDate: effectiveAt === 'onHire' ? hireDate : effectiveAt,
    endDate: null,
    mode: 'month',
  });
  const [isEditing, setIsEditing] = useState(false);

  const formattedEffectiveAt = effectiveAt === 'onHire' ? hireDate : effectiveAt;

  useEffect(() => {
    if (Number(additionalCompensation.value) * CENTS_PER_DOLLAR !== currentValue && additionalCompensation.valid) {
      const newAdditionalCompensations = updatedAdditionalCompensations.map((existingAdditionalCompensation) => {
        if (existingAdditionalCompensation.effectiveAt === effectiveAt) {
          return {
            ...existingAdditionalCompensation,
            value: Number(additionalCompensation.value) * CENTS_PER_DOLLAR,
          };
        }
        return existingAdditionalCompensation;
      });
      setUpdatedAdditionalCompensations(newAdditionalCompensations);
      setAdditionalCompensationsRequireUpdate(true);
    }
  }, [additionalCompensation, setUpdatedAdditionalCompensations]);

  useEffect(() => {
    if (
      effectiveDate.startDate &&
      effectiveAt !== 'onHire' &&
      !stringDate.isSameMonth({ date1: effectiveDate.startDate, date2: effectiveAt })
    ) {
      const newAdditionalCompensations = updatedAdditionalCompensations.map((existingAdditionalCompensation) => {
        if (existingAdditionalCompensation.effectiveAt === effectiveAt) {
          return {
            ...existingAdditionalCompensation,
            updatedEffectiveDate: effectiveDate.startDate as string,
          };
        }
        return existingAdditionalCompensation;
      });
      setUpdatedAdditionalCompensations(newAdditionalCompensations);
      setAdditionalCompensationsRequireUpdate(true);
    }
  }, [effectiveDate, setUpdatedAdditionalCompensations]);

  useEffect(() => {
    if (frequency.selected?.value !== currentFrequency) {
      const newAdditionalCompensations = updatedAdditionalCompensations.map((existingAdditionalCompensation) => {
        if (existingAdditionalCompensation.effectiveAt === effectiveAt) {
          return {
            ...existingAdditionalCompensation,
            frequency: frequency.selected?.value ?? currentFrequency,
            effectiveMonthPattern:
              frequency.selected?.value === 'monthly' ? null : existingAdditionalCompensation.effectiveMonthPattern,
          };
        }
        return existingAdditionalCompensation;
      });
      setUpdatedAdditionalCompensations(newAdditionalCompensations);
      setAdditionalCompensationsRequireUpdate(true);
    }
  }, [frequency, setUpdatedAdditionalCompensations]);

  useEffect(() => {
    if (effectiveMonthPattern.selected?.value && effectiveMonthPattern.selected.value !== `${currentTiming}`) {
      const newAdditionalCompensations = updatedAdditionalCompensations.map((existingAdditionalCompensation) => {
        if (existingAdditionalCompensation.effectiveAt === effectiveAt) {
          return {
            ...existingAdditionalCompensation,
            effectiveMonthPattern: Number(effectiveMonthPattern.selected?.value ?? currentTiming),
          };
        }
        return existingAdditionalCompensation;
      });
      setUpdatedAdditionalCompensations(newAdditionalCompensations);
      setAdditionalCompensationsRequireUpdate(true);
    }
  }, [effectiveMonthPattern, setUpdatedAdditionalCompensations]);

  const handleDeleteAdditionalCompensation = async (): Promise<void> => {
    setUpdatedAdditionalCompensations((prev) =>
      prev.filter(
        (existingAdditionalCompensation) =>
          existingAdditionalCompensation.effectiveAt === 'onHire' ||
          !stringDate.isSameDate({ date1: existingAdditionalCompensation.effectiveAt, date2: effectiveAt }),
      ),
    );
    setAdditionalCompensationsRequireUpdate(true);
  };

  const handleEditClick = (): void => {
    setIsEditing(true);
    setAdditionalCompensation((prev) => ({ ...prev, disabled: false }));
    setFrequency((prev) => ({ ...prev, disabled: false }));
    setEffectiveMonthPattern((prev) => ({ ...prev, disabled: frequency.selected?.value === 'monthly' }));
  };

  useEffect(() => {
    if (frequency.selected?.value === 'monthly' && frequency.selected.value !== currentFrequency) {
      setEffectiveMonthPattern({
        ...effectiveMonthPattern,
        options: quarterlyEffectivePatterns,
        selected: undefined,
        disabled: true,
      });
    } else if (frequency.selected?.value === 'quarterly' && frequency.selected.value !== currentFrequency) {
      setEffectiveMonthPattern({
        ...effectiveMonthPattern,
        options: quarterlyEffectivePatterns,
        selected: {
          label: 'Jan, Apr, Jul, Oct',
          value: '1',
        },
        disabled: !isEditing,
      });
    } else if (frequency.selected?.value === 'annually' && frequency.selected.value !== currentFrequency) {
      setEffectiveMonthPattern({
        ...effectiveMonthPattern,
        options: monthOptions,
        selected: {
          label: 'January',
          value: '1',
        },
        disabled: !isEditing,
      });
    }
  }, [frequency.selected?.value]);

  return (
    <div className={`flex flex-row gap-2 items-start my-1 transition-all`}>
      <div className="w-[23%]">
        <CurrencyInput
          id={`additionalCompensation-${formattedEffectiveAt}`}
          state={additionalCompensation}
          setState={setAdditionalCompensation}
        />
      </div>
      <div className="w-[23%]">
        <Select id="frequency" state={frequency} setState={setFrequency} required />
      </div>
      <div className="w-[23%]">
        <Select id="effectiveMonthPattern" state={effectiveMonthPattern} setState={setEffectiveMonthPattern} required />
      </div>
      {effectiveAt === 'onHire' ? (
        <div className="w-[23%] py-2 flex justify-center items-center border border-gray-300 rounded bg-neutral-25">
          <Typography id={`pay-change-date-${formattedEffectiveAt}`} color="disabled">
            Hire Date
          </Typography>
        </div>
      ) : (
        <div className="w-[23%]">
          <PeriodPicker
            disabled={!isEditing}
            beAfter={hireDate}
            id="effectiveDate"
            state={effectiveDate}
            setState={setEffectiveDate}
            disabledStyle="bordered"
          />
        </div>
      )}
      {!isEditing && (
        <PencilSquareIcon
          onClick={handleEditClick}
          className="fixed right-6 mt-2.5 size-5 text-neutral-100 cursor-pointer hover:text-neutral-400"
        />
      )}
      {isEditing && effectiveAt !== 'onHire' && (
        <TrashIcon
          onClick={handleDeleteAdditionalCompensation}
          className="fixed right-6 mt-2.5 size-5 text-neutral-100 cursor-pointer hover:text-neutral-400"
          data-testid={`delete-additional-compensation-${formattedEffectiveAt}`}
        />
      )}
    </div>
  );
};

export default AdditionalCompensation;
