import { isBefore, startOfMonth } from 'date-fns';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import Typography from '~/components/Typography';
import { IFormulaType, IRoundingInstructions } from '~/components/Formulas/context/types';
import useFormulaContext from '~/components/Formulas/context/useFormulaContext';
import { IFormattingEnum, IRoundDirectionEnum } from '~/services/parallel/formulas.types';
import * as stringDate from '~/utils/stringDate';
import { useSelector } from 'react-redux';
import { State } from '~/store';
interface IValueState {
  type: 'actual' | 'override' | 'calculated';
  value: number | null;
  formatting: IFormattingEnum | null;
  roundingInstructions: IRoundingInstructions | null;
}

const roundNumber = (value: number, rounding: IRoundingInstructions | null): number => {
  const precision = rounding?.precision ?? 0.01;
  const multiplier = 1 / precision;

  switch (rounding?.direction) {
    case IRoundDirectionEnum.Up:
      return Math.ceil(value * multiplier) / multiplier;
    case IRoundDirectionEnum.Down:
      return Math.floor(value * multiplier) / multiplier;
    case IRoundDirectionEnum.Nearest:
    default:
      return Math.round(value * multiplier) / multiplier;
  }
};

const MonthCell = ({
  columnWidth,
  data,
  columnIndex,
}: {
  columnWidth: number;
  data: IFormulaType;
  columnIndex: number;
}): React.ReactElement => {
  const isPulling = useSelector((state: State) => state.integrations.isPulling);
  const { selectedMonths, updateFormulaMonthsValue, loadingFormulas, pendingTabTarget, setPendingTabTarget, viewOnly } =
    useFormulaContext();
  const isViewOnly = Boolean(viewOnly || isPulling);
  const [isFocused, setIsFocused] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const [pendingMonthData, setPendingMonthData] = useState<IValueState>();
  const [originalMonthData, setOriginalMonthData] = useState<IValueState>();

  const LEADING_COLUMN_COUNT = 2;
  const month = useMemo(() => selectedMonths[columnIndex - LEADING_COLUMN_COUNT], [selectedMonths, columnIndex]);

  useEffect(() => {
    if (month in data.monthlyValues) {
      const activeMonthData = data.monthlyValues[month];
      if (activeMonthData.actualValue !== null) {
        setOriginalMonthData({
          type: 'actual',
          value: Math.round(activeMonthData.actualValue * 10000) / 100,
          formatting: activeMonthData.formatting,
          roundingInstructions: activeMonthData.roundingInstructions,
        });
      } else if (activeMonthData.overrideValue !== null) {
        setOriginalMonthData({
          type: 'override',
          value: Math.round(activeMonthData.overrideValue * 10000) / 100,
          formatting: activeMonthData.formatting,
          roundingInstructions: activeMonthData.roundingInstructions,
        });
      } else {
        setOriginalMonthData({
          type: 'calculated',
          value: activeMonthData.calculatedValue,
          formatting: activeMonthData.formatting,
          roundingInstructions: activeMonthData.roundingInstructions,
        });
      }
    }
  }, [data.monthlyValues[month], month, columnIndex]);

  useEffect(() => {
    // When new "original" data is set, reset the editable value
    setPendingMonthData(originalMonthData);
  }, [originalMonthData]);

  useEffect(() => {
    if (isFocused) {
      // Set focus and select the input
      inputRef.current?.focus();
      inputRef.current?.select();
    }
  }, [isFocused]);

  const formattedLabelValue = useMemo(() => {
    if (originalMonthData === undefined) return null;

    const rounding = originalMonthData.roundingInstructions;
    if (originalMonthData.value === null) originalMonthData.value = 0;
    switch (originalMonthData.formatting) {
      case IFormattingEnum.Percent: {
        return `${originalMonthData.value}%`;
      }
      case IFormattingEnum.Currency: {
        const val = originalMonthData.value / 100;
        const roundedVal = pendingMonthData?.type === 'calculated' ? roundNumber(val, rounding) : val;
        const precision = rounding?.precision ?? 0.01;
        const decimalPlaces = Math.max(0, -Math.floor(Math.log10(precision)));
        return roundedVal.toLocaleString('en-US', {
          maximumFractionDigits: decimalPlaces,
          style: 'currency',
          currency: 'USD',
        });
      }
      default: {
        const val = originalMonthData.value / 100;
        const roundedVal = pendingMonthData?.type === 'calculated' ? roundNumber(val, rounding) : val;
        const precision = rounding?.precision ?? 0.01;
        const decimalPlaces = Math.max(0, -Math.floor(Math.log10(precision)));
        return roundedVal.toLocaleString('en-US', {
          maximumFractionDigits: decimalPlaces,
        });
      }
    }
  }, [originalMonthData, pendingMonthData]);

  const styles = useMemo((): {
    className: string;
    color: 'orange' | 'actualBlue' | 'empty' | 'primary';
  } => {
    if (!originalMonthData) return { className: '', color: 'primary' };

    if (originalMonthData.type === 'actual' && !isViewOnly) {
      // Actual
      return { className: 'text-orange', color: 'orange' };
    } else if (originalMonthData.type === 'override' && !isViewOnly) {
      // Override, past or future
      return { className: 'text-[#3C98C0]', color: 'actualBlue' };
    } else if (isBefore(startOfMonth(month), startOfMonth(new Date()))) {
      // Formula driven, past
      return { className: 'text-neutral-200', color: 'empty' };
    }

    // Default: Formula driven, future
    return { className: 'text-neutral-800', color: 'primary' };
  }, [originalMonthData]);

  const handleTab = useCallback(
    (e: React.KeyboardEvent) => {
      e.preventDefault();
      // Blur the input to trigger any blur handlers
      (e.target as HTMLInputElement).blur();

      // Set the pending tab target
      const nextColumnIndex = columnIndex + 1;
      setPendingTabTarget({
        columnIndex: nextColumnIndex,
        formulaUuid: data.formulaUuid,
      });
    },
    [columnIndex, data.formulaUuid],
  );

  const saveChanges = (monthValues: (number | null)[]): void => {
    setIsFocused(false);

    const isSingleFieldEdit = monthValues.length === 1;
    if (isSingleFieldEdit) {
      if (monthValues[0] === originalMonthData?.value) {
        // User didn't change the value, no changes
        return;
      }
      if (monthValues[0] === null) {
        // User cleared the value, reset to original
        setPendingMonthData(originalMonthData);
      }
    }

    const startIndex = columnIndex - LEADING_COLUMN_COUNT;
    const endIndex = startIndex + monthValues.length;
    const months = selectedMonths.slice(startIndex, endIndex);
    updateFormulaMonthsValue({
      formulaUuid: data.formulaUuid,
      months: months.map((month) => stringDate.MMMyyyyToStringDate(month)),
      values: monthValues.map((val) => (val === null ? null : val / 100)),
      // TODO - get the end of the screen date to optimize request.
      lastNeededDate: '2025-01-01',
    });
  };

  const isLoading = useMemo(
    () =>
      loadingFormulas.some(
        (loadingFormula) =>
          loadingFormula.months.includes(month) && loadingFormula.formulaUuids.includes(data.formulaUuid),
      ),
    [loadingFormulas, month],
  );

  const showYearDivider = month.includes('Dec');

  // When the pending tab target is set to this month, focus the cell. This is triggered when the user tabs from a previous cell.
  useEffect(() => {
    if (
      !isLoading &&
      pendingTabTarget &&
      pendingTabTarget.columnIndex === columnIndex &&
      pendingTabTarget.formulaUuid === data.formulaUuid
    ) {
      setIsFocused(true);
      setPendingTabTarget(null);
    }
  }, [isLoading, pendingTabTarget, columnIndex, data.formulaUuid]);

  const handlePaste = (e: React.ClipboardEvent): void => {
    e.preventDefault();

    const pastedText = e.clipboardData.getData('text');

    // Split pasted text by tabs or newlines to handle spreadsheet data
    const values = pastedText
      .split(/[\t\n]/)
      .map((val) => val.trim())
      .filter((val) => val !== '')
      .map((val) => Number(val.replace(/[^0-9.-]/g, '')))
      .filter((val) => !isNaN(val))
      .map((val) => val * 100);

    if (values.length > 0) {
      // Handle first value in current cell
      setPendingMonthData((prev) => ({
        ...prev!,
        value: values[0],
      }));

      saveChanges(values);
    }
  };

  if (!originalMonthData || !pendingMonthData) return <div />;

  return (
    <div
      className={`${isPulling ? 'bg-neutral-25' : 'bg-white'} h-[48px] text-nowrap overflow-hidden relative flex items-center justify-end${showYearDivider ? ' border-r border-neutral-50' : ''}${isFocused ? ' py-[2px] px-[2px]' : ''}`}
      style={{ minWidth: `${columnWidth}px`, maxWidth: `${columnWidth}px` }}
      data-testid={
        isLoading ? `month-cell-${data.label.name}-${month}-loading` : `month-cell-${data.label.name}-${month}`
      }
    >
      {!isFocused && (
        <div
          className={`flex items-center justify-end overflow-hidden w-full h-full border ${isPulling ? 'border-neutral-25' : 'border-white'} p-3 rounded ${
            !isLoading
              ? `${!isViewOnly ? 'cursor-text hover:border-neutral-100' : 'cursor-default'}`
              : 'relative before:absolute before:inset-0 before:bg-neutral-25 after:absolute after:inset-0 after:bg-shimmer after:bg-[length:1000px_100%] after:animate-shimmer cursor-default'
          }`}
          onClick={() => {
            if (!isLoading && !isViewOnly) {
              setIsFocused(true);
            }
          }}
          data-testid={`month-cell-${data.label.name}-${month}-container`}
        >
          <Typography
            className={`tracking-normal text-sm font-normal leading-6 overflow-hidden ${isLoading ? 'opacity-50' : ''}`}
            color={styles.color}
          >
            {formattedLabelValue}
          </Typography>
        </div>
      )}
      {isFocused && !isViewOnly && (
        <NumericFormat
          getInputRef={inputRef}
          data-testid={`month-cell-${data.label.name}-${month}-input`}
          className={`${styles.className} rounded border-2 border-gray-300 hover:border-neutral-100 !shadow-none text-right px-[0.57rem] h-[46px] w-full disabled:bg-neutral-25 disabled:text-neutral-75 focus:border-green-15 focus:outline-none`}
          allowLeadingZeros
          allowNegative={originalMonthData.formatting !== IFormattingEnum.Percent}
          decimalScale={3}
          value={
            pendingMonthData.formatting !== IFormattingEnum.Percent && pendingMonthData.value !== null
              ? pendingMonthData.value / 100
              : pendingMonthData.value
          }
          onValueChange={(values) => {
            const newValue =
              pendingMonthData.formatting !== IFormattingEnum.Percent && values.floatValue !== undefined
                ? values.floatValue * 100
                : values.floatValue;
            setPendingMonthData({ ...pendingMonthData, value: newValue !== undefined ? newValue : null });
          }}
          onBlur={() => saveChanges([pendingMonthData.value])}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              saveChanges([pendingMonthData.value !== null ? pendingMonthData.value : null]);
            } else if (e.key === 'Escape') {
              setIsFocused(false);
              setPendingMonthData(originalMonthData);
            } else if (e.key === 'Tab') {
              if (e.shiftKey) {
                // Handle shift+tab to go backwards
                e.preventDefault();
                setPendingTabTarget({
                  columnIndex: columnIndex - 1,
                  formulaUuid: data.formulaUuid,
                });
              } else {
                handleTab(e);
              }
            }
          }}
          prefix={pendingMonthData.formatting === IFormattingEnum.Currency ? '$' : undefined}
          suffix={pendingMonthData.formatting === IFormattingEnum.Percent ? '%' : undefined}
          onPaste={handlePaste}
        />
      )}
    </div>
  );
};

export default MonthCell;
