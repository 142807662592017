import React, { useState } from 'react';
import Modal from '~/components/Modal';
import PositionForm from './PositionForm';
import { createPosition } from '../utils/createPosition';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '~/store';
import { updateScenarioLoadingState } from '~/store/scenarioSlice';
import { IPositionFormState } from './usePositionFormState';

interface Props {
  isOpen: boolean;
  setModal: (isOpen: boolean) => void;
  modalSource?: string;
  positionFormState: IPositionFormState;
  reload: () => void;
  awaitCalculations?: boolean;
  setHasReloaded?: React.Dispatch<React.SetStateAction<boolean>>;
  createScenario?: boolean;
}

const CreatePosition = ({
  isOpen,
  setModal,
  positionFormState: {
    errorMessage,
    hireDate,
    setHireDate,
    title,
    setTitle,
    payRate,
    setPayRate,
    selectDepartment,
    setSelectDepartment,
    resetFormState,
    organizationUuid,
    numberToCreate,
    setNumberToCreate,
    isChecked,
    setIsChecked,
    selectEmploymentType,
    setSelectEmploymentType,
    commission,
    setCommission,
    commissionFrequency,
    setCommissionFrequency,
    commissionEffectiveMonthPattern,
    setCommissionEffectiveMonthPattern,
    bonus,
    setBonus,
    bonusFrequency,
    setBonusFrequency,
    bonusEffectiveMonthPattern,
    setBonusEffectiveMonthPattern,
  },
  reload,
  awaitCalculations,
  setHasReloaded,
  createScenario,
}: Props): React.ReactNode => {
  const dispatch = useDispatch();
  const activeScenarioUuid = useSelector((state: State) => state.scenario.activeScenarioUuid);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [addCommission, setAddCommission] = useState(false);
  const [addBonus, setAddBonus] = useState(false);

  return (
    <Modal isOpen={isOpen} title="Create Position" size="sm">
      <div data-testid="create-position-modal" className="mt-2 w-full">
        <PositionForm
          isChecked={isChecked}
          setIsChecked={setIsChecked}
          onClose={() => {
            setModal(false);
            setTimeout(() => {
              resetFormState();
            }, 200);
            setAddCommission(false);
            setAddBonus(false);
          }}
          hireDate={hireDate}
          setHireDate={setHireDate}
          title={title}
          setTitle={setTitle}
          payRate={payRate}
          setPayRate={setPayRate}
          selectDepartment={selectDepartment}
          setSelectDepartment={setSelectDepartment}
          errorMessage={errorMessage}
          isSubmitting={isSubmitting}
          numberToCreate={numberToCreate}
          setNumberToCreate={setNumberToCreate}
          selectEmploymentType={selectEmploymentType}
          setSelectEmploymentType={setSelectEmploymentType}
          commission={commission}
          setCommission={setCommission}
          commissionFrequency={commissionFrequency}
          setCommissionFrequency={setCommissionFrequency}
          commissionEffectiveMonthPattern={commissionEffectiveMonthPattern}
          setCommissionEffectiveMonthPattern={setCommissionEffectiveMonthPattern}
          bonus={bonus}
          setBonus={setBonus}
          bonusFrequency={bonusFrequency}
          setBonusFrequency={setBonusFrequency}
          bonusEffectiveMonthPattern={bonusEffectiveMonthPattern}
          setBonusEffectiveMonthPattern={setBonusEffectiveMonthPattern}
          addCommission={addCommission}
          setAddCommission={setAddCommission}
          addBonus={addBonus}
          setAddBonus={setAddBonus}
          createPosition={async () => {
            createPosition({
              organizationUuid,
              title,
              setTitle,
              payRate,
              setPayRate,
              hireDate,
              setHireDate,
              selectDepartment,
              setSelectDepartment,
              successCallback: async () => {
                setModal(false);
                resetFormState();
                setIsSubmitting(false);
                setAddCommission(false);
                setAddBonus(false);
                await reload();
                if (setHasReloaded) {
                  setHasReloaded(true);
                }
                if (createScenario) {
                  dispatch(updateScenarioLoadingState('idle'));
                }
              },
              scenarioUuid: activeScenarioUuid ?? undefined,
              setIsSubmitting,
              numberToCreate,
              setNumberToCreate,
              awaitCalculations,
              createScenario,
              employmentType: selectEmploymentType,
              commission,
              commissionFrequency,
              commissionEffectiveMonthPattern,
              bonus,
              bonusFrequency,
              bonusEffectiveMonthPattern,
              addCommission,
              addBonus,
            });
          }}
        />
      </div>
    </Modal>
  );
};

export default CreatePosition;
