import { handleArrowPresses } from './handleArrowPresses';
import { handleDeleteAndBackspace } from './handleDeleteAndBackspace';
import { handleSelectAttribute } from './handleSelectAttribute';
import { handleAddOperator } from './handleAddOperator';
import { handleRemoveSegmentToDelete } from './handleRemoveSegmentToDelete';
import { IFormula } from '~/services/parallel/formulas.types';
import {
  IFormulaSegment,
  IUpdateCalculationModifier,
  IUpdateTimeModifier,
  IVariables,
} from '~/pages/FinancialModelDeprecated/entity/types';

export const handleKeyDown = ({
  event,
  position,
  value,
  filteredFormulaList,
  highlightedFormula,
  setHighlightedFormula,
  inputPosition,
  setInputPosition,
  updatedFormula,
  setUpdatedFormula,
  setFormula,
  variables,
  setVariables,
  setValue,
  setEnteredConstantFrom,
  segmentToDelete,
  setSegmentToDelete,
  handleUpdateCalculationModifier,
  handleUpdateTimeModifier,
  formulaTitle,
  editingFormulaUuid,
  inputRef,
}: {
  event: React.KeyboardEvent<HTMLDivElement>;
  position?: 'beginning' | 'end';
  value: string;
  filteredFormulaList: IFormula[];
  highlightedFormula: {
    formula?: IFormula;
    index: number;
  };
  setHighlightedFormula: React.Dispatch<React.SetStateAction<{ formula?: IFormula; index: number }>>;
  segmentToDelete?: {
    segmentRef: React.RefObject<HTMLDivElement>;
    segmentIndex: number;
    segmentValue: string;
  };
  setSegmentToDelete: React.Dispatch<
    React.SetStateAction<
      | {
          segmentRef: React.RefObject<HTMLDivElement>;
          segmentIndex: number;
          segmentValue: string;
        }
      | undefined
    >
  >;
  inputPosition: number;
  setInputPosition: React.Dispatch<React.SetStateAction<number>>;
  updatedFormula: IFormulaSegment[];
  setUpdatedFormula: React.Dispatch<React.SetStateAction<IFormulaSegment[]>>;
  setFormula: React.Dispatch<React.SetStateAction<IFormulaSegment[]>>;
  variables: IVariables;
  setVariables: React.Dispatch<React.SetStateAction<IVariables>>;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  setEnteredConstantFrom: React.Dispatch<React.SetStateAction<'left' | 'right' | undefined>>;
  handleUpdateCalculationModifier?: ({
    calculationModifier,
    formulaForUpdate,
    formulaTextValue,
    refToUpdate,
  }: IUpdateCalculationModifier) => void;
  handleUpdateTimeModifier: ({
    timeModifier,
    formulaForUpdate,
    formulaTextValue,
    refToUpdate,
  }: IUpdateTimeModifier) => void;
  formulaTitle?: string;
  editingFormulaUuid?: string;
  inputRef: React.RefObject<HTMLDivElement>;
}): void => {
  const removeSegmentToDelete = (): void => {
    handleRemoveSegmentToDelete({
      segmentToDelete,
      setSegmentToDelete,
      updatedFormula,
      inputRef,
    });
  };

  event.stopPropagation();
  if (event.key === 'ArrowUp') {
    event.preventDefault();
    handleArrowPresses.upArrow({
      filteredFormulaList,
      setHighlightedFormula,
      setInputPosition,
      value,
    });
    removeSegmentToDelete();
  } else if (event.key === 'ArrowDown') {
    event.preventDefault();
    handleArrowPresses.downArrow({
      filteredFormulaList,
      setHighlightedFormula,
      setInputPosition,
      endPosition: updatedFormula.length,
      value,
    });
    removeSegmentToDelete();
  } else if (event.key === 'ArrowLeft' && (!value.length || position === 'beginning')) {
    event.preventDefault();
    handleArrowPresses.leftArrow({
      position,
      segmentToDelete,
      inputPosition,
      setInputPosition,
      updatedFormula,
      setUpdatedFormula,
      setFormula,
      variables,
      setVariables,
      value,
      setValue,
      setEnteredConstantFrom,
      inputRef,
    });
    removeSegmentToDelete();
  } else if (event.key === 'ArrowRight' && (!value.length || position === 'end')) {
    event.preventDefault();
    handleArrowPresses.rightArrow({
      position,
      segmentToDelete,
      inputPosition,
      setInputPosition,
      updatedFormula,
      setUpdatedFormula,
      setFormula,
      variables,
      setVariables,
      value,
      setValue,
      setEnteredConstantFrom,
      inputRef,
    });
    removeSegmentToDelete();
  } else if (
    (event.key === 'Delete' || event.key === 'Backspace') &&
    (!value.length ||
      (position === 'beginning' && event.key === 'Backspace') ||
      (position === 'end' && event.key === 'Delete'))
  ) {
    event.preventDefault();
    handleDeleteAndBackspace({
      event,
      position,
      segmentToDelete,
      setSegmentToDelete,
      inputPosition,
      setInputPosition,
      updatedFormula,
      setUpdatedFormula,
      setFormula,
      variables,
      setVariables,
      value,
      setValue,
      setEnteredConstantFrom,
      inputRef,
    });
  } else if (event.key === 'Enter') {
    event.preventDefault();
    if (highlightedFormula.formula && value.length) {
      handleSelectAttribute({
        selectedFormula: highlightedFormula.formula,
        formulaTitle,
        updatedFormula,
        setUpdatedFormula,
        setFormula,
        variables,
        setVariables,
        editingFormulaUuid,
        handleUpdateCalculationModifier,
        handleUpdateTimeModifier,
        inputPosition,
        setInputPosition,
        setValue,
        inputRef,
      });
    } else if (value.length) {
      handleAddOperator({
        operator: null,
        value,
        setValue,
        inputPosition,
        setInputPosition,
        updatedFormula,
        setUpdatedFormula,
        setFormula,
        variables,
        setVariables,
        inputRef,
      });
    }
  } else {
    removeSegmentToDelete();
  }
};
