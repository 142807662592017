import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { State } from '~/store';
import useInput from '~/components/Input/useInput';
import { useDatePicker } from '~/components/DatePicker';
import { IDatePickerState } from '~/components/DatePicker/useDatePicker';
import { useSelect } from '~/components/Select';
import { SelectState } from '~/components/Select/Select.types';
import { IEmploymentType } from '../entity/types';

export interface IInlineCreationFormState {
  errorMessage: string;
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
  organizationUuid: string;
  inlineCreate: boolean;
  setInlineCreate: React.Dispatch<React.SetStateAction<boolean>>;
  inlineCreateTitle: Types.InputState;
  setInlineCreateTitle: React.Dispatch<React.SetStateAction<Types.InputState>>;
  inlineCreateEmployeeName: Types.InputState;
  setInlineCreateEmployeeName: React.Dispatch<React.SetStateAction<Types.InputState>>;
  inlineCreateSelectDepartment: SelectState;
  setInlineCreateSelectDepartment: React.Dispatch<React.SetStateAction<SelectState>>;
  inlineCreateDate: IDatePickerState;
  setInlineCreateDate: React.Dispatch<React.SetStateAction<IDatePickerState>>;
  inlineCreatePayRate: Types.InputState;
  setInlineCreatePayRate: React.Dispatch<React.SetStateAction<Types.InputState>>;
  inlineCreateEmploymentType: IEmploymentType;
  setInlineCreateEmploymentType: React.Dispatch<React.SetStateAction<IEmploymentType>>;
  addInlineAdditionalCompensation: boolean;
  setAddInlineAdditionalCompensation: React.Dispatch<React.SetStateAction<boolean>>;
  addInlineCreateCommission: boolean;
  setAddInlineCreateCommission: React.Dispatch<React.SetStateAction<boolean>>;
  inlineCreateCommission: Types.InputState;
  setInlineCreateCommission: React.Dispatch<React.SetStateAction<Types.InputState>>;
  inlineCreateCommissionFrequency: SelectState;
  setInlineCreateCommissionFrequency: React.Dispatch<React.SetStateAction<SelectState>>;
  inlineCreateCommissionEffectiveMonth: SelectState;
  setInlineCreateCommissionEffectiveMonth: React.Dispatch<React.SetStateAction<SelectState>>;
  addInlineCreateBonus: boolean;
  setAddInlineCreateBonus: React.Dispatch<React.SetStateAction<boolean>>;
  inlineCreateBonus: Types.InputState;
  setInlineCreateBonus: React.Dispatch<React.SetStateAction<Types.InputState>>;
  inlineCreateBonusFrequency: SelectState;
  setInlineCreateBonusFrequency: React.Dispatch<React.SetStateAction<SelectState>>;
  inlineCreateBonusEffectiveMonth: SelectState;
  setInlineCreateBonusEffectiveMonth: React.Dispatch<React.SetStateAction<SelectState>>;
  resetInlineBonusAndCommission: () => void;
  clearInlineCreateForm: () => void;
  recommendedPayRateLoading: boolean;
  setRecommendedPayRateLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const useInlineCreationFormState = (): IInlineCreationFormState => {
  const [errorMessage, setErrorMessage] = useState('');
  const { uuid: organizationUuid } = useSelector((state: State) => state.organization);
  const [inlineCreate, setInlineCreate] = useState(false);
  const [inlineCreateTitle, setInlineCreateTitle] = useInput({
    value: '',
    errorMessage: '',
  });
  const [inlineCreateEmployeeName, setInlineCreateEmployeeName] = useInput({
    value: '',
    errorMessage: '',
    validation: /^.*$/,
  });
  const [inlineCreateSelectDepartment, setInlineCreateSelectDepartment] = useSelect({
    options: [],
  });
  const [inlineCreateDate, setInlineCreateDate] = useDatePicker({
    errorMessage: '',
  });
  const [inlineCreatePayRate, setInlineCreatePayRate] = useInput({
    // eslint-disable-next-line security/detect-unsafe-regex
    validation: /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
    errorMessage: '',
  });
  const [inlineCreateEmploymentType, setInlineCreateEmploymentType] = useState<IEmploymentType>(
    IEmploymentType.FullTime,
  );

  const [addInlineAdditionalCompensation, setAddInlineAdditionalCompensation] = useState<boolean>(false);

  const [addInlineCreateCommission, setAddInlineCreateCommission] = useState<boolean>(false);
  const [inlineCreateCommission, setInlineCreateCommission] = useInput({
    validation: /^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
  });
  const [inlineCreateCommissionFrequency, setInlineCreateCommissionFrequency] = useSelect({
    options: [
      { label: 'Monthly', value: 'monthly' },
      { label: 'Quarterly', value: 'quarterly' },
      { label: 'Annually', value: 'annually' },
    ],
    selected: { label: 'Monthly', value: 'monthly' },
  });
  const [inlineCreateCommissionEffectiveMonth, setInlineCreateCommissionEffectiveMonth] = useSelect({
    options: [
      { label: 'Jan, Apr, Jul, Oct', value: '1' },
      { label: 'Feb, May, Aug, Nov', value: '2' },
      { label: 'Mar, Jun, Sep, Dec', value: '3' },
    ],
    selected: { label: 'Jan, Apr, Jul, Oct', value: '1' },
  });

  const [addInlineCreateBonus, setAddInlineCreateBonus] = useState<boolean>(false);
  const [inlineCreateBonus, setInlineCreateBonus] = useInput({
    validation: /^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
  });
  const [inlineCreateBonusFrequency, setInlineCreateBonusFrequency] = useSelect({
    options: [
      { label: 'Monthly', value: 'monthly' },
      { label: 'Quarterly', value: 'quarterly' },
      { label: 'Annually', value: 'annually' },
    ],
    selected: { label: 'Monthly', value: 'monthly' },
  });
  const [inlineCreateBonusEffectiveMonth, setInlineCreateBonusEffectiveMonth] = useSelect({
    options: [
      { label: 'Jan, Apr, Jul, Oct', value: '1' },
      { label: 'Feb, May, Aug, Nov', value: '2' },
      { label: 'Mar, Jun, Sep, Dec', value: '3' },
    ],
    selected: { label: 'Jan, Apr, Jul, Oct', value: '1' },
  });
  const [recommendedPayRateLoading, setRecommendedPayRateLoading] = useState(false);
  useEffect(() => {
    if (inlineCreateCommissionFrequency.selected?.value === 'monthly') {
      setInlineCreateCommissionEffectiveMonth({
        ...inlineCreateCommissionEffectiveMonth,
        options: [
          { label: 'Jan, Apr, Jul, Oct', value: '1' },
          { label: 'Feb, May, Aug, Nov', value: '2' },
          { label: 'Mar, Jun, Sep, Dec', value: '3' },
        ],
        selected: {
          label: 'Jan, Apr, Jul, Oct',
          value: '1',
        },
      });
    } else if (inlineCreateCommissionFrequency.selected?.value === 'quarterly') {
      setInlineCreateCommissionEffectiveMonth({
        ...inlineCreateCommissionEffectiveMonth,
        options: [
          { label: 'Jan, Apr, Jul, Oct', value: '1' },
          { label: 'Feb, May, Aug, Nov', value: '2' },
          { label: 'Mar, Jun, Sep, Dec', value: '3' },
        ],
        selected: {
          label: 'Jan, Apr, Jul, Oct',
          value: '1',
        },
      });
    } else if (inlineCreateCommissionFrequency.selected?.value === 'annually') {
      setInlineCreateCommissionEffectiveMonth({
        ...inlineCreateCommissionEffectiveMonth,
        options: [
          { label: 'January', value: '1' },
          { label: 'February', value: '2' },
          { label: 'March', value: '3' },
          { label: 'April', value: '4' },
          { label: 'May', value: '5' },
          { label: 'June', value: '6' },
          { label: 'July', value: '7' },
          { label: 'August', value: '8' },
          { label: 'September', value: '9' },
          { label: 'October', value: '10' },
          { label: 'November', value: '11' },
          { label: 'December', value: '12' },
        ],
        selected: {
          label: 'January',
          value: '1',
        },
      });
    }
  }, [inlineCreateCommissionFrequency.selected?.value]);

  useEffect(() => {
    if (inlineCreateBonusFrequency.selected?.value === 'monthly') {
      setInlineCreateBonusEffectiveMonth({
        ...inlineCreateBonusEffectiveMonth,
        options: [
          { label: 'Jan, Apr, Jul, Oct', value: '1' },
          { label: 'Feb, May, Aug, Nov', value: '2' },
          { label: 'Mar, Jun, Sep, Dec', value: '3' },
        ],
        selected: {
          label: 'Jan, Apr, Jul, Oct',
          value: '1',
        },
      });
    } else if (inlineCreateBonusFrequency.selected?.value === 'quarterly') {
      setInlineCreateBonusEffectiveMonth({
        ...inlineCreateBonusEffectiveMonth,
        options: [
          { label: 'Jan, Apr, Jul, Oct', value: '1' },
          { label: 'Feb, May, Aug, Nov', value: '2' },
          { label: 'Mar, Jun, Sep, Dec', value: '3' },
        ],
        selected: {
          label: 'Jan, Apr, Jul, Oct',
          value: '1',
        },
      });
    } else if (inlineCreateBonusFrequency.selected?.value === 'annually') {
      setInlineCreateBonusEffectiveMonth({
        ...inlineCreateBonusEffectiveMonth,
        options: [
          { label: 'January', value: '1' },
          { label: 'February', value: '2' },
          { label: 'March', value: '3' },
          { label: 'April', value: '4' },
          { label: 'May', value: '5' },
          { label: 'June', value: '6' },
          { label: 'July', value: '7' },
          { label: 'August', value: '8' },
          { label: 'September', value: '9' },
          { label: 'October', value: '10' },
          { label: 'November', value: '11' },
          { label: 'December', value: '12' },
        ],
        selected: {
          label: 'January',
          value: '1',
        },
      });
    }
  }, [inlineCreateBonusFrequency.selected?.value]);

  const resetInlineBonusAndCommission = (): void => {
    setAddInlineCreateCommission(false);
    setInlineCreateCommission((prev) => ({ ...prev, value: '', touched: false, pristine: true, valid: false }));
    setInlineCreateCommissionFrequency((prev) => ({
      ...prev,
      options: [
        { label: 'Monthly', value: 'monthly' },
        { label: 'Quarterly', value: 'quarterly' },
        { label: 'Annually', value: 'annually' },
      ],
      selected: { value: 'monthly', label: 'Monthly' },
      touched: false,
      pristine: true,
      valid: false,
    }));
    setInlineCreateCommissionEffectiveMonth((prev) => ({
      ...prev,
      options: [
        { label: 'Jan, Apr, Jul, Oct', value: '1' },
        { label: 'Feb, May, Aug, Nov', value: '2' },
        { label: 'Mar, Jun, Sep, Dec', value: '3' },
      ],
      selected: { value: '1', label: 'Jan, Apr, Jul, Oct' },
      touched: false,
      pristine: true,
      valid: false,
    }));
    setAddInlineCreateBonus(false);
    setInlineCreateBonus((prev) => ({ ...prev, value: '', touched: false, pristine: true, valid: false }));
    setInlineCreateBonusFrequency((prev) => ({
      ...prev,
      options: [
        { label: 'Monthly', value: 'monthly' },
        { label: 'Quarterly', value: 'quarterly' },
        { label: 'Annually', value: 'annually' },
      ],
      selected: { value: 'monthly', label: 'Monthly' },
      touched: false,
      pristine: true,
      valid: false,
    }));
    setInlineCreateBonusEffectiveMonth((prev) => ({
      ...prev,
      options: [
        { label: 'Jan, Apr, Jul, Oct', value: '1' },
        { label: 'Feb, May, Aug, Nov', value: '2' },
        { label: 'Mar, Jun, Sep, Dec', value: '3' },
      ],
      selected: { value: '1', label: 'Jan, Apr, Jul, Oct' },
      touched: false,
      pristine: true,
      valid: false,
    }));
  };

  const clearInlineCreateForm = (): void => {
    setInlineCreateTitle((prev) => ({ ...prev, value: '', touched: false, pristine: true, valid: false }));
    setInlineCreateEmployeeName((prev) => ({ ...prev, value: '', touched: false, pristine: true }));
    setInlineCreateSelectDepartment((prev) => ({
      ...prev,
      selected: { value: null, label: null },
      touched: false,
      pristine: true,
      valid: false,
    }));
    setInlineCreateDate((prev) => ({
      ...prev,
      valid: false,
      value: { startDate: null, endDate: null },
      touched: false,
      pristine: true,
    }));
    setInlineCreatePayRate((prev) => ({ ...prev, value: '', touched: false, pristine: true, valid: false }));
    setInlineCreate(false);
    setInlineCreateEmploymentType(IEmploymentType.FullTime);
    resetInlineBonusAndCommission();
  };

  return {
    errorMessage,
    setErrorMessage,
    organizationUuid,
    inlineCreate,
    setInlineCreate,
    inlineCreateTitle,
    setInlineCreateTitle,
    inlineCreateEmployeeName,
    setInlineCreateEmployeeName,
    inlineCreateSelectDepartment,
    setInlineCreateSelectDepartment,
    inlineCreateDate,
    setInlineCreateDate,
    inlineCreatePayRate,
    setInlineCreatePayRate,
    inlineCreateEmploymentType,
    setInlineCreateEmploymentType,
    addInlineAdditionalCompensation,
    setAddInlineAdditionalCompensation,
    addInlineCreateCommission,
    setAddInlineCreateCommission,
    inlineCreateCommission,
    setInlineCreateCommission,
    inlineCreateCommissionFrequency,
    setInlineCreateCommissionFrequency,
    inlineCreateCommissionEffectiveMonth,
    setInlineCreateCommissionEffectiveMonth,
    addInlineCreateBonus,
    setAddInlineCreateBonus,
    inlineCreateBonus,
    setInlineCreateBonus,
    inlineCreateBonusFrequency,
    setInlineCreateBonusFrequency,
    inlineCreateBonusEffectiveMonth,
    setInlineCreateBonusEffectiveMonth,
    resetInlineBonusAndCommission,
    clearInlineCreateForm,
    recommendedPayRateLoading,
    setRecommendedPayRateLoading,
  };
};

export default useInlineCreationFormState;
