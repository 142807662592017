import React, { useContext, useEffect, useState } from 'react';
import LineGraph from '~/components/LineGraph';
import HeadcountGraphTooltip from './HeadcountGraphTooltip';
import { createHeadcountGraphData } from '../utils/createHeadcountGraphData';
import { useSelector } from 'react-redux';
import { State } from '~/store';
import { HeadcountContext } from '../context/HeadcountContext';
import { IDataArrayDictionary, ILineProps } from '~/components/LineGraph/entity/types';
import Typography from '~/components/Typography';
import useQueryParams from '~/utils/hooks/useQueryParams';
import * as stringDate from '~/utils/stringDate';

const HeadcountGraphs = (): React.ReactElement => {
  const { defaultGraphStartDate, defaultGraphEndDate } = useSelector((state: State) => state.user.preferences);
  const organizationUuid = useSelector((state: State) => state.organization.uuid);

  const departments = useSelector((state: State) => state.organization.departments);
  const { positions, search } = useContext(HeadcountContext);
  const [totalData, setTotalData] = useState<IDataArrayDictionary[]>([]);
  const [departmentData, setDepartmentData] = useState<IDataArrayDictionary[]>([]);
  const [departmentLines, setDepartmentLines] = useState<ILineProps[]>([]);
  const totalLines = [{ dataKey: 'total', stroke: '#64755C' }];
  const [queryParams] = useQueryParams();
  const selectDepartments = queryParams.get('departments');

  useEffect(() => {
    const startDate = defaultGraphStartDate ? defaultGraphStartDate : stringDate.getStringDate();
    const endDate = defaultGraphEndDate ? defaultGraphEndDate : stringDate.getStringDate();
    const getGraphData = async (): Promise<void> => {
      if (positions) {
        const graphData = createHeadcountGraphData({
          startDate,
          endDate,
          departments,
          search: search.value,
          positions,
          departmentFilter: selectDepartments ?? 'all',
        });
        setDepartmentLines(graphData.departmentLines);
        setTotalData(graphData.total.data);
        setDepartmentData(graphData.departments.data);
      }
    };

    getGraphData();
  }, [
    organizationUuid,
    positions,
    defaultGraphStartDate,
    defaultGraphEndDate,
    selectDepartments,
    departments,
    search.value,
  ]);

  const departmentTitle = (
    <div className="flex items-center justify-start gap-3">
      <Typography className="mr-2">👤 Headcount</Typography>
      {departmentLines.map((line) => {
        if (line.dataKey !== 'date') {
          return (
            <div className="flex items-center gap-2" key={line.dataKey}>
              <div className="size-2 rounded-full" style={{ backgroundColor: line.stroke }} />
              <Typography>{line.dataKey}</Typography>
            </div>
          );
        }
        return null;
      })}
    </div>
  );

  return (
    <div className="flex max-md:flex-col  max-md:h-[480px] items-center justify-between gap-4 mb-8 h-[243px] min-w-[500px] px-10">
      <div className="w-1/2 max-md:w-full h-full">
        {Boolean(departmentData.length) && (
          <LineGraph
            data={departmentData}
            yFormatter={(value: number | null) => (value ? value.toString() : '0')}
            card={{
              title: departmentTitle,
            }}
            dataKeys={['date', ...departmentLines.map((line) => line.dataKey)]}
            lines={departmentLines}
            roundTicksToMoney={false}
            customTooltip={<HeadcountGraphTooltip lines={departmentLines} />}
            id="headcount-departments"
            turnOffGradient
          />
        )}
      </div>
      <div className="w-1/2 max-md:w-full h-full">
        {Boolean(totalData.length) && (
          <LineGraph
            data={totalData}
            lines={totalLines}
            yFormatter={(value: number | null) => (value ? value.toString() : '0')}
            card={{
              title: '👥 Total Headcount',
            }}
            dataKeys={['date', 'total']}
            roundTicksToMoney={false}
            customTooltip={<HeadcountGraphTooltip lines={totalLines} />}
            id="headcount-total"
            turnOffGradient
          />
        )}
      </div>
    </div>
  );
};

export default HeadcountGraphs;
