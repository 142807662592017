import { z } from 'zod';
import {
  ZPositionDetails,
  headcountLoaderSchema,
  ZPositionDetailsWithOrderedDates,
  ZTemporalValue,
  ZPositionSchema,
  ZAdditionalCompensationValue,
} from './schemas';

export type IPosition = z.infer<typeof ZPositionSchema>;
export type IPositionDetails = z.infer<typeof ZPositionDetails>;
export type IPositionDetailsWithOrderedDates = z.infer<typeof ZPositionDetailsWithOrderedDates>;
export type IHeadcountLoader = z.infer<typeof headcountLoaderSchema>;
export type ITemporalValue = z.infer<typeof ZTemporalValue>;
export type IAdditionalCompensationValue = z.infer<typeof ZAdditionalCompensationValue>;

export enum IEmploymentType {
  FullTime = 'fulltime',
  PartTime = 'parttime',
  Contractor = 'contractor',
}

export const employmentTypeToLabel = {
  [IEmploymentType.FullTime]: 'Full Time',
  [IEmploymentType.PartTime]: 'Part Time',
  [IEmploymentType.Contractor]: 'Contractor',
};
