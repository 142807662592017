import { ExpenseDriverEnum } from '~/pages/ExpensesDeprecated/components/types';

export const getHeadcountDriverTypeSelected = ({
  driver,
}: {
  driver: ExpenseDriverEnum;
}): { label: string; value: string } => {
  switch (driver) {
    case 'headcountPercentCompensation':
      return {
        label: 'Percent of Monthly Compensation',
        value: 'headcountPercentCompensation',
      };
    case 'setCost':
    case 'headcountFixed':
    default:
      return {
        label: 'Dollar Amount per Employee',
        value: 'headcountFixed',
      };
  }
};
