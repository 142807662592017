import React, { useEffect, useState } from 'react';
import Button from '../Button';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { State } from '~/store';
import { IGraphData } from '~/pages/Dashboard/entity/types';
import request from '~/utils/request';
import { IDashboardConfiguration } from '~/store/organizationSlice';
import { dashboardConfigToFormulasToFetch } from '~/pages/Dashboard/utils/dashboardConfigToFormulasToFetch';
import { StatusCodes } from 'http-status-codes';
import logger from '~/utils/logger';
import { ZGraphData } from '~/pages/Dashboard/entity/schemas';
import { IAPIResponse } from '~/utils/types';
import { addMonths } from 'date-fns';
import useQueryParams from '~/utils/hooks/useQueryParams';
import { PlusIcon } from '@heroicons/react/24/outline';

const MissingRevenueGoal = (): React.ReactNode | null => {
  const organizationUuid = useSelector((state: State) => state.organization.uuid);
  const dashboardConfiguration = useSelector((state: State) => state.organization.configuration.dashboardConfiguration);
  const [show, setShow] = useState<boolean>(false);
  const [queryParams] = useQueryParams();

  const fetchReports = async ({
    dashboardConfiguration,
    organizationUuid,
  }: {
    dashboardConfiguration: IDashboardConfiguration;
    organizationUuid: string;
  }): Promise<IGraphData | null> => {
    try {
      const formulasToFetch = dashboardConfigToFormulasToFetch({ dashboardConfiguration });
      const response = (await request({
        url: '/reports/consolidate',
        method: 'GET',
        params: {
          formulaUuids: formulasToFetch,
          startDate: new Date(),
          endDate: addMonths(new Date(), 1),
        },
        headers: { 'Organization-Uuid': organizationUuid },
      })) as IAPIResponse;
      if (response.status === StatusCodes.OK) {
        const parsedReports = ZGraphData.parse(response.data.data);
        return parsedReports;
      }
      throw new Error('Failed to fetch reports');
    } catch (error) {
      if (error instanceof Error) logger.error(error);
      return null;
    }
  };

  const determineToShow = async ({
    dashboardConfiguration,
    organizationUuid,
  }: {
    dashboardConfiguration: IDashboardConfiguration;
    organizationUuid: string;
  }): Promise<void> => {
    const reports = await fetchReports({ dashboardConfiguration, organizationUuid });
    if (reports) {
      const revenueReportGoalExists = Object.values(reports.consolidated ?? {})
        .find((report) => report.title === 'Revenue')
        ?.companyGoals.find((goal) => goal.targetDate);
      if (!revenueReportGoalExists) {
        setShow(true);
      }
    }
  };

  useEffect(() => {
    if (organizationUuid) {
      determineToShow({ dashboardConfiguration, organizationUuid });
    }
  }, [dashboardConfiguration, organizationUuid]);

  useEffect(() => {
    if (queryParams.get('showMissingRevenueGoal') === 'false') {
      setShow(false);
    }
  }, [queryParams]);

  if (!show) return null;

  return (
    <Link to={`/dashboard?showRevenueGoal=true`} className="flex flex-row whitespace-nowrap justify-center mb-3">
      <Button
        fill="destructiveClear"
        className="!w-fit justify-center pl-4 !pr-3.5 shadow-[0px_1px_2px_0px_rgba(0,_0,_0,_0.10)] !py-2.5 bg-white border border-green-75 rounded-xl mb-2"
      >
        Missing Revenue Goal <PlusIcon className="size-4 stroke-1.5" />
      </Button>
    </Link>
  );
};

export default MissingRevenueGoal;
