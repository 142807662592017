import useInput from '~/components/Input/useInput';
import { State } from '~/store';
import { authSlice } from '~/store/authSlice';
import { emailValidator, passwordValidator } from '~/utils/validators';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';
import request from '~/utils/request';
import useQueryParams from '~/utils/hooks/useQueryParams';
import Login from './Login';
import { AxiosResponse } from 'axios';
import FullPageLoading from '~/components/FullPageLoading';
import { GoogleOAuthProvider } from '@react-oauth/google';

export interface IExchangeOtpForTokensResponse {
  status: number;
  data: {
    data?: {
      accessToken: string;
      refreshToken: string;
    };
  };
}

export const requestTokens = async ({
  methodType,
  email,
  password,
  token,
}: {
  methodType: string;
  email?: string;
  password?: string;
  token?: string;
}): Promise<AxiosResponse<{ data: { accessToken: string; refreshToken: string } }>> =>
  request({
    url: '/auth/tokens',
    method: 'POST',
    body: {
      method: methodType,
      payload: {
        email,
        password: methodType === 'standard' ? password : undefined,
        token: methodType === 'google' ? token : undefined,
      },
    },
  });

const LoginContainer = (): React.ReactNode => {
  const navigate = useNavigate();
  const [queryParams] = useQueryParams();
  const redirectUrl = queryParams.get('redirectUrl');
  const [accessToken, setAccessToken] = useState<string>(localStorage.getItem('accessToken') ?? '');
  const [refreshToken, setRefreshToken] = useState<string>(localStorage.getItem('refreshToken') ?? '');
  const oneTimePasscode = queryParams.get('otp');
  const emailValue = queryParams.get('emailValue');
  let method = queryParams.get('method') ?? 'passwordless';
  if (import.meta.env.VITE_NODE_ENV === 'local' && !queryParams.get('method')) {
    method = 'standard';
  }
  const dispatch = useDispatch();
  const { isLoggedIn } = useSelector((state: State) => state.auth);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [email, setEmail] = useInput({
    validation: emailValidator,
    errorMessage: 'Please enter a valid email',
    value: import.meta.env.VITE_NODE_ENV === 'local' ? '@getparallel.com' : '',
  });
  const [password, setPassword] = useInput({
    validation: passwordValidator,
    errorMessage: 'Please enter a valid password (8 characters, a number, and a special character)',
    value: import.meta.env.VITE_NODE_ENV === 'local' ? 'Password123!' : '',
    pristine: import.meta.env.VITE_NODE_ENV !== 'local',
    valid: import.meta.env.VITE_NODE_ENV === 'local',
  });
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setErrorMessage('');
  }, [email.value, password.value]);

  useEffect(() => {
    if (accessToken && refreshToken) {
      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('refreshToken', refreshToken);
      dispatch(authSlice.actions.login());
    }
    setIsLoading(false);
  }, [accessToken, refreshToken, dispatch]);

  const attemptLogin = async (): Promise<void> => {
    if ((method === 'standard' && email.valid && password.valid) || (method === 'passwordless' && email.valid)) {
      const authResponse = await requestTokens({
        methodType: method,
        email: email.value,
        password: password.value,
      });

      if (authResponse.status >= 400) setErrorMessage('Unable to log in. Please try again or contact support.');

      if (method === 'standard') {
        localStorage.setItem('accessToken', authResponse.data.data.accessToken);
        localStorage.setItem('refreshToken', authResponse.data.data.refreshToken);
        setAccessToken(authResponse.data.data.accessToken);
        setRefreshToken(authResponse.data.data.refreshToken);
      }

      if (authResponse.status < 400 && method === 'passwordless') {
        navigate(`/auth/link-sent?emailValue=${encodeURIComponent(email.value)}`);
      }
    } else {
      setEmail({ ...email, touched: true, pristine: false });
      setPassword({ ...password, touched: true, pristine: false });
    }
  };

  const exchangeOtpForTokens = async ({
    oneTimePasscode,
    email,
  }: {
    oneTimePasscode: string;
    email: string;
  }): Promise<void> => {
    const response = (await request({
      url: `/auth/otp`,
      method: 'POST',
      body: {
        oneTimePasscode,
        email,
      },
    })) as IExchangeOtpForTokensResponse;

    if (response.status === 200 && response.data.data) {
      setAccessToken(response.data.data.accessToken);
      setRefreshToken(response.data.data.refreshToken);
    } else {
      setErrorMessage('The link you used has expired or is invalid');
    }
  };

  useEffect(() => {
    if (oneTimePasscode && emailValue) {
      exchangeOtpForTokens({
        oneTimePasscode: oneTimePasscode,
        email: emailValue,
      });
    }
  }, [oneTimePasscode, emailValue]);

  const handleGoogleLogin = async (token: string): Promise<void> => {
    try {
      const response = await requestTokens({
        methodType: 'google',
        token,
      });

      if (response.status >= 400) {
        setErrorMessage('Unable to log in with Google. Please try again or contact support.');
        return;
      }

      localStorage.setItem('accessToken', response.data.data.accessToken);
      localStorage.setItem('refreshToken', response.data.data.refreshToken);
      setAccessToken(response.data.data.accessToken);
      setRefreshToken(response.data.data.refreshToken);
    } catch (error) {
      setErrorMessage('Failed to authenticate with Google');
    }
  };

  if (isLoggedIn) {
    const CHANGE_URL = '/dashboard';
    return <Navigate to={redirectUrl ?? CHANGE_URL} replace />;
  }

  if (isLoading) {
    return <FullPageLoading isVisible color="green" text="" opacity="1" size="size-[75px]" isStatic />;
  }

  return (
    <div>
      <GoogleOAuthProvider clientId={import.meta.env.VITE_GOOGLE_CLIENT_ID}>
        <Login
          email={email}
          setEmail={setEmail}
          usePassword={method === 'standard'}
          password={password}
          setPassword={setPassword}
          login={attemptLogin}
          errorMessage={errorMessage}
          onGoogleLogin={handleGoogleLogin}
        />
      </GoogleOAuthProvider>
    </div>
  );
};

export default LoginContainer;
