import React, { useContext, useEffect } from 'react';
import Modal from '~/components/Modal';
import { useSelector } from 'react-redux';
import { State } from '~/store';
import ExpenseForm from './CreateExpenseForm';
import createExpense from '~/pages/Expenses/components/CreateExpense/createExpense';
import { ExpensesPageContext } from '~/pages/Expenses/context/ExpensesContext';
import toast from 'react-hot-toast';
import request from '~/utils/request';
import { ZExpense } from '~/pages/Expenses/entity/schemas';
import { z } from 'zod';
import { IExpense } from '../types';
import useFormulaContext from '~/components/Formulas/context/useFormulaContext';

const CreateExpenseModal = (): React.ReactNode => {
  const {
    expenses,
    setExpenses,
    proposedExpenses,
    setProposedExpenses,
    recommendedExpenses,
    setRecommendedExpenses,
    expenseModal,
    setExpenseModal,
    expenseUuid,
    setExpenseUuid,
    revalidateFilteredExpensesReport,
    revalidateFormulaList,
    reload,
    formulaList,
    variables,
    setVariables,
    displayFormulaError,
    setDisplayFormulaError,
    formula,
    setFormula,
    updatedFormula,
    setUpdatedFormula,
    formulaValue,
    setFormulaValue,
    desiredCreationStatus,
    setDesiredCreationStatus,
    expenseIntegrationMappings,
    formState,
  } = useContext(ExpensesPageContext);
  const { refreshData } = useFormulaContext();
  const activeScenarioUuid = useSelector((state: State) => state.scenario.activeScenarioUuid);
  const organizationUuid = useSelector((state: State) => state.organization.uuid);

  useEffect(() => {
    const getExpenseDetails = async (): Promise<void> => {
      if (!expenseUuid) return;
      const fetchExpenseResponse = await request({
        url: `/expenses/${expenseUuid.expenseUuid}`,
        headers: { 'Organization-Uuid': organizationUuid },
        params: { scenarioUuid: activeScenarioUuid },
        method: 'GET',
      });
      if (fetchExpenseResponse.status >= 400) return;

      const parsedResponse = z
        .object({
          data: z.object({ data: ZExpense }),
        })
        .parse(fetchExpenseResponse);

      formState.setFormStateFromIExpense({
        expense: parsedResponse.data.data,
        expenseIntegrationMappings,
      });
    };

    if (expenseUuid) {
      getExpenseDetails();
    }
  }, [expenseUuid]);

  useEffect(() => {
    if (expenseUuid) {
      const expenseFormula = formulaList.find(
        (formula) => formula.recipe.relatedResourceUuid === expenseUuid.expenseUuid,
      );
      if (!expenseFormula) return;
      if (formState.type === 'custom') {
        formState.setFormulaInputState((prev) => ({
          ...prev,
          formulaState: {
            ...prev.formulaState,
            formula: expenseFormula.recipe.expression,
            variables: expenseFormula.recipe.variables,
            formulaList: formulaList,
            editable: true,
            topLevelFormulaUuid: expenseFormula.formulaUuid,
          },
          formulaTitle: formState.name.value,
          variables: expenseFormula.recipe.variables,
          isCustom: expenseModal,
          formulaUuid: expenseFormula.formulaUuid,
        }));
      } else {
        formState.setFormulaInputState({
          formulaState: {
            topLevelFormulaUuid: expenseFormula.formulaUuid,
            formula: '',
            variables: {},
            formulaList: formulaList,
            editable: true,
          },
          variables: {},
          isCustom: false,
          formulaUuid: expenseFormula.formulaUuid,
        });
        setFormula([]);
        setVariables({});
        setUpdatedFormula([]);
      }
    } else {
      formState.setIntegrationMappings((prevState) => ({
        ...prevState,
        options: expenseIntegrationMappings.reduce(
          (acc, mapping) => {
            acc.push({
              label: mapping.name,
              value: mapping.uuid,
              disabled: mapping.currentlyInUse,
            });

            return acc;
          },
          [] as { label: string; value: string; disabled?: boolean }[],
        ),
      }));
      formState.setFormulaInputState((prev) => ({
        ...prev,
        formulaState: {
          ...prev.formulaState,
          formula: formula.map((f) => f.textValue).join(''),
          variables: variables,
          formulaList: formulaList,
          editable: true,
        },
        formulaTitle: formState.name.value,
        variables: variables,
        isCustom: expenseModal && formState.type === 'custom',
        formulaUuid: undefined,
      }));
    }
  }, [expenseModal, formState.type]);

  return (
    <Modal
      id={expenseUuid && expenseUuid.type === 'edit' ? 'edit-expense-modal' : 'create-expense-modal'}
      isOpen={expenseModal}
      title={expenseUuid && expenseUuid.type === 'edit' ? 'Edit Expense' : 'New Expense'}
      size="sm"
    >
      <div data-testid="create-expense-modal-container" className="mt-2 w-full">
        <ExpenseForm
          onClose={() => {
            setExpenseModal(false);
            setTimeout(() => {
              formState.resetFormState();
              setExpenseUuid(undefined);
              setFormula([]);
              setVariables({});
              setUpdatedFormula([]);
            }, 200);
          }}
          formState={formState}
          variables={variables}
          setVariables={setVariables}
          displayFormulaError={displayFormulaError}
          setDisplayFormulaError={setDisplayFormulaError}
          formula={formula}
          setFormula={setFormula}
          updatedFormula={updatedFormula}
          setUpdatedFormula={setUpdatedFormula}
          formulaValue={formulaValue}
          setFormulaValue={setFormulaValue}
          formulaList={formulaList}
          createExpense={async () => {
            await createExpense({
              organizationUuid,
              scenarioUuid: activeScenarioUuid,
              expenseUuid: expenseUuid,
              type:
                formState.type === 'headcountDriven'
                  ? formState.headcountDriverType.selected?.value ?? null
                  : formState.type,
              name: formState.name.value,
              category: formState.category.selected?.value ?? null,
              frequency: formState.frequency.selected?.value ?? null,
              amount:
                formState.headcountDriverType.selected?.value === 'headcountPercentCompensation'
                  ? formState.percentage.value
                  : formState.amount.value,
              departments: formState.departments.selected
                ? formState.departments.selected.map((department) => department.value)
                : null,
              startDate: formState.startDate.startDate,
              endDate: formState.endDate.endDate,
              dataSourceUuids: formState.integrationMappings.selected
                ? formState.integrationMappings.selected.reduce((acc, mapping) => {
                    if (mapping.value) {
                      acc.push(mapping.value);
                    }
                    return acc;
                  }, [] as string[])
                : undefined,
              updatedFormula,
              variables,
              successCallback: ({ createdExpense }: { createdExpense: IExpense }) => {
                refreshData();
                setExpenseModal(false);
                setExpenseUuid(undefined);
                setDesiredCreationStatus('created');
                if (expenseUuid && expenseUuid.type === 'edit' && createdExpense.creationStatus === 'created') {
                  const expenseIndex = expenses.findIndex((expense) => expense.uuid === expenseUuid.uuid);
                  if (expenseIndex !== -1) {
                    const updatedExpenses = [...expenses];
                    updatedExpenses[expenseIndex] = createdExpense;
                    setExpenses(updatedExpenses);
                  } else {
                    reload();
                  }
                } else if (expenseUuid && expenseUuid.type === 'edit' && createdExpense.creationStatus === 'proposed') {
                  const recommendedExpenseIndex = recommendedExpenses.findIndex(
                    (expense) => expense.uuid === expenseUuid.uuid,
                  );
                  const proposedExpenseIndex = proposedExpenses.findIndex(
                    (expense) => expense.uuid === expenseUuid.uuid,
                  );
                  if (recommendedExpenseIndex !== -1) {
                    const updatedRecommendedExpenses = [...recommendedExpenses].filter(
                      (expense) => expense.uuid !== expenseUuid.uuid,
                    );
                    setRecommendedExpenses(updatedRecommendedExpenses);
                    setProposedExpenses([...proposedExpenses, createdExpense]);
                  } else if (proposedExpenseIndex !== -1) {
                    const updatedProposedExpenses = [...proposedExpenses];
                    updatedProposedExpenses[proposedExpenseIndex] = createdExpense;
                    setProposedExpenses(updatedProposedExpenses);
                  } else {
                    reload();
                  }
                } else {
                  if (createdExpense.creationStatus === 'proposed') {
                    setProposedExpenses([...proposedExpenses, createdExpense]);
                  } else {
                    setExpenses([...expenses, createdExpense]);
                  }
                }
                revalidateFilteredExpensesReport();
                revalidateFormulaList();
                formState.resetFormState();
                setDisplayFormulaError({ isDisplayed: false, message: '' });
                setFormulaValue('');
                setUpdatedFormula([]);
                setFormula([]);
                setVariables({});
                toast.success(`Expense ${expenseUuid ? 'updated' : 'created'}`);
              },
              failureCallback: () => {
                toast.error('Failed to create expense');
              },
              validateFormState: (): boolean => {
                return formState.validateFormState({ expenses, formulas: formulaList });
              },
              formulaList,
              setDisplayFormulaError,
              desiredCreationStatus,
              employmentTypes: formState.employmentTypes.selected
                ? formState.employmentTypes.selected
                    .map((employmentType) => employmentType.value)
                    .filter((value): value is string => value !== null && value !== undefined)
                : ['all'],
            });
          }}
        />
      </div>
    </Modal>
  );
};

export default CreateExpenseModal;
