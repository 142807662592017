import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IIntegrationSources } from '~/services/parallel/integrations.types';

export interface IIntegrationState {
  lastPulled: Date | null;
  isPulling: boolean;
  source?: IIntegrationSources;
}

const initialState: IIntegrationState = {
  lastPulled: null,
  isPulling: false,
  source: undefined,
};

export const integrationsSlice = createSlice({
  name: 'integrations',
  initialState,
  reducers: {
    update: (state, action: PayloadAction<IIntegrationState>) => action.payload,
    reset: () => initialState,
  },
});

export const { update } = integrationsSlice.actions;

export default integrationsSlice.reducer;
