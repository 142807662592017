import React, { useEffect, useState } from 'react';
import useFormulaContext from '../../../../components/Formulas/context/useFormulaContext';
import { IFormula, IFormulaDependencyGraph, IFormulaTypeEnum } from '~/services/parallel/formulas.types';
import '@xyflow/react/dist/style.css';
import { Edge, MarkerType, Node, ReactFlow, ReactFlowProvider, useReactFlow } from '@xyflow/react';
import Select, { useSelect } from '~/components/Select';
import { SelectType } from '~/components/Select/Select.types';
import FormulaNode from './FormulaNode';
import { IRecordTypeEnum } from '~/components/Formulas/FormulasTable/TableBody';
import { IFormulaType, IFormulaData, IGroupType } from '~/components/Formulas/context/types';

export interface IFormulaNodeData extends Record<string, unknown> {
  formula: IFormula;
  label: string;
  dataType: 'flowsToSelected' | 'selected' | 'flowsFromSelected';
  position: {
    x: number;
    y: number;
  };
  type: 'formula';
}

const nodeTypes = {
  formula: FormulaNode,
};

const getNodesAndEdges = ({
  selectedFormula,
  formulaDictionary,
  dependencyGraph,
  center,
}: {
  selectedFormula: IFormula;
  formulaDictionary: Record<string, IFormula>;
  dependencyGraph: IFormulaDependencyGraph;
  center: {
    x: number;
    y: number;
  };
}): { nodes: Node<IFormulaNodeData>[]; edges: Edge[] } => {
  let numOfInboundNodes = 0;
  const nodes: Node<IFormulaNodeData>[] = [
    {
      id: selectedFormula.formulaUuid,
      data: {
        formula: selectedFormula,
        label: selectedFormula.recipe.name,
        dataType: 'selected',
        position: center,
        type: 'formula',
      },
      position: center,
      type: 'formula',
    },
  ];
  dependencyGraph[selectedFormula.formulaUuid].forEach((formula) => {
    const position = {
      x: center.x - 450,
      y:
        center.y +
        (numOfInboundNodes - Math.floor(dependencyGraph[selectedFormula.formulaUuid].length / 2)) * (center.y * 0.65),
    };
    nodes.push({
      id: formula.formulaUuid,
      data: {
        formula,
        label: formula.recipe.name,
        dataType: 'flowsToSelected',
        position,
        type: 'formula',
      },
      position,
      type: 'formula',
    });
    numOfInboundNodes++;
  });
  const edges: Edge[] = [];
  dependencyGraph[selectedFormula.formulaUuid].forEach((formula, index) => {
    edges.push({
      id: `${formula.formulaUuid}-${selectedFormula.formulaUuid}`,
      source: formula.formulaUuid,
      target: selectedFormula.formulaUuid,
      sourceHandle: formula.formulaUuid,
      targetHandle: selectedFormula.formulaUuid,
      markerEnd:
        index === 0
          ? {
              type: MarkerType.Arrow,
              width: 33,
              height: 18,
              color: '#C9C9C9',
            }
          : undefined,
      animated: true,
      style: {
        stroke: '#C9C9C9',
        strokeWidth: 1.5,
      },
    });
  });
  const nodesToPush: Node<IFormulaNodeData>[] = [];
  Object.keys(dependencyGraph).forEach((formulaUuid) => {
    if (formulaUuid in formulaDictionary) {
      const keyFormulas = dependencyGraph[formulaUuid];
      if (keyFormulas.some((formula) => selectedFormula.formulaUuid === formula.formulaUuid)) {
        nodesToPush.push({
          id: formulaUuid,
          data: {
            formula: formulaDictionary[formulaUuid],
            label: formulaDictionary[formulaUuid].recipe.name,
            dataType: 'flowsFromSelected',
            type: 'formula',
            position: {
              x: 0,
              y: 0,
            },
          },
          position: {
            x: 0,
            y: 0,
          },
          type: 'formula',
        });
        edges.push({
          id: `${selectedFormula.formulaUuid}-${formulaUuid}`,
          source: selectedFormula.formulaUuid,
          target: formulaUuid,
          sourceHandle: selectedFormula.formulaUuid,
          targetHandle: formulaUuid,
          markerEnd: {
            type: MarkerType.Arrow,
            width: 33,
            height: 18,
            color: '#C9C9C9',
          },
          animated: true,
          style: {
            stroke: '#C9C9C9',
            strokeWidth: 1.5,
          },
        });
      }
    }
  });
  nodesToPush.forEach((node, index) => {
    const position = {
      x: center.x + 400,
      y: center.y + (index - Math.floor(nodesToPush.length / 2)) * (center.y * 0.65),
    };
    nodes.push({
      ...node,
      data: {
        ...node.data,
        position,
      },
      position,
    });
  });

  return {
    nodes,
    edges,
  };
};

const DisplayAttributesContent = (): React.ReactElement | null => {
  const { searchFilter, setSearchFilter, formulaDictionary, dependencyGraph, filteredFormulasData } =
    useFormulaContext();
  const reactFlow = useReactFlow();
  const [selectedFormula, setSelectedFormula] = useState<IFormula | null>(null);
  const [nodes, setNodes] = useState<Node<IFormulaNodeData>[]>([]);
  const [edges, setEdges] = useState<Edge[]>([]);
  const [selectAttribute, setSelectAttribute] = useSelect({
    options: [],
  });
  const [opacity, setOpacity] = useState(0);
  const [isNodeClick, setIsNodeClick] = useState(false);

  // Type guard to check if a formula data is a group
  const isGroup = (item: IFormulaData): item is IGroupType => {
    return item.type === IRecordTypeEnum.Group;
  };

  // Type guard to check if a formula data is a formula
  const isFormula = (item: IFormulaData): item is IFormulaType => {
    return item.type === IRecordTypeEnum.Formula;
  };

  useEffect(() => {
    if (searchFilter.value && filteredFormulasData.length) {
      let closestFormulaUuid: string | undefined;
      const formulas: IFormulaType[] = [];

      // First collect all formulas into a flat array
      filteredFormulasData.forEach((item) => {
        if (isGroup(item)) {
          formulas.push(...item.formulas);
        } else if (isFormula(item)) {
          formulas.push(item);
        }
      });

      // Find the formula with name that best matches the search
      const searchLower = searchFilter.value.trim().toLowerCase();
      let bestMatchScore = -1;
      let bestMatchFormula: IFormulaType | undefined;

      formulas.forEach((formula) => {
        const formulaName = formula.label.name;
        const normalizedName = formulaName.trim().toLowerCase();

        // Exact match gets highest priority
        if (normalizedName === searchLower) {
          bestMatchScore = Number.MAX_VALUE;
          bestMatchFormula = formula;
        }
        // Starts with gets second priority
        else if (normalizedName.startsWith(searchLower) && bestMatchScore < 1000) {
          bestMatchScore = 1000;
          bestMatchFormula = formula;
        }
        // Contains gets third priority
        else if (normalizedName.includes(searchLower) && bestMatchScore < 100) {
          bestMatchScore = 100;
          bestMatchFormula = formula;
        }
      });

      if (bestMatchFormula !== undefined) {
        closestFormulaUuid = bestMatchFormula.formulaUuid;
      } else {
        closestFormulaUuid = formulas[0]?.formulaUuid;
      }

      if (!closestFormulaUuid) {
        closestFormulaUuid = filteredFormulasData.flatMap((item) => {
          if (item.type === IRecordTypeEnum.Group) {
            return item.formulas;
          } else {
            return [item];
          }
        })[0].formulaUuid;
      }

      if (!Object.keys(formulaDictionary).includes(closestFormulaUuid)) {
        setSelectedFormula(null);
      } else {
        setSelectAttribute({
          ...selectAttribute,
          selected: {
            label: formulaDictionary[closestFormulaUuid].recipe.name,
            value: closestFormulaUuid,
          },
        });
        setSelectedFormula(formulaDictionary[closestFormulaUuid]);
      }
    } else {
      setSelectedFormula(null);
    }
  }, [filteredFormulasData, searchFilter.value, formulaDictionary]);

  useEffect(() => {
    if (!selectedFormula) {
      setNodes([]);
      setEdges([]);
      return;
    }
    const { nodes, edges } = getNodesAndEdges({
      selectedFormula,
      formulaDictionary,
      dependencyGraph,
      center: { x: 100, y: 100 }, // arbitrary center because fitView will handle centering the graph
    });
    setNodes(nodes);
    setEdges(edges);
    setTimeout(() => {
      reactFlow.fitView({
        padding: 0.2,
        duration: 200,
      });
    }, 100); // set timeout gives time for nodes to be re-rendered in ReactFlow before fitView is called
  }, [selectedFormula, formulaDictionary, dependencyGraph]);

  useEffect(() => {
    setSelectAttribute((prev) => {
      return {
        ...prev,
        options: filteredFormulasData
          .flatMap((item) => {
            if (item.type === IRecordTypeEnum.Group) {
              return item.formulas;
            } else {
              return item;
            }
          })
          .filter((formula) =>
            formula.label.name.trim().toLowerCase().includes(searchFilter.value.trim().toLowerCase()),
          )
          .map((formula) => ({
            label: formula.label.name,
            value: formula.formulaUuid,
          })),
        selected: selectedFormula
          ? {
              label: selectedFormula.recipe.name,
              value: selectedFormula.formulaUuid,
            }
          : undefined,
      };
    });
  }, [filteredFormulasData, selectedFormula]);

  useEffect(() => {
    if (!isNodeClick) {
      setOpacity(0);
      const debounceTimer = setTimeout(() => {
        setOpacity(1);
      }, 500);
      return () => clearTimeout(debounceTimer);
    }
    setIsNodeClick(false);
    return undefined;
  }, [searchFilter.value]);

  if (!searchFilter.value) return null;

  const handleSelectChange = (values: SelectType): void => {
    const selected = selectAttribute.options.find((option) => option.value === values.value);
    if (selected?.value) {
      setSelectAttribute((prev) => ({
        ...prev,
        selected: {
          label: selected.label,
          value: selected.value,
          valid: true,
          pristine: false,
          touched: true,
        },
      }));
      setSelectedFormula(formulaDictionary[selected.value]);
    }
  };

  const handleNodeClick = (event: React.MouseEvent, node: Node<IFormulaNodeData>): void => {
    const data: IFormulaNodeData = node.data;
    if (data.formula.type === IFormulaTypeEnum.ModelBuilder) {
      if (data.dataType === 'selected') {
        reactFlow.fitView({
          padding: 0.2,
          duration: 200,
        });
      } else {
        setIsNodeClick(true);
        setSearchFilter({
          ...searchFilter,
          value: data.label,
        });
      }
    }
  };

  return (
    <div className="w-full h-full bg-white p-5">
      <div className="bg-neutral-15 border border-neutral-50 rounded h-full w-full">
        <div
          className={`w-[200px] absolute top-8 right-8 z-10 pointer-events-auto ${selectAttribute.options.length < 2 && 'hidden'}`}
        >
          <Select
            id="display-attributes-select"
            state={selectAttribute}
            setState={setSelectAttribute}
            onChange={handleSelectChange}
          />
        </div>
        <div className="w-full h-full transition-opacity duration-200 ease-in-out" style={{ opacity }}>
          <ReactFlow
            nodes={nodes}
            edges={edges}
            fitView
            fitViewOptions={{ padding: 0.2, duration: 200 }}
            onNodeClick={handleNodeClick}
            nodeTypes={nodeTypes}
            proOptions={{ hideAttribution: true }}
          />
        </div>
      </div>
    </div>
  );
};

const DisplayAttributes = (): React.ReactElement => {
  return (
    <ReactFlowProvider>
      <DisplayAttributesContent />
    </ReactFlowProvider>
  );
};

export default DisplayAttributes;
