import React from 'react';
import Button from '~/components/Button';
import CurrencyInput from '../../../components/Input/currencyInput/CurrencyInput';
import { SelectState } from '~/components/Select/Select.types';
import Select from '~/components/Select';
import Divider from '~/components/Divider';
import { TrashIcon } from '@heroicons/react/24/outline';

const AdditionalCompensationInputs = ({
  addCommission,
  setAddCommission,
  commission,
  setCommission,
  commissionFrequency,
  setCommissionFrequency,
  commissionEffectiveMonthPattern,
  setCommissionEffectiveMonthPattern,
  addBonus,
  setAddBonus,
  bonus,
  setBonus,
  bonusFrequency,
  setBonusFrequency,
  bonusEffectiveMonthPattern,
  setBonusEffectiveMonthPattern,
}: {
  commission: Types.InputState;
  setCommission: React.Dispatch<React.SetStateAction<Types.InputState>>;
  commissionFrequency: SelectState;
  setCommissionFrequency: React.Dispatch<React.SetStateAction<SelectState>>;
  commissionEffectiveMonthPattern: SelectState;
  setCommissionEffectiveMonthPattern: React.Dispatch<React.SetStateAction<SelectState>>;
  bonus: Types.InputState;
  setBonus: React.Dispatch<React.SetStateAction<Types.InputState>>;
  bonusFrequency: SelectState;
  setBonusFrequency: React.Dispatch<React.SetStateAction<SelectState>>;
  bonusEffectiveMonthPattern: SelectState;
  setBonusEffectiveMonthPattern: React.Dispatch<React.SetStateAction<SelectState>>;
  addCommission: boolean;
  setAddCommission: React.Dispatch<React.SetStateAction<boolean>>;
  addBonus: boolean;
  setAddBonus: React.Dispatch<React.SetStateAction<boolean>>;
}): React.ReactNode => {
  return (
    <div className="flex flex-col">
      <div
        className={`w-full grid grid-cols-3 gap-2 relative transition-all duration-300 ${!addCommission ? 'max-h-0 h-0 overflow-hidden opacity-0' : 'max-h-[67px] h-[67px] mt-4 opacity-100'}`}
      >
        <CurrencyInput
          id="commission"
          label="Commission"
          state={commission}
          setState={setCommission}
          inputClassName="col-span-1"
        />
        <Select
          id="commissionFrequency"
          label="Frequency"
          state={commissionFrequency}
          setState={setCommissionFrequency}
          className={commissionFrequency.selected?.value === 'monthly' ? 'col-span-2' : 'col-span-1'}
        />
        {commissionFrequency.selected?.value !== 'monthly' && (
          <Select
            id="commissionEffectiveMonthPattern"
            label="When"
            state={commissionEffectiveMonthPattern}
            setState={setCommissionEffectiveMonthPattern}
            className="col-span-1"
          />
        )}
        <TrashIcon
          onClick={() => setAddCommission(false)}
          className={`w-5 h-5 cursor-pointer text-neutral-100 hover:text-red-500 absolute -right-6 top-[35px]`}
        />
      </div>
      <div
        className={`w-full grid grid-cols-3 gap-2 relative transition-all duration-300 ${!addBonus ? 'max-h-0 h-0 overflow-hidden opacity-0' : 'max-h-[67px] h-[67px] mt-4 opacity-100 '}`}
      >
        <CurrencyInput id="bonus" label="Bonus" state={bonus} setState={setBonus} inputClassName="col-span-1" />
        <Select
          id="bonusFrequency"
          label="Frequency"
          state={bonusFrequency}
          setState={setBonusFrequency}
          className={bonusFrequency.selected?.value === 'monthly' ? 'col-span-2' : 'col-span-1'}
        />
        {bonusFrequency.selected?.value !== 'monthly' && (
          <Select
            id="bonusEffectiveMonthPattern"
            label="When"
            state={bonusEffectiveMonthPattern}
            setState={setBonusEffectiveMonthPattern}
            className="col-span-1"
          />
        )}
        <TrashIcon
          onClick={() => setAddBonus(false)}
          className={`w-5 h-5 cursor-pointer text-neutral-100 hover:text-red-500 absolute -right-6 top-[35px]`}
        />
      </div>
      <div
        className={`flex flex-row items-center whitespace-nowrap gap-1 transition-all duration-300 ${!addCommission || !addBonus ? 'max-h-6 h-6 mt-3' : 'max-h-0 h-0 mt-0'}`}
      >
        <Button
          id="add-commission-create-position"
          fill="clear"
          className={`transition-all duration-300 !px-0 !py-0 whitespace-nowrap overflow-hidden ${!addCommission ? '!max-w-[135px] !w-[135px]' : '!max-w-0 !w-0'}`}
          onClick={() => setAddCommission(true)}
        >
          Add Commission
        </Button>
        {!addCommission && !addBonus && <Divider orientation="vertical" className="text-neutral-50" />}
        <Button
          id="add-bonus-create-position"
          fill="clear"
          className={`transition-all duration-300 !px-0 !py-0 whitespace-nowrap overflow-hidden ${!addBonus ? '!max-w-[87px] !w-[87px]' : '!max-w-0 !w-0'}`}
          onClick={() => setAddBonus(true)}
        >
          Add Bonus
        </Button>
      </div>
    </div>
  );
};

export default AdditionalCompensationInputs;
