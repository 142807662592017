import { handleRemoveSegmentToDelete } from './handleRemoveSegmentToDelete';
import { IFormulaSegment, IVariables } from '~/pages/FinancialModelDeprecated/entity/types';
import { handleDeleteAndBackspace } from './handleDeleteAndBackspace';
import { handleArrowPresses } from './handleArrowPresses';
import { IFormula } from '~/services/parallel/formulas.types';

export const handleKeyDownWithSelectedVariable = ({
  event,
  filteredFormulaList,
  segmentToDelete,
  setSegmentToDelete,
  inputPosition,
  setInputPosition,
  updatedFormula,
  setUpdatedFormula,
  setFormula,
  variables,
  setVariables,
  value,
  setValue,
  setEnteredConstantFrom,
  setHighlightedFormula,
  inputRef,
}: {
  event: React.KeyboardEvent<HTMLDivElement>;
  filteredFormulaList: IFormula[];
  position?: 'beginning' | 'end';
  value: string;
  setHighlightedFormula: React.Dispatch<React.SetStateAction<{ formula?: IFormula; index: number }>>;
  segmentToDelete?: {
    segmentRef: React.RefObject<HTMLDivElement>;
    segmentIndex: number;
    segmentValue: string;
  };
  setSegmentToDelete: React.Dispatch<
    React.SetStateAction<
      | {
          segmentRef: React.RefObject<HTMLDivElement>;
          segmentIndex: number;
          segmentValue: string;
        }
      | undefined
    >
  >;
  inputPosition: number;
  setInputPosition: React.Dispatch<React.SetStateAction<number>>;
  updatedFormula: IFormulaSegment[];
  setUpdatedFormula: React.Dispatch<React.SetStateAction<IFormulaSegment[]>>;
  setFormula: React.Dispatch<React.SetStateAction<IFormulaSegment[]>>;
  variables: IVariables;
  setVariables: React.Dispatch<React.SetStateAction<IVariables>>;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  setEnteredConstantFrom: React.Dispatch<React.SetStateAction<'left' | 'right' | undefined>>;
  inputRef: React.RefObject<HTMLDivElement>;
}): void => {
  const onRemoveSegmentToDelete = (): void => {
    handleRemoveSegmentToDelete({
      segmentToDelete,
      setSegmentToDelete,
      updatedFormula,
      inputRef,
    });
  };
  if (
    (!document.activeElement || document.activeElement !== inputRef.current) &&
    (event.key === 'Delete' || event.key === 'Backspace')
  ) {
    event.preventDefault();
    handleDeleteAndBackspace({
      event,
      segmentToDelete,
      setSegmentToDelete,
      inputPosition,
      setInputPosition,
      updatedFormula,
      setUpdatedFormula,
      setFormula,
      variables,
      setVariables,
      value,
      setValue,
      setEnteredConstantFrom,
      inputRef,
    });
  } else if (event.key === 'ArrowLeft') {
    event.preventDefault();
    handleArrowPresses.leftArrow({
      segmentToDelete,
      inputPosition,
      setInputPosition,
      updatedFormula,
      setUpdatedFormula,
      setFormula,
      variables,
      setVariables,
      value,
      setValue,
      setEnteredConstantFrom,
      inputRef,
    });
    onRemoveSegmentToDelete;
  } else if (event.key === 'ArrowRight') {
    event.preventDefault();
    handleArrowPresses.rightArrow({
      segmentToDelete,
      inputPosition,
      setInputPosition,
      updatedFormula,
      setUpdatedFormula,
      setFormula,
      variables,
      setVariables,
      value,
      setValue,
      setEnteredConstantFrom,
      inputRef,
    });
    onRemoveSegmentToDelete;
  } else if (event.key === 'ArrowUp') {
    event.preventDefault();
    handleArrowPresses.upArrow({
      filteredFormulaList,
      setHighlightedFormula,
      setInputPosition,
      value,
    });
    onRemoveSegmentToDelete;
  } else if (event.key === 'ArrowDown') {
    event.preventDefault();
    handleArrowPresses.downArrow({
      filteredFormulaList,
      setHighlightedFormula,
      setInputPosition,
      endPosition: updatedFormula.length,
      value,
    });
    onRemoveSegmentToDelete;
  }
};
