import React, { useState } from 'react';
import Typography from '~/components/Typography';
import { IFormulaNodeData } from './index';
import { Handle, Position } from '@xyflow/react';
import { IFormulaTypeEnum } from '~/services/parallel/formulas.types';

const FormulaNode = ({ data }: { data: IFormulaNodeData }): React.ReactElement => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = (): void => {
    setIsHovered(true);
  };

  const handleMouseLeave = (): void => {
    setIsHovered(false);
  };

  return (
    <div>
      {isHovered && data.formula.type !== IFormulaTypeEnum.ModelBuilder && (
        <div className="absolute top-[-75px] left-0 z-10 bg-black rounded-lg px-2 py-2 w-[280px]">
          <Typography size="sm" color="white">
            This node cannot be selected because it represents an expense.
          </Typography>
        </div>
      )}
      <div
        className={`border ${
          data.dataType === 'selected' ? 'bg-white border-neutral-100' : `bg-neutral-15 border-neutral-75`
        } 
      rounded-3xl py-1 px-3 ${data.formula.type === IFormulaTypeEnum.ModelBuilder ? 'hover:cursor-pointer hover:bg-green-15 hover:border-green-200' : 'hover:cursor-default'}`}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Typography
          size="sm"
          color={
            data.dataType === 'selected'
              ? 'primary'
              : isHovered && data.formula.type === IFormulaTypeEnum.ModelBuilder
                ? 'primaryGreen'
                : 'empty'
          }
        >
          {data.label}
        </Typography>
        {(data.dataType === 'flowsToSelected' || data.dataType === 'selected') && (
          <Handle
            type="source"
            position={Position.Right}
            id={data.formula.formulaUuid}
            style={{ background: 'transparent', border: 'none' }}
          />
        )}
        {(data.dataType === 'flowsFromSelected' || data.dataType === 'selected') && (
          <Handle
            type="target"
            position={Position.Left}
            id={data.formula.formulaUuid}
            style={{ background: 'transparent', border: 'none' }}
          />
        )}
      </div>
    </div>
  );
};

export default FormulaNode;
