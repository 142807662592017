/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import React, { ReactElement, useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { CellContext } from '@tanstack/react-table';
import { useInput } from '~/components/Input/InputWrapper';
import { IFormulaOverride, IFormulaActual } from '../../entity/types';
import { addMonths, endOfMonth, isAfter, isBefore, isSameMonth, startOfMonth } from 'date-fns';
import date from '~/utils/dates/date';
import { FinancialModelContext } from '../../context/FinancialModelContext';
import { parseSpreadsheetData } from '~/utils/parseSpreadsheetData';
import { formatDateToISO } from '~/utils/dates/formatDateToISO';
import { NumericFormat } from 'react-number-format';
import formatCurrency from '~/utils/formatCurrency';
import Typography from '~/components/Typography';
import numberWithCommas from '~/utils/numberWithCommas';
import { IFormattingEnum } from '~/services/parallel/formulas.types';
import { IRoundingInstructions } from '~/components/Formulas/context/types';

enum IValueType {
  Calculated = 'calculated',
  Actual = 'actual',
  Override = 'override',
}

const MonthCell = ({
  cellContext,
}: {
  cellContext: CellContext<
    Record<
      string,
      {
        date?: string;
        value: string;
        overrides?: IFormulaOverride[] | null;
        actuals?: IFormulaActual[] | null;
        uuid?: string;
        colIndex?: number;
        rowIndex?: number;
        formatting?: IFormattingEnum | null;
        formulaTitle?: string;
        roundingInstructions?: IRoundingInstructions;
        dataSourceUuids: string[];
      }
    >,
    {
      date?: string;
      value: string;
      overrides?: IFormulaOverride[] | null;
      actuals?: IFormulaActual[] | null;
      uuid?: string;
      colIndex?: number;
      rowIndex?: number;
      formatting?: IFormattingEnum | null;
      formulaTitle?: string;
      roundingInstructions?: IRoundingInstructions;
      dataSourceUuids: string[];
    }
  >;
}): ReactElement => {
  const { overridesList, setOverridesList, selectedMonthCell, setSelectedMonthCell, actualsList, setActualsList } =
    useContext(FinancialModelContext);
  const initialValue = cellContext.getValue();
  const inputRef = useRef<HTMLInputElement>(null);

  const getInputValue = ({
    value,
    formatting,
    isActualOrOverride,
  }: {
    value: string;
    formatting?: IFormattingEnum | null;
    isActualOrOverride?: boolean;
  }): string => {
    let numToFormat = Number(value);

    if (isActualOrOverride) {
      numToFormat = numToFormat * 100;
    }

    switch (formatting) {
      case IFormattingEnum.Percent:
        return parseFloat(numToFormat.toFixed(2)).toString();
      case IFormattingEnum.Currency:
        return (numToFormat / 100).toFixed(2);
      default:
        return parseFloat((numToFormat / 100).toFixed(2)).toString();
    }
  };

  const [value, setValue] = useInput({
    value: getInputValue({
      value: initialValue?.value ?? '0',
      formatting: initialValue?.formatting,
    }),
    validation: /^.*$/,
    valid: true,
  });

  const initialValueType = useMemo(() => {
    if (initialValue.date) {
      if (
        initialValue &&
        'actuals' in initialValue &&
        initialValue.actuals?.findIndex((actual) => isSameMonth(actual.date, initialValue.date as string)) !== -1
      ) {
        return IValueType.Actual;
      } else if (
        initialValue &&
        'overrides' in initialValue &&
        initialValue.overrides?.findIndex((override) => isSameMonth(override.date, initialValue.date as string)) !== -1
      ) {
        return IValueType.Override;
      }
    }
    return IValueType.Calculated;
  }, [initialValue]);

  const currentValueType = useMemo(() => {
    const valueType = initialValueType;
    const formattedInitialValue = getInputValue({
      value: initialValue?.value ?? '0',
      formatting: initialValue?.formatting,
    });
    if (value.value && value.value !== formattedInitialValue) {
      if (isBefore(startOfMonth(initialValue.date as string), startOfMonth(date()))) {
        return IValueType.Actual;
      } else if (isSameMonth(initialValue.date as string, date()) || isAfter(initialValue.date as string, date())) {
        return IValueType.Override;
      }
    }

    return valueType;
  }, [initialValue, initialValueType, value]);

  useEffect(() => {
    if (currentValueType !== initialValueType) {
      const currentFoundOverride = overridesList[initialValue.uuid as string]?.find(
        (override) => override.date === initialValue.date,
      );
      const currentFoundActual = actualsList[initialValue.uuid as string]?.find(
        (actual) => actual.date === initialValue.date,
      );

      const isUpdatingActual = currentValueType === IValueType.Actual && !currentFoundActual;
      const isUpdatingOverride = currentValueType === IValueType.Override && !currentFoundOverride;
      if (isUpdatingActual || isUpdatingOverride) {
        setValue((prev) => ({
          ...prev,
          value: getInputValue({
            value: initialValue.value,
            formatting: initialValue.formatting,
          }),
        }));
      }
    } else {
      const currentFoundOverride = overridesList[initialValue.uuid as string]?.find(
        (override) => override.date === initialValue.date,
      );
      const currentFoundActual = actualsList[initialValue.uuid as string]?.find(
        (actual) => actual.date === initialValue.date,
      );
      const formattedValue =
        initialValue.formatting === IFormattingEnum.Percent ? Number(value.value) / 100 : Number(value.value);

      if (currentValueType === IValueType.Actual && currentFoundActual && currentFoundActual.value !== formattedValue) {
        setValue((prev) => ({
          ...prev,
          value: getInputValue({
            value: (currentFoundActual.value * 100).toString(),
            formatting: initialValue.formatting,
          }),
        }));
      }

      if (
        currentValueType === IValueType.Override &&
        currentFoundOverride &&
        currentFoundOverride.value !== formattedValue
      ) {
        setValue((prev) => ({
          ...prev,
          value: getInputValue({
            value: (currentFoundOverride.value * 100).toString(),
            formatting: initialValue.formatting,
          }),
        }));
      }

      if (
        currentValueType !== IValueType.Override &&
        currentValueType !== IValueType.Actual &&
        currentFoundActual &&
        currentFoundActual.value !== formattedValue
      ) {
        setValue((prev) => ({
          ...prev,
          value: getInputValue({
            value: (currentFoundActual.value * 100).toString(),
            formatting: initialValue.formatting,
          }),
        }));
      }

      if (
        currentValueType !== IValueType.Override &&
        currentValueType !== IValueType.Actual &&
        currentFoundOverride &&
        currentFoundOverride.value !== formattedValue
      ) {
        setValue((prev) => ({
          ...prev,
          value: getInputValue({
            value: (currentFoundOverride.value * 100).toString(),
            formatting: initialValue.formatting,
          }),
        }));
      }
    }
  }, [overridesList, actualsList]);

  useEffect(() => {
    if (
      selectedMonthCell &&
      selectedMonthCell?.colIndex === initialValue?.colIndex &&
      selectedMonthCell?.rowIndex === initialValue?.rowIndex
    ) {
      inputRef.current?.focus();
      inputRef.current?.select();
    }
  }, [selectedMonthCell]);

  const handleUpdateOverrideList = (): void => {
    setOverridesList((prevOverridesList) => {
      const overrides: IFormulaOverride[] =
        initialValue &&
        'uuid' in initialValue &&
        initialValue.uuid &&
        Object.keys(prevOverridesList).includes(initialValue.uuid) &&
        prevOverridesList[initialValue.uuid].length
          ? [...prevOverridesList[initialValue.uuid]]
          : [];

      const foundOverrideIndex = overrides.findIndex((override) => override.date === initialValue.date);

      const formattedValue =
        initialValue.formatting === IFormattingEnum.Percent ? Number(value.value) / 100 : Number(value.value);

      const formattedInitialValue = getInputValue({
        value: initialValue.value,
        formatting: initialValue.formatting,
      });

      if (!value.value && foundOverrideIndex !== -1) {
        overrides.splice(foundOverrideIndex, 1);
      } else if (value.value && value.value !== formattedInitialValue && foundOverrideIndex === -1) {
        overrides.push({
          date: initialValue.date as string,
          value: formattedValue,
        });
      } else if (foundOverrideIndex >= 0) {
        overrides[foundOverrideIndex].value = formattedValue;
      }

      const newOverridesList = { ...prevOverridesList };
      if (initialValue?.uuid) {
        newOverridesList[initialValue.uuid] = overrides;
      }

      return newOverridesList;
    });
  };

  const handleUpdateActualList = (): void => {
    setActualsList((prevActualsList) => {
      const actuals: IFormulaActual[] =
        initialValue?.uuid &&
        Object.keys(prevActualsList).includes(initialValue.uuid) &&
        prevActualsList[initialValue.uuid].length
          ? [...prevActualsList[initialValue.uuid]]
          : [];

      const foundActualIndex = actuals.findIndex((actual) => actual.date === initialValue.date);

      const formattedValue =
        initialValue.formatting === IFormattingEnum.Percent ? Number(value.value) / 100 : Number(value.value);

      const formattedInitialValue = getInputValue({
        value: initialValue.value,
        formatting: initialValue.formatting,
      });

      if (!value.value && foundActualIndex !== -1) {
        actuals.splice(foundActualIndex, 1);
      } else if (value.value && value.value !== formattedInitialValue && foundActualIndex === -1) {
        actuals.push({
          date: initialValue.date as string,
          value: formattedValue,
        });
      } else if (foundActualIndex >= 0) {
        actuals[foundActualIndex].value = formattedValue;
      }

      const newActualsList = { ...prevActualsList };
      if (initialValue?.uuid) {
        newActualsList[initialValue.uuid] = actuals;
      }

      return newActualsList;
    });
  };

  const handleOnBlur = useCallback(async (): Promise<void> => {
    if (!value.value && currentValueType === IValueType.Calculated) {
      setValue((prev) => ({
        ...prev,
        value: getInputValue({
          value: initialValue.value,
          formatting: initialValue.formatting,
        }),
      }));
    } else if (
      (initialValue?.date && (isSameMonth(initialValue.date, date()) || isAfter(initialValue.date, date()))) ||
      currentValueType === IValueType.Override
    ) {
      handleUpdateOverrideList();
    } else if (
      (initialValue?.date && isBefore(startOfMonth(initialValue.date), startOfMonth(date()))) ||
      currentValueType === IValueType.Actual
    ) {
      handleUpdateActualList();
    }

    setSelectedMonthCell((prev) => {
      delete prev.colIndex;
      delete prev.rowIndex;
      return prev;
    });
  }, [initialValue, value]);

  const moveFocus = ({ colIndex, rowIndex }: { colIndex: number; rowIndex: number }): void => {
    if (
      (selectedMonthCell && (selectedMonthCell.colIndex !== colIndex || selectedMonthCell.rowIndex !== rowIndex)) ||
      !selectedMonthCell
    ) {
      setSelectedMonthCell((prev) => {
        if (prev.colIndex === colIndex && prev.rowIndex === rowIndex) {
          return prev;
        }

        let newColIndex = colIndex;
        let newRowIndex = rowIndex;

        if (newRowIndex > prev.maxRow) {
          newRowIndex = prev.minRow;
          if (newColIndex + 1 > prev.maxCol) {
            newColIndex = prev.minCol;
          } else {
            newColIndex += 1;
          }
        }

        if (newRowIndex < prev.minRow) {
          newRowIndex = prev.maxRow;
          if (newColIndex - 1 < prev.minCol) {
            newColIndex = prev.maxCol;
          } else {
            newColIndex -= 1;
          }
        }

        if (newColIndex > prev.maxCol) {
          newColIndex = prev.minCol;
          if (newRowIndex + 1 > prev.maxRow) {
            newRowIndex = prev.minRow;
          } else {
            newRowIndex += 1;
          }
        }

        if (newColIndex < prev.minCol) {
          newColIndex = prev.maxCol;
          if (newRowIndex - 1 < prev.minRow) {
            newRowIndex = prev.maxRow;
          } else {
            newRowIndex -= 1;
          }
        }

        return {
          ...prev,
          colIndex: newColIndex,
          rowIndex: newRowIndex,
        };
      });
    }
  };

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent): void => {
      if (event.key === 'Enter') {
        event.preventDefault();
        handleOnBlur();
        if (initialValue.colIndex && initialValue.rowIndex) {
          moveFocus({
            colIndex: initialValue.colIndex,
            rowIndex: event.shiftKey ? initialValue.rowIndex - 1 : initialValue.rowIndex + 1,
          });
        }
      } else if (event.key === 'Escape') {
        if ((Number(initialValue.value) / 100).toString() !== value.value) {
          setValue((prev) => ({
            ...prev,
            value: getInputValue({
              value: initialValue.value,
              formatting: initialValue.formatting,
            }),
          }));
          inputRef.current?.focus();
        } else {
          inputRef.current?.blur();
        }
      } else if (event.key === 'Tab' || (event.key === 'Tab' && event.shiftKey)) {
        event.preventDefault();
        handleOnBlur();
        if (initialValue.colIndex && initialValue.rowIndex) {
          moveFocus({
            colIndex: event.shiftKey ? initialValue.colIndex - 1 : initialValue.colIndex + 1,
            rowIndex: initialValue.rowIndex,
          });
        }
      }
    },
    [initialValue, value],
  );

  const handlePaste = useCallback(
    (event: React.ClipboardEvent): void => {
      event.preventDefault();
      if (initialValue.uuid) {
        const currentRowOverrides = [...(overridesList[initialValue.uuid] ?? [])];
        const currentRowActuals = [...(actualsList[initialValue.uuid] ?? [])];
        const initialMonth = initialValue.date;
        if (!initialMonth) return;

        const spreadsheetData = parseSpreadsheetData({
          pasteData: event.clipboardData.getData('text'),
        });

        const today = startOfMonth(date());

        spreadsheetData[0].forEach((value, index) => {
          // the presence of an empty string indicates that invalid data was in the paste, so maintain the original value
          if (value === '') return;

          const month = endOfMonth(addMonths(date(initialMonth), index));
          const formattedMonth = formatDateToISO({ date: month });

          if (isBefore(startOfMonth(month), today)) {
            const actualIndex = currentRowActuals.findIndex((actual) => actual.date === formattedMonth);
            if (actualIndex !== -1) {
              currentRowActuals[actualIndex] = {
                ...currentRowActuals[actualIndex],
                value:
                  initialValue.formatting === IFormattingEnum.Percent ? parseFloat(value) / 100 : parseFloat(value),
              };
            } else {
              currentRowActuals.push({
                date: formattedMonth,
                value:
                  initialValue.formatting === IFormattingEnum.Percent ? parseFloat(value) / 100 : parseFloat(value),
              });
            }
          } else {
            const overrideIndex = currentRowOverrides.findIndex((override) => override.date === formattedMonth);
            if (overrideIndex !== -1) {
              currentRowOverrides[overrideIndex] = {
                ...currentRowOverrides[overrideIndex],
                value:
                  initialValue.formatting === IFormattingEnum.Percent ? parseFloat(value) / 100 : parseFloat(value),
              };
            } else {
              currentRowOverrides.push({
                date: formattedMonth,
                value:
                  initialValue.formatting === IFormattingEnum.Percent ? parseFloat(value) / 100 : parseFloat(value),
              });
            }
          }
        });

        const newOverridesList = {
          ...overridesList,
          [initialValue.uuid]: currentRowOverrides,
        };
        const newActualsList = {
          ...actualsList,
          [initialValue.uuid]: currentRowActuals,
        };
        setOverridesList(newOverridesList);
        setActualsList(newActualsList);
      }
    },
    [initialValue, overridesList, actualsList],
  );

  const inputClassName = useMemo((): {
    className: string;
    color: 'orange' | 'actualBlue' | 'empty' | 'primary';
  } => {
    if (currentValueType === IValueType.Actual) {
      return { className: 'text-orange', color: 'orange' };
    } else if (currentValueType === IValueType.Override) {
      return { className: 'text-[#3C98C0]', color: 'actualBlue' };
    } else if (initialValue.date && isBefore(startOfMonth(initialValue.date), startOfMonth(date()))) {
      return { className: 'text-neutral-200', color: 'empty' };
    } else {
      return { className: 'text-neutral-800', color: 'primary' };
    }
  }, [currentValueType, initialValue]);

  const formattedValue = useMemo(() => {
    if (value.value === '') return '';
    switch (initialValue?.formatting) {
      case IFormattingEnum.Currency:
        return formatCurrency(Number(value.value) * 100, initialValue.roundingInstructions ? false : true);
      case IFormattingEnum.Percent:
        return `${value.value}%`;
      case IFormattingEnum.Number:
      default:
        return numberWithCommas(Number(value.value));
    }
  }, [initialValue?.formatting, value.value]);

  return (
    <div
      className="w-[130px] max-w-[130px] text-right px-1"
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        if (initialValue?.colIndex && initialValue?.rowIndex) {
          moveFocus({
            colIndex: initialValue.colIndex,
            rowIndex: initialValue.rowIndex,
          });
        }
      }}
      onPaste={(e) => handlePaste(e as unknown as React.ClipboardEvent)}
    >
      {selectedMonthCell?.colIndex === initialValue?.colIndex &&
      selectedMonthCell?.rowIndex === initialValue?.rowIndex ? (
        <NumericFormat
          getInputRef={inputRef}
          data-testid={`${cellContext.column.id}-${initialValue.formulaTitle}-input`}
          className={`${inputClassName.className} !border-transparent hover:!border hover:!border-neutral-100 !shadow-none text-right 
          px-[0.57rem] h-[42px] w-full border border-solid border-gray-300  disabled:bg-neutral-25 disabled:text-neutral-75 
          focus:outline-none focus-visible:border-green-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 
          focus-visible:ring-offset-2 focus-visible:ring-offset-green-300 rounded shadow-sm`}
          disabled={value.disabled}
          allowLeadingZeros
          allowNegative={initialValue.formatting === IFormattingEnum.Percent ? false : true}
          value={value.value}
          onValueChange={(values) => {
            setValue((prev) => ({
              ...prev,
              value: values.value,
            }));
          }}
          onBlur={handleOnBlur}
          suffix={initialValue.formatting === IFormattingEnum.Percent ? '%' : undefined}
          onKeyDown={handleKeyDown}
        />
      ) : (
        <div
          data-testid={`${cellContext.column.id}-${initialValue.formulaTitle}-value`}
          className={`w-full h-full cursor-text border border-white hover:border hover:border-neutral-100 p-2 rounded`}
        >
          {value.value === '' ? (
            <div className="h-6 max-h-6 min-h-6 w-full max-w-full min-w-full"></div>
          ) : (
            <Typography color={inputClassName.color}>{formattedValue}</Typography>
          )}
        </div>
      )}
    </div>
  );
};

export default MonthCell;
