import React, { createContext, useEffect, useState } from 'react';
import { IConversation, IMessageRoleEnum } from '~/pages/Dashboard/entity/types';
import * as api from '~/services/parallel';
import { useSelector } from 'react-redux';
import { State } from '~/store';
import logger from '~/utils/logger';
import toast from 'react-hot-toast';

interface IChatContext {
  allConversations: IConversation[];
  setAllConversations: React.Dispatch<React.SetStateAction<IConversation[]>>;
  selectedConversationUuid: string | null;
  setSelectedConversationUuid: (conversationUuid: string | null) => Promise<void>;
  allConversationsDict: Record<string, IConversation>;
  sendMessage: ({ conversationUuid, message }: { conversationUuid: string | null; message: string }) => Promise<void>;
  isSendingMessage: boolean;
  focusTextInput: number;
  isLoading: boolean;
}

const initialContext: IChatContext = {
  allConversations: [],
  setAllConversations: () => {},
  selectedConversationUuid: null,
  setSelectedConversationUuid: () => Promise.resolve(),
  allConversationsDict: {},
  sendMessage: () => Promise.resolve(),
  isSendingMessage: false,
  focusTextInput: 0,
  isLoading: true,
};

export const ChatContext = createContext<IChatContext>(initialContext);

export const ChatContextProvider = ({ children }: { children: React.ReactNode }): React.ReactElement => {
  const organizationUuid = useSelector((state: State) => state.organization.uuid);
  const [allConversations, setAllConversations] = useState<IConversation[]>([]);
  const [selectedConversationUuid, setSelectedConversationUuid] = useState<string | null>(null);
  const [allConversationsDict, setAllConversationsDict] = useState<Record<string, IConversation>>({});
  const [isSendingMessage, setIsSendingMessage] = useState<boolean>(false);
  const [focusTextInput, setFocusTextInput] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [hasConversationError, setHasConversationError] = useState<boolean>(false);

  const fetchAllConversations = async (organizationUuid: string): Promise<void> => {
    try {
      setIsLoading(true);
      const conversations = await api.chat.getConversations({ organizationUuid });
      setAllConversations(conversations);
    } catch (error) {
      if (error instanceof Error) logger.error(error);
      toast.error('Failed to fetch conversations');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAllConversations(organizationUuid);
  }, [organizationUuid]);

  useEffect(() => {
    if (hasConversationError) {
      const messages = allConversationsDict[selectedConversationUuid ?? 'PLACEHOLDER'].messages;
      messages.push({
        role: IMessageRoleEnum.System,
        content: 'Unexpected error occurred. Parallel has been notified, please refresh or try again later.',
        isError: true,
      });
      setAllConversationsDict((prev) => ({
        ...prev,
        [selectedConversationUuid ?? 'PLACEHOLDER']: { ...prev[selectedConversationUuid ?? 'PLACEHOLDER'], messages },
      }));
    }
  }, [hasConversationError]);

  useEffect(() => {
    const conversationDict = allConversations.reduce((acc: Record<string, IConversation>, conversation) => {
      acc[conversation.uuid] = conversation;
      return acc;
    }, {});
    setAllConversationsDict({
      ...conversationDict,
      PLACEHOLDER: {
        uuid: 'PLACEHOLDER',
        title: 'Placeholder',
        isRead: false,
        createdBy: '',
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        messages: [],
        status: 'ok',
      },
    });
  }, [allConversations]);

  const fetchSelectedConversation = async (conversationUuid: string | null): Promise<void> => {
    try {
      if (!conversationUuid) {
        setSelectedConversationUuid(null);
      }

      if (conversationUuid && conversationUuid in allConversationsDict) {
        setSelectedConversationUuid(conversationUuid);
      } else if (conversationUuid) {
        const conversation = await api.chat.getConversation({ organizationUuid, conversationUuid });
        setAllConversationsDict((prev) => ({ ...prev, [conversationUuid]: conversation }));
        setSelectedConversationUuid(conversationUuid);
      }
      setFocusTextInput((prev) => prev + 1);
    } catch (error) {
      if (error instanceof Error) logger.error(error);
      toast.error('Failed to fetch conversation');
    }
  };

  const sendMessage = async ({
    conversationUuid,
    message,
  }: {
    conversationUuid: string | null;
    message: string;
  }): Promise<void> => {
    try {
      setIsSendingMessage(true);
      const messageToAdd = {
        role: IMessageRoleEnum.User,
        content: message,
      };
      const selectedConversation = allConversationsDict[conversationUuid ?? 'PLACEHOLDER'];
      const messages = conversationUuid ? [...selectedConversation.messages, messageToAdd] : [messageToAdd];
      selectedConversation.messages = messages;
      if (!conversationUuid) {
        selectedConversation.title = message;
      }
      setAllConversationsDict((prev) => ({ ...prev, [conversationUuid ?? 'PLACEHOLDER']: selectedConversation }));
      setSelectedConversationUuid(conversationUuid ?? 'PLACEHOLDER');
      const conversation = await api.chat.sendMessage({ organizationUuid, conversationUuid, message });
      setHasConversationError(conversation.status === 'error');
      setAllConversationsDict((prev) => ({ ...prev, [conversation.uuid]: conversation }));
      if (!conversationUuid) {
        setAllConversations((prev) => [conversation, ...prev]);
        setSelectedConversationUuid(conversation.uuid);
      }
    } catch (error) {
      if (error instanceof Error) logger.error(error);
      setHasConversationError(true);
    } finally {
      setIsSendingMessage(false);
    }
  };

  return (
    <ChatContext.Provider
      value={{
        allConversations,
        setAllConversations,
        selectedConversationUuid,
        setSelectedConversationUuid: fetchSelectedConversation,
        allConversationsDict,
        sendMessage,
        isSendingMessage,
        focusTextInput,
        isLoading,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};
