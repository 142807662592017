import React, { ReactElement, useEffect, useMemo } from 'react';
import FormulasTable from '~/components/Formulas/FormulasTable';
import useFormulaContext from '~/components/Formulas/context/useFormulaContext';
import { IColumn } from '~/components/Formulas/FormulasTable/types';
import ModelBuilderExpression from '~/components/Formulas/ExpressionBuilder';
import MonthCell from '~/components/Formulas/MonthValueEditor/MonthCell';
import Typography from '~/components/Typography';
import ColumnResize from '~/components/Formulas/FormulasTable/ColumnResize';
import ExpenseLabel from './components/CustomTableColumns/ExpenseLabel';

const ExpenseFormulasTable = ({ reload }: { reload: () => void }): React.ReactElement => {
  const { filteredFormulasData, onDragEnd, selectedMonths, scrollEnabled, refreshData } = useFormulaContext();

  useEffect(() => {
    refreshData();
  }, [reload]);

  const columns = useMemo<IColumn[]>(() => {
    return [
      {
        key: 'label',
        Header: ({ columnWidth }: { columnWidth: number; columnIndex: number }): React.ReactElement => (
          <div
            className={`flex sticky top-0 left-0 border-b bg-white border-neutral-50 pl-4 pt-2 z-20 border-r`}
            style={{
              width: `${columnWidth}px`,
              minWidth: `${columnWidth}px`,
            }}
          >
            <Typography size="xs" color="lightGray">
              NAME
            </Typography>
            <ColumnResize columnIndex={0} />
          </div>
        ),
        Cell: ExpenseLabel,
      },
      {
        key: 'formula',
        Header: ({
          columnWidth,
          style,
        }: {
          columnWidth: number;
          columnIndex: number;
          style?: React.CSSProperties;
        }): React.ReactElement => (
          <div
            className={`flex sticky top-0 left-0 border-b bg-white border-neutral-50 pl-4 pt-2 z-20 border-r`}
            style={{
              width: `${columnWidth}px`,
              minWidth: `${columnWidth}px`,
              boxShadow: '6px 0px 8px rgba(0, 0, 0, 0.03)',
              ...style,
            }}
          >
            <Typography size="xs" color="lightGray">
              FORMULA
            </Typography>
            <ColumnResize columnIndex={1} />
          </div>
        ),
        Cell: ModelBuilderExpression,
        viewOnly: true,
      },
      ...selectedMonths.map((month) => ({
        key: `month-${month}`,
        Header: ({ columnWidth, columnIndex }: { columnWidth: number; columnIndex: number }): ReactElement => {
          const LEADING_COLUMN_COUNT = 2;
          const month = selectedMonths[columnIndex - LEADING_COLUMN_COUNT];
          const showYearDivider = month.includes('Dec');
          return (
            <div
              className={`flex justify-end items-end py-2 bg-white relative border-b border-neutral-50 px-4${showYearDivider ? ' border-r' : ''}`}
              style={{ width: `${columnWidth}px`, minWidth: `${columnWidth}px` }}
            >
              <Typography size="xs" color="lightGray">
                {month.toUpperCase()}
              </Typography>
            </div>
          );
        },
        Cell: MonthCell,
      })),
    ];
  }, [selectedMonths]);

  return (
    <div
      className={`relative max-w-full flex flex-col justify-between 'h-[calc(100vh-162px)] ${scrollEnabled.x ? 'overflow-x-auto' : 'overflow-x-hidden overscroll-x-none'} ${scrollEnabled.y ? 'overflow-y-auto' : 'overflow-y-hidden overscroll-y-none'}`}
      data-testid="expense-formulas-table"
    >
      <FormulasTable columns={columns} data={filteredFormulasData} onDragEnd={onDragEnd} />
    </div>
  );
};

export default ExpenseFormulasTable;
