import { handleTraverseFormulaList } from './handleTraverseFormulaList';
import { commonOperations } from './commonOperations';
import updateFormulaAndVariables from '~/pages/FinancialModelDeprecated/utils/updateFormulaAndVariables';
import { z } from 'zod';
import { handleAddOperator } from './handleAddOperator';
import { IFormula } from '~/services/parallel/formulas.types';
import { IFormulaSegment, IVariables } from '~/pages/FinancialModelDeprecated/entity/types';

const upArrow = ({
  filteredFormulaList,
  setHighlightedFormula,
  setInputPosition,
  value,
}: {
  filteredFormulaList: IFormula[];
  setHighlightedFormula: React.Dispatch<React.SetStateAction<{ formula?: IFormula; index: number }>>;
  setInputPosition: React.Dispatch<React.SetStateAction<number>>;
  value: string;
}): void => {
  if (filteredFormulaList.length && value.length) {
    handleTraverseFormulaList({
      filteredFormulaList,
      setHighlightedFormula,
      traversalDirection: 'up',
    });
  } else if (!value.length) {
    setInputPosition(0);
  }
};

export const downArrow = ({
  filteredFormulaList,
  setHighlightedFormula,
  setInputPosition,
  endPosition,
  value,
}: {
  filteredFormulaList: IFormula[];
  setHighlightedFormula: React.Dispatch<React.SetStateAction<{ formula?: IFormula; index: number }>>;
  setInputPosition: React.Dispatch<React.SetStateAction<number>>;
  endPosition: number;
  value: string;
}): void => {
  if (filteredFormulaList.length && value.length) {
    handleTraverseFormulaList({
      filteredFormulaList,
      setHighlightedFormula,
      traversalDirection: 'down',
    });
  } else if (!value.length) {
    setInputPosition(endPosition);
  }
};

export const rightArrow = ({
  position,
  segmentToDelete,
  inputPosition,
  setInputPosition,
  updatedFormula,
  setUpdatedFormula,
  setFormula,
  variables,
  setVariables,
  value,
  setValue,
  setEnteredConstantFrom,
  inputRef,
}: {
  position?: 'beginning' | 'end';
  segmentToDelete?: {
    segmentRef: React.RefObject<HTMLDivElement>;
    segmentIndex: number;
    segmentValue: string;
  };
  inputPosition: number;
  setInputPosition: React.Dispatch<React.SetStateAction<number>>;
  updatedFormula: IFormulaSegment[];
  setUpdatedFormula: React.Dispatch<React.SetStateAction<IFormulaSegment[]>>;
  setFormula: React.Dispatch<React.SetStateAction<IFormulaSegment[]>>;
  variables: IVariables;
  setVariables: React.Dispatch<React.SetStateAction<IVariables>>;
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  setEnteredConstantFrom: React.Dispatch<React.SetStateAction<'left' | 'right' | undefined>>;
  inputRef: React.RefObject<HTMLDivElement>;
}): void => {
  if (inputPosition < updatedFormula.length && !segmentToDelete && !position) {
    const selectedSegment = updatedFormula[inputPosition];
    if (selectedSegment.type === 'constant' || selectedSegment.type === 'invalid') {
      commonOperations({
        indexToDelete: inputPosition,
        variableKeyToDelete: selectedSegment.textValue,
        enteredFrom: 'left',
        updateInputPosition: (prevPosition) => Math.min(prevPosition, updatedFormula.length),
        updatedFormula,
        setUpdatedFormula,
        setFormula,
        variables,
        setVariables,
        setValue,
        setEnteredConstantFrom,
        setInputPosition,
        inputRef,
      });
    } else {
      setInputPosition((prevPosition) => Math.min(prevPosition + 1, updatedFormula.length));
    }
  } else if (segmentToDelete && !position) {
    setInputPosition(() => Math.min(Math.max(segmentToDelete.segmentIndex + 1, 0), updatedFormula.length));
  } else if (position === 'end') {
    const isValidNumber = z.number().safeParse(Number(value.trim()));
    const { newFormula, newVariables } = updateFormulaAndVariables({
      valuesToAdd: [
        {
          type: isValidNumber.success ? 'constant' : 'invalid',
          value: value.trim(),
          newIndex: inputPosition,
        },
      ],
      formulaCopy: [...updatedFormula],
      variablesCopy: { ...variables },
    });
    const segmentToSelectIndex = inputPosition + 1;
    const selectedSegment = newFormula[segmentToSelectIndex];
    if (selectedSegment && (selectedSegment.type === 'constant' || selectedSegment.type === 'invalid')) {
      commonOperations({
        indexToDelete: segmentToSelectIndex,
        variableKeyToDelete: selectedSegment.textValue,
        enteredFrom: 'left',
        updateInputPosition: () => segmentToSelectIndex,
        formulaToUse: newFormula,
        variablesToUse: newVariables,
        updatedFormula,
        setUpdatedFormula,
        setFormula,
        variables,
        setVariables,
        setValue,
        setEnteredConstantFrom,
        setInputPosition,
        inputRef,
      });
    } else {
      handleAddOperator({
        operator: null,
        value,
        setValue,
        updatedFormula,
        setUpdatedFormula,
        setFormula,
        variables,
        setVariables,
        inputPosition,
        setInputPosition,
        inputRef,
      });
    }
  }
};

const leftArrow = ({
  position,
  segmentToDelete,
  inputPosition,
  setInputPosition,
  updatedFormula,
  setUpdatedFormula,
  setFormula,
  variables,
  setVariables,
  value,
  setValue,
  setEnteredConstantFrom,
  inputRef,
}: {
  position?: 'beginning' | 'end';
  segmentToDelete?: {
    segmentRef: React.RefObject<HTMLDivElement>;
    segmentIndex: number;
    segmentValue: string;
  };
  inputPosition: number;
  setInputPosition: React.Dispatch<React.SetStateAction<number>>;
  updatedFormula: IFormulaSegment[];
  setUpdatedFormula: React.Dispatch<React.SetStateAction<IFormulaSegment[]>>;
  setFormula: React.Dispatch<React.SetStateAction<IFormulaSegment[]>>;
  variables: IVariables;
  setVariables: React.Dispatch<React.SetStateAction<IVariables>>;
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  setEnteredConstantFrom: React.Dispatch<React.SetStateAction<'left' | 'right' | undefined>>;
  inputRef: React.RefObject<HTMLDivElement>;
}): void => {
  if (inputPosition > 0 && !segmentToDelete && !position) {
    const selectedSegment = updatedFormula[inputPosition - 1];
    if (selectedSegment.type === 'constant' || selectedSegment.type === 'invalid') {
      commonOperations({
        indexToDelete: inputPosition - 1,
        variableKeyToDelete: selectedSegment.textValue,
        enteredFrom: 'right',
        updateInputPosition: (prevPosition) => Math.max(prevPosition - 1, 0),
        updatedFormula,
        setUpdatedFormula,
        setFormula,
        variables,
        setVariables,
        setValue,
        setEnteredConstantFrom,
        setInputPosition,
        inputRef,
      });
    } else {
      setInputPosition((prevPosition) => Math.max(prevPosition - 1, 0));
    }
  } else if (segmentToDelete && !position) {
    setInputPosition(() => Math.max(segmentToDelete.segmentIndex, 0));
  } else if (position === 'beginning') {
    const isValidNumber = z.number().safeParse(Number(value.trim()));
    const { newFormula, newVariables } = updateFormulaAndVariables({
      valuesToAdd: [
        {
          type: isValidNumber.success ? 'constant' : 'invalid',
          value: value.trim(),
          newIndex: inputPosition,
        },
      ],
      formulaCopy: [...updatedFormula],
      variablesCopy: { ...variables },
    });
    const segmentToSelectIndex = inputPosition - 1;
    const selectedSegment = newFormula[segmentToSelectIndex];
    if (selectedSegment.type === 'constant' || selectedSegment.type === 'invalid') {
      commonOperations({
        indexToDelete: segmentToSelectIndex,
        variableKeyToDelete: selectedSegment.textValue,
        enteredFrom: 'right',
        updateInputPosition: () => segmentToSelectIndex,
        formulaToUse: newFormula,
        variablesToUse: newVariables,
        updatedFormula,
        setUpdatedFormula,
        setFormula,
        variables,
        setVariables,
        setValue,
        setEnteredConstantFrom,
        setInputPosition,
        inputRef,
      });
    } else {
      handleAddOperator({
        operator: null,
        desiredInputIndex: inputPosition,
        value,
        setValue,
        updatedFormula,
        setUpdatedFormula,
        setFormula,
        variables,
        setVariables,
        inputPosition,
        setInputPosition,
        inputRef,
      });
    }
  }
};

export const handleArrowPresses = { upArrow, downArrow, leftArrow, rightArrow };
