import React, { useEffect, useState } from 'react';
import Typography from '../Typography';
import { Popover } from '@headlessui/react';
import { EditorContent, useEditor } from '@tiptap/react';
import { Markdown } from 'tiptap-markdown';
import StarterKit from '@tiptap/starter-kit';
import { useLocation } from 'react-router-dom';
import { SparklesIcon as SparklesIconOutline } from '@heroicons/react/24/outline';
import { SparklesIcon as SparklesIconSolid } from '@heroicons/react/24/solid';
import { useSelector } from 'react-redux';
import type { State } from '~/store';

export const RazzleDazzleBox = (): React.ReactNode => {
  const activeScenarioUuid = useSelector((state: State) => state.scenario.activeScenarioUuid);
  const [blurbContent, setBlurbContent] = useState<string>('');
  const location = useLocation();

  useEffect(() => {
    switch (location.pathname) {
      case '/dashboard':
        setBlurbContent('');
        break;
      case '/headcount':
        setBlurbContent(`
Insights Based on Your Current and Future Hires

**Possible Hiring Mismatch:** Planned roles skew heavily toward operations vs revenue-generating positions. At your current stage, prioritize hires that drive growth, like sales or marketing.

**Distribute Hiring Schedule:** 80% of open roles are projected to onboard in Q1, risking strain on resources.
        `);
        break;
      case '/expenses':
        setBlurbContent(`
Here are three key insights after reviewing your expenses:

**Adjust Advertising Forecast:** Align budgets with historical spending, as 20% remains consistently unused for Advertising.

**High Travel Expenses:** Expenses in the Travel category have exceeded the forecast by 30%. Adjust forecast or restrict spending.

**Vendor Overlap:** Evaluate tools like Notion, Asana, Monday.com, and Trello for redundancy.
        `);
        break;
      case '/financial-model':
        setBlurbContent('');
        break;
      default:
        setBlurbContent('');
        break;
    }
  }, [location.pathname]);

  const editor = useEditor({
    content: blurbContent,
    editable: false,
    enableInputRules: false,
    extensions: [StarterKit, Markdown],
    editorProps: {
      attributes: {
        class: 'prose is-focused w-full',
      },
    },
  });

  useEffect(() => {
    if (editor) {
      editor.commands.setContent(blurbContent);
    }
  }, [editor, blurbContent]);

  return (
    <div
      id="conversation-box"
      className={`${activeScenarioUuid && 'hidden'} ${!blurbContent.trim() && 'hidden'} fixed z-40 right-12 bottom-4 flex flex-col items-end max-sm:right-0 max-sm:items-center max-sm:w-screen`}
    >
      <Popover className="relative">
        {({ open }) => (
          <>
            {open && (
              <Popover.Panel
                static
                className="absolute bottom-full right-0 mb-6 w-[453px] rounded-lg bg-white p-4 shadow-lg ring-1 ring-black/5 max-sm:right-[10vw]"
              >
                <div className="flex gap-3">
                  <div className="flex-shrink-0 pt-1">
                    <div className="p-2 rounded-full border border-green-400 bg-green-25">
                      <SparklesIconSolid className="size-5 text-green-400" />
                    </div>
                  </div>
                  <EditorContent editor={editor} />
                </div>
              </Popover.Panel>
            )}

            <Popover.Button className="w-fit flex flex-row gap-2 bg-white py-3 px-4 rounded-full max-sm:w-[80vw] max-sm:justify-center border border-gray-200 focus:outline-none shadow-md animate-chromatic-shadow">
              <Typography color="primaryGreen">CFO Insight</Typography>
              <SparklesIconOutline className="size-6 text-green-400" />
            </Popover.Button>
          </>
        )}
      </Popover>
    </div>
  );
};
