import React, { ForwardedRef } from 'react';
import { formatCurrencyInput } from './currencyInputFormatter';

interface Props {
  id: string;
  placeholder?: string;
  value: string;
  onChange: (value: string) => void;
  onBlur?: () => void;
  disabled?: boolean;
  className: string;
  onKeyDown?: (event: React.KeyboardEvent) => void;
  required?: boolean;
  showError?: boolean;
  ref?: ForwardedRef<HTMLTextAreaElement | HTMLInputElement>;
}

const BaseCurrencyInput = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      id,
      placeholder = 'Please enter an amount',
      value,
      onChange,
      onBlur,
      disabled = false,
      className,
      onKeyDown,
      required = false,
      showError = false,
    },
    ref,
  ): React.ReactElement => {
    // Handles cursor positioning after formatting
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
      const input = e.target;
      const selectionStart = input.selectionStart ?? 0;
      const oldValue = input.value;
      const newValue = e.target.value;
      const oldFormatChars = (oldValue.slice(0, selectionStart).match(/[,]/g) || []).length;

      onChange(newValue);

      setTimeout(() => {
        const formattedValue = formatCurrencyInput({ inputValue: newValue });
        const newFormatChars = (formattedValue.slice(0, selectionStart).match(/[,]/g) || []).length;
        const adjustment = newFormatChars - oldFormatChars;

        input.setSelectionRange(selectionStart + adjustment, selectionStart + adjustment);
      }, 0);
    };

    const handleKeyDown = (event: React.KeyboardEvent): void => {
      if (event.key === '.' && value.includes('.')) {
        event.preventDefault();
        return;
      }

      if (
        !/[\d.]/.test(event.key) &&
        event.key !== 'Backspace' &&
        event.key !== 'Delete' &&
        event.key !== 'ArrowLeft' &&
        event.key !== 'ArrowRight' &&
        event.key !== 'Tab'
      ) {
        event.preventDefault();
      }
      if (onKeyDown) onKeyDown(event);
    };

    return (
      <input
        data-testid={`${id}-input`}
        name={id}
        type="text"
        value={formatCurrencyInput({
          inputValue: value,
        })}
        onChange={handleChange}
        onBlur={onBlur}
        disabled={disabled}
        required={required}
        onKeyDown={handleKeyDown}
        ref={ref}
        placeholder={placeholder}
        className={`${className} px-[0.57rem] h-[42px] w-full border border-solid ${
          showError ? 'border-red-300' : 'border-gray-300'
        } disabled:bg-neutral-25 disabled:text-neutral-75 focus:outline-none focus-visible:border-green-500 focus-visible:ring-2 
        focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-green-300 rounded 
        shadow-sm`}
        autoComplete="off"
      />
    );
  },
);

BaseCurrencyInput.displayName = 'BaseCurrencyInput';

export default BaseCurrencyInput;
