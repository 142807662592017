import React, { useState, useRef, useEffect, useMemo } from 'react';
import { createPortal } from 'react-dom';
import { Transition } from '@headlessui/react';
import Button from '~/components/Button';
import Checkbox from '~/components/Checkbox';
import SegmentedControl from '~/components/SegmentedControl';
import Select, { useSelect } from '~/components/Select';
import useFormulaContext from '~/components/Formulas/context/useFormulaContext';
import { IFormattingEnum, IFormula, IRoundDirectionEnum } from '~/services/parallel/formulas.types';
import SelectMultiple from '~/components/SelectMultiple';
import { useSelectMultiple } from '~/components/SelectMultiple';
import { isEqual } from 'lodash';
import { IIntegrationSources } from '~/utils/schemas/integrations';
import MinMaxInput from './MinMaxInput';

interface Props {
  isOpen: boolean;
  position: {
    left: number;
    top: number;
    bottom: number;
  };
  id: string;
  onClose: () => void;
  editLabel: () => void;
  formulaUuid: string;
  formula: IFormula;
  isProtected: boolean;
}

const ConfigurationPopover = ({
  isOpen,
  id,
  formula,
  formulaUuid,
  onClose,
  editLabel,
  position,
  isProtected,
}: Props): React.ReactPortal => {
  const {
    formulaDictionary,
    updateFormulaFormatting,
    updateFormulaRounding,
    updateFormulaMinMax,
    dataSources,
    updateFormulaDataSource,
    deleteFormula,
    availableIntegration,
  } = useFormulaContext();

  const [formatting, setFormatting] = useState<IFormattingEnum>(formula.formatting ?? IFormattingEnum.Number);
  const [isMinMaxValid, setIsMinMaxValid] = useState(true);
  const [roundResults, setRoundResults] = useState<boolean>(Boolean(formula.recipe.roundingInstructions));
  const prevRoundResults = useRef<boolean>(roundResults);
  const [roundingDirection, setRoundingDirection] = useState<IRoundDirectionEnum>(
    formula.recipe.roundingInstructions?.direction ?? IRoundDirectionEnum.Nearest,
  );

  const [addMinOrMax, setAddMinOrMax] = useState<boolean>(false);
  const [minOrMax, setMinOrMax] = useState<'min' | 'max'>(
    formula.recipe.min ? 'min' : formula.recipe.max ? 'max' : 'min',
  );
  const [minMaxValue, setMinMaxValue] = useState<string | null>(formula.recipe.min ?? formula.recipe.max ?? null);
  const ROUNDING_PRECISION_OPTIONS = [
    {
      label: 'Whole Number',
      value: '1',
    },
    {
      label: 'Nearest 10',
      value: '10',
    },
    {
      label: 'Nearest 100',
      value: '100',
    },
    {
      label: 'Nearest 1000',
      value: '1000',
    },
    {
      label: 'Nearest 10000',
      value: '10000',
    },
    {
      label: 'Nearest 100000',
      value: '100000',
    },
    {
      label: '1 Decimal Place',
      value: '0.1',
    },
    {
      label: '2 Decimal Places',
      value: '0.01',
    },
  ];
  const [roundingPrecision, setRoundingPrecision] = useSelect({
    options: ROUNDING_PRECISION_OPTIONS,
    selected: ROUNDING_PRECISION_OPTIONS.find(
      (option) => option.value === formula.recipe.roundingInstructions?.precision.toString(),
    ),
  });

  const [integrationMappingEnabled, setIntegrationMappingEnabled] = useState<boolean>(
    formula.dataSourceUuids.length > 0,
  );
  const [dataSourceState, setDataSourceState] = useSelectMultiple({
    options: [],
  });

  const popoverRef = useRef<HTMLDivElement>(null);

  // Submit request to update formatting when changed
  useEffect(() => {
    if (formatting !== formula.formatting) {
      updateFormulaFormatting({ formulaUuid, formatting });
    }

    if (formatting === IFormattingEnum.Percent) {
      setRoundResults(false);
      setRoundingDirection(IRoundDirectionEnum.Nearest);
    }
  }, [formatting]);

  // Submit request to update formatting when changed
  useEffect(() => {
    if (minOrMax === 'min' && minMaxValue !== (formula.recipe.min ?? null)) {
      updateFormulaMinMax({ formula, min: minMaxValue, max: null });
    } else if (minOrMax === 'max' && minMaxValue !== (formula.recipe.max ?? null)) {
      updateFormulaMinMax({ formula, min: null, max: minMaxValue });
    }
  }, [minMaxValue, minOrMax]);

  useEffect(() => {
    setMinMaxValue(formula.recipe.min ?? formula.recipe.max ?? null);
    if (formula.recipe.min) {
      setMinOrMax('min');
      setAddMinOrMax(true);
    } else if (formula.recipe.max) {
      setMinOrMax('max');
      setAddMinOrMax(true);
    } else {
      setAddMinOrMax(false);
    }
  }, [formula.recipe.min, formula.recipe.max]);

  // Set the options for datasource select component
  useEffect(() => {
    setDataSourceState((prevState) => {
      const matchingDataSources = dataSources.filter((ds) => formula.dataSourceUuids.includes(ds.uuid));
      return {
        ...prevState,
        options: [
          ...dataSources.map((ds) => ({
            label: ds.name,
            value: ds.uuid,
            disabled: ds.currentlyInUse && !formula.dataSourceUuids.includes(ds.uuid),
          })),
        ],
        selected: matchingDataSources.length
          ? matchingDataSources.map((ds) => ({
              label: ds.name,
              value: ds.uuid,
            }))
          : [],
      };
    });
  }, [dataSources]);

  // Form cleanup:Reset rounding precision and direction when round results is disabled
  useEffect(() => {
    if (!roundResults) {
      setRoundingPrecision((prevState) => ({
        ...prevState,
        selected: undefined,
      }));
      setRoundingDirection(IRoundDirectionEnum.Nearest);
    }
  }, [roundResults]);

  // Submit request to update rounding when changed
  useEffect(() => {
    if (roundResults) {
      if (
        roundingPrecision.selected?.value !== formula.recipe.roundingInstructions?.precision.toString() ||
        roundingDirection !== formula.recipe.roundingInstructions?.direction
      ) {
        updateFormulaRounding({
          formulaUuid,
          direction: roundingDirection,
          roundingPrecision: Number(roundingPrecision.selected?.value),
        });
      }
    } else if (prevRoundResults.current) {
      updateFormulaRounding({
        formulaUuid,
      });
    }
    prevRoundResults.current = roundResults;
  }, [roundResults, roundingPrecision, roundingDirection]);

  // Close the popover when clicking outside or pressing escape
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent): void => {
      if (popoverRef.current && !popoverRef.current.contains(event.target as Node)) {
        if (isMinMaxValid) {
          updateDataSource();
          onClose();
        }
      }
    };

    const handleEscapeKey = (event: KeyboardEvent): void => {
      if (event.key === 'Escape' && isMinMaxValid) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
      document.addEventListener('keydown', handleEscapeKey);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, [dataSourceState, isMinMaxValid, onClose, isOpen]);

  const updateDataSource = (): void => {
    const newDataSources: string[] =
      dataSourceState.selected?.map((ds) => ds.value).filter((value): value is string => value !== null) ?? [];
    if (!isEqual(newDataSources, formula.dataSourceUuids)) {
      updateFormulaDataSource({
        formulaUuid,
        dataSourceUuids: newDataSources,
      });
    }
  };

  const isNotReferencedInOtherFormulas = useMemo(() => {
    return !Object.values(formulaDictionary).some((f) => {
      return Object.values(f.recipe.variables).some((variable) => {
        return variable.formulaUuid === formulaUuid;
      });
    });
  }, [formulaDictionary, formulaUuid]);

  let integrationName;
  if (availableIntegration !== null) {
    integrationName = availableIntegration === IIntegrationSources.Quickbooks ? 'Quickbooks' : 'Xero';
  }

  // Determine if popover should show above or below the label
  const shouldShowAbove = useMemo(() => {
    const windowHeight = window.innerHeight;
    const spaceBelow = windowHeight - position.bottom;
    const POPOVER_MAX_HEIGHT = 480;
    return spaceBelow < POPOVER_MAX_HEIGHT && position.top > POPOVER_MAX_HEIGHT;
  }, [position.bottom]);

  return createPortal(
    <Transition
      show={isOpen}
      enter="transition ease-out duration-200"
      enterFrom="opacity-0 translate-y-1"
      enterTo="opacity-100 translate-y-0"
      leave="transition ease-in duration-150"
      leaveFrom="opacity-100 translate-y-0"
      leaveTo="opacity-0 translate-y-1"
    >
      <div
        ref={popoverRef}
        id={id}
        data-testid="configuration-popover"
        className="absolute flex-col w-[245px] bg-white z-30 rounded-lg shadow-md flex"
        style={{
          left: position.left,
          top: shouldShowAbove ? 'auto' : position.bottom + 4,
          bottom: shouldShowAbove ? window.innerHeight - position.top + 4 : 'auto',
        }}
      >
        <div className="border-b p-2">
          <SegmentedControl
            name="format-control"
            value={formatting}
            setValue={(val) => {
              setFormatting(val as IFormattingEnum);
            }}
            segments={[
              { value: IFormattingEnum.Number, label: '#' },
              { value: IFormattingEnum.Currency, label: '$' },
              { value: IFormattingEnum.Percent, label: '%' },
            ]}
          />
        </div>
        <div className="border-b">
          <div className="flex flex-col gap-2 p-2">
            <Checkbox
              disabled={formatting === IFormattingEnum.Percent}
              id={`roundResults-${formula.recipe.name}`}
              label="Round results"
              checked={roundResults}
              toggleValue={() => setRoundResults(!roundResults)}
            />
            {roundResults && (
              <SegmentedControl
                name="round-direction"
                value={roundingDirection}
                setValue={(val) => {
                  setRoundingDirection(val as IRoundDirectionEnum);
                }}
                segments={[
                  { value: IRoundDirectionEnum.Nearest, label: 'Nearest' },
                  { value: IRoundDirectionEnum.Down, label: 'Down' },
                  { value: IRoundDirectionEnum.Up, label: 'Up' },
                ]}
              />
            )}
            {roundResults && (
              <Select
                id={`roundingPrecision-${formula.recipe.name}`}
                placeholder="Please select"
                state={roundingPrecision}
                setState={setRoundingPrecision}
              />
            )}
          </div>
        </div>
        <div className="border-b">
          <div className="flex flex-col gap-2 p-2">
            <Checkbox
              disabled={formatting === IFormattingEnum.Percent}
              id={`add-min-or-max-${id}`}
              label="Add min/max value"
              checked={addMinOrMax}
              toggleValue={() => {
                if (addMinOrMax) {
                  setMinMaxValue(null);
                  setIsMinMaxValid(true);
                }
                setAddMinOrMax(!addMinOrMax);
              }}
            />
            {addMinOrMax && (
              <div className="flex flex-col gap-2">
                <SegmentedControl
                  name="min-or-max"
                  value={minOrMax}
                  setValue={(val) => {
                    setMinOrMax(val as 'min' | 'max');
                  }}
                  segments={[
                    { value: 'min', label: 'Min' },
                    { value: 'max', label: 'Max' },
                  ]}
                />
                <MinMaxInput
                  onValidationChange={(valid) => setIsMinMaxValid(valid)}
                  minMaxValue={minMaxValue}
                  setMinMaxValue={setMinMaxValue}
                />
              </div>
            )}
          </div>
        </div>
        {availableIntegration && (
          <div className="border-b">
            <div className="p-2 flex flex-col gap-2">
              <Checkbox
                id={`integrationMapping-${id}`}
                label={`Connect to ${integrationName}`}
                checked={integrationMappingEnabled}
                toggleValue={() => {
                  setIntegrationMappingEnabled(!integrationMappingEnabled);
                  updateFormulaDataSource({
                    formulaUuid,
                    dataSourceUuids: [],
                  });
                }}
              />
              {integrationMappingEnabled && (
                <SelectMultiple
                  id="connected-data-source"
                  state={dataSourceState}
                  setState={setDataSourceState}
                  label="Connected Data Source"
                  className="!w-auto"
                />
              )}
            </div>
          </div>
        )}
        <div className="flex-col py-1">
          <Button fill="clear" className="!justify-start !px-4" onClick={editLabel} disabled={isProtected}>
            Rename
          </Button>
          <Button
            disabled={!isNotReferencedInOtherFormulas || isProtected}
            fill="destructiveClear"
            className="!justify-start !px-4"
            onClick={() => {
              onClose();
              deleteFormula(formulaUuid);
            }}
          >
            Delete
          </Button>
        </div>
      </div>
    </Transition>,
    document.body,
  );
};

export default ConfigurationPopover;
