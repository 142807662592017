import React, { createContext, ReactElement, RefObject, useEffect, useMemo, useState } from 'react';
import { IExpense } from '~/pages/ExpensesDeprecated/components/types';
import { IDepartmentDict } from '~/pages/Settings/Departments/entity/types';
import { IConsolidatedReportCollection } from '~/pages/Dashboard/entity/types';
import { IPositionDetails } from '~/pages/Headcount/entity/types';
import { useSelector } from 'react-redux';
import { State } from '~/store';
import { useExpensesData } from '../useExpensesData';
import { useSelect } from '~/components/Select';
import { useInput } from '~/components/Input';
import { SelectState } from '~/components/Select/Select.types';
import { IIntegrationMapping, IIntegration } from '~/services/parallel/integrations.types';
import * as api from '~/services/parallel';
import useExpenseFormState, { IExpenseFormState } from '../components/CreateExpense/useExpenseFormState';
import { IStringDate } from '~/utils/stringDate/types';
import { IFormulaTypeEnum, IRecipeVariables } from '~/services/parallel/formulas.types';
import { IFormula } from '~/services/parallel/formulas.types';

interface IProps {
  children: React.ReactNode;
}

export interface IUpdatedFormula {
  element: ReactElement;
  ref: RefObject<HTMLDivElement>;
  textValue: string;
  type: 'constant' | 'formula' | 'operator' | 'invalid' | 'calculated';
}

interface IExpensesPageContext {
  expenses: IExpense[];
  setExpenses: React.Dispatch<React.SetStateAction<IExpense[]>>;
  departmentDict: IDepartmentDict;
  expenseModal: boolean;
  setExpenseModal: (value: boolean) => void;
  expenseUuid?: {
    uuid: string;
    expenseUuid: string;
    type: 'edit' | 'create';
  };
  setExpenseUuid: (uuid: { uuid: string; expenseUuid: string; type: 'edit' | 'create' } | undefined) => void;
  discontinueExpense?: { uuid: string; expenseUuid: string; startDate: string; expenseTitle?: string };
  setDiscontinueExpense: (
    value: { uuid: string; expenseUuid: string; startDate: string; expenseTitle?: string } | undefined,
  ) => void;
  deleteExpense?: { uuid: string; expenseUuid: string; expenseTitle?: string };
  setDeleteExpense: (value: { uuid: string; expenseUuid: string; expenseTitle?: string } | undefined) => void;
  reload: () => void;
  reports: IConsolidatedReportCollection;
  lockedDate: IStringDate | undefined;
  setLockedDate: (date: IStringDate | undefined) => void;
  searchByName: string;
  setSearchByName: (name: string) => void;
  positions: IPositionDetails[];
  loading: boolean;
  revalidateFilteredExpensesReport: () => void;
  revalidateFormulaList: () => void;
  filteredExpensesReportLoading: boolean;
  showPastExpenses: boolean;
  setShowPastExpenses: (value: boolean) => void;
  categories: SelectState;
  setCategories: React.Dispatch<React.SetStateAction<SelectState>>;
  types: SelectState;
  setTypes: React.Dispatch<React.SetStateAction<SelectState>>;
  searchState: Types.InputState;
  setSearchState: React.Dispatch<React.SetStateAction<Types.InputState>>;
  formulaList: IFormula[];
  setFormulaList: React.Dispatch<React.SetStateAction<IFormula[]>>;
  variables: IRecipeVariables;
  setVariables: React.Dispatch<React.SetStateAction<IRecipeVariables>>;
  displayFormulaError: {
    isDisplayed: boolean;
    message: string;
  };
  setDisplayFormulaError: React.Dispatch<
    React.SetStateAction<{
      isDisplayed: boolean;
      message: string;
    }>
  >;
  formula: IUpdatedFormula[];
  setFormula: React.Dispatch<React.SetStateAction<IUpdatedFormula[]>>;
  updatedFormula: IUpdatedFormula[];
  setUpdatedFormula: React.Dispatch<React.SetStateAction<IUpdatedFormula[]>>;
  formulaValue: string;
  setFormulaValue: React.Dispatch<React.SetStateAction<string>>;
  connectedIntegrations: IIntegration[];
  autoGeneratedExpenses: boolean;
  setAutoGeneratedExpenses: React.Dispatch<React.SetStateAction<boolean>>;
  proposedExpenses: IExpense[];
  setProposedExpenses: React.Dispatch<React.SetStateAction<IExpense[]>>;
  recommendedExpenses: IExpense[];
  setRecommendedExpenses: React.Dispatch<React.SetStateAction<IExpense[]>>;
  desiredCreationStatus: string;
  setDesiredCreationStatus: React.Dispatch<React.SetStateAction<string>>;
  expenseIntegrationMappings: IIntegrationMapping[];
  setExpenseIntegrationMappings: React.Dispatch<React.SetStateAction<IIntegrationMapping[]>>;
  formState: IExpenseFormState;
  isProposedExpenseLoadingDictionary: Record<string, boolean>;
  setIsProposedExpenseLoadingDictionary: React.Dispatch<React.SetStateAction<Record<string, boolean>>>;
  expensesDictionary: Record<string, IExpense>;
}

const initialContext: IExpensesPageContext = {
  expenses: [],
  setExpenses: () => {},
  departmentDict: {},
  expenseModal: false,
  setExpenseModal: () => {},
  expenseUuid: undefined,
  setExpenseUuid: () => {},
  discontinueExpense: undefined,
  setDiscontinueExpense: () => {},
  deleteExpense: undefined,
  setDeleteExpense: () => {},
  reload: () => {},
  reports: {} as IConsolidatedReportCollection,
  lockedDate: undefined,
  setLockedDate: () => {},
  searchByName: '',
  setSearchByName: () => {},
  positions: [],
  loading: false,
  revalidateFilteredExpensesReport: () => {},
  revalidateFormulaList: () => {},
  filteredExpensesReportLoading: false,
  showPastExpenses: false,
  setShowPastExpenses: () => {},
  categories: {} as SelectState,
  setCategories: () => {},
  types: {} as SelectState,
  setTypes: () => {},
  searchState: {} as Types.InputState,
  setSearchState: () => {},
  formulaList: [],
  setFormulaList: () => {},
  variables: {},
  setVariables: () => {},
  displayFormulaError: {
    isDisplayed: false,
    message: '',
  },
  setDisplayFormulaError: () => {},
  formula: [],
  setFormula: () => {},
  updatedFormula: [],
  setUpdatedFormula: () => {},
  formulaValue: '',
  setFormulaValue: () => {},
  connectedIntegrations: [],
  autoGeneratedExpenses: false,
  setAutoGeneratedExpenses: () => {},
  proposedExpenses: [],
  setProposedExpenses: () => {},
  recommendedExpenses: [],
  setRecommendedExpenses: () => {},
  desiredCreationStatus: 'created',
  setDesiredCreationStatus: () => {},
  expenseIntegrationMappings: [],
  setExpenseIntegrationMappings: () => {},
  formState: {} as IExpenseFormState,
  isProposedExpenseLoadingDictionary: {},
  setIsProposedExpenseLoadingDictionary: () => {},
  expensesDictionary: {},
};

export const ExpensesPageContext = createContext<IExpensesPageContext>(initialContext);

export const ExpensesPageContextProvider = ({ children }: IProps): React.ReactElement => {
  const {
    loading,
    initialExpenses,
    initialReports,
    initialPositions,
    revalidate,
    revalidateFilteredExpensesReport,
    revalidateFormulaList,
    filteredExpensesReportLoading,
    formulaList: formulas,
    connectedIntegrations,
    initialProposedExpenses,
    initialRecommendedExpenses,
  } = useExpensesData();
  const departments = useSelector((state: State) => state.organization.departments);
  const organization = useSelector((state: State) => state.organization);
  const [expenses, setExpenses] = useState<IExpense[]>([]);
  const [proposedExpenses, setProposedExpenses] = useState<IExpense[]>([]);
  const [recommendedExpenses, setRecommendedExpenses] = useState<IExpense[]>([]);
  const [formulaList, setFormulaList] = useState<IFormula[]>([]);
  const [autoGeneratedExpenses, setAutoGeneratedExpenses] = useState<boolean>(false);
  const [desiredCreationStatus, setDesiredCreationStatus] = useState<string>('created');
  const [expenseIntegrationMappings, setExpenseIntegrationMappings] = useState<IIntegrationMapping[]>([]);
  const [isProposedExpenseLoadingDictionary, setIsProposedExpenseLoadingDictionary] = useState<Record<string, boolean>>(
    {},
  );
  const formState = useExpenseFormState();
  const expensesDictionary = useMemo(() => {
    return expenses.reduce(
      (acc, expense) => {
        acc[expense.expenseUuid] = expense;
        return acc;
      },
      {} as Record<string, IExpense>,
    );
  }, [expenses]);

  useEffect(() => {
    formState.resetFormState();
  }, [autoGeneratedExpenses]);

  useMemo(() => {
    setExpenses(initialExpenses);
  }, [initialExpenses]);

  useMemo(() => {
    setFormulaList(formulas);
  }, [formulas]);

  const reports = useMemo(() => {
    return initialReports;
  }, [initialReports]);

  const positions = useMemo(() => {
    return initialPositions;
  }, [initialPositions]);

  useMemo(() => {
    setProposedExpenses(initialProposedExpenses);
  }, [initialProposedExpenses]);

  useMemo(() => {
    setRecommendedExpenses(initialRecommendedExpenses);
  }, [initialRecommendedExpenses]);

  const fetchIntegrationMappings = async (): Promise<void> => {
    const integrationMappings = await api.integrations.listMappings({
      organizationUuid: organization.uuid,
      integrationUuid: connectedIntegrations[0].uuid,
      scope: IFormulaTypeEnum.Expense,
    });
    setExpenseIntegrationMappings(integrationMappings);
  };

  useEffect(() => {
    if (connectedIntegrations.length) {
      fetchIntegrationMappings();
    }
  }, [connectedIntegrations, organization.uuid, expenses, proposedExpenses]);

  useEffect(() => {
    setIsProposedExpenseLoadingDictionary(
      proposedExpenses.reduce((acc: Record<string, boolean>, expense) => {
        acc[expense.expenseUuid] = false;
        return acc;
      }, {}),
    );
  }, [proposedExpenses]);

  const [expenseModal, setExpenseModal] = React.useState<boolean>(false);
  const [expenseUuid, setExpenseUuid] = React.useState<
    { uuid: string; expenseUuid: string; type: 'edit' | 'create'; desiredCreationStatus?: string } | undefined
  >(undefined);
  const [discontinueExpense, setDiscontinueExpense] = React.useState<
    { uuid: string; expenseUuid: string; startDate: string; expenseTitle?: string } | undefined
  >(undefined);
  const [deleteExpense, setDeleteExpense] = React.useState<
    | {
        uuid: string;
        expenseUuid: string;
        expenseTitle?: string;
      }
    | undefined
  >(undefined);
  const [lockedDate, setLockedDate] = useState<IStringDate | undefined>(undefined);
  const [searchByName, setSearchByName] = useState<string>('');
  const [showPastExpenses, setShowPastExpenses] = useState<boolean>(false);

  const [categories, setCategories] = useSelect({
    options: [
      { value: 'all', label: 'All Categories' },
      { value: 'People & Facilities', label: 'People & Facilities' },
      { value: 'Cost of Goods Sold', label: 'Cost of Goods Sold' },
      { value: 'Software', label: 'Software' },
      { value: 'Marketing', label: 'Marketing' },
      { value: 'Other', label: 'Other' },
    ],
  });
  const [types, setTypes] = useSelect({
    options: [
      { value: 'all', label: 'All Frequencies' },
      { value: 'oneTime', label: 'One Time' },
      { value: 'monthly', label: 'Monthly' },
      { value: 'quarterly', label: 'Quarterly' },
      { value: 'annually', label: 'Annually' },
      { value: 'onHire', label: 'On Hire' },
    ],
  });
  const [searchState, setSearchState] = useInput({ validation: /.*/ });
  const [variables, setVariables] = useState<IRecipeVariables>({});
  const [displayFormulaError, setDisplayFormulaError] = useState<{
    isDisplayed: boolean;
    message: string;
  }>({
    isDisplayed: false,
    message: '',
  });
  const [formula, setFormula] = useState<IUpdatedFormula[]>([]);
  const [updatedFormula, setUpdatedFormula] = useState<IUpdatedFormula[]>([]);
  const [formulaValue, setFormulaValue] = useState<string>('');

  const departmentDict = departments.reduce((acc, department) => {
    acc[department.uuid] = department;
    return acc;
  }, {} as IDepartmentDict);

  return (
    <ExpensesPageContext.Provider
      value={{
        expenses,
        setExpenses,
        departmentDict,
        expenseUuid,
        setExpenseUuid,
        expenseModal,
        setExpenseModal,
        discontinueExpense,
        setDiscontinueExpense,
        deleteExpense,
        setDeleteExpense,
        reload: revalidate,
        reports,
        lockedDate,
        setLockedDate,
        searchByName,
        setSearchByName,
        positions,
        loading,
        revalidateFilteredExpensesReport,
        revalidateFormulaList,
        filteredExpensesReportLoading,
        showPastExpenses,
        setShowPastExpenses,
        categories,
        setCategories,
        types,
        setTypes,
        searchState,
        setSearchState,
        formulaList,
        setFormulaList,
        variables,
        setVariables,
        displayFormulaError,
        setDisplayFormulaError,
        formula,
        setFormula,
        updatedFormula,
        setUpdatedFormula,
        formulaValue,
        setFormulaValue,
        connectedIntegrations,
        autoGeneratedExpenses,
        setAutoGeneratedExpenses,
        proposedExpenses,
        setProposedExpenses,
        recommendedExpenses,
        setRecommendedExpenses,
        desiredCreationStatus,
        setDesiredCreationStatus,
        expenseIntegrationMappings,
        setExpenseIntegrationMappings,
        formState,
        isProposedExpenseLoadingDictionary,
        setIsProposedExpenseLoadingDictionary,
        expensesDictionary,
      }}
    >
      {children}
    </ExpensesPageContext.Provider>
  );
};
