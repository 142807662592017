import React, { useContext } from 'react';
import { IExpense } from '../types';
import Typography from '~/components/Typography';
import formatPercent from '~/utils/formatPercent';
import formatCurrency from '~/utils/formatCurrency';
import Button from '~/components/Button';
import { ExpensesPageContext } from '~/pages/ExpensesDeprecated/context/ExpensesContext';
import { convertDepartmentUuidsToDisplayName } from '~/pages/ExpensesDeprecated/utils/convertDepartmentUuidsToDisplayName';
import Divider from '~/components/Divider';

const TYPE_DISPLAY_MAP = {
  setCost: 'Set Cost',
  headcountFixed: 'Amount per Employee',
  headcountPercentCompensation: 'Percent of Salary',
  custom: 'Custom',
};

const FREQUENCY_DISPLAY_MAP = {
  monthly: 'Monthly',
  oneTime: 'One Time',
  onHire: 'On Hire',
  quarterly: 'Quarterly',
  annually: 'Annually',
};

const RecommendedExpenseCard = ({ expense }: { expense: IExpense }): React.ReactNode => {
  const { departmentDict, setExpenseUuid, setExpenseModal, setDesiredCreationStatus, formState } =
    useContext(ExpensesPageContext);
  const getAcronym = (name: string): string => {
    const words = name.split(' ');
    if (words.length > 1) {
      return `${words[0][0]}${words[words.length - 1][0]}`.toUpperCase();
    }
    return name[0].toUpperCase();
  };
  return (
    <div className="flex flex-col border border-neutral-50 pt-4 pb-3 rounded-lg bg-neutral-15">
      <div className="flex flex-row w-full px-6">
        <div className="max-h-[46px] max-w-[46px] min-h-[46px] min-w-[46px] text-[16.5px] text-neutral-200 bg-neutral-15 border border-neutral-50 rounded-full flex items-center justify-center mr-4">
          {getAcronym(expense.name)}
        </div>
        <div className="flex flex-row w-full">
          <div className="flex flex-col w-1/3">
            <Typography weight="semibold" color="empty">
              {expense.name}
            </Typography>
            <Typography color="empty">{TYPE_DISPLAY_MAP[expense.context.driver]}</Typography>
          </div>
          <div className="flex flex-col w-1/6">
            <Typography color="empty">Amount</Typography>
            {expense.context.amount ? (
              <Typography color="empty">
                {expense.context.driver === 'headcountPercentCompensation'
                  ? formatPercent({ value: expense.context.amount / 100 })
                  : formatCurrency(expense.context.amount)}
              </Typography>
            ) : (
              <Typography color="empty">-</Typography>
            )}
          </div>
          <div className="flex flex-col w-1/6">
            <Typography color="empty">Frequency</Typography>
            <Typography color="empty">{FREQUENCY_DISPLAY_MAP[expense.context.frequency]}</Typography>
          </div>
          <div className="flex flex-col w-1/6">
            <Typography color="empty">Departments</Typography>
            <Typography color="empty">
              {expense.context.departments[0] === 'ALL'
                ? 'All'
                : convertDepartmentUuidsToDisplayName({
                    departmentUuids: expense.context.departments,
                    departmentDict,
                  })}
            </Typography>
          </div>
          <Button
            onClick={() => {
              setExpenseUuid({
                uuid: expense.uuid,
                expenseUuid: expense.expenseUuid,
                type: 'edit',
              });
              setDesiredCreationStatus('proposed');
              setExpenseModal(true);
              formState.setCategory((prev) => ({
                ...prev,
                disabled: true,
              }));
            }}
            fill="clear"
            className="!w-fit !px-2"
          >
            Add
          </Button>
        </div>
      </div>
      <Divider className="mt-4 mb-3" />
      <div className="px-6">
        <Typography color="empty" className="italic">
          {`This expense doesn't link to actuals, so it can't be reconciled, but we can still include it in your forecast`}
        </Typography>
      </div>
    </div>
  );
};

export default RecommendedExpenseCard;
