import React, { ReactElement, useContext, useEffect, useState } from 'react';
import Button from '~/components/Button';
import Typography from '~/components/Typography';
import { useInput } from '~/components/Input/InputWrapper';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '~/store';
import request from '~/utils/request';
import { ZPositionDetails } from '~/pages/Headcount/entity/schemas';
import { z } from 'zod';
import { HeadcountContext } from '~/pages/Headcount/context/HeadcountContext';
import { useBlocker } from 'react-router-dom';
import ConfirmPrompt from '~/components/ConfirmPrompt';
import { CENTS_PER_DOLLAR } from '~/utils/constants/currency';
import { StatusCodes } from 'http-status-codes';
import toast from 'react-hot-toast';
import { fetchSpecificPositions } from '../../utils/fetchSpecificPositions';
import { isSameDay } from 'date-fns';
import logger from '~/utils/logger';
import { handleCreateScenario } from '~/utils/handleCreateScenario';
import { IAPIResponse } from '~/utils/types';
import { updateScenarioLoadingState } from '~/store/scenarioSlice';
import { IAdditionalCompensationValue } from '../../entity/types';
import { useSelect } from '~/components/Select';
import AdditionalCompensation, { quarterlyEffectivePatterns, monthOptions } from './AdditionalCompensation';
import NewAdditionalCompensationForm from './NewAdditionalCompensationForm';
import usePeriodPicker from '~/components/PeriodPicker/usePeriodPicker';

const ZUpdatePositionResponse = z.object({
  data: z.object({
    data: ZPositionDetails,
  }),
  status: z.number(),
});

interface Props {
  onClose: () => void;
  onBack: () => void;
  positionUuid: string | null;
  positionEffectiveAt: string | null;
  changeHistory: IAdditionalCompensationValue[];
  createScenario?: boolean;
  additionalCompensationType: 'bonuses' | 'commissions';
}

const ChangeAdditionalCompensation = ({
  onClose,
  onBack,
  positionUuid,
  positionEffectiveAt,
  changeHistory,
  createScenario,
  additionalCompensationType,
}: Props): ReactElement => {
  const { renderedPositions, setRenderedPositions, positions, setPositions, setPositionDataDict } =
    useContext(HeadcountContext);
  const dispatch = useDispatch();

  const [showBlocker, setShowBlocker] = useState(false);
  const { uuid: organizationUuid } = useSelector((state: State) => state.organization);
  const { activeScenarioUuid } = useSelector((state: State) => state.scenario);
  const [addNewAdditionalCompensation, setAddNewAdditionalCompensation] = useState(false);

  const [currentHireDate, setCurrentHireDate] = useState<string>('');

  const [effectiveDate, setEffectiveDate] = usePeriodPicker({
    startDate: null,
    endDate: null,
    mode: 'month',
    errorMessage: 'Effective date required',
  });

  const hasInitial =
    changeHistory.length && changeHistory.some((compensation) => compensation.effectiveAt === 'onHire');

  let createInitialAdditionalComp: IAdditionalCompensationValue[] = [];
  if (!hasInitial) {
    createInitialAdditionalComp = [
      {
        value: 0,
        frequency: 'monthly',
        effectiveAt: 'onHire',
        effectiveMonthPattern: null,
      },
    ];
  }
  const [intialAdditionalCompensation, setIntialAdditionalCompensation] =
    useState<IAdditionalCompensationValue[]>(createInitialAdditionalComp);

  const [additionalCompensation, setAdditionalCompensation] = useInput({
    // eslint-disable-next-line security/detect-unsafe-regex
    validation: /\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
    errorMessage: 'Amount is required',
  });
  const [additionalCompensationFrequency, setAdditionalCompensationFrequency] = useSelect({
    options: [
      { label: 'Monthly', value: 'monthly' },
      { label: 'Quarterly', value: 'quarterly' },
      { label: 'Annually', value: 'annually' },
    ],
    selected: { label: 'Monthly', value: 'monthly' },
  });
  const [additionalCompensationEffectiveMonthPattern, setAdditionalCompensationEffectiveMonthPattern] = useSelect({
    options: quarterlyEffectivePatterns,
    selected: { label: 'Jan, Apr, Jul, Oct', value: '1' },
  });
  const [updatedAdditionalCompensations, setUpdatedAdditionalCompensations] =
    useState<(IAdditionalCompensationValue & { updatedEffectiveDate?: string })[]>(changeHistory);
  const [additionalCompensationsRequireUpdate, setAdditionalCompensationsRequireUpdate] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (additionalCompensationFrequency.selected?.value === 'monthly') {
      setAdditionalCompensationEffectiveMonthPattern({
        ...additionalCompensationEffectiveMonthPattern,
        options: quarterlyEffectivePatterns,
        selected: undefined,
        disabled: true,
      });
    } else if (additionalCompensationFrequency.selected?.value === 'quarterly') {
      setAdditionalCompensationEffectiveMonthPattern({
        ...additionalCompensationEffectiveMonthPattern,
        options: quarterlyEffectivePatterns,
        selected: {
          label: 'Jan, Apr, Jul, Oct',
          value: '1',
        },
        disabled: false,
      });
    } else if (additionalCompensationFrequency.selected?.value === 'annually') {
      setAdditionalCompensationEffectiveMonthPattern({
        ...additionalCompensationEffectiveMonthPattern,
        options: monthOptions,
        selected: {
          label: 'January',
          value: '1',
        },
        disabled: false,
      });
    }
  }, [additionalCompensationFrequency.selected?.value]);

  const resetAdditionalCompensationForm = (): void => {
    if (positionEffectiveAt) {
      setEffectiveDate({
        ...effectiveDate,
        startDate: null,
        endDate: null,
        mode: 'month',
        valid: true,
      });
    }
    setAdditionalCompensation({
      ...additionalCompensation,
      value: '',
      valid: false,
      pristine: true,
      touched: false,
      disabled: false,
    });
  };

  useEffect(() => {
    setUpdatedAdditionalCompensations(changeHistory);
  }, [changeHistory]);

  const onCloseModal = (): void => {
    onClose();
    setTimeout(() => {
      resetAdditionalCompensationForm();
      setAddNewAdditionalCompensation(false);
      setUpdatedAdditionalCompensations(changeHistory);
      setAdditionalCompensationsRequireUpdate(false);
    }, 300);
  };

  useEffect(() => {
    if (renderedPositions.length) {
      const activePosition = renderedPositions.find((position) => position.positionUuid === positionUuid);
      if (activePosition) {
        setCurrentHireDate(activePosition.hireDate);
        setEffectiveDate({
          ...effectiveDate,
        });
      }
    }
  }, [renderedPositions.length]);

  const handleCreatePayChange = async (): Promise<void> => {
    if (!positionUuid) return;
    try {
      setIsLoading(true);
      let additionalCompensationsForUpdate = [...updatedAdditionalCompensations];
      if (intialAdditionalCompensation.length && intialAdditionalCompensation[0].value !== 0) {
        additionalCompensationsForUpdate.push(...intialAdditionalCompensation);
      }
      if (addNewAdditionalCompensation && effectiveDate.startDate) {
        // Remove an existing pay rate if it is for the same effective date
        const isOverrideToInitialAdditionalCompensation = isSameDay(currentHireDate, effectiveDate.startDate);
        additionalCompensationsForUpdate = additionalCompensationsForUpdate.filter(
          (additionalCompensationForUpdate) => {
            return (
              !isOverrideToInitialAdditionalCompensation &&
              !isSameDay(additionalCompensationForUpdate.effectiveAt, effectiveDate.startDate as string)
            );
          },
        );
        // Add the new pay rate
        additionalCompensationsForUpdate.push({
          value: Number(additionalCompensation.value) * CENTS_PER_DOLLAR,
          frequency: additionalCompensationFrequency.selected?.value ?? 'monthly',
          effectiveMonthPattern:
            additionalCompensationFrequency.selected?.value !== 'monthly'
              ? Number(additionalCompensationEffectiveMonthPattern.selected?.value)
              : null,
          effectiveAt: isOverrideToInitialAdditionalCompensation ? 'onHire' : effectiveDate.startDate,
        });
      }
      if (additionalCompensationsRequireUpdate || addNewAdditionalCompensation) {
        let updateBody = {};
        if (additionalCompensationType === 'bonuses') {
          updateBody = {
            bonuses: additionalCompensationsForUpdate.map((additionalComp) => {
              if (additionalComp.updatedEffectiveDate) {
                const updatedAdditionalComp = {
                  ...additionalComp,
                  effectiveAt: additionalComp.updatedEffectiveDate,
                };
                delete updatedAdditionalComp.updatedEffectiveDate;
                return updatedAdditionalComp;
              }
              return additionalComp;
            }),
          };
        } else {
          updateBody = {
            commissions: additionalCompensationsForUpdate.map((additionalComp) => {
              if (additionalComp.updatedEffectiveDate) {
                const updatedAdditionalComp = {
                  ...additionalComp,
                  effectiveAt: additionalComp.updatedEffectiveDate,
                };
                delete updatedAdditionalComp.updatedEffectiveDate;
                return updatedAdditionalComp;
              }
              return additionalComp;
            }),
          };
        }
        const newAdditionalCompensationResponse = (await request({
          method: 'PATCH',
          url: `/organizations/${organizationUuid}/positions/${positionUuid}`,
          params: {
            scenarioUuid: activeScenarioUuid ?? undefined,
            createScenario: activeScenarioUuid ? false : createScenario,
            awaitCalculations: activeScenarioUuid ? true : false,
          },
          body: updateBody,
        })) as IAPIResponse;

        const parsedResponse = ZUpdatePositionResponse.parse(newAdditionalCompensationResponse);

        if (parsedResponse.status === StatusCodes.CREATED && renderedPositions.length) {
          await handleCreateScenario({
            response: newAdditionalCompensationResponse,
          });
          const [updatedPosition] = await fetchSpecificPositions({
            positionUuids: [positionUuid],
            organizationUuid,
            scenarioUuid: activeScenarioUuid ?? undefined,
          });
          const updateIndex = renderedPositions.findIndex((position) => position.positionUuid === positionUuid);

          const updatedPositionIndex = positions?.findIndex((position) => position.positionUuid === positionUuid);

          if (updatedPositionIndex !== undefined && updatedPositionIndex !== -1 && positions) {
            setPositions((prev) =>
              [...prev].map((position) =>
                position.positionUuid === updatedPosition.positionUuid
                  ? { ...updatedPosition, orderedDate: position.orderedDate }
                  : position,
              ),
            );
          }
          if (updateIndex !== -1) {
            setRenderedPositions((prev) =>
              [...prev].map((position) =>
                position.positionUuid === updatedPosition.positionUuid
                  ? { ...updatedPosition, orderedDate: position.orderedDate }
                  : position,
              ),
            );
          }
          setPositionDataDict((prev) => ({
            ...prev,
            [positionUuid]: {
              employeeName: updatedPosition.employeeName ?? '',
              title: updatedPosition.title,
              department: updatedPosition.currentDepartment,
              hireDate: updatedPosition.hireDate,
              termDate: updatedPosition.termDate,
              fullyBurdenedCost: updatedPosition.fullyBurdenedCost,
              currentPayRate: updatedPosition.currentPayRate,
              employmentType: updatedPosition.employmentType,
              currentBonus: updatedPosition.currentBonus,
              currentCommission: updatedPosition.currentCommission,
              bonuses: updatedPosition.bonuses,
              commissions: updatedPosition.commissions,
            },
          }));
          onCloseModal();
        } else {
          toast.error('Failed to make changes');
        }
      }
    } catch (error) {
      if (error instanceof Error) {
        logger.error(error);
      }
      toast.error('Failed to make changes');
    } finally {
      dispatch(updateScenarioLoadingState('idle'));
      setIsLoading(false);
    }
  };

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      additionalCompensationsRequireUpdate && currentLocation.pathname !== nextLocation.pathname,
  );

  const compensationTypeText = additionalCompensationType === 'bonuses' ? 'Bonus' : 'Commission';

  return (
    <div className="flex flex-col w-full">
      <ConfirmPrompt
        isOpen={blocker.state === 'blocked' || showBlocker}
        onClose={() => (blocker.state === 'blocked' ? blocker.reset() : setShowBlocker(false))}
        onConfirm={() => {
          if (blocker.state === 'blocked') {
            blocker.proceed();
          } else {
            setShowBlocker(false);
            onCloseModal();
          }
        }}
        title="Changes Will Not be Saved"
        message="Any changes made will not be saved. Are you sure you want to Cancel"
        cancelButtonText="Continue Editing"
        confirmButtonText="Yes, Cancel"
      />
      <div className="flex flex-col w-full" data-testid="pay-change-modal">
        <div className="flex flex-col gap-2">
          <div className="flex flex-row mt-2 gap-2">
            <Typography color="secondary" className="w-[23%]">
              {compensationTypeText}
            </Typography>
            <Typography color="secondary" className="w-[23%]">
              Frequency
            </Typography>
            <Typography color="secondary" className="w-[23%]">
              Timing
            </Typography>
            <Typography color="secondary" className="w-[23%]">
              Effective
            </Typography>
          </div>
          <div data-testid="pay-history-list" className="flex flex-col gap-2">
            {!!intialAdditionalCompensation.length && (
              <AdditionalCompensation
                key="initial-compensation"
                effectiveAt="onHire"
                hireDate={currentHireDate}
                currentValue={0}
                currentFrequency="monthly"
                currentTiming={null}
                updatedAdditionalCompensations={intialAdditionalCompensation}
                setUpdatedAdditionalCompensations={setIntialAdditionalCompensation}
                additionalCompensationsRequireUpdate={additionalCompensationsRequireUpdate}
                setAdditionalCompensationsRequireUpdate={setAdditionalCompensationsRequireUpdate}
              />
            )}
            {currentHireDate &&
              updatedAdditionalCompensations
                .sort((versionA, versionB) => {
                  if (versionA.effectiveAt === 'onHire') return -1;
                  if (versionB.effectiveAt === 'onHire') return 1;
                  return new Date(versionA.effectiveAt).getTime() - new Date(versionB.effectiveAt).getTime();
                })
                .map((version) => (
                  <AdditionalCompensation
                    key={`${positionUuid}-${version.effectiveAt}`}
                    effectiveAt={version.effectiveAt}
                    hireDate={currentHireDate}
                    currentValue={version.value}
                    currentFrequency={version.frequency}
                    currentTiming={version.effectiveMonthPattern}
                    updatedAdditionalCompensations={updatedAdditionalCompensations}
                    setUpdatedAdditionalCompensations={setUpdatedAdditionalCompensations}
                    additionalCompensationsRequireUpdate={additionalCompensationsRequireUpdate}
                    setAdditionalCompensationsRequireUpdate={setAdditionalCompensationsRequireUpdate}
                  />
                ))}
          </div>
          <NewAdditionalCompensationForm
            addNewAdditionalCompensation={addNewAdditionalCompensation}
            hireDate={currentHireDate}
            setAddNewAdditionalCompensation={setAddNewAdditionalCompensation}
            effectiveDate={effectiveDate}
            setEffectiveDate={setEffectiveDate}
            additionalCompensation={additionalCompensation}
            setAdditionalCompensation={setAdditionalCompensation}
            frequency={additionalCompensationFrequency}
            setFrequency={setAdditionalCompensationFrequency}
            timing={additionalCompensationEffectiveMonthPattern}
            setTiming={setAdditionalCompensationEffectiveMonthPattern}
            onCancel={() => {
              resetAdditionalCompensationForm();
              setAddNewAdditionalCompensation(false);
            }}
          />
        </div>
        <div className="flex flex-row w-full justify-between mt-2">
          {!additionalCompensationsRequireUpdate && !addNewAdditionalCompensation ? (
            <Button id="back-to-compensation" onClick={onBack} fill="clear" className="!w-fit !px-0">
              Back
            </Button>
          ) : (
            <Button
              id="cancel-pay-change"
              onClick={() => (additionalCompensationsRequireUpdate ? setShowBlocker(true) : onCloseModal())}
              fill="clear"
              className="!w-fit !px-0"
            >
              Cancel
            </Button>
          )}
          <Button
            id="save-pay-change"
            className="!w-auto"
            onClick={() => {
              if (addNewAdditionalCompensation) {
                if (effectiveDate.valid && additionalCompensation.valid && effectiveDate.startDate) {
                  handleCreatePayChange();
                } else {
                  setEffectiveDate((prev) => ({ ...prev, pristine: false, touched: true, valid: !!prev.startDate }));
                  setAdditionalCompensation({ ...additionalCompensation, pristine: false, touched: true });
                }
              } else {
                handleCreatePayChange();
              }
            }}
            disabled={!additionalCompensationsRequireUpdate && !addNewAdditionalCompensation}
            loading={isLoading}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ChangeAdditionalCompensation;
