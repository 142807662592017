import React, { useMemo, useState } from 'react';
import Typography from '~/components/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '~/store';
import { addScenarioUuid, removeScenarioUuid, updateScenarioList } from '~/store/scenarioSlice';
import { useDatePicker } from '~/components/DatePicker';
import { formatInTimeZone } from 'date-fns-tz';
import Datepicker from 'react-tailwindcss-datepicker';
import { usePopper } from 'react-popper';
import request from '~/utils/request';
import { IAPIResponse } from '~/utils/types';
import { IScenario } from '~/pages/Dashboard/entity/types';
import { StatusCodes } from 'http-status-codes';

const BaseModelAsOfItem = (): React.ReactNode => {
  const dispatch = useDispatch();
  const { selectedScenarioUuids, scenarios } = useSelector((state: State) => state.scenario);
  const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null);
  const organizationUuid = useSelector((state: State) => state.organization.uuid);

  const temporaryStaticScenarioUuid = useMemo(() => {
    return selectedScenarioUuids.find((uuid) => {
      const scenario = scenarios.find((s) => s.uuid === uuid);
      return scenario && scenario.type === 'temporaryStatic';
    });
  }, [selectedScenarioUuids, scenarios]);

  const initialDate = useMemo(() => {
    if (temporaryStaticScenarioUuid) {
      const scenario = scenarios.find((s) => s.uuid === temporaryStaticScenarioUuid);
      return scenario ? scenario.effectiveAt : undefined;
    }
    return null;
  }, [temporaryStaticScenarioUuid, selectedScenarioUuids, scenarios]);

  const [baseModelAsOf, setBaseModelAsOf] = useDatePicker({
    value: {
      startDate: initialDate ?? null,
      endDate: initialDate ?? null,
    },
  });
  const [isHovered, setIsHovered] = useState(false);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'right',
  });

  const shouldBeDisabled = useMemo((): boolean => {
    if (selectedScenarioUuids.length < 3) {
      return false;
    } else if (temporaryStaticScenarioUuid && selectedScenarioUuids.includes(temporaryStaticScenarioUuid)) {
      return false;
    } else {
      return true;
    }
  }, [baseModelAsOf.value.startDate, selectedScenarioUuids, temporaryStaticScenarioUuid]);

  const baseModelTag = (
    <div className="bg-orange-25 bg-opacity-70 rounded-md py-1 !h-fit px-2 flex items-center justify-center">
      <Typography size="2xs" color="orange">
        Base Model
      </Typography>
    </div>
  );

  const handleCreateTemporaryStaticScenario = async ({ effectiveDate }: { effectiveDate: string }): Promise<void> => {
    if (!effectiveDate) return;

    const response = (await request({
      method: 'POST',
      url: `/organizations/${organizationUuid}/scenarios`,
      body: {
        type: 'temporaryStatic',
        changeDescription: `Base Model As Of ${formatInTimeZone(effectiveDate, 'UTC', 'MM-dd-yyyy')}`,
        asOfDate: effectiveDate,
      },
    })) as IAPIResponse<IScenario>;

    if (response.status === StatusCodes.CREATED) {
      dispatch(addScenarioUuid(response.data.data.uuid));
      dispatch(updateScenarioList([...scenarios.filter((s) => s.type !== 'temporaryStatic'), response.data.data]));
    }
  };

  const handleValueChange = async ({
    startDate,
    endDate,
    temporaryStaticScenarioUuid,
  }: {
    startDate: string | null;
    endDate: string | null;
    temporaryStaticScenarioUuid?: string;
  }): Promise<void> => {
    if (!startDate) {
      dispatch(removeScenarioUuid(temporaryStaticScenarioUuid));
      dispatch(updateScenarioList([...scenarios.filter((s) => s.type !== 'temporaryStatic')]));
      setBaseModelAsOf((prevState) => {
        return {
          ...prevState,
          value: {
            startDate: null,
            endDate: null,
          },
        };
      });
      return;
    }
    if (temporaryStaticScenarioUuid) {
      dispatch(removeScenarioUuid(temporaryStaticScenarioUuid));
    }
    setBaseModelAsOf((prevState) => {
      return {
        ...prevState,
        value: {
          startDate: startDate,
          endDate: endDate,
        },
        valid: true,
        pristine: false,
        touched: true,
      };
    });
    await handleCreateTemporaryStaticScenario({ effectiveDate: startDate });
  };

  return (
    <div
      className={`w-full flex px-6 justify-between items-center py-1.5 group rounded-md cursor-pointer ${
        shouldBeDisabled ? '' : isHovered ? 'bg-neutral-25' : 'hover:bg-neutral-25'
      }`}
      key={temporaryStaticScenarioUuid}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="flex flex-row gap-2">
        {baseModelTag}
        <div
          ref={setReferenceElement}
          className={`flex flex-row justify-start align-middle items-center ${shouldBeDisabled ? 'cursor-default' : ' cursor-pointer '}`}
        >
          <Typography
            color={shouldBeDisabled ? 'empty' : 'primary'}
            size="sm"
            className={`h-[26px] flex items-center mr-2`}
          >
            Past Model
          </Typography>
          <div ref={setPopperElement} style={{ ...styles.popper, zIndex: 50 }} {...attributes.popper}>
            <Datepicker
              primaryColor={'sky'}
              inputId="base-model-as-of"
              asSingle={true}
              value={{
                startDate: baseModelAsOf.value.startDate
                  ? formatInTimeZone(new Date(baseModelAsOf.value.startDate), 'UTC', 'MM/dd/yyyy')
                  : null,
                endDate: baseModelAsOf.value.endDate
                  ? formatInTimeZone(new Date(baseModelAsOf.value.endDate), 'UTC', 'MM/dd/yyyy')
                  : null,
              }}
              useRange={false}
              startFrom={baseModelAsOf.value.startDate ? new Date(baseModelAsOf.value.startDate) : null}
              displayFormat={'MM/DD/YYYY'}
              onChange={(newValue) => {
                const startDate = newValue?.startDate;
                const endDate = newValue?.endDate;
                handleValueChange({
                  startDate: startDate ? startDate.toString() : null,
                  endDate: endDate ? endDate.toString() : null,
                  temporaryStaticScenarioUuid: temporaryStaticScenarioUuid,
                });
              }}
              disabled={shouldBeDisabled}
              popoverDirection="down"
              inputClassName={`rounded w-full cursor-pointer max-w-[140px] max-h-[34px] border border-neutral-50 p-[0.57rem] h-[42px] focus:outline-none focus-visible:border-green-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-green-300`}
              readOnly
              placeholder="Select Date"
            />
          </div>
        </div>
      </div>
      <label
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="inline-flex items-center cursor-pointer"
      >
        <input
          data-testid={`scenario-input-base-model-as-of`}
          value={`scenario-input-base-model-as-of`}
          id={`scenario-input-base-model-as-of`}
          type="checkbox"
          name="role"
          checked={!!temporaryStaticScenarioUuid}
          className="sr-only peer"
          disabled={shouldBeDisabled}
          onChange={() => {
            if (!shouldBeDisabled) {
              dispatch(removeScenarioUuid(temporaryStaticScenarioUuid));
              dispatch(updateScenarioList([...scenarios.filter((s) => s.type !== 'temporaryStatic')]));
              setBaseModelAsOf((prevState) => {
                return {
                  ...prevState,
                  value: {
                    startDate: null,
                    endDate: null,
                  },
                };
              });
            }
          }}
        />
        <div
          className={`relative w-9 h-5 bg-neutral-100 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-white after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-green-400 ${
            shouldBeDisabled ? 'opacity-75 after:bg-green-25 after:border-green-25' : ''
          }`}
        ></div>
      </label>
    </div>
  );
};

export default BaseModelAsOfItem;
